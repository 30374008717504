import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { encryptData, decryptData, BASE_URL, IMG_URL, decryptDataConvertForChat, encryptDataConvertForChat, generateChatKey } from '../API/ApiUrl';
import DOMPurify from "dompurify";
import Linkify from 'react-linkify';


const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex);
};

const LinkPreviewComponent = ({ url,text,dataclass }) => {
    // console.log('=====',url)

    const [metadata, setMetadata] = useState(null);

    useEffect(() => {
        const userToken = localStorage.getItem("UserToken");
        if (userToken) {
          axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
        }

        const fetchMetadata = async () => {
            try {

                await axios.post(BASE_URL + `get_link_preview`, {
                    url: url, 
                  })
                  .then((res) => {
                  console.log(res.data.Status)
                  try {
                    if(res.data.Status != 0){
                        setMetadata(res.data);
                    }else{
                        setMetadata('No Data');
                    }
                    
                  } catch (error) {
                    setMetadata('');
                  }

                });
                
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        fetchMetadata();
    }, [url]);

    if (!metadata) {
        return <div>Loading preview for {url}...</div>;
    }

    return (
        
        <div className={dataclass}>
            {metadata && 
                <>
                    <div className="row">
                    <h2 className='text-start'>{metadata?.title}</h2>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12 text-start">
                            <p>{metadata?.description}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            {metadata?.images?.length > 0 && <img style={{ height: "100px" }} className='image-box' src={metadata?.images[0]} alt="Preview" />}
                        </div>
                    </div>
                </>
            }
            <div className="row pt-2">
                <p className='text-start'>
                    <Linkify>{text}</Linkify>
                </p>
            </div>
            
        </div>
    );
};

const App = ({data,dataclass}) => {
    console.log(dataclass)
    const [dclass, setDclass] = useState(dataclass);
    const [text, setText] = useState(data);
    const urls = extractUrls(text);

    return (
        <div>
            <div >
                {urls && urls.map((url, index) => (
                    <>
                    {index == 0 &&(
                        <LinkPreviewComponent key={index} url={url} text={text} dataclass={dclass} />
                    )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default App;

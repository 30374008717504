import React, { useEffect, useState } from "react";
import "../Login/Login.css"
import { Col, Container, Form, Row } from "react-bootstrap";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify';
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import Spinner from "react-bootstrap/Spinner";
import { backgroundImage } from "../assets/base64/file";

export default function AccountSetup() {

  let navigate = useNavigate()
  let location = useLocation()

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phonenuber, setPhonenuber] = useState("");
  const [cleared, setCleared] = useState(false);
  const [isloading, setIsloading] = useState(false)


  let UToken;
  try {
    const { token } = location.state;
    UToken = token;
  } catch (e) {
    //id not found
  }

  console.log(UToken)

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    let utoken = localStorage.getItem("UserToken")
    if (utoken) {
      navigate("/homescreen")
    }
    return () => { };
  }, [cleared]);

  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const handleDateChange = (e) => {
    setDob(e.target.value);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const jsonData = {
      first_name: firstname,
      last_name: lastname,
      gender: gender,
      date_of_birth: dob,
      country_code: 91,
      phone_number: phonenuber,
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", UToken);

    const encrypt = encryptData(jsonData);
    console.log('encrypt', encrypt);

    if (!gender) {
      toast.error("Please select Male/Female");
      setTimeout(() => {
        setIsloading(false)
      }, 1000);
    } else {
      if (phonenuber == "") {
        toast.error("Please Enter Mobile Number");
        setTimeout(() => {
          setIsloading(false)
        }, 1000);
      } else {
        if (isValidPhoneNumber(phonenuber)) {
          fetch(BASE_URL + "account_setup", {
            method: 'POST',
            body: JSON.stringify({ "accountData": encrypt }),
            headers: myHeaders
          })
            .then(response => response.json())
            .then(response => {
              if (response.Status === 0) {
                console.log(response, "no")
                toast.error(response.Message);
              } else {
                toast.success(response.Message);
                const decrypt = decryptData(response.info);
                localStorage.setItem("UserToken", UToken)
                localStorage.setItem("UserId", decrypt.user_id);
                navigate("/homescreen")
              }
              setIsloading(false)
            })
            .catch((error) => {
              console.log(error);
              setIsloading(false)
            })
        }
        else {
          toast.error("Please Enter Number in Formate");
          setTimeout(() => {
            setIsloading(false)
          }, 1000);
        }
      }
    }
  }

  return (
    <>
      <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row>
          <Col lg={6} md={6} sm={12} className="m-part-1">
            <div className="lgn-rgt">
              <img src={logo} className="img-fluid img-1" alt="" />
              <img src={oneg8} className="img-fluid img-1" alt="" />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="m-part-2">
            <div className="lgn-left">
              <h5 className="lgn-head">Account Setup</h5>
              <p className="lgn-acnt">Let’s get start!</p>

              <form action="" onSubmit={HandleSubmit} >

                <div className="floating-label-input">
                  <input id="text" type="text" className='new-put' value={firstname} onChange={(e) => setFirstname(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">First Name</label>
                </div>

                <div className="floating-label-input">
                  <input id="text" type="text" className='new-put' value={lastname} onChange={(e) => setLastname(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Last Name</label>
                </div>

                <Form.Select size="lg" className="i-put-select" placeholder="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                  <option className="gen-der">Gender</option>
                  <option required value="1">Male</option>
                  <option required value="2">Female</option>
                </Form.Select>

                <div className="lgn-input-date">
                  <input type="date" id="date" placeholder="Date Of Birth" max={formattedToday} className="i-put-date" value={dob} onChange={handleDateChange} required />
                  <label for="text">
                    <span>Date Of Birth</span>
                  </label>
                </div>

                <div className="country-number">
                  <PhoneInput
                    international
                    withCountryCallingCode
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    value={phonenuber}
                    initialValueFormat="national"
                    onChange={setPhonenuber}
                    rules={{ required: true }} />
                </div>

                <button type="submit" className="bt-1 mt-5">
                  {isloading ? <Spinner className="loderImage" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : "Save"}
                </button>

              </form>

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

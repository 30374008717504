import React, { useEffect, Component, useState, useRef } from "react";
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import axios from "axios";
import QRCode from "react-qr-code";
import Modal from 'react-bootstrap/Modal';
import Sidebar from "../Sidebar/Sidebar";
import WalletSidebar from "../Wallet/WalletSidebar";
import headlogo from "../assets/img/Sidebar/Group 1000003209.png";
import "../Wallet/WalletHome.css";
import bar from "../assets/img/Sidebar/menu (1).png";
import WalletLogo from "../assets/img/Wallet/wallet-logo.png";
import ethlogo from "../assets/img/Wallet/eth-logo.png";
import bnblogo from "../assets/img/Wallet/bnb-logo.png";
import mtclogo from "../assets/img/Wallet/mtc-logo.png";
import btclogo from "../assets/img/Wallet/btc-logo.png";
import accountlogo from "../assets/img/Wallet/acc.png";
import ogo4logo from "../assets/img/Wallet/ogo4.png";
import news1 from "../assets/img/Wallet/news1.jpg";
import news2 from "../assets/img/Wallet/news2.jpg";
import news3 from "../assets/img/Wallet/news3.jpg";
import bnclogo from "../assets/img/Wallet/bcn.png";
import tusdtlogo from "../assets/img/Wallet/tether-usdt.png";
import {
  encryptData,
  decryptData,
  walletEncryptfunction,
  walletDecryptfunction,
  BASE_URL,
  IMG_URL,
} from "../API/ApiUrl";
import { useLazyGetCoinOverviewQuery } from "../apiService/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { combineSlices } from "@reduxjs/toolkit";
const super_key = "okofepdkhmwtqlusydnqzcqvkxfnumzi";

const hanldeSidebar = () => {
  document.getElementById("functionSidebar").click();
};

export default function WalletHome() {
  const walletHomeDIv = useRef(null);
  const [allAccountList, setAllAccountList] = useState("");
  const [confirmTxData, setConfirmTxData] = useState("");
  const [IsWalletCreted, setIsWalletCreted] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isTxLoader, setIsTxLoader] = useState(false);
  const [isbtnloading, setIsbtnLoading] = useState(false);


  const [mnomonic, setMnomonic] = useState();
  const [encryptedMemonic, setEncryptedMnomonic] = useState();
  const [singleAccountData, setSingleAccountData] = useState("");
  const [tokenData, setTokenData] = useState("");
  const [accountName, setAccountName] = useState("");
  const [coinName, setCoinName] = useState("");
  const [coinNameForList, setCoinNameForList] = useState("");
  const [coininAsset, setCoinAsset] = useState("");
  const [coinType, setCoinType] = useState("");
  const [coinlogo, setCoinlogo] = useState("");
  const [passwordCheckformemonic, setPasswordCheckformemonic] = useState("");
  const [privateKeyExport, setPrivateKeyExport] = useState("");
  const [MnemonicModal, setMnemonicModal] = useState("");




  const [networkName, setNetworkName] = useState("OneG8 Network");
  const [addAccount, setAddAccount] = useState("Enter Account Name");
  const [privateKey, setPrivateKey] = useState("");
  const [type, setType] = useState("eth");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [singleTxDetails, setSingleTxDetails] = useState("");
  const [watchList, setWatchList] = useState("");
  const [watchListWithMarketData, setWatchListWithMarketData] = useState("");




  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimal, setTokenDecimal] = useState("");
  const [coinBalance, setCoinBalance] = useState("");
  const [coinBalanceInUSD, setCoinBalanceInUSD] = useState("");
  const [isToken, setIsToken] = useState(false);
  const [isCallCoinApi, setIsCallCoinApi] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState("");
  const weiToEther = (wei) => (wei / 1e18).toString();

  const [toAddress, setToAddress] = useState("");
  const [toTxamount, setToTxamount] = useState("");
  const [setPassword, setSetPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [setPasswordError, setSetPasswordError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordCheck, setPasswordCheck] = useState("");
  const [coinOverviewData, setCoinOverviewData] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(
    localStorage.getItem("isPasswordCorrect") === "true"
  ); // Check local storage for password status

  const [getCoinOverview, data] = useLazyGetCoinOverviewQuery();
  console.log(data);

  // const getcoindetailsFunction = () => {
  //   alert('1')
  //     setIsCallCoinApi(true)
  // };
  useEffect(() => {
    // console.log(isCallCoinApi)
    // if (!isCallCoinApi) return;

    const fetchData = async () => {
      try {
        const res = await getCoinOverview();
        const coinData = res.data.data;

        const g8Coin = coinData.filter(coin => coin.id === "g8coin");
        const otherCoins = coinData.filter(coin => coin.id !== "g8coin");

        const reorderedCoins = [...g8Coin, ...otherCoins];


        setCoinOverviewData(reorderedCoins);
        console.log(reorderedCoins);
        // if (newData.length > 0) {
        //     setMediaData((mediaData) => [...mediaData, ...res.data.data])
        //     setHasMoreData(true);
        //     setNoData(false);
        // } else {
        //     setHasMoreData(false);
        //     setNoData(true);
        // }
        // setShowDataDiv(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error state if needed
      }
    };

    fetchData();

    // Clean up function
    return () => {
      // Cancel any ongoing requests or clean up if needed
    };
  }, []); // Run effect when parameter or page changes

  useEffect(() => {
    const userToken = localStorage.getItem("UserToken");
    if (userToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    }
    setIsPasswordSet(localStorage.getItem("is_wallet_pass_set"));
    checkPasswordIsCreatedOrNot();

    // for set password ===========
    setIsPasswordCorrect(localStorage.getItem("isPasswordCorrect") === "true");
    // Check if 30 minutes have passed since the password was set
    const thirtyMinutesInMillis = 30 * 60 * 1000; // 30 minutes in milliseconds
    const passwordSetTime = parseInt(
      localStorage.getItem("passwordSetTime"),
      10
    );
    if (Date.now() - passwordSetTime > thirtyMinutesInMillis) {
      localStorage.removeItem("isPasswordCorrect");
      localStorage.removeItem("passwordSetTime");
    }
    // end===========
  }, []);



  useEffect(() => {}, []);

  const handleClick = (ref) => {
    console.log(ref);
    // Triggering click event on the specified anchor element
    ref.current.click();
  };
  useEffect(() => {
    const hash = window.location.hash;
    // setTimeout(() => {
    if (isLoader == false) {
      if (hash == "#wallet") {
        document.getElementById("clickforwallet").click();
      }
      else if (hash == "#explorer") {
        document.getElementById("clickforexplorer").click();
      }
      else if (hash == "#news") {
        document.getElementById("clickfornews").click();
      }
      else if (hash == "#watches") {
        document.getElementById("clickforwatches").click();
      }
      else if (hash == "#overview") {
        document.getElementById("clickforoverview").click();
      }
      else if (hash == "#settings") {
        document.getElementById("clickforsettings").click();
      }else{
        document.getElementById("clickforwallet").click();
      }
    }
    // }, 2000);
  }, [isLoader]);





  const getAllAccount = (e) => {
    var networkNameLocalStorage = localStorage.getItem("networkName");
    var checkSingleAccountDataPresent =
      localStorage.getItem("singleAccountData");

    if (networkNameLocalStorage != null) {
      changeNetWork(networkNameLocalStorage);
    } else {
      changeNetWork("OneG8 Network");
      var networkNameLocalStorage = "OneG8 Network";
    }

    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/getMyAddress`, {
        network: networkNameLocalStorage,
        user_id: user_id,
      })
      .then((res) => {
        var data = res.data;
        console.log(data.success);
        if (data.success == true) {
          setAllAccountList(data.address); // for all account list set
          if (checkSingleAccountDataPresent != null) {
            singleAccountFunction(JSON.parse(checkSingleAccountDataPresent)); //for existing account set
          } else {
            //for new account data set
            setSingleAccountData(data.defaultAddressData);
            setTokenData(data.default_token_list);
            setAccountName(data.defaultAddressData.account_name);
            localStorage.setItem(
              "singleAccountData",
              JSON.stringify(data.defaultAddressData)
            );
            coinBalanceFunction(
              networkNameLocalStorage,
              data.defaultAddressData
            );
          }
          setIsWalletCreted(true);
          setIsLoader(false);

          console.log(tokenData);
          console.log(data);
          console.log(singleAccountData);
          console.log(allAccountList);
          // console.log(allAccountList.data.defaultAddressData.address)
        } else {
          if (data.msg == "wallet is not created") {
            setIsWalletCreted(false);
          } else {
            toast.error(data.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      });
  };
  function singleAccountFunction(singleData) {
    var networkNameLocalStorage = localStorage.getItem("networkName");
    console.log("networkName", networkName);

    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/singleAddressData`, {
        account_id: singleData.accountID,
        type: "eth",
        network: networkNameLocalStorage,
        user_id: user_id,
      })
      .then((res) => {
        var data = res.data;
        setSingleAccountData(data.singleAccountData);
        console.log(data.single_tokenData);
        setTokenData(data.single_tokenData);
        setAccountName(data.singleAccountData.account_name);
        console.log(data);
        console.log("tokenData", tokenData);
        console.log(singleAccountData);

        localStorage.setItem(
          "singleAccountData",
          JSON.stringify(data.singleAccountData)
        );
        // changeNetWork(networkNameLocalStorage)

        coinBalanceFunction(networkNameLocalStorage, data.singleAccountData);
      });
  }
  const coinBalanceFunction = (networkNameSet, data) => {
    if (networkNameSet == "Ethereum Network") {
      setCoinBalance(data?.eth_balance);
      setCoinBalanceInUSD(data?.eth_usd);
      setCoinName("eth");
      setCoinNameForList("eth");
      setCoinType("eth");
    }
    if (networkNameSet == "Matic Network") {
      setCoinBalance(data?.matic_balance);
      setCoinBalanceInUSD(data?.matic_usd);
      setCoinName("matic");
      setCoinNameForList("matic");

      setCoinType("matic");
    }
    if (networkNameSet == "Binance Network") {
      setCoinBalance(data?.bnb_balance);
      setCoinBalanceInUSD(data?.bnb_usd);
      setCoinName("bnb");
      setCoinNameForList("bnb");

      setCoinType("bnb");
    }
    if (networkNameSet == "Bitcoin Network") {
      setCoinBalance(data?.btc_balance);
      setCoinBalanceInUSD(data?.btc_usd);
      setCoinName("btc");
      setCoinNameForList("btc");

      setCoinType("btc");
    }
    if (networkNameSet == "OneG8 Network") {
      setCoinBalance(data?.one_balance);
      setCoinBalanceInUSD(data?.one_usd);
      setCoinName("g8");
      setCoinNameForList("g8c");

      setCoinType("g8");
    }
  };

  // useEffect(() => {
  //   changeNetWork(networkName);
  // },[networkName])

  // const changeNetWork1 = (networkNameSet) => {
  //   setNetworkName(networkNameSet);
  // }
  const tokenTabRef = useRef(null);

  const changeNetWork = (networkNameSet) => {

    // click tab uing ref
    if (tokenTabRef.current) {
        tokenTabRef.current.click();
    }

    console.log(networkNameSet);
    // alert('1')
    localStorage.setItem("networkName", networkNameSet);
    var checkSingleAccountDataPresent =
      localStorage.getItem("singleAccountData");
    setIsToken(false);
    if (networkNameSet == "Ethereum Network") {
      setCoinName("eth");
      setCoinNameForList("eth");

      setCoinType("eth");
      setCoinlogo(ethlogo);
      setNetworkName("Ethereum Network");
    }
    if (networkNameSet == "Matic Network") {
      setCoinName("matic");
      setCoinNameForList("matic");

      setCoinType("matic");
      setCoinlogo(mtclogo);

      setNetworkName("Matic Network");
    }
    if (networkNameSet == "Binance Network") {
      setCoinName("bnb");
      setCoinNameForList("bnb");

      setCoinType("bnb");
      setCoinlogo(bnblogo);

      setNetworkName("Binance Network");
    }
    if (networkNameSet == "Bitcoin Network") {
      setCoinName("btc");
      setCoinNameForList("btc");

      setCoinType("btc");
      setCoinlogo(btclogo);

      setNetworkName("Bitcoin Network");
      setType("btc");
    }
    if (networkNameSet == "OneG8 Network") {
      setCoinName("g8");
      setCoinNameForList("g8c");

      setCoinType("g8");
      setCoinlogo(ogo4logo);

      setNetworkName("OneG8 Network");
    }

    if (checkSingleAccountDataPresent != null) {
      singleAccountFunction(JSON.parse(checkSingleAccountDataPresent)); //for existing account set
    }
    // toast.success('Network Network has been set to ' + networkName, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  const handleChangeAddAccount = (event) => {
    console.log(event.target.value);
    setAddAccount(event.target.value);
  };
  const handelSubmitAddAccount = (event) => {
    console.log("Submitted value:", addAccount);

    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/createNewAccount`, {
        Account_name: addAccount,
        user_id: user_id,
      })
      .then((res) => {
        if (res.data.success == true) {
          toast.success("Account Create Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
  };
  const handelSaveEditAccountName = (e) => {
    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/changeAccountName`, {
        account_id: singleAccountData.accountID,
        account_name: accountName,
        user_id: user_id,
      })
      .then((res) => {
        if (res.data.success == true) {
          toast.success("Account Name change successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setSingleAccountData((prevData) => ({
            ...prevData,
            account_name: accountName,
          }));
          setAccountName("");
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
  };
  const handelChangeEditAccountName = (e) => {
    setAccountName(e.target.value);
    // setSingleAccountData(prevData => ({
    //   ...prevData,
    //   account_name: e.target.value
    // }));
  };
  const handelChangeImportAccountName = (e) => {
    setAccountName(e.target.value);
  };
  const handelChangeImportAccountPrivateKey = (e) => {
    setPrivateKey(e.target.value);
  };
  const handelSaveImportAccount = (e) => {
    var encryptedPrivateKey = walletEncryptfunction(privateKey, super_key);
    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/importETHAccount`, {
        account_id: singleAccountData.accountID,
        account_name: accountName,
        user_id: user_id,
        encryptedData: encryptedPrivateKey,
        type: "eth",
      })
      .then((res) => {
        if (res.data.success == true) {
          toast.success("Import Account successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
  };
  const handelChangeImportToken = (e) => {
    setTokenAddress(e.target.value);
    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/getContractDeatils`, {
        user_id: user_id,
        accountID: singleAccountData.accountID,
        coin_type: coinName,
        address: e.target.value,
        // type:"eth",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success == true) {
          setTokenSymbol(res.data.symbol);
          setTokenDecimal(res.data.decimals);
          toast.success("Fetching details successfully", {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        }
      });
  };
  const handelSaveImportToken = (e) => {
    var checkSingleAccountDataPresent =
      localStorage.getItem("singleAccountData");
    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/importToken`, {
        user_id: user_id,
        symbol: tokenSymbol,
        decimal: tokenDecimal,
        account_id: singleAccountData.accountID,
        network: networkName,
        // type:"eth",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success == true) {
          if (checkSingleAccountDataPresent != null) {
            singleAccountFunction(JSON.parse(checkSingleAccountDataPresent)); //for existing account set
          }

          toast.success("Fetching details successfully", {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        }
      });
  };
  const handelChangeToAddress = (e) => {
    setToAddress(e.target.value);
  };
  const handelChangeToAmount = (e) => {
    setToTxamount(e.target.value);
  };
  const handelConfirmTx = (e) => {
    if (toTxamount == "" || toAddress == "") {
      toast.error("All fields are mandatory.", {
        position: "top-right",
        autoClose: 5000,
        theme: "dark",
      });
      return false;
    }
    if (toAddress.length != 42 && networkName != "Bitcoin Network") {
      toast.error("The address must be 43 characters long.", {
        position: "top-right",
        autoClose: 5000,
        theme: "dark",
      });
      return false;
    }
    if (toTxamount > coinBalance) {
      toast.error(
        "The transaction amount exceeds your balance. Insufficient funds.",
        {
          position: "top-right",
          autoClose: 5000,
          theme: "dark",
        }
      );
      return false;
    }
    document.getElementById("clickforsendscreen").click();

    if (isToken == false) {
      var checkSingleAccountDataPresent =
        localStorage.getItem("singleAccountData");
      var user_id = localStorage.getItem("UserId");
      axios
        .post(BASE_URL + `api/wallet/confirmTx`, {
          user_id: user_id,
          type: type,
          address: networkName != 'Bitcoin Network' ? singleAccountData.address : singleAccountData.btc_address,
          coin_type: coinType,
          network: networkName,
          txamount: toTxamount,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success == true) {
            setConfirmTxData(res.data);
            // toast.success("Confirm Transaction", {
            //   position: "top-right",
            //   autoClose: 5000,
            //   theme: "dark",
            // });
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          }
        });
    } else {
      var user_id = localStorage.getItem("UserId");
      axios
        .post(BASE_URL + `api/wallet/confirmTxForToken`, {
          user_id: user_id,
          type: type,
          from_address: singleAccountData.address,
          tokenAddress: tokenAddress,
          symbol: coinName,
          coin_type: coinType,
          network: networkName,
          txamount: toTxamount,
          accountID: singleAccountData.accountID,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success == true) {
            setConfirmTxData(res.data);
            toast.success("Confirm Transaction", {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          }
        });
    }
  };
  const handelTxSend = (e) => {
    setIsTxLoader(true);
    var checkSingleAccountDataPresent =
      localStorage.getItem("singleAccountData");
    var user_id = localStorage.getItem("UserId");
    if (isToken == false) {
      axios
        .post(BASE_URL + `api/wallet/sendEthAmount`, {
          user_id: user_id,
          type: type,
          coin_type: coinName,
          from_address: networkName != "Bitcoin Network" ? singleAccountData.address : singleAccountData.btc_address,
          accountID: singleAccountData.accountID,
          account_From_ID: singleAccountData.accountID,
          is_import: singleAccountData.is_import,
          to_address: toAddress,
          network: networkName,
          txamount: toTxamount,
        })
        .then((res) => {
          console.log(res.data);
          setIsTxLoader(false);

          if (res.data.success == true) {
            if (checkSingleAccountDataPresent != null) {
              singleAccountFunction(JSON.parse(checkSingleAccountDataPresent)); //for existing account set
            }
            // $("#wallet").click();
            // document.getElementById("wallet").click();

            toast.success("Transaction successfully " + res.data.tx, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });

            handleClick(walletHomeDIv);
          } else {
            toast.error(res.data.error.shortMessage, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          }
        });
    } else {
      axios
        .post(BASE_URL + `api/wallet/sendingtoken`, {
          user_id: user_id,
          type: type,
          to_address: toAddress,
          tokenAddress: tokenAddress,
          token_id: tokenData.token_id,
          txamount: toTxamount,
          accountID: singleAccountData.accountID,
          is_import: singleAccountData.is_import,
          coin_type: coinType,
          network_name: networkName,
          from_address: singleAccountData.address,
          account_From_ID: singleAccountData.accountID,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success == true) {
            if (checkSingleAccountDataPresent != null) {
              singleAccountFunction(JSON.parse(checkSingleAccountDataPresent)); //for existing account set
            }
            // $("#wallet").click();
            // document.getElementById("wallet").click();

            toast.success("Transaction successfully " + res.data.tx, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });

            handleClick(walletHomeDIv);
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          }
        });
    }
  };
  const checkPasswordIsCreatedOrNot = (e) => {
    let jsonData = {
      user_id: localStorage.getItem("UserId"),
    };
    const encrypt = encryptData(jsonData);

    axios
      .post(BASE_URL + "edit_profile", {
        editData: encrypt,
      })
      .then((res) => {
        console.log("================", res.data);
        if (res.data.Status === 0) {
          console.log(res.data, "no");
        } else {
          let info = res.data.info;
          const decrypt = decryptData(info);
          console.log(decrypt);
          // alert(decrypt.wallet_password != null)
          if (decrypt.wallet_password != null) {
            getAllAccount();
          } else {
            // alert('1')

            // isLoader
            setIsPasswordSet(false);
            setIsWalletCreted(false);
            setIsLoader(false);

            // isPasswordSet
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handelCreateWalletFunction = (e) => {
    // setPassword
    var user_id = localStorage.getItem("UserId");
    axios
      .post(BASE_URL + `api/wallet/createWalletFromMnemonic`, {
        user_id: user_id,
        mnemonic: encryptedMemonic,
        password: setPassword,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success == true) {
          getAllAccount();
          toast.success("Wallet Created", {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        } else {
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        }
      });
  };

  const passwordFlagFunction = (e) => {
    console.log(
      setPassword.password == "" && setPassword.confirmPassword == ""
    );
    if (setPassword.password == "" && setPassword.confirmPassword == "") {
      setSetPasswordError((prevErrors) => ({
        ...prevErrors,
        password: "Please enter Password.",
        confirmPassword: "Please enter Confirm Password.",
      }));
    } else {
      if (
        setPasswordError.password == "" &&
        setPasswordError.confirmPassword == ""
      ) {
        axios.get(BASE_URL + `api/wallet/revealMnemonic`).then((res) => {
          var decryptMnomonic = walletDecryptfunction(
            res.data.mnemonic,
            super_key
          );
          setMnomonic(decryptMnomonic);
          setEncryptedMnomonic(res.data.mnemonic);
          setIsPasswordSet(true);
        });
      }
    }
  };
  const onSetPasswordInputChange = (e) => {
    const { name, value } = e.target;
    setSetPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setSetPasswordError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (
            setPassword.confirmPassword &&
            value !== setPassword.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = setPassword.confirmPassword
              ? ""
              : setPasswordError.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (setPassword.password && value !== setPassword.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlePasswordChange = (event) => {
    setPasswordCheck(event.target.value);
  };



  const handleAccessModule = () => {
    setIsLoader(true)
    let jsonData = {
      user_id: localStorage.getItem("UserId"),
      password: passwordCheck,
    };
    const encrypt = encryptData(jsonData);
    axios
      .post(BASE_URL + `check_password`, {
        checkData: encrypt,
      })
      .then((res) => {
        console.log(res.data.Status);
        setIsLoader(false)

        if (res.data.Status == 1) {
          setIsPasswordCorrect(true);
          localStorage.setItem("isPasswordCorrect", "true");
          localStorage.setItem("passwordSetTime", Date.now());

          toast.success("Password is correct", {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
          // handleClick(walletHomeDIv);
        } else {
          setIsPasswordCorrect(false);

          toast.error(res.data.Message, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
          });
        }
      });
    return;

    // Compare the entered password with the expected password
    if (passwordCheck === "akash") {
      setIsPasswordCorrect(true);
      localStorage.setItem("isPasswordCorrect", "true");
      localStorage.setItem("passwordSetTime", Date.now());
    } else {
      setIsPasswordCorrect(false);
      // Optionally, display an error message here
    }
  };

  function handelOpenSingleTokenCoinData(data) {
    console.log(data);
    var checkSingleAccountDataPresent =
      localStorage.getItem("singleAccountData");
    setCoinName(data.symbol);
    setCoinAsset(data.assetInCurruncy);
    setCoinBalance(data.balance);
    setCoinBalanceInUSD(data.tokenPrice);
    setIsToken(true);
    setTokenAddress(data.contract);
    setTokenId(data.token_id);
  }

  function getHistory() {
    // alert(singleAccountData.btc_address)
    var user_id = localStorage.getItem("UserId");
    if(isToken == false){


      if (networkName == 'OneG8 Network') {


      axios.post(`https://mainnet.oneg8.network/api?module=account&action=txlist&address=${singleAccountData?.address}`, {
      })
      .then((res) => {
        var data = res.data;
        console.log(data);
        if(data.message == "OK"){
          setTransactionHistory(data.result)
        }else{

        }


      });
    }if(networkName == 'Bitcoin Network'){

      axios.post(BASE_URL + `api/wallet/getbitcoinHistory`, {
        address:singleAccountData.btc_address, 
      })
      .then((res) => {
        var data = res.data;
        if(data.success == true){
          setTransactionHistory(data.data)
        }else{

        }

      }); 
    }else{

        axios.post(BASE_URL + `api/wallet/getTransactionHistory`, {
          fromAddress:singleAccountData.address,
          network: networkName,
          user_id: user_id,
        })
        .then((res) => {
          var data = res.data;
          if(data.success == true){
            setTransactionHistory(data.data)
          }else{

          }

        });
    }

    }else{
      if (networkName == 'OneG8 Network') {


      axios.post(`https://mainnet.oneg8.network/api?module=account&action=tokentx&address=${singleAccountData?.address}&contractaddress=${tokenAddress}`, {
      })
      .then((res) => {
        var data = res.data;
        console.log(data);
        if(data.message == "OK"){
          setTransactionHistory(data.result)
        }else{

        }


      });
    }else{
      axios.post(BASE_URL + `api/wallet/getTransactionHistoryForToken`, {
        tokenId:tokenId,
        fromAddress:singleAccountData.address,
        network: networkName,
        user_id: user_id,
      })
      .then((res) => {
        var data = res.data;
        console.log(data);
        if(data.success == true){
          setTransactionHistory(data.data)
        }else{

        }


      });
    }


    }


  }

  function singleShowTxDetails(data) {
    console.log(data)
    setSingleTxDetails(data)
  }
  const formatTimestamp = (timestamp) => {
    const timestampMs = parseInt(timestamp) * 1000; // Convert seconds to milliseconds
    return new Date(timestampMs).toLocaleString();
  };
  const addRemoveFav = (id) => {
    console.log(id)
     axios.post(BASE_URL + `api/wallet/addRemoveToWatchList`, {
        coin:id,
      })
      .then((res) => {
        var data = res.data;
        console.log(data);
        if(data.success == true){


        }else{

        }
        listWatchlist();
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // alert(data.success.message)


      });
  };
  useEffect(() => {
    // listWatchlist();
  }, []);
  const listWatchlist = () => {
     axios.get(BASE_URL + `api/wallet/listWatchlist`)
      .then((res) => {
        var data = res.data;
        if(data.success == true){
          setWatchList(data.list)

          const idsString = data.list.map(item => item.coin_name).join(',');
          getMarketDetailslistWatchlist(idsString);
        }else{

        }
        console.log(data)


      });
  };
  const getMarketDetailslistWatchlist = (list) => {

    axios.post(BASE_URL + `api/wallet/getMarketDetailslistWatchlist`,{
      coinlist:list,
    })
      .then((res) => {
        var data = res.data;
        if(data.success == true){
          setWatchListWithMarketData(data.data)
        }else{

        }
        console.log(data)


      });
  }

const onchangeexportMenmonic = (e) => {
  setPasswordCheckformemonic(e.target.value);
}


const [showModal, setShowModal] = useState(false);

// const handleShow = () => setShowModal(true);
const handleClose = () => setShowModal(false);

function exportMenmonic() {
  setIsbtnLoading(true)

  if(passwordCheckformemonic == ''){
    toast.error('password is mandatory!', {
      position: "top-right",
      autoClose: 5000,
      theme: "dark",
    });
    return;
  }

  let jsonData = {
    user_id: localStorage.getItem("UserId"),
    password: passwordCheckformemonic,
  };
  const encrypt = encryptData(jsonData);
  axios.post(BASE_URL + `check_password`, {
      checkData: encrypt,
    })
    .then((res) => {
  setIsbtnLoading(false)


      // $('#MnemonicRevealModal').modal('hide');
      console.log(res.data.Status);
      setIsLoader(false)

      if (res.data.Status == 1) {
        setIsPasswordCorrect(true);
        setMnemonicModal(false);
        localStorage.setItem("isPasswordCorrect", "true");
        localStorage.setItem("passwordSetTime", Date.now());

        toast.success("Password is correct", {
          position: "top-right",
          autoClose: 5000,
          theme: "dark",
        });
        setShowModal(true)


          var user_id = localStorage.getItem("UserId");
          axios.post(BASE_URL + `api/wallet/exportMnemonic`, {
            user_id: user_id
          })
          .then((res) => {
            try {
              var data = res.data;
              setPrivateKeyExport(data.mnemonic);
            } catch (error) {
              console.log(error)
            }

          });

        // handleClick(walletHomeDIv);
      } else {
        setIsPasswordCorrect(false);

        toast.error(res.data.Message, {
          position: "top-right",
          autoClose: 5000,
          theme: "dark",
        });
        setShowModal(false)
      }
    });
  return;
}
function lockWallet() {
  localStorage.removeItem("isPasswordCorrect");
  localStorage.removeItem("passwordSetTime");
  setIsPasswordCorrect(false);

}

const MnemonicRevealModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
    <div className="modal-dialog" role="document">
        <div className="modal-content modal-bg">
            <div className="modal-header">
                <h5 className="modal-title text-white" id="exampleModalLabel">
                    Get Mnemonic
                </h5>
                <button
                    type="button"
                    className="close px-5"
                    onClick={onHide}
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="row pt-3">
                    <div className="col-12 text-left pt-4 px-3">
                        <span className="text-gray">
                            Save it somewhere safe and secret
                        </span>
                        <br />
                        <span className="text-white">Your Mnemonic </span>
                        <br />
                        <br />
                        <div className="row-color1 pt-3 px-3">
                            <div className="text-center my-auto text-white word-break">
                                {privateKeyExport}
                            </div>
                        </div>
                        <div className="col-12 text-center pt-5">
                            <button type="button" className="btncopy"  onClick={() => {
                                      navigator.clipboard.writeText(
                                        privateKeyExport
                                      );
                                      toast.success(
                                        "Copyed " + privateKeyExport,
                                        { theme: "dark" }
                                      );
                                    }}>
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Modal>
  );
}




  return (
    <div>
      <a id="clickforwallet" href="#wallet" />
      {/* <a id="clickforexplorer" href="#explorer" />
      <a id="clickforoverview" href="#overview" />
      <a id="clickfornews" href="#news" /> */}
      <a id="clickforwatches" href="#watches" />
      <a id="clickforsettings" href="#settings" />

      <div className="row " style={{ height: "100vh", overflowY: "scroll" }}>
        {IsWalletCreted && isLoader == false ? (
          <>
            <div className="col-2">
              <WalletSidebar data={walletHomeDIv} />
            </div>
            <div className="col-10">
              {!isPasswordCorrect && (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <div className="form-wallet">
                    <div className="head-logo text-center">
                      <img src={headlogo} />
                    </div>
                    <h4 className="text-center py-3">
                      Sign in to your ONEG8 Wallet
                    </h4>

                    <small className="text-center pt-3">
                      Attention: For security reasons, your Wallet environment
                      will automatically log out after 30 minutes of inactivity.
                      If you plan to leave your desktop, please remember to log
                      out to ensure the safety of your account.{" "}
                    </small>
                    <br />
                    <br />
                    <h5>Thank you. </h5>

                    <div className="user-box pt-3">
                      <input
                        name="password"
                        value={passwordCheck}
                        onChange={handlePasswordChange}
                        type="text"
                        className="walletInput"
                        id="exampleInputPassword1"
                        placeholder="Enter Password"
                        // onKeyDown={handleAccessModule}
                      />
                    </div>
                    <div className="text-center pt-5">
                      <button
                        type="submit"
                        className="btndatag8create"
                        onClick={handleAccessModule}
                      >
                        Submit & Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isPasswordCorrect && (
                <>
                  <section id="wallet">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>

                    {singleAccountData == "" ? (
                      <span>no data</span>
                    ) : (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-10 pad-left3">
                            <div className="container wallet-container">
                              <div className="row p-2 blackrow">
                                <div className="col-3 text-left">
                                  <button
                                    type="button"
                                    className="wallet-btn"
                                    data-toggle="modal"
                                    data-target="#NetworkModal"
                                  >
                                    {networkName}
                                    <i className="fa-solid fa-circle-chevron-down text-white mx-2"></i>
                                  </button>
                                </div>

                                <div className="col-9 text-left">
                                  <div className="row row-color1 pointer">
                                    <div className="col-3 text-center my-auto">
                                      <div
                                        className="row"
                                        data-toggle="modal"
                                        data-target="#AccountModal"
                                      >
                                        <div className="col-1 block-1">
                                          <img
                                            src={accountlogo}
                                            className="coin-img"
                                          />
                                        </div>
                                        <div className="col-7 text-left my-auto text-white limitchar2">
                                          {singleAccountData.account_name}
                                        </div>
                                        <div className="col-1 text-left my-auto">
                                          <i className="fa-solid fa-circle-chevron-down text-white"></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 text-end my-auto">
                                      <span className="text-white">
                                        Address:{" "}
                                      </span>
                                    </div>
                                    <div className="col-5 text-left block-1">
                                      <span
                                        type="button"
                                        className="btn-wallet1 px-3"
                                      >
                                        <button className="limitchar">
                                          {networkName != 'Bitcoin Network' ? (
                                            <>
                                            {singleAccountData.address}
                                            </> 
                                          ):(
                                            <>
                                            {singleAccountData.btc_address}
                                            </>
                                          )}
                                          
                                        </button>
                                        <i
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              singleAccountData.address
                                            );
                                            toast.success(
                                              "Copied " +
                                                singleAccountData.address,
                                              { theme: "dark" }
                                            );
                                          }}
                                          className="fa-regular fa-copy text-white"
                                        ></i>
                                      </span>
                                    </div>
                                    <div
                                      className="col-1 text-left my-auto"
                                      data-toggle="modal"
                                      data-target="#Edit-Modal"
                                    >
                                      <i className="fa-solid fa-ellipsis-vertical text-white mx-2 pointer"></i>
                                    </div>
                                    <div className="col-1 text-left my-auto" onClick={lockWallet}>
                                      <i className="fas fa-lock text-white mx-2 pointer"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row pt-5">
                                <div className="col-12 text-center my-auto">
                                  <span className="text-white big-font text-uppercase">
                                    {coinBalance != "" ? coinBalance : 0}{" "}
                                    {coinName == "g8" ? "G8C" : coinName}{" "}
                                  </span>
                                </div>
                                <div className="col-12 text-center my-auto">
                                  <span className="text-white small-font">
                                    ${" "}
                                    {coinBalanceInUSD != ""
                                      ? coinBalanceInUSD
                                      : 0}{" "}
                                    {isToken == true ? coininAsset : "USD"}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div className="row p-4 text-center justify-content-center borderrow">
                                  <div className="col-4 text-left">
                                    <a href="#ConfirmScreen">
                                      <button
                                        type="button"
                                        className="btnreceive"
                                      >
                                        <i className="fa-solid fa-circle-up text-white px-2"></i>
                                        Send
                                      </button>
                                    </a>
                                  </div>
                                  <div className="col-4 text-right">
                                    <a href="#reciveScreen">
                                      <button type="button" className="btnsend">
                                        <i className="fa-solid fa-circle-down text-white px-2"></i>
                                        Receive
                                      </button>
                                    </a>
                                  </div>
                                </div>
                                <div className="row pt-3">
                                  <ul class="nav nav-tabs margin-0" id="walletTab" role="tablist">
                                    <li class="nav-item col-6">
                                      <a class="nav-link active" ref={tokenTabRef} id="token-tab" data-toggle="tab" href="#tokentab" role="tab" aria-controls="home" aria-selected="true">Tokens</a>
                                    </li>
                                    <li class="nav-item col-6 " onClick={(e) => getHistory()}>
                                      <a class="nav-link" id="history-tab" data-toggle="tab" href="#historytab" role="tab" aria-controls="profile" aria-selected="false">History</a>
                                    </li>
                                  </ul>
                                 </div>
                                 <div class="tab-content" id="myTabContent">
                                  <div class="tab-pane fade show active" id="tokentab" role="tabpanel" aria-labelledby="tokentab">
                                      <div className="col-12 pt-3">
                                        <div
                                          className="row overview-row pointer m-0 pb-2 "
                                          // onClick={() =>
                                          //   handelOpenSingleTokenCoinData(item)
                                          // }
                                          onClick={(e) => changeNetWork(networkName)}
                                        >
                                          <div className="col-1 text-center my-auto">
                                            <img
                                              src={coinlogo}
                                              className="crypto-logo"
                                            />
                                          </div>
                                          <div className="col-5 text-start my-auto">
                                            <span className="text-white small-font text-uppercase">
                                              {coinNameForList}
                                            </span>
                                          </div>
                                          <div className="col-6 text-end my-auto">
                                            <span className="text-white small-font1 text-uppercase">
                                              {coinBalance != "" ? coinBalance : 0} {coinNameForList}
                                            </span>
                                            <br />
                                            <span className="text-white small-font1">
                                              {coinBalanceInUSD != ""
                                                ? coinBalanceInUSD
                                                : 0}{" "}
                                              USD
                                            </span>
                                          </div>
                                        </div>

                                        {tokenData == null ? (
                                          <span>no data</span>
                                        ) : (
                                          tokenData.map((item, index) => (
                                            <div
                                              className="row overview-row pointer m-0 pb-2 "
                                              onClick={() =>
                                                handelOpenSingleTokenCoinData(item)
                                              }
                                            >
                                              <div className="col-1 text-center my-auto">
                                                {item.symbol === 'BCN' ? (
                                                  <img src={bnclogo} className="crypto-logo 1" />
                                                ) : item.symbol === 'USDT' ? (
                                                  <img src={tusdtlogo} className="crypto-logo 1" />
                                                )  : (
                                                  <InitialsAvatar name={item.symbol} />
                                                )}

                                              </div>
                                              <div className="col-5 text-start my-auto">
                                                <span className="text-white small-font">
                                                  {item.symbol}
                                                </span>
                                              </div>
                                              <div className="col-6 text-end my-auto">
                                                <span className="text-white small-font1">
                                                  {item.balance} {item.symbol}
                                                </span>
                                                <br />
                                                <span className="text-white small-font1">
                                                  {item.tokenPrice}{" "}
                                                  {item.assetInCurruncy}
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        )}
                                      </div>
                                      {
                                        networkName != 'Bitcoin Network' &&(
                                        <div className="row p-4 text-center justify-content-center">
                                          <div className="col-4 text-center py-3">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#TokenModal"
                                              className="btn-wallet btn-lg btn-block"
                                            >
                                              Import Tokens
                                            </button>
                                          </div>
                                        </div>
                                        )
                                      }
                                      
                                  </div>
                                  <div class="tab-pane fade" id="historytab" role="tabpanel" aria-labelledby="historytab">
                                    <div className="col-12 pt-3">


                                          {transactionHistory?.length > 0 ? (

                                            networkName != "Bitcoin Network" ?(
                                              transactionHistory.map((item, index) => (
                                                <>
                                                  <div className="row overview-row m-0 p-0" data-toggle="modal" data-target="#history-modal" onClick={() => {singleShowTxDetails(item)}} key={index}>
                                                    <div className="col-1 text-center my-auto">
                                                    {item.from === singleAccountData.address ? (
                                                           <i className="fa-solid fa-arrow-up text-pink"></i>
                                                        ) : (
                                                          <i className="fa-solid fa-arrow-down text-green"></i>
                                                        )}
  
                                                    </div>
                                                    <div className="col-7 text-start my-auto">
                                                      <span className="text-white small-font p-1">
                                                        {item.from === singleAccountData.address ? (
                                                          <span className="text-pink">Sent</span>
                                                        ) : (
                                                          <span className="text-green">Receive</span>
                                                        )}
                                                      </span>
                                                      <br />
                                                      <span className="text-white small-font1 p-1">
                                                        {item.timeStamp}
                                                      </span>
                                                      <br />
                                                      <span className="text-white small-font1 p-1">
                                                        From: {item.from}
                                                      </span>
                                                    </div>
                                                    <div className="col-4 text-end my-auto">
                                                    {item.from_address === singleAccountData.address ? (
                                                          <span className="text-pink small-font p-1 text-uppercase"> {weiToEther(item.value)} {item.coin_name} {coinName == 'g8' ? 'G8C' : coinName}</span>
                                                        ) : (
                                                          <span className="text-green small-font p-1 text-uppercase"> {weiToEther(item.value)} {item.coin_name} {coinName == 'g8' ? 'G8C' : coinName}</span>
                                                        )}
                                                    </div>
                                                  </div>
                                                </>
                                              ))
                                            ):(
                                              transactionHistory.map((item, index) => (
                                                <>
                                                  <div className="row border p-2 mb-3 mx-1">
                                                    <div className="col-12 text-center py-2">{item.txid}</div>
                                                    <div className="col-5">
                                                      <div className="text-center">{item.vin.length} Inputs Consumed</div>
                                                      {
                                                        item.vin.map((item2, index2) => (
                                                          <div className="row curveBorder p-2 mb-3">
                                                            <div>{item2.prevout.value / 100000000} BTC from</div>
                                                            <div>{item2.prevout.scriptpubkey_address}</div>
                                                            
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                    <div className="col-1 my-auto text-center">
                                                      <i class="fas fa-arrows-alt-h"></i>
                                                    </div>
                                                    <div className="col-6">
                                                      <div className="text-center">{item.vout.length} Output Created</div>
                                                        {
                                                          item.vout.map((item2, index2) => (
                                                            <div className="row curveBorder p-2 mb-3">
                                                              <div>{item2.value / 100000000} BTC to</div>
                                                              <div>{item2.scriptpubkey_address}</div>
                                                            </div>
                                                          ))
                                                        }
                                                    </div>
                                                    <div className="col-12 text-center py-2">Value Transacted : {item.vout[0].value/ 100000000 } BTC</div>
                                                  </div>
                                                </>
                                              ))
                                            )

                                            
                                          ) : (
                                            <p className="text-white text-center pt-5">No transactions available.</p>
                                          )}

                                           {/* <div className="row overview-row m-0 p-0" data-toggle="modal"data-target="#history-modal">
                                                  <div className="col-1 text-center my-auto">
                                                  <i class="fa-solid fa-arrow-down text-green"></i>
                                                  </div>
                                                  <div className="col-7 text-start my-auto">
                                                    <span className="text-white small-font p-1">
                                                      Receive
                                                    </span>
                                                    <br />
                                                    <span className="text-white small-font1 p-1">
                                                      22/02/22 10:00 AM
                                                    </span>
                                                    <br />
                                                    <span className="text-white small-font1 p-1">
                                                    From: 0x54a08484fd52da4f91d72909dd8732b399102f3b
                                                    </span>
                                                  </div>
                                                  <div className="col-4 text-end my-auto">
                                                    <span className="text-green small-font p-1">
                                                      0.00 USD
                                                    </span>
                                                  </div>
                                            </div> */}


                                        <div className="pt-5"></div>
                                    </div>
                                  </div>

                                </div>


                              </div>
                            </div>
                            <div className="pt-5"></div>
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                  <section id="reciveScreen">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <div className="col-10 pad-left3">
                        <div className="container wallet-container">
                          <div className="col-8 mx-auto px-5 pt-3">
                            <div className="row">
                              <div className="col-12 px-0 text-start pt-3">
                                <a href="#wallet">
                                  <span className="btn-1 btn-info">Back</span>
                                </a>
                              </div>
                              <div className="col-12 text-center">
                                <span className="text-white mid-font">
                                  Receive {networkName} Coin{" "}
                                </span>
                              </div>
                            </div>
                            <br />
                          </div>

                          <div className="col-8 px-5 mx-auto ">
                            <div className="row row-color1 p-2">
                              <div className="col-12 text-left pt-5">
                                <div className="text-center">
                                  {/* <img src="img/qr.jpg" className="qr-img" /> */}
                                  <div
                                    style={{
                                      height: "auto",
                                      margin: "0 auto",
                                      maxWidth: 150,
                                      width: "100%",
                                    }}
                                  >
                                    {singleAccountData == "" ? (
                                      "loading"
                                    ) : (
                                      <QRCode
                                        size={400}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                        }}
                                        value={networkName != 'Bitcoin Network' ? singleAccountData?.address : singleAccountData?.btc_address}
                                        viewBox={`0 0 256 256`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-center pt-3">
                                <span
                                  type="button"
                                  className="btn-wallet1 px-3"
                                >
                                  {networkName != 'Bitcoin Network' ? (
                                    <div>
                                      {singleAccountData?.address}
                                    </div>
                                  ):(
                                  <div>
                                    {singleAccountData?.btc_address}
                                  </div>
                                  )}
                                  
                                </span>
                              </div>
                              <div className="col-12 text-left pt-3">
                                <div className="row p-4">
                                  <button
                                    type="button"
                                    className="btn-wallet btn-lg btn-block"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        singleAccountData.address
                                      );
                                      toast.success(
                                        "Copyed " + singleAccountData.address,
                                        { theme: "dark" }
                                      );
                                    }}
                                  >
                                    Copy Address
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-5"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="ConfirmScreen">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <div className="col-10 pad-left3">
                        <div className="container wallet-container">
                          <div className="col-12 mx-auto px-5 pt-3">
                            <div className="row">
                              <div className="col-12 px-0 text-start pt-3">
                                <a href="#wallet">
                                  <span className="btn-1 btn-info">Back</span>
                                </a>
                              </div>
                              <div className="col-12 text-center">
                                <span className="text-white mid-font">
                                  Confirm {networkName} Coin
                                </span>
                              </div>
                            </div>
                            <br />
                          </div>
                          <div className="col-12 px-5 mx-auto ">
                            <div className="row row-color1 p-2">
                              <div className="col-12 text-left pt-5">
                                <span className="text-white">From </span>
                                <br />
                                <div className="col-12 text-left">
                                  <div className="row row-color1 mx-1">
                                    <div className="col-3 text-center my-auto">
                                      <div
                                        className="row"
                                        data-toggle="modal"
                                        data-target="#AccountModal"
                                      >
                                        <div className="col-1 text-center">
                                          <img
                                            src={accountlogo}
                                            className="coin-img"
                                          />
                                        </div>
                                        <div className="col-8 text-left my-auto text-white">
                                          {singleAccountData?.account_name}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 text-right my-auto">
                                      <span className="text-white">
                                        {coinBalance} {coinName}{" "}
                                      </span>
                                    </div>
                                    <div className="col-3 text-end my-auto block-1">
                                      <span className="text-white">
                                        Address :{" "}
                                      </span>
                                    </div>
                                    <div className="col-4 text-start my-auto block-1">
                                      <span
                                        type="button"
                                        className="btn-wallet1 px-3 limitchar3"
                                      >
                                        {networkName != 'Bitcoin Network' ? singleAccountData?.address : singleAccountData?.btc_address}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-left pt-5">
                                <span className="text-white">To </span>
                                <br />
                                <input
                                  type="text"
                                  value={toAddress}
                                  onChange={handelChangeToAddress}
                                />
                              </div>
                              <div className="col-12 text-left pt-3">
                                <span className="text-white">Amount </span>
                                <br />
                                <input
                                  type="text"
                                  value={toTxamount}
                                  onChange={handelChangeToAmount}
                                />
                              </div>
                              <div className="col-12 text-left pt-3">
                                <div className="row p-4">
                                  <a
                                    id="clickforsendscreen"
                                    href="#sendScreen"
                                  ></a>
                                  <button
                                    type="button"
                                    onClick={handelConfirmTx}
                                    className="btn-1"
                                  >
                                    Send & confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="pt-5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="sendScreen">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <div className="col-10 pad-left3">
                        <div className="container wallet-container">
                          <div className="mx-auto px-5 pt-3">
                            <div className="row">
                              <div className="col-12 px-0 text-start">
                                <a href="#wallet">
                                  <span className="btn-1">Back</span>
                                </a>
                              </div>
                              <div className="col-12 text-center">
                                <span className="text-white mid-font">
                                  Send {networkName} Coin{" "}
                                </span>
                              </div>
                            </div>
                            <br />
                          </div>
                          <div className="col-12 px-5 mx-auto ">
                            <div className="row row-color1 p-2">
                              <div className="col-12 text-left pt-5">
                                <span className="text-white">From </span>
                                <br />
                                <div className="col-12 text-left">
                                  <div className="row row-color1 p-1">
                                    <div className="col-3 text-center my-auto">
                                      <div
                                        className="row"
                                        data-toggle="modal"
                                        data-target="#AccountModal"
                                      >
                                        <div className="col-1 text-center">
                                          <img
                                            src={accountlogo}
                                            className="coin-img"
                                          />
                                        </div>
                                        <div className="col-8 text-left my-auto text-white">
                                          {singleAccountData?.account_name}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-1 text-right my-auto">
                                      <span className="text-white">
                                        {coinBalance} {coinName}{" "}
                                      </span>
                                    </div>
                                    <div className="col-3 text-end my-auto block-1">
                                      <span className="text-white">
                                        Address :{" "}
                                      </span>
                                    </div>
                                    <div className="col-4 text-start my-auto block-1">
                                      <span
                                        type="button"
                                        className="btn-wallet1 px-3 limitchar3"
                                      >
                                        {networkName != 'Bitcoin Network' ? singleAccountData?.address : singleAccountData?.btc_address}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-left pt-3 px-0">
                                <span className="text-white px-2">To </span>
                                <br />
                                <div className="col-12 text-white row-color1 px-2">
                                  {toAddress}
                                </div>
                              </div>
                              <div className="col-12 text-left pt-3">
                                <div className="row row-color1 mb-4">
                                  <div className="col-5 text-white">
                                    <div>Estimated gas And fee</div>
                                    <div>Gas and fee in USDT</div>
                                  </div>
                                  <div className="col-7 text-white">
                                    <div>gas := {confirmTxData.gasFees}</div>
                                    <div>
                                      gas in USDT := ${" "}
                                      {confirmTxData.gasAmountUsd}
                                    </div>
                                  </div>
                                </div>
                                <div className="row row-color1">
                                  <div className="col-5 text-white">
                                    <div>Total (Amount + Gas fee)</div>
                                    <div>Total in USDT</div>
                                  </div>
                                  <div className="col-7 text-white">
                                    <div>
                                      := {confirmTxData.totalAmount} {coinType}
                                    </div>
                                    <div>
                                      := $ {confirmTxData.totalAmountUsd}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-left pt-3">
                                <div className="row p-4">
                                  {/* <button type="button" onClick={handelConfirmTx} className="btn-wallet btn-lg btn-block">Send & confirm</button> */}
                                  {isTxLoader == true ? (
                                    <button
                                      class="btn-1"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Processing...
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={handelTxSend}
                                      className="btn-1"
                                    >
                                      Send
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="pt-5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="explorer">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <div className="col-10 pad-left3">
                        <div className="container wallet-container p-2">
                          <div className="embed-responsive embed-responsive-21by9">
                            <iframe
                              className="embed-responsive-item"
                              width="100%"
                              height="1000"
                              src="https://mainnet.oneg8.network/"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>



                  <section id="settings">
                    <div className="text-center pad-left">
                      <img src={WalletLogo} className="logo-img" />
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <div className="col-8 pad-left">
                        <div className="container wallet-container p-4 overflow-hidden">

                        <div
                            className="row" onClick={() => setMnemonicModal(true)}
                          >
                            <div className="col-1 text-center pointer">
                              <i className="fa-solid fa-key text-white pointer"></i>
                            </div>
                            <div className="col-8 text-left my-auto text-white pointer">
                              Get Mnemonic
                            </div>
                            <div className="col-2 text-left my-auto pointer">
                              <i className="fa-solid fa-chevron-right text-white pointer"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {isLoader == false ? (
              <div className="container wallet-container">
                <div className="text-center pad-left">
                  <img src={WalletLogo} className="logo-img" />
                </div>
                <div className="col-8 p-2 px-5 mx-auto ">
                  <div
                    className="row row-color1 p-2"
                    style={{ height: "500px" }}
                  >
                    {isPasswordSet == false ? (
                      <div className="col-12 text-center mt-3 pb-2">
                        <div className="row p-2 justify-content-center">
                          <div className="col-12 text-center my-auto pb-3">
                            <span className="text-white mid-font">
                              Set Password{" "}
                            </span>
                            <br />
                            <span className="text-white small-font1">
                              This password is used to protect your wallet and
                              provided access. It cannot be reset
                            </span>
                          </div>
                          <div className="col-6 row-color2 p-3">
                            <div className="form-group">
                              <label
                                for="exampleInputPassword1"
                                className="text-white"
                              >
                                Password
                              </label>
                              <input
                                name="password"
                                value={setPassword.password}
                                onChange={onSetPasswordInputChange}
                                onBlur={validateInput}
                                type="text"
                                className=" walletInput"
                                id="exampleInputPassword1"
                                placeholder="Password"
                              />
                              {setPasswordError.password && (
                                <span className="err text-danger">
                                  {setPasswordError.password}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label
                                for="exampleInputPassword1"
                                className="text-white"
                              >
                                Confirm Password
                              </label>
                              <input
                                name="confirmPassword"
                                value={setPassword.confirmPassword}
                                onChange={onSetPasswordInputChange}
                                onBlur={validateInput}
                                type="text"
                                className=" walletInput"
                                id="exampleInputPassword1"
                                placeholder="Confirm Password"
                              />
                              {setPasswordError.confirmPassword && (
                                <span className="err text-danger">
                                  {setPasswordError.confirmPassword}
                                </span>
                              )}
                            </div>
                            <button
                              type="submit"
                              className="btn-wallet mt-3"
                              onClick={passwordFlagFunction}
                            >
                              Submit & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 text-center  mt-3 ">
                        <div className="col-12 text-center my-auto pb-2">
                          <span className="text-white mid-font">
                            Write Down Your Secret Recovery Phase{" "}
                          </span>
                          <br />
                          <span className="text-white small-font1">
                            Write down this 12-word secret recovery phrase and
                            save it in a place that you trust and accessible by
                            you only.{" "}
                          </span>
                        </div>
                        <div className="row p-2">
                          {mnomonic == "" ? (
                            <>
                              <span>No Data</span>
                            </>
                          ) : (
                            <>
                              {
                                <div className="col-12 text-center my-auto">
                                  <form>
                                    <textarea
                                      readOnly
                                      type="text"
                                      value={mnomonic}
                                    />
                                  </form>
                                </div>
                              }
                            </>
                          )}
                        </div>
                        <div className="row p-4 justify-content-end">
                          <button
                            type="button"
                            className="btn-wallet btn-lg btn-block col-3 mx-3"
                            onClick={() => {
                              navigator.clipboard.writeText(mnomonic);
                              toast.success(mnomonic, { theme: "dark" });
                            }}
                          >
                            Copy Mnemonic
                          </button>
                          <button
                            type="button"
                            className="btn-wallet btn-lg btn-block col-3"
                            onClick={handelCreateWalletFunction}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center pt-5">Loading....</div>
            )}
          </>
        )}
      </div>

      <div className="modal fade" id="NetworkModal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Select a Network
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="row p-3 net-row m-2"
                onClick={(e) => changeNetWork("OneG8 Network")}
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#NetworkModal"
              >
                <div className="col-2 text-center">
                  <img src={ogo4logo} className="crypto-logo" />
                </div>
                <div className="col-10 text-left my-auto">
                  <span className="text-white">ONEG8 Network </span>
                </div>
              </div>
              <div
                className="row p-3 net-row m-2"
                onClick={(e) => changeNetWork("Ethereum Network")}
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#NetworkModal"
              >
                <div className="col-2 text-center">
                  <img src={ethlogo} className="crypto-logo" />
                </div>
                <div className="col-10 text-left my-auto">
                  <span className="text-white">Ethereum Network </span>
                </div>
              </div>
              <div
                className="row p-3 net-row m-2"
                onClick={(e) => changeNetWork("Binance Network")}
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#NetworkModal"
              >
                <div className="col-2 text-center">
                  <img src={bnblogo} className="crypto-logo" />
                </div>
                <div className="col-10 text-left my-auto">
                  <span className="text-white">Binance Network </span>
                </div>
              </div>
              <div
                className="row p-3 net-row m-2"
                onClick={(e) => changeNetWork("Matic Network")}
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#NetworkModal"
              >
                <div className="col-2 text-center">
                  <img src={mtclogo} className="crypto-logo" />
                </div>
                <div className="col-10 text-left my-auto">
                  <span className="text-white">Matic Network</span>
                </div>
              </div>
              <div
                className="row p-3 net-row m-2"
                onClick={(e) => changeNetWork("Bitcoin Network")}
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#NetworkModal"
              >
                <div className="col-2 text-center">
                  <img src={btclogo} className="crypto-logo" />
                </div>
                <div className="col-10 text-left my-auto">
                  <span className="text-white">Bitcoin Network </span>
                </div>
              </div>
              {/* <div className="row p-3">
                    <div className="col-12 text-center">
                        <button type="button" className="btn-wallet btn-lg btn-block">Add Network</button>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>

      <MnemonicRevealModal show={showModal} onHide={handleClose} />

      <div className="modal fade" id="AccountModal" tabindex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Select an Account
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {allAccountList == "" ? (
                <>
                  <span>No Data</span>
                </>
              ) : (
                <>
                  {allAccountList.map((item, index) => (
                    <div
                      className="row p-3 row-color pointer m-1"
                      onClick={(e) => singleAccountFunction(item)}
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#AccountModal"
                    >
                      <div className="col-2 text-center my-auto">
                        <img src={accountlogo} className="coin-img1" />
                      </div>
                      <div className="col-5 text-left my-auto">
                        <span className="text-white">{item.account_name}</span>
                        <br />
                        <span className="text-white">{item.shortAddress}</span>
                      </div>
                      <div className="col-5 text-right my-auto">
                        {networkName === "OneG8 Network" ? (
                          <div>
                            <span className="text-white">
                              {item.one_balance} G8{" "}
                            </span>
                            <br />
                            <span className="text-white">
                              {item.one_usd} USD{" "}
                            </span>
                          </div>
                        ) : networkName === "Bitcoin Network" ? (
                          <div>
                            <span className="text-white">
                              {item.btc_balance} BTC{" "}
                            </span>
                            <br />
                            <span className="text-white">
                              {item.btc_usd} USD{" "}
                            </span>
                          </div>
                        ) : networkName === "Binance Network" ? (
                          <div>
                            <span className="text-white">
                              {item.bnb_balance} BNB{" "}
                            </span>
                            <br />
                            <span className="text-white">
                              {item.bnb_usd} USD{" "}
                            </span>
                          </div>
                        ) : networkName === "Matic Network" ? (
                          <div>
                            <span className="text-white">
                              {item.matic_balance != ""
                                ? item.matic_balance
                                : 0}{" "}
                              MATIC{" "}
                            </span>
                            <br />
                            <span className="text-white">
                              {item.matic_usd != "" ? item.matic_usd : 0} USD{" "}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span className="text-white">
                              {item.eth_balance} ETH{" "}
                            </span>
                            <br />
                            <span className="text-white">
                              {item.eth_usd} USD{" "}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div className="row p-3">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#AccountTabModal"
                    className="btn-1"
                  >
                    Add Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AccountTabModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Add an Account
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left">
                  <span
                    className="text-white pointer"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#AddAccountModal"
                  >
                    <i className="fa-solid fa-plus text-white px-5"></i>Add
                    Account{" "}
                  </span>
                  <br />
                </div>
                <div className="col-12 text-left pt-4" data-dismiss="modal">
                  <span
                    className="text-white pointer"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#ImportAccountModal"
                  >
                    <i className="fa-solid fa-file-invoice text-white px-5"></i>
                    Import Account{" "}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddAccountModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Add Account
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left">
                  <span className="text-white">
                    Created accounts are viewable in your wallet but are not
                    recoverable with your ONEG8 wallet secret recovery phrase{" "}
                  </span>
                  <br />
                </div>

                <div className="col-12 text-left pt-4">
                  <span className="text-white">Give Your Account a Name </span>
                  <br />
                  <input
                    type="text"
                    value={addAccount}
                    onChange={handleChangeAddAccount}
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-right" data-dismiss="modal">
                  <button
                    type="submit"
                    onClick={handelSubmitAddAccount}
                    className="btncreate"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="ImportAccountModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Import Account
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left">
                  <span className="text-white">
                    Imported accounts are viewable in your wallet but are not
                    recoverable with your ONEG8 wallet secret recovery phrase{" "}
                  </span>
                  <br />
                </div>

                <div className="col-12 text-left pt-5">
                  <span className="text-white">Give Your Account a Name </span>
                  <br />
                  <input
                    type="text"
                    value={accountName}
                    onChange={handelChangeImportAccountName}
                  />
                </div>

                <div className="col-12 text-left pt-4">
                  <span className="text-white">
                    Paste your private key here{" "}
                  </span>
                  <br />
                  <textarea
                    value={privateKey}
                    onChange={handelChangeImportAccountPrivateKey}
                  ></textarea>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-right" data-dismiss="modal">
                  <button
                    type="button"
                    onClick={handelSaveImportAccount}
                    className="btncreate"
                  >
                    Import
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="TokenModal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Import New Token
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left pt-4">
                  <span className="text-white">Token Address </span>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Token Address"
                    value={tokenAddress}
                    onChange={handelChangeImportToken}
                  />
                </div>
                <div className="col-12 text-left pt-4">
                  <span className="text-white">Token symbol</span>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Token Symbol"
                    value={tokenSymbol}
                    readOnly
                  />
                </div>
                <div className="col-12 text-left pt-4">
                  <span className="text-white">Token decimal</span>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Token Decimal"
                    value={tokenDecimal}
                    readOnly
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-right" data-dismiss="modal">
                  <button
                    type="button"
                    className="btncreate"
                    onClick={handelSaveImportToken}
                  >
                    Import
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="Edit-Modal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Add Account
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row py-3">
                <div className="col-12 text-left pointer">
                  <span
                    className="text-white"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#EditAccountModal"
                  >
                    <i className="fa-regular fa-pen-to-square text-white px-5"></i>
                    Edit Account Name{" "}
                  </span>
                  <br />
                </div>
                <div className="col-12 text-left pt-4 pointer">
                  <span
                    className="text-white"
                    data-toggle="modal"
                    data-target="#ImportAccountModal"
                  >
                    <i className="fa-solid fa-share-nodes text-white px-5"></i>
                    Share{" "}
                  </span>
                  <br />
                </div>
                <div className="col-12 text-left pt-4 pointer">
                  <span
                    className="text-white"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#PrivateKeyModal"
                  >
                    <i className="fa-solid fa-key text-white px-5"></i>Show
                    Private Key{" "}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="EditAccountModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Edit Account Name
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left pt-4">
                  <form>
                    <span className="text-white">Name </span>
                    <br />
                    <input
                      type="text"
                      value={accountName}
                      onChange={handelChangeEditAccountName}
                    />
                  </form>
                </div>
                <div className="col-12 text-left pt-4">
                  <form>
                    <span className="text-white">Address </span>
                    <br />
                    <input
                      type="text"
                      value={singleAccountData.address}
                      readOnly
                    />
                  </form>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div
                  className="col-6 text-right"
                  data-dismiss="modal"
                  onClick={handelSaveEditAccountName}
                >
                  <button type="button" className="btncreate">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="PrivateKeyModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Edit Account Name
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left">
                  <span className="text-white">
                    Save it somewhere safe and secret{" "}
                  </span>
                  <br />
                </div>

                <div className="col-12 text-left key-container mt-2">
                  <div className="row">
                    <div className="col-2 text-center my-auto">
                      <i className="fa-regular fa-eye-slash text-red"></i>
                    </div>
                    <div className="col-10 text-left py-3">
                      <span className="text-white small-font1">
                      Never disclose this key. Anyone with your private key can fully control your account, including transferring away any of your funds.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-left pt-4">
                  <form>
                    <span className="text-white">Enter Password </span>
                    <br />
                    <input type="text" value="" />
                  </form>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-right" data-dismiss="modal">
                  <button
                    type="button"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#HoldKeyModal"
                    className="btncreate"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="HoldKeyModal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Keep your private key safe
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left">
                  <span className="text-white">
                    Your Private Key provides full access to your wallet and
                    funds.
                  </span>
                  <br />
                </div>

                <div className="col-12 text-left mt-2">
                  <span className="text-white">
                    Do not share this with anyone.{" "}
                  </span>
                </div>
              </div>
              <div className="row p-4">
                <button
                  type="button"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#RevealModal"
                  className="animated-button btn-wallet btn-lg btn-block"
                >
                  Hold to reveal private key
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="RevealModal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Keep your private key safe
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12 text-left key-container mt-2">
                <div className="row">
                  <div className="col-2 text-center my-auto">
                    <i className="fa fa-exclamation-circle text-red"></i>
                  </div>
                  <div className="col-10 text-left py-3">
                    <span className="text-white small-font1">
                      Warning: Never disclose this key. Anyone with your private
                      keys can steal any assets held in your account.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="content">
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="pill"
                        href="#textkey"
                      >
                        Text
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="#qr-code"
                      >
                        QR Code
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div id="textkey" className="container tab-pane active">
                      <div className="col-12 text-left py-3">
                        <span className="text-white small-font1">
                          Your Private Key
                        </span>
                      </div>
                      <div className="row row-color1 p-1">
                        <div className="col-12 text-center my-auto text-white word-break">
                          FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364141
                        </div>
                      </div>
                      <div className="col-12 text-center py-3">
                        <button type="button" className="btncopy">
                          Copy
                        </button>
                      </div>
                    </div>
                    <div id="qr-code" className="container tab-pane fade">
                      <div className="text-center">
                        <img src="img/qr.jpg" className="qr-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-4">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn-wallet btn-lg btn-block"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
        show={MnemonicModal}
        onHide={() => setMnemonicModal(false)}
        contentClassName=""
        centered
      >
        <div className="modal-dialog m-0" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Get Mnemonic
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left pt-4">
                    <span className="text-white">
                      Enter Password to continue
                    </span>
                    <br />
                    <input type="text" onChange={onchangeexportMenmonic} value={passwordCheckformemonic}/>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-6 text-left" data-dismiss="modal">
                  <button type="button" className="btncancel">
                    Cancel
                  </button>
                </div>
                <div
                  className="col-6 text-right"
                >
                  {isbtnloading ?(<button type="button" className="btncreate">
                    Loading...
                  </button>):(<button type="button" className="btncreate" onClick={exportMenmonic}>
                    Next
                  </button>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="modal fade"
        id="MnemonicRevealModal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-header">
              <h5 className="modal-title text-white" id="exampleModalLabel">
                Get Mnemonic
              </h5>
              <button
                type="button"
                className="close px-5"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-12 text-left pt-4">
                  <span className="text-gray">
                    Save it somewhere safe and secret
                  </span>
                  <br />
                  <span className="text-white">Your Mnemonic </span>
                  <br />
                  <br />
                  <div className="row-color1 p-3">
                    <div className=" text-center my-auto text-white word-break">
                      car bus train super sugar vehicle dynamite painting plan
                      yolk fourth gate
                    </div>
                  </div>
                  <div className="col-12 text-center py-3">
                    <button type="button" className="btncopy">
                      Copy
                    </button>
                  </div>
                </div>
              </div>
              <div className="row p-4">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn-wallet btn-lg btn-block"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

          <div className="modal fade" id="history-modal" tabindex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content modal-bg">
                <div className="modal-header">
                      <h5 className="modal-title text-white" id="exampleModalLabel">
                        {singleTxDetails.from === singleAccountData.address ? (
                              <span>Sent</span>
                            ) : (
                              <span>Receive</span>
                            )}
                      </h5>
                      <button type="button" className="close px-5" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                <div className="modal-body">
                      <div className="row overview-row m-0 p-0">
                        <div className="col-6 text-start my-auto">
                          <span className="text-white small-font p-1">
                          Status
                          </span>
                          <br />
                          <span className="text-green small-font1 p-1">
                          confirmed
                          </span>
                        </div>
                        <div className="col-6 text-end my-auto">
                          <span className="text-white small-font p-1">
                          Date
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1">
                          {formatTimestamp(singleTxDetails.timeStamp)}
                          </span>
                        </div>

                      </div>
                      <div className="row overview-row m-0 p-0">
                        <div className="col-6 text-start my-auto">
                          <span className="text-white small-font p-1">
                          From
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1 limittx">
                          {singleTxDetails.from}

                          </span>
                        </div>
                        <div className="col-6 text-end my-auto">
                          <span className="text-white small-font p-1">
                          To
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1 limittx">
                          {singleTxDetails.to}

                          </span>
                        </div>

                      </div>
                      <div className="row overview-row m-0 p-0">
                        <div className="col-12 text-start my-auto">
                          <span className="text-white small-font p-1">
                          Nonce
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1 limittx">
                          {singleTxDetails.nonce}
                          </span>
                        </div>


                      </div>
                      <div className="row overview-row m-0 p-0">
                        <div className="col-6 text-start my-auto">
                          <span className="text-white small-font1 p-1">
                          Amount
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1">
                          Gas Limit(Units)
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1">
                          Gas Price
                          </span>
                          <br />
                          <br />
                          <span className="text-white small-font1 p-1">
                          Total
                          </span>
                        </div>
                        <div className="col-6 text-start my-auto">
                        <span className="text-white small-font1 p-1">
                          {weiToEther(singleTxDetails.value)} {coinName}
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1">
                          {singleTxDetails.gas}
                          </span>
                          <br />
                          <span className="text-white small-font1 p-1">
                          {singleTxDetails.gasPrice}

                          </span>
                          <br />
                          <br />
                          <span className="text-white small-font1 p-1">
                          {weiToEther(singleTxDetails.value)} {coinName}
                          </span>
                        </div>

                      </div>
                      <div className="row m-0 pt-5">
                        <div className="col-12 text-center my-auto pointer"   onClick={() => {
                                            navigator.clipboard.writeText(
                                              singleTxDetails.hash
                                            );
                                            toast.success(
                                              "Copied " +
                                                singleTxDetails.hash,
                                              { theme: "dark" }
                                            );
                                          }}>
                          <span className="text-white small-font p-1"><i class="fa-regular fa-copy px-2"></i>
                          Copy Transaction Hash
                          </span>
                         </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import JoinForm from "./JoinForm";
import "./styles.css";
import Conference from "./Conference";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  selectHMSMessages,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import Footer from "./Footer";
import { Loader } from "./Loader";
import Header from "./Header";
import Chat from "./Chat"; // Import Chat component

const loadingStates = [HMSRoomState.Connecting, HMSRoomState.Disconnecting];

export default function App() {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const roomState = useHMSStore(selectRoomState);
  const messages = useHMSStore(selectHMSMessages);
  const hmsActions = useHMSActions();
  const [showChat, setShowChat] = useState(false); // Toggle state for chat
  const [newMessages, setNewMessages] = useState(0); // New messages count
  const [userName, setUserName] = useState(""); // User name state
  const chatRef = useRef(null); // Ref to chat container

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  useEffect(() => {
    // Update new messages count
    if (messages.length > 0) {
      setNewMessages((prev) => prev + 1);
    }
  }, [messages]);

  useEffect(() => {
    // Close chat if clicked outside
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target)) {
        setShowChat(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatRef]);

  const handleToggleChat = () => {
    setShowChat((prev) => !prev);
    if (!showChat) setNewMessages(0); // Reset new messages count when opening chat
  };

  if (loadingStates.includes(roomState) || !roomState) {
    return <Loader />;
  }

  return (
    <div className="conferenceApp">
      {isConnected ? (
        <>
          <Header />
          <Conference />
          <div className="chat-icon" onClick={handleToggleChat}>
            💬
            {newMessages > 0 && <span className="badge">{newMessages}</span>}
          </div>
          {showChat && (
            <div ref={chatRef}>
              <Chat userName={userName} /> {/* Pass user name to Chat */}
            </div>
          )}
          <Footer />
        </>
      ) : (
        <JoinForm setUserName={setUserName} />  
      )}
    </div>
  );
}

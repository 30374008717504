import "./App.css";
import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage,getToken } from "firebase/messaging";

import { toast, ToastContainer } from "react-toastify";
import Message from "./Firebase/MessageComponent.jsx";
import "react-toastify/dist/ReactToastify.css"; 
import { messaging } from "./Firebase/Firebase";
import axios from 'axios';
import RouteData from "./RouteData/RouteData";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();  
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: "BIolkdpyje0TtVKtByLLxhMhw-AYr0iFLoxlwEOmblF8Uw6b16McO506aq1S1dEIKFKHWORdV4nZ-6s2nk_jm6Y",
        });
        
        console.log("Token generated : ", token);
        localStorage.setItem("fcm_token",token)
      } else if (permission === "denied") {
        // alert("You denied for the notification");
      }
    } catch (error) {
        console.error('ServiceWorker registration failed:', error);
        requestPermission(); 
    }
   
  } 

  useEffect( () => {
    
    requestPermission(); 
    onMessage(messaging, (payload) => {
      toast(<Message notification={payload.notification} />);
    }); 
    
    const userToken = localStorage.getItem("UserToken");
    console.log(userToken);
    if (userToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    }
  }, []);
  return (
    <>
      <RouteData />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme="light"
      />
    </>
  );
}

export default App;

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../API/ApiUrl'
import { deleteDriveToken, deleteSkipGeneratePassword, getDriveTokenData } from '../util/cacheData';
import { useSelector, useDispatch } from 'react-redux';
import { setCustomData } from './customData';
// import { CallCustomHooks } from '../util/CallCustomHooks';

// import { getCookie, storeCookie, deleteCookie } from '../../utils/cookie-function';




const apiVersion = "v1"; 
/// if aapko baseurl change kerna hai to Apiurl.js mai jaker kero change yaha se mt kero build ke time pr platform ka change hota hai and datag8 ka vaise 
/// he rheta hai and error ata hai 
const baseUrl = BASE_URL;    
///


function CallDispatch(){
        // CallCustomHooks({callCheckDrivePassword: Date.now()})
}


const baseQueryWithAuth = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
        headers.set('authorization', 'Bearer ' + localStorage.getItem("UserToken"));
        headers.set('driveAuthorization', 'Bearer ' + sessionStorage.getItem("driveToken"));

    },
});

const refreshHeaderWithAuth = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
        // console.log(headers, getState());
        headers.set('authorization', 'Bearer ' + localStorage.getItem("UserToken"));
    },
});

const authenticateApi = async (args, api, extraOptions) => {
    
    let result = await baseQueryWithAuth(args, api, extraOptions);

    if (result?.error?.status === 401) {
        const refreshResult = await refreshHeaderWithAuth(
            { url: `auth/${apiVersion}/refreshToken`, method: 'GET' },
            api,
            extraOptions
        )
        if (refreshResult?.data?.accessToken) {
            let userData = localStorage.getItem("UserToken")
            userData.access_token = refreshResult.data.accessToken
            localStorage.setItem("UserToken", userData);
            // let storeData = storeCookie("userData", userData);
            // if (storeData) {
            //     result = await baseQueryWithAuth(args, api, extraOptions)
            // }
        }
        else {
            // deleteCookie("userData");
            window.location.href = "/login";
        }
    }
    if (result?.error?.status == 500 && result?.error?.data?.message == "Drive token unauthorized" && !result?.error?.data?.status) {
        deleteDriveToken();
        deleteSkipGeneratePassword();
        window.location.reload(false);
    }
    return result;

}

export default authenticateApi;
import { createSlice } from "@reduxjs/toolkit";



const customePostDataSlice=createSlice({
    name:"customePostData",
    initialState:{},
    reducers:{
        setcustomePostData:(state,action)=>{
            return action.payload;
        }
    }
})

const {setcustomePostData} =customePostDataSlice.actions;

export {
    setcustomePostData,
    customePostDataSlice
} 
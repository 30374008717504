import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Login/Login";
import ForgotPassword from "../Login/ForgotPassword";
import Verification from "../Login/Verification";
import ResetPassword from "../Login/ResetPassword";
import SignUp from "../Register/SignUp";
import AccountSetup from "../Register/AccountSetup";
import HomeScreen from "../HomeScreen/HomeScreen";
import Sidebar from "../Sidebar/Sidebar";
import SearchSidebar from "../Search/SearchSidebar";
import UserProfile from "../UserProfile/UserProfile";
import Notification from "../Notification/Notification";
import Profile from "../Profile/Profile";
import ProfilePost from "../Profile/ProfilePost";
import ProfileVlog from "../Profile/ProfileVlog";
import Setting from "../Setting/Setting";
import Location from "../Location/Location";
import BusinessProfile from "../Business/BusinessProfile";
import Calender from "../Calender/Calender";
import Stories from "../Stories/Stories";
import Message from "../Message/Message";
import Conferance from "../Conferance";
import MemberSidebar from "../Message/MenberSidebar";
import GroupAdmin from "../GroupAdmin/GroupAdmin";
import Oneg8Post from "../Oneg8Post/Oneg8Post";
import UserPost from "../UserProfile/UserPost";
import SinglePost from "../UserProfile/SinglePost";
import SavePost from "../SavePostVlog/SavePost";
import Reels from "../Reels/Reels";
import ProfileReels from "../Profile/ProfileReels";
import SaveReels from "../SavePostVlog/SaveReels";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import WalletHome from "../Wallet/WalletHome";
import Overview from "../Wallet/Overview";
import SendScreen from "../Wallet/SendScreen";
import ReciveScreen from "../Wallet/ReciveScreen";
import DataG8Home from "../DataG8/DataG8Home";
import Trash from "../DataG8/Trash";
import SocialMedia from "../DataG8/SocialMedia";
import D8Messanger from "../DataG8/D8Messanger";
import DataG8Favourites from "../DataG8/Datag8Favourites";
import ShareWithMe from "../DataG8/ShareWithMe";
import TestHome from "../test/testcomponent.js";
// import Drive from "../Drive/index.jsx";
import DataG8 from '../DataG8';
import Dummyencrypt from '../DataG8/dummyencryption';
import NewReel from '../Reels/NewReel';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../TermsConditions/TermsConditions';




export default function RouteData() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/accountsetup" element={<AccountSetup />} />
          <Route path="/homescreen" element={<HomeScreen />} />
          <Route path="/sidebar" element={<Sidebar />} />
          <Route path="/reels" element={<Reels />} />
          <Route path="/searchsidebar" element={<SearchSidebar />} />

          <Route path="/userprofile/:id" element={<UserProfile />} />
          <Route path="/userprofile" element={<UserProfile />} />
          
          <Route path="/singlePost/:id" element={<SinglePost />} />
          <Route path="/userpost/:id" element={<UserPost />} />
          <Route path="/userpost" element={<UserPost />} />

          <Route path="/notification" element={<Notification />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/profilepost/:id" element={<ProfilePost />} />
          <Route path="/profilepost" element={<ProfilePost />} />

          <Route path="/userreels/:id" element={<ProfileReels />} />
          <Route path="/userreels" element={<ProfileReels />} />

          <Route path="/savereels" element={<SaveReels />} />

          <Route path="/profilevlog" element={<ProfileVlog />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/location" element={<Location />} />
          <Route path="/bprofile" element={<BusinessProfile />} />
          <Route path="/calender" element={<Calender />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/message" element={<Message />} />
          <Route path="/conference" element={<Conferance />} />
          <Route path="/membersidebar" element={<MemberSidebar />} />
          <Route path="/groupadmin" element={<GroupAdmin />} />
          <Route path="/oneg8post" element={<Oneg8Post />} />

          <Route path="/savepost" element={<SavePost />} />
          <Route path="/delete_account" element={<DeleteAccount />} />


          <Route path="/WalletHome" element={<WalletHome />} />
          <Route path="/Overview" element={<Overview />} />
          <Route path="/SendScreen" element={<SendScreen />} />
          <Route path="/ReciveScreen" element={<ReciveScreen />} />
          {/* <Route path="/drive" element={<Drive />} /> */}





          <Route path='/datag8' element={<DataG8 />}>
          <Route path="" element={<DataG8Home />} />
           <Route path="Trash" element={<Trash />} />
          <Route path="SocialMedia" element={<SocialMedia />} />
          <Route path="D8Messanger" element={<D8Messanger />} />
          <Route path="favourites" element={<DataG8Favourites />} />
          <Route path="share-with-me" element={<ShareWithMe />} />
          {/* <Route path="/test" element={<TestHome />} /> */}
          </Route>

          <Route path="/encrypt" element={<Dummyencrypt />} />

          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />}></Route>
          <Route path='/TermsConditions' element={<TermsConditions />}></Route>




          <Route path="/newreel" element={<NewReel />} />




        </Routes>
      </BrowserRouter>
    </>
  );
}

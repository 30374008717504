import React, { useState } from 'react'
import "./Setting.css"
import "../Login/Login.css"
import Sidebar from '../Sidebar/Sidebar'
import bar from "../assets/img/Sidebar/menu (1).png"
import { Col, Container, Form, Row } from "react-bootstrap";
import go from "../assets/img/Sidebar/ONEG8-Psd-20 1.png"
import arrow from "../assets/img/Sidebar/back-arrow.png"
import uploadimg from "../assets/img/Sidebar/upload.png"
import move from "../assets/img/Setting/delete.png"
import { clsx } from 'clsx';
// import Switch from '@material-ui/core/Switch';
// import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
import Modal from 'react-bootstrap/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Spinner from "react-bootstrap/Spinner";
import { toast } from 'react-toastify';
import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Circles } from 'react-loader-spinner'
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"


let x = []
function Setting() {
    let location = useLocation()
    let navigate = useNavigate()

    // const lovelyStyles = useLovelySwitchStyles();

    const [firstName, setFirstname] = useState("")
    const [lastName, setLastname] = useState("")
    const [gender, setGender] = useState("")
    const [date, setDate] = useState("")
    const [mobilenumber, setMobilenumber] = useState("")
    const [images, setImages] = useState("")
    const [img, setimg] = useState("")
    const [currentPassword, setCurrentpassword] = useState("")
    const [newPassword, setNewpassword] = useState("")
    const [confirmPassword, setConfirmpassword] = useState("")
    const [accountType, setAccountType] = useState("")

    const [isloading, setIsloading] = useState(false)
    const [mypublic, setMyPublic] = useState(false)
    const [MyPrivet, setMyPrivet] = useState(false)
    const [accountDelete, setAccountDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [toggled1, setToggled1] = useState(0);
    const [toggled2, setToggled2] = useState(0);
    const [toggled3, setToggled3] = useState(0);
    const [toggled4, setToggled4] = useState(0);
    const [toggled5, setToggled5] = useState(0)
    const [isFlag, setIsFlag] = useState(1)
    const [isSubFlag, setIsSubFlag] = useState(0)
    const [isChange, setIsChange] = useState(1)

    const [type, setType] = useState("password");
    const [type1, setType1] = useState("password");
    const [type2, setType2] = useState("password");
    const [icon, setIcon] = useState("bi bi-eye-slash-fill");
    const [icon1, setIcon1] = useState("bi bi-eye-slash-fill");
    const [icon2, setIcon2] = useState("bi bi-eye-slash-fill");
    const [account, setAccount] = useState("PUBLIC")

    const [savePost, setSavepost] = useState([])
    const [saveVlog, setSavevlog] = useState([])
    const [blockUserData, setBlockUserData] = useState([])
    const [reelsView, setReelsview] = useState([])

    const [userData, setUserData] = useState({})

    const notifyDatas = [
        'is_all_push_notification',
        'is_post_comment_notification',
        'is_follow_notification',
        'is_message_notification',
        'is_oneg8_notification'
    ]

    const savePosts = (type) => {
        navigate("/savepost", { state: { Type: type } })
    }

    const saveReels = () => {
        navigate("/savereels")
    }

    const switchHandler = (e, type) => {
        var values = e.target.checked == true ? "1" : "0";;
        if (type == 1) {
            setToggled1(values)
            IsHandleSubmit(e, notifyDatas[type - 1], values)
        } else if (type == 2) {
            setToggled2(values)
            IsHandleSubmit(e, notifyDatas[type - 1], values)
        } else if (type == 3) {
            setToggled3(values)
            IsHandleSubmit(e, notifyDatas[type - 1], values)
        } else if (type == 4) {
            setToggled4(values)
            IsHandleSubmit(e, notifyDatas[type - 1], values)
        } else if (type == 5) {
            setToggled5(values)
            IsHandleSubmit(e, notifyDatas[type - 1], values)
        }
    }

    const handleOptionChange = (event) => {
        setAccount(event.target.value);
    }

    const show = () => {
        type === "password" ? setType("text") : setType("password");
        icon === "bi bi-eye-slash-fill" ? setIcon("bi bi-eye-fill") : setIcon("bi bi-eye-slash-fill")
    }
    const show1 = () => {
        type1 === "password" ? setType1("text") : setType1("password");
        icon1 === "bi bi-eye-slash-fill" ? setIcon1("bi bi-eye-fill") : setIcon1("bi bi-eye-slash-fill")
    }
    const show2 = () => {
        type2 === "password" ? setType2("text") : setType2("password");
        icon2 === "bi bi-eye-slash-fill" ? setIcon2("bi bi-eye-fill") : setIcon2("bi bi-eye-slash-fill")
    }

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    const getBase64 = (file) => {
        x = []
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // console.log(reader.result);
            x.push(reader.result)
            setImages(x)
        }
    }

    const handlefile = (image) => {
        setimg(image[0])
        setImages([])
        for (let i = 0; i < image.length; i++) {
            getBase64(image[i])
        }
    }

    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // Format date as 'yyyy-mm-dd'

    if (localStorage.getItem("UserToken")) {

        let UToken = localStorage.getItem("UserToken")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", UToken);

    }

    //Get edit profile
    const HandleSubmit = (e) => {

        let jsonData = {
            user_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "edit_profile", {
            method: 'POST',
            body: JSON.stringify({ "editData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    const decrypt = decryptData(response.info);
                    setFirstname(decrypt.first_name)
                    setLastname(decrypt.last_name)
                    setGender(decrypt.gender)
                    setDate(decrypt.date_of_birth)
                    setMobilenumber(decrypt.phone_number)
                    setImages(decrypt.profile_pic ? IMG_URL + decrypt.profile_pic : "")
                    setAccount(decrypt.account_type)
                    setToggled1(decrypt.is_all_push_notification)
                    setToggled2(decrypt.is_post_comment_notification)
                    setToggled3(decrypt.is_follow_notification)
                    setToggled4(decrypt.is_message_notification)
                    setToggled5(decrypt.is_oneg8_notification)
                    setUserData(decrypt)
                    console.log("account type >>>", decrypt)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //save post list
    const HandlePost = (e) => {
        setIsLoading(true)
        let jsonData = {
            page_no: 1,
            post_type: "POST"
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_bookmark_post", {
            method: 'POST',
            body: JSON.stringify({ "bookmarkData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                } else {
                    console.log(response, "yes")
                    let key = response.key
                    const decrypt = decryptData(key);
                    setSavepost(decrypt.info)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
    }

    //save vlog list
    const HandleVlog = (e) => {
        setIsLoading(true)
        let jsonData = {
            page_no: 1,
            post_type: "VLOG"
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_bookmark_post", {
            method: 'POST',
            body: JSON.stringify({ "bookmarkData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                } else {
                    console.log(response, "yes")
                    let key = response.key
                    const decrypt = decryptData(key);
                    setSavevlog(decrypt.info)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
    }

    //save reels list
    const MyProfileReelsList = (e) => {
        setIsLoading(true)
        let jsonData = {
            page_no: 1,
            post_type: 'REEL',
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_bookmark_post", {
            method: 'POST',
            body: JSON.stringify({ "bookmarkData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setReelsview(decrypt.info)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
    }

    // Edit profile
    const IsHandleSubmit = (e, type, value) => {
        e.preventDefault();
        setIsloading(true);
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (img) {
            formdata.append("profile_pic", img);
        }
        let jsonData = {
            first_name: firstName,
            last_name: lastName,
            gender: gender,
            date_of_birth: date,
            country_code: 91,
            phone_number: mobilenumber,
            user_id: localStorage.getItem("UserId"),
            account_type: accountType,
        }
        jsonData[type] = value;
        console.log('jsonData >>', jsonData);
        const encrypt = encryptData(jsonData);
        formdata.append("editData", encrypt)

        fetch(BASE_URL + "edit_profile", {
            method: 'POST',
            body: formdata,
            headers: myHeaders1,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    toast.error(response.Message);
                } else {
                    const decrypt = decryptData(encrypt);
                    console.log(response, "yes")
                    setIsloading(false)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })
    }

    //change password
    const HandlePassword = (e) => {
        e.preventDefault();
        setIsloading(true);
        let jsonData = {
            password: currentPassword,
            new_pass: newPassword,
        }
        const encrypt = encryptData(jsonData);
        console.log('encrypt', encrypt);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("UserToken"));

        if (newPassword == confirmPassword) {
            fetch(BASE_URL + "change_password", {
                method: 'POST',
                body: JSON.stringify({ "changeData": encrypt }),
                headers: myHeaders
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        toast.error(response.Message);
                        setIsloading(false)
                    } else {
                        console.log(response, "yes")
                        toast.success(response.Message);
                        const decrypt = decryptData(encrypt);
                        setCurrentpassword("")
                        setConfirmpassword("")
                        setNewpassword("")
                    }
                    setIsloading(false)
                })
                .catch((error) => {
                    console.log(error);
                    setIsloading(false)
                })
        }
        else {
            toast.error("Don't match password");
            setTimeout(() => {
                setIsloading(false)
            }, 1000);
        }
    }

    // block list API
    const handleBlock = (e) => {
        let jsonData = {
            page_no: 1,
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_block_user", {
            method: 'POST',
            body: JSON.stringify({ "blockData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response, "yes")
                    let key = response.key
                    const decrypt = decryptData(key);
                    setBlockUserData(decrypt.info)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //handle Unblock API
    const handleUnblock = (id, index) => {
        let list = [...blockUserData]
        list.splice(index, 1)
        setBlockUserData(list)
        let jsonData = {
            user_id: id,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "block_unblock_user", {
            method: 'POST',
            body: JSON.stringify({ "blockData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    toast.error(response.Message)
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} className='sidebar-menu' alt="" />
            <Sidebar />

            <div className='setting-data'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} className='setting-part1'>
                            <div className='set-part-1 p-4'>
                                <p className='setting-head'>Settings</p>

                                <div className='setting-sidebar'>
                                    <p className={clsx([isFlag === 1 ? "active-menu" : "setting-menu"])} onClick={() => { setIsFlag(1); setIsSubFlag(0); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M13 11.1504H7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2 11.1498V6.52985C2 4.48985 3.65 2.83984 5.69 2.83984H11.31C13.35 2.83984 15 4.10984 15 6.14984" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17.48 12.2004C16.98 12.6804 16.74 13.4204 16.94 14.1804C17.19 15.1104 18.11 15.7004 19.07 15.7004H20V17.1504C20 19.3604 18.21 21.1504 16 21.1504H6C3.79 21.1504 2 19.3604 2 17.1504V10.1504C2 7.94039 3.79 6.15039 6 6.15039H16C18.2 6.15039 20 7.95039 20 10.1504V11.6003H18.92C18.36 11.6003 17.85 11.8204 17.48 12.2004Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M22 12.6196V14.6796C22 15.2396 21.5399 15.6996 20.9699 15.6996H19.0399C17.9599 15.6996 16.97 14.9097 16.88 13.8297C16.82 13.1997 17.0599 12.6096 17.4799 12.1996C17.8499 11.8196 18.36 11.5996 18.92 11.5996H20.9699C21.5399 11.5996 22 12.0596 22 12.6196Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        My Wallet</p>
                                    <p className={clsx([isFlag === 2 ? "active-menu" : "setting-menu"])} onClick={() => { setIsFlag(2); setIsSubFlag(0); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18.5 12.6504V16.3504C18.5 19.4704 15.59 22.0004 12 22.0004C8.41 22.0004 5.5 19.4704 5.5 16.3504V12.6504C5.5 15.7704 8.41 18.0004 12 18.0004C15.59 18.0004 18.5 15.7704 18.5 12.6504Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M18.5 7.65C18.5 8.56 18.25 9.4 17.81 10.12C16.74 11.88 14.54 13 12 13C9.46 13 7.26 11.88 6.19 10.12C5.75 9.4 5.5 8.56 5.5 7.65C5.5 6.09 6.22999 4.68 7.39999 3.66C8.57999 2.63 10.2 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M18.5 7.65V12.65C18.5 15.77 15.59 18 12 18C8.41 18 5.5 15.77 5.5 12.65V7.65C5.5 4.53 8.41 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Purchase G8 Coins</p>
                                    <p className={clsx([isFlag === 3 ? "active-menu" : "setting-menu"])} onClick={() => { setIsFlag(3); setIsSubFlag(0); HandleSubmit() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M19.2101 15.741L15.67 19.281C15.53 19.421 15.4 19.681 15.37 19.871L15.18 21.221C15.11 21.711 15.45 22.051 15.94 21.981L17.29 21.791C17.48 21.761 17.75 21.631 17.88 21.491L21.42 17.951C22.03 17.341 22.32 16.631 21.42 15.731C20.53 14.841 19.8201 15.131 19.2101 15.741Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M18.7001 16.25C19.0001 17.33 19.84 18.17 20.92 18.47" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Account Settings</p>
                                    <p className={clsx([isFlag === 4 ? "active-menu" : "setting-menu"])} onClick={() => { setIsFlag(4); setIsSubFlag(0); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M22 10.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H14" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5L15.06 10.56" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Interactions</p>
                                    <p className={clsx([isFlag === 5 ? "active-menu" : "setting-menu"])} onClick={() => { setIsFlag(5); setIsSubFlag(0); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11 19.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11 12.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11 5.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 5.5L4 6.5L7 3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 12.5L4 13.5L7 10.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 19.5L4 20.5L7 17.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        About ONG8</p>
                                </div>
                            </div>
                            <div className='vr text-light'></div>
                            {/* set-line */}

                            {
                                isFlag === 1 ?
                                    <div className='set-part-2'>


                                        <p className='setting-head'>my Wallet</p>

                                        <div className='boost-head'>
                                            <div className='boost-coins'>
                                                <p className='coin-1'>Available G8 Coins</p>
                                                <p className='coin-2'>2500</p>
                                            </div>
                                            <div>
                                                <img src={go} className='boost-go' />
                                            </div>
                                        </div>

                                        <div className='setting-mid-1 pt-4'>
                                            <p>GB Coins</p>
                                        </div>
                                        <div>
                                            <div className='wallet-list'>
                                                <p className='wallet-list-1'>Spend minimum 20 Minutes</p>
                                                <div className='d-flex wallet-list-2'>
                                                    <p className='m-0'>10</p>
                                                    <img src={go} width={16} height={16} />
                                                </div>
                                            </div>
                                            <div className='wallet-list'>
                                                <p className='wallet-list-1'>Spend minimum 20 Minutes</p>
                                                <div className='d-flex wallet-list-2'>
                                                    <p className='m-0'>10</p>
                                                    <img src={go} width={16} height={16} />
                                                </div>
                                            </div>
                                            <div className='wallet-list'>
                                                <p className='wallet-list-1'>Spend minimum 20 Minutes</p>
                                                <div className='d-flex wallet-list-2'>
                                                    <p className='m-0'>10</p>
                                                    <img src={go} width={16} height={16} />
                                                </div>
                                            </div>
                                            <div className='wallet-list'>
                                                <p className='wallet-list-1'>Spend minimum 20 Minutes</p>
                                                <div className='d-flex wallet-list-2'>
                                                    <p className='m-0'>10</p>
                                                    <img src={go} width={16} height={16} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    : isFlag === 2 ?

                                        <div className='set-part-2'>
                                            <p className='setting-head'>Purchase Get Coins</p>

                                            <div className='purchs-content'>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>
                                                <label className='purchs-data'>
                                                    <input type='radio' className='purchs-radio' name='gender' />
                                                    <img src={go} width={40} />
                                                    <p>100 GB Coins</p>
                                                    <p>$10</p>
                                                </label>

                                                <button className='boost-btn'>Purchase</button>
                                            </div>
                                        </div>

                                        : isFlag === 3 ?
                                            isSubFlag === 0 ?

                                                <div className='set-part-2'>
                                                    <p className='setting-head'>Purchase Get Coins</p>

                                                    <div>
                                                        <div className='account-plate' onClick={() => setIsSubFlag(1)}>
                                                            <p>Account Type</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => setIsSubFlag(2)}>
                                                            <p>Edit Profile</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => setIsSubFlag(3)}>
                                                            <p>Change Password</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => { setIsSubFlag(4); HandlePost() }}>
                                                            <p>Saved</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => setIsSubFlag(5)}>
                                                            <p>Notifications</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => { setIsSubFlag(6); handleBlock() }}>
                                                            <p>Blocked Users</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className='account-plate' onClick={() => setAccountDelete(true)}>
                                                            <p>Delete Account</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>

                                                : isSubFlag === 1 ?
                                                    <div className='set-part-2'>
                                                        <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                        <p className='setting-head'>Account Type</p>
                                                        <p className='acc-type-text'>Select Your Account Type</p>
                                                        <div className='type-acc'>
                                                            <label className='intraction-plate'>
                                                                <p>Public</p>
                                                                <input type='radio' className='purchs-radio' value="PUBLIC"
                                                                    checked={account === "PUBLIC"} onChange={handleOptionChange} name='acctype'
                                                                    onClick={() => { setMyPublic(true); setAccountType("PUBLIC") }}
                                                                />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>Private</p>
                                                                <input type='radio' className='purchs-radio' value="PRIVATE"
                                                                    checked={account === "PRIVATE"} onChange={handleOptionChange} name='acctype'
                                                                    onClick={() => { setMyPrivet(true); setAccountType("PRIVATE") }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    : isSubFlag === 2 ?
                                                        <div className='set-part-2'>
                                                            <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                            <p className='setting-head'>Edit Profile</p>

                                                            <form action="" onSubmit={IsHandleSubmit}>

                                                                <div className='mt-4 mb-4 d-flex align-items-center'>
                                                                    {images ?
                                                                        <>
                                                                            <div className='d-flex'>
                                                                                <img src={images} width={100} height={100} style={{ objectFit: "cover", borderRadius: "10px" }} alt="" />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <img src={uploadimg} width={100} height={100} alt="" />
                                                                        </>
                                                                    }
                                                                    <input type="file" id="uploadedit" hidden onChange={(e) => handlefile(e.target.files)} name="img" />
                                                                    <label for="uploadedit" className='inp-value1 ms-4'>Upload</label>
                                                                </div>
                                                                <div className='editprofile-inp'>
                                                                    <div className='d-flex mt-3'>
                                                                        <div className="floating-label-input me-2">
                                                                            <input id="text" type="text" className='new-put' value={firstName} onChange={(e) => setFirstname(e.target.value)} autoComplete="off" placeholder=" " />
                                                                            <label className="floating-label">First Name</label>
                                                                        </div>
                                                                        <div className="floating-label-input ms-2">
                                                                            <input id="text" type="text" className='new-put' value={lastName} onChange={(e) => setLastname(e.target.value)} autoComplete="off" placeholder=" " />
                                                                            <label className="floating-label">Last Name</label>
                                                                        </div>

                                                                    </div>

                                                                    <Form.Select className="i-put-select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                                        <option className="gen-der">Gender</option>
                                                                        <option value="1">Men</option>
                                                                        <option value="2">Woman</option>
                                                                    </Form.Select>

                                                                    <div className="lgn-input-date">
                                                                        <input type="date" id="date" placeholder="Date Of Birth" max={formattedToday} value={date} onChange={(e) => setDate(e.target.value)} className="i-put-date" />
                                                                        <label for="text">
                                                                            <span>Date Of Birth</span>
                                                                        </label>
                                                                    </div>

                                                                    <div className="country-number">
                                                                        <PhoneInput
                                                                            international
                                                                            withCountryCallingCode
                                                                            countryCallingCodeEditable={false}
                                                                            defaultCountry="US"
                                                                            initialValueFormat="national"
                                                                            value={mobilenumber}
                                                                            onChange={setMobilenumber}
                                                                        />
                                                                    </div>

                                                                    <button type='submit' className="bt-1 mt-5">
                                                                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                                                            < span className="visually-hidden">Loading...</span>
                                                                        </Spinner> : "Save"}
                                                                    </button>

                                                                </div>
                                                            </form>
                                                        </div>
                                                        : isSubFlag === 3 ?
                                                            <div className='set-part-2'>
                                                                <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                                <p className='setting-head'>Change Password</p>

                                                                <form action="" onSubmit={HandlePassword}>

                                                                    <div className="floating-label-input">
                                                                        <input id="password" type={type} className='new-put' value={currentPassword} minLength={8} onChange={(e) => setCurrentpassword(e.target.value)} autoComplete="off" required placeholder=" " />
                                                                        <label className="floating-label">Current Password</label>
                                                                        <span className="pass-eye1" style={{ left: "260px" }}>
                                                                            <i onClick={show} className={icon}></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="floating-label-input">
                                                                        <input id="password" type={type1} className='new-put' value={newPassword} minLength={8} onChange={(e) => setNewpassword(e.target.value)} autoComplete="off" required placeholder=" " />
                                                                        <label className="floating-label">New Password</label>
                                                                        <span className="pass-eye1" style={{ left: "284px" }}>
                                                                            <i onClick={show1} className={icon1}></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="floating-label-input">
                                                                        <input id="password" type={type2} className='new-put' value={confirmPassword} minLength={8} onChange={(e) => setConfirmpassword(e.target.value)} autoComplete="off" required placeholder=" " />
                                                                        <label className="floating-label">Confirm New Password</label>
                                                                        <span className="pass-eye1" style={{ left: "220px" }}>
                                                                            <i onClick={show2} className={icon2}></i>
                                                                        </span>
                                                                    </div>

                                                                    <button type='submit' className="bt-1 mt-4">
                                                                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                                                            < span className="visually-hidden">Loading...</span>
                                                                        </Spinner> : "Save"}
                                                                    </button>
                                                                </form>

                                                            </div>
                                                            : isSubFlag === 4 ?
                                                                <div className='set-part-2'>
                                                                    <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                                    <p className='setting-head'>Saved</p>

                                                                    <div className='datapro-pvo'>
                                                                        <p className={clsx([isChange === 1 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(1)}>Posts</p>
                                                                        <p className={clsx([isChange === 2 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(2); HandleVlog() }}>Vlogs</p>
                                                                        <p className={clsx([isChange === 3 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(3); MyProfileReelsList() }}>Reels</p>
                                                                    </div>

                                                                    <span style={{ color: "#838287" }}>
                                                                        <hr />
                                                                    </span>

                                                                    {
                                                                        isChange === 1 ?

                                                                            <>
                                                                                <Container style={{ width: "500px" }}>
                                                                                    <Row>
                                                                                        {
                                                                                            isLoading ?
                                                                                                <Circles
                                                                                                    height="40"
                                                                                                    width="40"
                                                                                                    color="#fff"
                                                                                                    ariaLabel="circles-loading"
                                                                                                    wrapperStyle={{}}
                                                                                                    visible={true}
                                                                                                    wrapperClass="profile-loader"
                                                                                                />
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        savePost ==
                                                                                                            ""
                                                                                                            ?
                                                                                                            <p className='text-light d-flex justify-content-center mt-5' >No Data Found</p>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    savePost.map((item) => {
                                                                                                                        return (
                                                                                                                            <Col lg={4} md={4} sm={4}>
                                                                                                                                {
                                                                                                                                    item.post[0].thumbnail != null ?
                                                                                                                                        <video src={IMG_URL + item.post[0].image} onClick={() => savePosts('POST')} className='img-pic'></video>
                                                                                                                                        :
                                                                                                                                        <img src={IMG_URL + item.post[0].image} onClick={() => savePosts('POST')} className='img-pic' alt="" />
                                                                                                                                }
                                                                                                                            </Col>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                        {/* <p onClick={onScroll} className='text-light fs-5 d-flex justify-content-center'>view more</p> */}

                                                                                    </Row>
                                                                                </Container>
                                                                            </>

                                                                            : isChange === 2 ?

                                                                                <>
                                                                                    <Container style={{ width: "500px" }}>
                                                                                        <Row>
                                                                                            {
                                                                                                isLoading ?
                                                                                                    <Circles
                                                                                                        height="40"
                                                                                                        width="40"
                                                                                                        color="#fff"
                                                                                                        ariaLabel="circles-loading"
                                                                                                        wrapperStyle={{}}
                                                                                                        visible={true}
                                                                                                        wrapperClass="profile-loader"
                                                                                                    />
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            saveVlog ==
                                                                                                                ""
                                                                                                                ?
                                                                                                                <p className='text-light d-flex justify-content-center mt-5' >No Data Found</p>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        saveVlog.map((item) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        item.post.map((i) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <Col lg={4} md={4} sm={4}>
                                                                                                                                                        <video className='img-pic' onClick={() => savePosts('VLOG')} >
                                                                                                                                                            <source src={IMG_URL + i.image} />
                                                                                                                                                        </video>
                                                                                                                                                    </Col>
                                                                                                                                                </>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }

                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                            {/* <p onClick={onScroll1} className='text-light fs-5 d-flex justify-content-center'>view more</p> */}
                                                                                        </Row>
                                                                                    </Container>

                                                                                </>
                                                                                :

                                                                                <Container style={{ width: "500px" }}>
                                                                                    <Row>
                                                                                        {
                                                                                            isLoading ?
                                                                                                <Circles
                                                                                                    height="40"
                                                                                                    width="40"
                                                                                                    color="#fff"
                                                                                                    ariaLabel="circles-loading"
                                                                                                    wrapperStyle={{}}
                                                                                                    visible={true}
                                                                                                    wrapperClass="profile-loader"
                                                                                                />
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        reelsView == "" ?
                                                                                                            <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    reelsView.map((item, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <Col lg={4} md={4} sm={4} key={index} onClick={saveReels}>
                                                                                                                                    {item.post && item.post.length > 0 && item.post[0].thumbnail ? (
                                                                                                                                        <img src={IMG_URL + item.post[0].thumbnail} className='img-pic1' alt="" />
                                                                                                                                    ) : (
                                                                                                                                        // Render alternative content if image is not available
                                                                                                                                        <img src={placeholderimg} className='img-pic1' alt="" />
                                                                                                                                    )}
                                                                                                                                </Col >
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                        }

                                                                                        {/* <p onClick={onScroll1} className='text-light fs-5 d-flex justify-content-center'>view more</p> */}

                                                                                    </Row >
                                                                                </Container>


                                                                    }
                                                                </div>
                                                                : isSubFlag === 5 ?
                                                                    <div className='set-part-2'>
                                                                        <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                                        <p className='setting-head'>Notifications</p>
                                                                        <div className='type-acc'>
                                                                            <div className='notification-plate'>
                                                                                <p>Push Notifications</p>
                                                                                {/* <Switch
                                                                                    classes={lovelyStyles}
                                                                                    name="is_all_push_notification"
                                                                                    defaultChecked={toggled1 === 1 ? true : false}
                                                                                    onChange={(e) => switchHandler(e, 1)}
                                                                                /> */}
                                                                            </div>
                                                                            <div className='notification-plate'>
                                                                                <p>Posts, Stories and Comments</p>
                                                                                {/* <Switch
                                                                                    classes={lovelyStyles}
                                                                                    name='is_post_comment_notification'
                                                                                    defaultChecked={toggled2 === 1 ? true : false}
                                                                                    onChange={(e) => switchHandler(e, 2)}
                                                                                /> */}
                                                                            </div>
                                                                            <div className='notification-plate'>
                                                                                <p>Follow, Following</p>
                                                                                {/* <Switch
                                                                                    classes={lovelyStyles}
                                                                                    name='is_follow_notification'
                                                                                    defaultChecked={toggled3 === 1 ? true : false}
                                                                                    onChange={(e) => switchHandler(e, 3)}
                                                                                /> */}
                                                                            </div>
                                                                            <div className='notification-plate'>
                                                                                <p>Messages</p>
                                                                                {/* <Switch
                                                                                    classes={lovelyStyles}
                                                                                    name='is_message_notification'
                                                                                    defaultChecked={toggled4 === 1 ? true : false}
                                                                                    onChange={(e) => switchHandler(e, 4)}
                                                                                /> */}
                                                                            </div>
                                                                            <div className='notification-plate'>
                                                                                <p>OneG8</p>
                                                                                {/* <Switch
                                                                                    classes={lovelyStyles}
                                                                                    name='is_oneg8_notification'
                                                                                    defaultChecked={toggled5 === 1 ? true : false}
                                                                                    onChange={(e) => switchHandler(e, 5)}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='set-part-2'>
                                                                        <img src={arrow} width={25} className='pb-3 back-arrow' onClick={() => setIsSubFlag(0)} />
                                                                        <p className='setting-head'>Blocked Users</p>
                                                                        {
                                                                            blockUserData == "" ?
                                                                                <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        blockUserData.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className='block-plate' key={index}>
                                                                                                        <div className='d-flex'>
                                                                                                            <img src={IMG_URL + item.profile_pic} className='rounded object-fit-cover' width={50} />
                                                                                                            <div className='block-plate-1 ps-3'>
                                                                                                                <div className='d-flex align-items-cenetr'>
                                                                                                                    <p>{item.first_name}</p>
                                                                                                                    <p className='ps-2'>{item.last_name}</p>
                                                                                                                </div>
                                                                                                                <p>Blocked</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <button className='unblock-btn' onClick={() => handleUnblock(item.block_to, index)}>Unblock</button>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </div>

                                            : isFlag === 4 ?
                                                <div className='set-part-2'>
                                                    <p className='setting-head'>Interactions</p>

                                                    <div>
                                                        <div className='intraction-data'>
                                                            <p className='intraction-text'>Message</p>
                                                            <p className='intraction-new'>Who is allowed to message you?</p>
                                                            <label className='intraction-plate'>
                                                                <p>Evreyone</p>
                                                                <input type='radio' name='message' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>People You Follow</p>
                                                                <input type='radio' name='message' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>Your Followers</p>
                                                                <input type='radio' name='message' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>You Follow & Your Followers</p>
                                                                <input type='radio' name='message' className='purchs-radio' />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='intraction-data'>
                                                            <p className='intraction-text'>Comments</p>
                                                            <p className='intraction-new'>Who is allowed to comments on your posts?</p>
                                                            <label className='intraction-plate'>
                                                                <p>Evreyone</p>
                                                                <input type='radio' name='comment' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>People You Follow</p>
                                                                <input type='radio' name='comment' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>Your Followers</p>
                                                                <input type='radio' name='comment' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>You Follow & Your Followers</p>
                                                                <input type='radio' name='comment' className='purchs-radio' />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='intraction-data'>
                                                            <p className='intraction-text'>Calls</p>
                                                            <p className='intraction-new'>Who is allowed to call you?</p>
                                                            <label className='intraction-plate'>
                                                                <p>Evreyone</p>
                                                                <input type='radio' name='call' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>People You Follow</p>
                                                                <input type='radio' name='call' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>Your Followers</p>
                                                                <input type='radio' name='call' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>You Follow & Your Followers</p>
                                                                <input type='radio' name='call' className='purchs-radio' />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='intraction-data'>
                                                            <p className='intraction-text'>Video Call</p>
                                                            <p className='intraction-new'>Who is allowed to Video call you?</p>
                                                            <label className='intraction-plate'>
                                                                <p>Evreyone</p>
                                                                <input type='radio' name='video' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>People You Follow</p>
                                                                <input type='radio' name='video' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>Your Followers</p>
                                                                <input type='radio' name='video' className='purchs-radio' />
                                                            </label>
                                                            <label className='intraction-plate'>
                                                                <p>You Follow & Your Followers</p>
                                                                <input type='radio' name='video' className='purchs-radio' />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='set-part-2'>
                                                    <p className='setting-head'>About OneG8</p>


                                                    <Link className={location.pathname === "/TermsConditions" } to={"/TermsConditions"}>
                                                    <div className='account-plate'>
                                                        <p>Terms & Conditions</p>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                    </Link>
                                                    <Link className={location.pathname === "/PrivacyPolicy" } to={"/PrivacyPolicy"}>
                                                    <div className='account-plate'>
                                                        <p>Privacy Policy</p>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                    </Link>


                                                </div>

                            }


                        </Col>
                    </Row>
                </Container >

                {/* public account */}
                <Modal show={mypublic} onHide={() => setMyPublic(false)} centered>
                    <div className='setting-public'>
                        <p className=''>Are you absolutely certain that you want <br />
                            to proceed with changing your account <br />
                            type to Public?</p>
                        <button className="bt-1 mt-4" onClick={(e) => { setMyPublic(false); IsHandleSubmit(e) }}>OK</button>
                    </div>

                </Modal>

                {/* privet account */}
                <Modal show={MyPrivet} onHide={() => setMyPrivet(false)} centered>
                    <div className='setting-public'>
                        <p className=''>Are you absolutely certain that you want <br />
                            to proceed with changing your account <br />
                            type to Privet?</p>
                        <button className="bt-1 mt-4" onClick={(e) => { setMyPrivet(false); IsHandleSubmit(e) }}>OK</button>
                    </div>

                </Modal>

                <Modal show={accountDelete} onHide={() => setAccountDelete(false)} centered >

                    <div className='text-center mt-4 mb-4'>
                        <img src={move} width={100} />
                    </div>
                    <p className='text-center remove-acc'>
                        Are you absolutely certain that you <br />
                        want to proceed with deleting <br />
                        your account?</p>
                    <div className='kyc-con-btn'>
                        <button className="delete-btn" onClick={() => setAccountDelete(false)}>Yes, Delete</button>
                    </div>



                </Modal>
            </div >
        </>
    )
}

export default Setting;

import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider,OAuthProvider} from "firebase/auth";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCGyyykRSw6HISxtd6NcS4tV918n0iOHik",
  authDomain: "oneg8-b51e4.firebaseapp.com",
  projectId: "oneg8-b51e4",
  storageBucket: "oneg8-b51e4.appspot.com",
  messagingSenderId: "736061687100",
  appId: "1:736061687100:web:aba9a95b2df6ba1be40a39",
  measurementId: "G-17F4HMMWJW"
}; 

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const googleprovider = new GoogleAuthProvider();
const appleprovider = new OAuthProvider('apple.com');

export {auth,googleprovider,appleprovider,messaging,firebaseConfig};
import React, { useEffect, useRef, useState } from 'react'
import 'react-alice-carousel/lib/alice-carousel.css';
import "../Sidebar/Sidebar.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SearchSidebar from '../Search/SearchSidebar'
import Notification from '../Notification/Notification'
import Modal from 'react-bootstrap/Modal';
import playvlog from "../assets/img/Sidebar/play-circle.png"
import createpost from "../assets/img/Sidebar/Feed.png"
import createoneg8 from "../assets/img/Sidebar/video-play.png"
import upload from "../assets/img/Sidebar/Upload 1.png"
import cancel from "../assets/img/Sidebar/cancel.png"
import back from "../assets/img/Sidebar/back-arrow.png"
import mans1 from "../assets/img/Sidebar/sub-pro.png"
import data8 from "../assets/img/Sidebar/ONEG8-Psd.png"
import verify1 from "../assets/img/Sidebar/verify-blue.png"
import gologo from "../assets/img/Sidebar/Mask group1.png"
import yes from "../assets/img/Sidebar/yes.png"
import Slider from "react-slick";
import Dropdown from 'react-bootstrap/Dropdown';
import member from "../assets/img/Sidebar/profile-2user.png"
import human1 from "../assets/img/Sidebar/s-1.png"
import human2 from "../assets/img/Sidebar/s-2.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import arrow from "../assets/img/Sidebar/back-arrow.png"
import move from "../assets/img/Sidebar/Ellipse 1240.png"
import log from "../assets/img/Sidebar/logout.png"
import go from "../assets/img/Sidebar/gogo.png"
import add from "../assets/img/Sidebar/addbtn.png"
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify';
import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl';
import Spinner from "react-bootstrap/Spinner";
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"
import { Form } from 'react-bootstrap';
import { Socket } from '../assets/Socket/Socket';
import { useSelector, useDispatch } from 'react-redux';

import {
    useAddPostMutation
} from '../apiService/index';
import { setcustomePostData } from '../apiService/commonPostData';


let x = []
let dm = []
export default function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const customPostData = useSelector((state) => state.customPostData);


    const [show, setShow] = useState(false)
    const [create, setCreate] = useState(false)
    const [date, setDate] = useState(false)
    const [bluteTick, setBlueTick] = useState(false)
    const [team, setTeam] = useState(false)
    const [out, setOut] = useState(false)
    const [CrtPost, setCrtPost] = useState(false)
    const [CrtStory, setCrtStory] = useState(false)
    const [CrtVlog, setCrtVlog] = useState(false)
    const [CrtOneg8, setCrtOneg8] = useState(false)
    const [CrtActivity, setCrtActivity] = useState(false)
    const [formKyc, setFormKyc] = useState(false)
    const [kycPrivet, setKycPrivet] = useState(false)
    const [kycDone, setKycDone] = useState(false)
    const [join, setJoin] = useState(false)
    const [invite, setInvite] = useState(false)
    const [remove, setRemove] = useState(false)
    const [notificationshow, setnotificationshow] = useState(false)
    const [searchsidebar, setsearchsidebar] = useState(false);
    const [isloading, setIsloading] = useState(false)

    const [type, setType] = useState([]);
    const [typeVlog, setTypevlog] = useState([]);
    const [typeReel, setTypeReel] = useState([]);
    const [img, setImg] = useState([])
    const [Reels, setReels] = useState([])
    const [imgVlog, setImgvlog] = useState([])
    const [imgReels, setImgReels] = useState([])
    const [data, setData] = useState([]);
    const [whatIs, setWhatIs] = useState("POST");

    const [vlogData, setVlogdata] = useState([])
    const [thumbnaillist, setthumbnaillist] = useState([]);
    const [thumbnaillistvlog, setthumbnaillistvlog] = useState([])
    const [thumbnaillistreel, setthumbnaillistreel] = useState([])

    const [personalData, setPersonalData] = useState({})
    const [businessData, setBusinessData] = useState({})

    const [image, setImage] = useState("")
    const [imageIdProof, setImageIdProof] = useState("")
    const [images, setImages] = useState("")
    const [imagesAddProof, setImagesAddProof] = useState("")
    const [postDescription, setPostDescription] = useState("")
    const [postDescriptionVlog, setPostDescriptionVlog] = useState("")
    const [postDescriptionReel, setPostDescriptionReel] = useState("")
    const [documetId, setDocumentId] = useState("")
    const [isCallApi, setIsCallApi] = useState(0)

    const [documetProof, setDocumentProof] = useState("")

    const [post, setPost] = useState(1)
    const [kycStep, setKycStep] = useState(1)

    const [addPost] = useAddPostMutation();


    //logout API
    const HandleClose = (e) => {
        e.preventDefault();
        setIsloading(true)

        const jsonData1 = {
            device_id: localStorage.getItem('deviceId'),
        }
        const encrypt = encryptData(jsonData1);
        console.log('encrypt', encrypt);

        fetch(BASE_URL + "log_out", {
            method: 'POST',
            body: JSON.stringify({ "logoutData": encrypt }),
            headers: myHeaders
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    //error data
                    console.log(response, "no")
                    toast.error(response.Message);
                    setIsloading(false)
                } else {

                    console.log(response, "yes")
                    toast.success(response.Message);
                    const decrypt = decryptData(encrypt);
                    console.log("decrypt", decrypt);
                    localStorage.clear();
                    navigate("/login")
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)

            })
    }

    //current user
    const HandleBio = () => {
        let jsonData = {
            user_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "edit_profile", {
            method: 'POST',
            body: JSON.stringify({ "editData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response, "yes")
                    const decrypt = decryptData(response.info);
                    setPersonalData(decrypt)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const opennotifySidebar = () => {
        setnotificationshow(true)
        document.getElementById('notifylay').style.display = 'block'
        document.getElementById('notifyside').style.marginLeft = '300px'
        document.getElementById('notifyside').style.left = '0'
    }

    const closenotifySidebar = () => {
        setnotificationshow(false)
        document.getElementById('notifylay').style.display = 'none'
        document.getElementById('notifyside').style.left = '-650px'
    }

    const opensearchSidebar = () => {
        setsearchsidebar(true)
        document.getElementById('over').style.display = 'block'
        document.getElementById('searchSidebar').style.marginLeft = '300px'
        document.getElementById('searchSidebar').style.left = '0'
    }

    const closesearchSidebar = () => {
        setsearchsidebar(false)
        document.getElementById('over').style.display = 'none'
        document.getElementById('searchSidebar').style.left = '-650px'
    }

    function managesidebar(type) {
        //1 - notification 2 - search
        if (type === 1) {
            if (notificationshow === false) {
                opennotifySidebar();
            } else {
                closenotifySidebar();
            }
            if (searchsidebar) {
                closesearchSidebar();
            }
            setIsCallApi(1)
        } else {
            if (searchsidebar === false) {
                opensearchSidebar();
            } else {
                closesearchSidebar();
            }
            if (notificationshow) {
                closenotifySidebar();
            }
            setIsCallApi(0)

        }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));

    const hanldeSidebar = () => {

        if (show) {

            setShow(false)
            document.getElementById('overlay').style.display = 'none'
            document.getElementById('sidebar').style.left = '-300px'

        } else {

            setShow(true)
            document.getElementById('overlay').style.display = 'block'
            document.getElementById('sidebar').style.left = '0'

        }
    }

    const homescreen = () => {
        navigate("/homescreen")
    }

    const handleLocation = () => {
        navigate("/location")
    }

    const handleCalender = () => {
        navigate("/calender")
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: true,
        className: "myCustomCarousel"
    };

    // create post all
    const base64ToFile = (base64String, filename, mimeType) => {
        const [contentType, base64Content] = base64String.split(',');

        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: contentType || mimeType });

        const file = new File([blob], filename, { type: contentType || mimeType });

        return file;
    };

    const handlefilepost = async (images) => {
        setData([...data, ...images]);
        const imageList = [...img];
        const typeList = [...type];
        const thumbnailList = [...thumbnaillist];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            if (file.type.includes('image')) {
                const reader = new FileReader();
                const imagePromise = new Promise((resolve) => {
                    reader.onload = (event) => {
                        imageList.push(event.target.result);
                        typeList.push(1); // Indicate image type
                        resolve();
                    };
                });
                reader.readAsDataURL(file);
                await imagePromise;
            } else if (file.type.includes('video')) {
                try {
                    const thumbnail = await generateThumbnailFromVideo(file);
                    let videoFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
                    imageList.push(URL.createObjectURL(file));
                    typeList.push(2);
                    thumbnailList.push(videoFile);
                } catch (error) {
                    console.error('Error generating thumbnail:', error);
                }
            }
        }

        setImg(imageList);
        setType(typeList);
        setthumbnaillist(thumbnailList);
    };

    const generateThumbnailFromVideo = (videoFile) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = async () => {
                const duration = video.duration;
                const middleTime = duration / 2; // Get the middle duration of the video

                // Seek to the middle time of the video
                video.currentTime = middleTime;

                // Wait for the seeking operation to complete
                await new Promise((resolve) => {
                    video.onseeked = resolve;
                });

                // Create a canvas element to draw the video frame
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);

                // Convert the canvas content to a data URL representing the thumbnail image
                const thumbnailData = canvas.toDataURL('image/jpeg');
                resolve(thumbnailData);
            };

            video.onerror = (error) => {
                reject(error);
            };

            video.src = URL.createObjectURL(videoFile);
        });
    };

    // create post image remove
    const imageRemove = (index) => {
        let image = [...img]
        image.splice(index, 1)
        setImg(image)
        let images = [...data]
        images.splice(index, 1)
        setData(images)
        let typ = [...type]
        typ.slice(index, 1)
        setType(typ)
    }

    //Create post API
    const IsCreatePost = (e) => {

        e.preventDefault();
        console.log("data >>>", data);
        console.log("thumbnail >>", thumbnaillist);
        setIsloading(true)

        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (data.length) {
            for (let index = 0; index < data.length; index++) {
                formdata.append("post", data[index]);
            }
        }
        if (thumbnaillist.length) {
            for (let j = 0; j < thumbnaillist.length; j++) {
                formdata.append("thumbnail", thumbnaillist[j]);
            }
        }

        const postData = {
            post_type: whatIs,
            post_caption: postDescription,
            type: type.toString(),
        }
        const encrypt = encryptData(postData);
        formdata.append("postData", encrypt)

        if (data == "") {
            toast.error("compulsory select Image/Video");
            setIsloading(false);
        }
        else {
            // fetch(BASE_URL + "add_post", {
            //     method: 'POST',
            //     body: formdata,
            //     headers: myHeaders1,
            // })
            //     .then(response => response.json())
            //     .then(response => {
            //         if (response.Status === 0) {
            //             console.log(response, "no")
            //             toast.error(response.Message);
            //         } else {
            //             console.log(response, "yes")
            //             toast.success(response.Message);
            //             const decrypt = decryptData(encrypt);
            //             setData([])
            //             setImg([])
            //             setCrtPost(false)
            //         }
            //         setIsloading(false)
            //         navigate("/homescreen")
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         setIsloading(false)
            //     })




            addPost(formdata).then((res) => {
                console.log(res.data)
                var response = res.data;
                if (response.Status === 0) {
                    console.log(response, "no")
                    toast.error(response.Message);
                } else {
                    console.log(response, "yes")
                    toast.success(response.Message);
                    const decrypt = decryptData(encrypt);
                    console.log('====',decrypt)
                    setData([])
                    setImg([])
                    setCrtPost(false)
                    setCrtStory(false)
                }
                setIsloading(false)
                // navigate("/homescreen")

                dispatch(setcustomePostData({ ...customPostData, postList: Date.now(),type:whatIs }));


            })
                .catch((err) => {
                    // toast.error(err.error.data.message);

                })
        }
    }

    // create vlog all
    const handlefilevlog = async (images) => {
        setVlogdata(images);
        const imageList = [];
        const typeList = [];
        const thumbnailList = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            if (file.type.includes('image')) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    imageList.push(event.target.result);
                    typeList.push(1); // Indicate image type
                };
                reader.readAsDataURL(file);
            } else if (file.type.includes('video')) {
                try {
                    const thumbnail = await generateThumbnailFromVideo(file);
                    let videoFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
                    imageList.push(URL.createObjectURL(file));
                    typeList.push(2);
                    thumbnailList.push(videoFile);
                } catch (error) {
                    console.error('Error generating thumbnail:', error);
                }
            }
        }
        setImgvlog(imageList);
        setTypevlog(typeList);
        setthumbnaillistvlog(thumbnailList);
    };

    //Create vlog API
    const IsCreateVlog = (e) => {
        e.preventDefault();
        setIsloading(true)
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (vlogData.length) {
            for (let index = 0; index < vlogData.length; index++) {
                formdata.append("post", vlogData[index]);
            }
        }

        if (thumbnaillistvlog.length) {
            for (let j = 0; j < thumbnaillistvlog.length; j++) {
                formdata.append("thumbnail", thumbnaillistvlog[j]);
                console.log("thumbnailVlog >>>", thumbnaillistvlog);
            }
        }

        const postData = {
            post_type: "VLOG",
            post_caption: postDescriptionVlog,
            type: typeVlog.toString(),
        }
        const encrypt = encryptData(postData);
        formdata.append("postData", encrypt)

        if (vlogData == "") {
            toast.error("compulsory select Video");
            setIsloading(false);
        }
        else {
            fetch(BASE_URL + "add_post", {
                method: 'POST',
                body: formdata,
                headers: myHeaders1,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        toast.error(response.Message);
                        setIsloading(false)
                    } else {
                        console.log(response, "yes")
                        toast.success(response.Message);
                        const decrypt = decryptData(encrypt);
                        setVlogdata([])
                        setImgvlog([])
                        setCrtVlog(false)
                        navigate("/homescreen")
                    }
                    setIsloading(false)
                })
                .catch((error) => {
                    console.log(error);
                    setIsloading(false)
                })
        }
    }

    const imageRemove1 = (index) => {
        let image = [...imgVlog]
        image.splice(index, 1)
        setImgvlog(image)
        let images = [...data]
        images.splice(index, 1)
        setData(images)
    }

    // craete reels all
    const imageRemove2 = (index) => {
        let image = [...Reels]
        image.splice(index, 1)
        setReels(image)
        let images = [...data]
        images.splice(index, 1)
        setData(images)
    }

    const handlefileReels = async (images) => {
        setImgReels(images);
        const imageList = [];
        const typeList = [];
        const thumbnailList = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            if (file.type.includes('image')) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    imageList.push(event.target.result);
                    typeList.push(1); // Indicate image type
                };
                reader.readAsDataURL(file);
            } else if (file.type.includes('video')) {
                try {
                    const thumbnail = await generateThumbnailFromVideo(file);
                    let videoFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
                    imageList.push(URL.createObjectURL(file));
                    typeList.push(2);
                    thumbnailList.push(videoFile);
                } catch (error) {
                    console.error('Error generating thumbnail:', error);
                }
            }
        }

        setReels(imageList);
        setTypeReel(typeList);
        setthumbnaillistreel(thumbnailList);
    };

    const IsCreateReels = (e) => {

        e.preventDefault();

        setIsloading(true)
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (imgReels.length) {
            for (let index = 0; index < imgReels.length; index++) {
                formdata.append("post", imgReels[index]);
            }
        }

        if (thumbnaillistreel.length) {
            for (let j = 0; j < thumbnaillistreel.length; j++) {
                formdata.append("thumbnail", thumbnaillistreel[j]);
            }
        }

        const postData = {
            post_type: "REEL",
            post_caption: postDescriptionReel,
            type: typeReel.toString(),
        }

        const encrypt = encryptData(postData);
        formdata.append("postData", encrypt)

        if (Reels == "") {
            toast.error("compulsory select Video");
            setIsloading(false);
        }
        else {
            fetch(BASE_URL + "add_post", {
                method: 'POST',
                body: formdata,
                headers: myHeaders1,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        toast.error(response.Message);
                        setIsloading(false)
                    } else {
                        console.log(response, "yes")
                        toast.success(response.Message);
                        const decrypt = decryptData(encrypt);
                        setImgReels([])
                        setReels([])
                        setCrtOneg8(false)
                        navigate("/reels")
                    }
                    setIsloading(false)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    // create activity
    function CreateActivity(props) {

        const [data, setData] = useState([])
        const [post, setPost] = useState(1)

        const settings = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrow: true,
            className: "myCustomCarousel"
        };

        const getBase64 = (file) => {
            x = []
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                x.push(reader.result)
                setData(x)
            }
        }

        const handlefile = (image) => {
            setData([])
            for (let i = 0; i < image.length; i++) {
                getBase64(image[i])
            }
        }

        const imageRemove = (ab) => {
            let delimage = data.filter(obj => obj != ab)
            setData(delimage)
        }

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className='crt-disc'>
                    {
                        post != 1 ?
                            <img src={back} width={25} onClick={() => setPost(post - 1)} /> : null
                    }
                    <p className='m-0'>Create Activity</p>

                    {
                        post == 1 ?

                            <button onClick={() => setPost(2)} className='crt-post-btn mx-2'>Next</button>
                            :
                            <button onClick={() => setCrtActivity(false)} className='crt-post-btn'>post</button>
                    }
                </div>

                <span className='hr-line'>
                    <hr />
                </span>



                <div className="">
                    {
                        post === 1 ?
                            (data.length === 0 ?

                                <div className="img-upload">
                                    <input type="file" id="upload" hidden onChange={(e) => handlefile(e.target.files)} name="img" multiple />
                                    <label for="upload" className='inp-value'><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload Image</label>
                                </div>

                                :

                                <Slider {...settings}>
                                    {
                                        data && data.map((i) => {
                                            return (
                                                <>
                                                    <div className='d-flex justify-content-center'>
                                                        <img src={i} alt='hello' className='preview-img' />
                                                        <p className='preview-delete' onClick={() => imageRemove(i)}> <img src={cancel} width={30} /></p>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }
                                </Slider>
                            )

                            :
                            (
                                <>
                                    <div className='activity-disck'>

                                        <div className="lgn-input-1">
                                            <input type="text" className="i-put-1" required />
                                            <label for="text">
                                                <span>Title</span>
                                            </label>
                                        </div>
                                        <div className="lgn-input-1">
                                            <input type="text" className="i-put-1" required />
                                            <label for="text">
                                                <span>Descrption</span>
                                            </label>
                                        </div>

                                        <div className="lgn-input-1 date-birth">
                                            <input type="date" id="date" placeholder="Date Of Birth" className="i-put-1" required />
                                            <label for="text">
                                                <span>Date</span>
                                            </label>
                                        </div>

                                        <div className='d-flex'>
                                            <div className="me-3" style={{ width: "100%" }}>
                                                <input type="time" className="i-put-1" required />
                                            </div>
                                            <div className="lgn-input-1" style={{ width: "100%" }}>
                                                <input type="text" className="i-put-1" required />
                                                <label for="text"><span>To</span></label>
                                            </div>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: "16px" }} >
                                            <label className="act-disk act-radio me-3" >
                                                <label for="text">Event</label>
                                                <input type="radio" name='actives' className='purchs-radio coin-radio' required />
                                            </label>

                                            <label className="act-disk act-radio">
                                                <label for="text">Offer</label>
                                                <input type="radio" name='actives' className='purchs-radio coin-radio' required />
                                            </label>
                                        </div>

                                    </div>
                                </>
                            )
                    }
                </div>


            </Modal>
        )
    }

    //refer-earn
    function ReferEarn(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className='boost-purchage pb-3'>
                    <p className='ps-5'>Refer & Earn</p>
                    <div onClick={props.onHide} className='purchage-close ps-4'>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className='refer-img'>
                    <img src={gologo} width={200} />
                </div>
                <p className='refer-name'>Your Referral Code:</p>
                <div className='earn-center'>
                    <div className='refer-center'>
                        <p className='m-0 ms-5'>24F5SG8</p>
                        <p className='m-0 ms-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.5996 2H15.5996" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21.9995 8V14.19C21.9995 15.74 20.7395 17 19.1895 17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </p>
                    </div>
                </div>
                <p className='refer-footer'>Share your referral code with your <br /> friends and get benefits.</p>



            </Modal>
        )
    }

    //team member
    function TeamMember(props) {

        return (
            <>
                <Modal
                    {...props}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className='hyep-head mt-2'>
                        <span>Team Member</span>
                        <button className='invite-btn' onClick={() => setInvite(true)}>Invite</button>
                    </div>
                    <hr />
                    <div>
                        <div className='hype-follow'>
                            <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                                <img className='hype-img-1' src={human1} />
                                <p className='m-0 ps-3'>Alisa Williams</p>
                            </div>
                            <button className='remove-btn' onClick={() => setRemove(true)}>Remove</button>
                        </div>
                        <div className='hype-follow'>
                            <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                                <img className='hype-img-1' src={human5} />
                                <p className='m-0 ps-3'>Jessy Nolan</p>
                            </div>
                            <button className='remove-btn' onClick={() => setRemove(true)}>Remove</button>
                        </div>
                        <div className='hype-follow'>
                            <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                                <img className='hype-img-1' src={human3} />
                                <p className='m-0 ps-3'>Alisa Williams</p>
                            </div>
                            <button className='remove-btn' onClick={() => setRemove(true)}>Remove</button>
                        </div>
                        <div className='hype-follow'>
                            <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                                <img className='hype-img-1' src={human4} />
                                <p className='m-0 ps-3'>Alisa Williams</p>
                            </div>
                            <button className='remove-btn' onClick={() => setRemove(true)}>Remove</button>
                        </div>
                        <div className='hype-follow'>
                            <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                                <img className='hype-img-1' src={human2} />
                                <p className='m-0 ps-3'>Alisa Williams</p>
                            </div>
                            <button className='remove-btn' onClick={() => setRemove(true)}>Remove</button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

    //invite friend
    function InviteFriend(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className='hyep-head mt-2'>

                    <span><img src={arrow} width={30} onClick={() => setInvite(false)} className='pe-2 back-arrow' />Invite Member</span>
                </div>
                <hr />

                <div className='srch-bar follow-srch d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <i className="bi bi-search"></i>
                    </div>
                    <div>
                        <input className='srch-inp' placeholder='Search' type='text' />
                    </div>
                </div>

                <div>
                    <div className='hype-follow'>
                        <div className='d-flex align-items-center hyep-data'>
                            <img className='hype-img-1' src={human1} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <button className='remove-btn' >Invited</button>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex align-items-center hyep-data'>
                            <img className='hype-img-1' src={human5} />
                            <p className='m-0 ps-3'>Jessy Nolan</p>
                        </div>
                        <button className='invite-btn' onClick={() => setJoin(true)} >Invite</button>

                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex align-items-center hyep-data'>
                            <img className='hype-img-1' src={human3} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <button className='remove-btn'>Invited</button>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex align-items-center hyep-data'>
                            <img className='hype-img-1' src={human4} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <button className='invite-btn' onClick={() => setJoin(true)}>Invite</button>
                    </div>

                    <div className='hype-follow'>
                        <div className='d-flex align-items-center hyep-data'>
                            <img className='hype-img-1' src={human2} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <button className='remove-btn'>Invited</button>
                    </div>
                </div>
            </Modal>
        );
    }

    // invite member
    function InviteMember(props) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>

                <div className='text-center mt-5 mb-4'>
                    <img src={move} width={120} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with deleting <br />
                    your account?</p>
                <div className='kyc-con-btn'>
                    <button className="invited-btn-1" onClick={props.onHide} >Yes, Invite</button>
                </div>

            </Modal>
        )
    }

    //remove member
    function RemoveMember(props) {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>

                <div className='text-center mt-5 mb-4'>
                    <img src={move} width={120} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with deleting <br />
                    your account?</p>
                <div className='kyc-con-btn'>
                    <button className="invited-btn-1" onClick={props.onHide}>Yes, Remove</button>
                </div>

            </Modal>
        )
    }

    // verify public
    const getBase = (file) => {
        dm = []
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            dm.push(reader.result)
            setImage(dm)
        }
    }

    const handlefile = (image) => {
        setImageIdProof(image[0])
        setImage('')
        for (let i = 0; i < image.length; i++) {
            getBase(image[i])
        }
    }

    const removePublicVerified = (dm) => {
        setImage('')
    }

    const getBases = (file) => {
        dm = []
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // console.log(reader.result);
            dm.push(reader.result)
            setImages(dm)
        }
    }

    const handlefiles = (image) => {
        setImagesAddProof(image[0])
        setImages('')
        for (let i = 0; i < image.length; i++) {
            getBases(image[i])
        }
    }

    const removePublicVerifieds = (dm) => {
        setImages('')
    }

    // public kyc form
    const handleKycApi = (e, type) => {
        e.preventDefault();
        setIsloading(true)
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (imageIdProof) {
            formdata.append("user_id_proof", imageIdProof);
        }
        if (imagesAddProof) {
            formdata.append("user_address_proof", imagesAddProof);
        }

        const postData = {
            user_role: "USER",
            user_id_proof_type: documetId,
            user_address_proof_type: documetProof,
        }
        const encrypt = encryptData(postData);
        formdata.append("kycData", encrypt)

        if (documetId == "" || documetProof == "") {
            toast.error("compulsory select Documents Types");
            setIsloading(false);
        }
        else {
            if (imageIdProof == "" || imagesAddProof == "") {
                toast.error("compulsory select ID proof image");
                setIsloading(false);
            } else {
                fetch(BASE_URL + "add_kyc", {
                    method: 'POST',
                    body: formdata,
                    headers: myHeaders1,
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.Status === 0) {
                            console.log(response, "no")
                            setIsloading(false)
                        } else {
                            console.log(response, "yes")
                            setImage('')
                            setImages('')
                            navigate("/profile")
                            setFormKyc(false)
                            setKycDone(true)
                        }
                        setIsloading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsloading(false)
                    })
            }
        }
    }

    const handleKycPrivetApi = () => {
        console.log("privet data >>>", businessData);
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setBusinessData({ ...businessData, [name]: value })
    }

    return (
        <>
            <p className="m-0" id='functionSidebar' onClick={hanldeSidebar}></p>

            <div className='Sidebar align-items-start d-flex flex-column mb-auto bd-highlight' style={{ height: "100vh" }} id='sidebar'>
                <div className='mb-auto'>
                    <div className='head-logo'>
                        <img src={headlogo} onClick={homescreen} />
                    </div>

                    <div className="SidebarItem">
                        {/* <Link to={"/walletHome"} >wallet</Link> */}


                        <Link to={"/homescreen"} className={location.pathname === "/homescreen" ? 'actives' : location.pathname === "/vlogscreen" ? 'actives' : 'sidedata'} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M4.29289 5H20.2929M15 11H20.2929M15 15H20.2929M15 19H20.2929M4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11H11C11.2652 11 11.5196 11.1054 11.7071 11.2929C11.8946 11.4804 12 11.7348 12 12V18C12 18.2652 11.8946 18.5196 11.7071 18.7071C11.5196 18.8946 11.2652 19 11 19H5C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18V12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Public Square
                        </Link>
                        <Link to={"/reels"} className={location.pathname === "/reels" ? 'actives' : 'sidedata'} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.52002 7.10999H21.48" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.52002 2.10999V6.96999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.48 2.10999V6.51999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.75 14.45V13.25C9.75 11.71 10.84 11.08 12.17 11.85L13.21 12.45L14.25 13.05C15.58 13.82 15.58 15.08 14.25 15.85L13.21 16.45L12.17 17.05C10.84 17.82 9.75 17.19 9.75 15.65V14.45V14.45Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Reels
                        </Link>
                        <Link to={"/message"} className={location.pathname === "/message" ? 'actives' : 'sidedata'}>
                            <div className='d-flex align-itens-start'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 10.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H14" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5L15.06 10.56" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {/* {
                            item.unread_count != 0 && ( */}
                                {/* <span className='notification-icon1-side'>
                                    <p className='notification-icon-side d-flex justify-content-center align-items-center m-0'>2</p>
                                </span> */}
                                {/* )
                            } */}
                            </div>
                            Messages
                        </Link>
                        <Link className={location.pathname === "/searchsidebar" ? 'actives' : 'sidedata'} onClick={() => managesidebar(2)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M3.31573 13.7811L4.04591 13.6098L3.31573 13.7811ZM3.31573 8.324L4.04591 8.49528L3.31573 8.324ZM18.7893 8.324L19.5195 8.15273L18.7893 8.324ZM18.7893 13.781L19.5195 13.9523L18.7893 13.781ZM13.781 18.7893L13.6098 18.0591L13.781 18.7893ZM8.324 18.7893L8.15273 19.5195L8.324 18.7893ZM8.324 3.31573L8.15272 2.58555L8.324 3.31573ZM13.781 3.31573L13.9523 2.58555L13.781 3.31573ZM20.4697 21.5303C20.7626 21.8232 21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L20.4697 21.5303ZM4.04591 13.6098C3.65136 11.9278 3.65136 10.1773 4.04591 8.49528L2.58555 8.15272C2.13815 10.06 2.13815 12.045 2.58555 13.9523L4.04591 13.6098ZM18.0591 8.49528C18.4537 10.1773 18.4537 11.9278 18.0591 13.6098L19.5195 13.9523C19.9669 12.045 19.9669 10.06 19.5195 8.15273L18.0591 8.49528ZM13.6098 18.0591C11.9278 18.4537 10.1773 18.4537 8.49528 18.0591L8.15273 19.5195C10.06 19.9669 12.045 19.9669 13.9523 19.5195L13.6098 18.0591ZM8.49528 4.04591C10.1773 3.65136 11.9278 3.65136 13.6098 4.04591L13.9523 2.58555C12.045 2.13815 10.06 2.13815 8.15272 2.58555L8.49528 4.04591ZM8.49528 18.0591C6.28757 17.5413 4.56377 15.8175 4.04591 13.6098L2.58555 13.9523C3.23351 16.7147 5.39038 18.8715 8.15273 19.5195L8.49528 18.0591ZM13.9523 19.5195C16.7147 18.8715 18.8715 16.7147 19.5195 13.9523L18.0591 13.6098C17.5413 15.8175 15.8175 17.5413 13.6098 18.0591L13.9523 19.5195ZM13.6098 4.04591C15.8175 4.56377 17.5413 6.28757 18.0591 8.49528L19.5195 8.15273C18.8715 5.39037 16.7147 3.23351 13.9523 2.58555L13.6098 4.04591ZM8.15272 2.58555C5.39037 3.23351 3.23351 5.39037 2.58555 8.15272L4.04591 8.49528C4.56377 6.28756 6.28757 4.56377 8.49528 4.04591L8.15272 2.58555ZM16.8048 17.8655L20.4697 21.5303L21.5303 20.4697L17.8655 16.8048L16.8048 17.8655Z" fill="white" />
                            </svg>
                            Search
                        </Link>
                        <Link className={location.pathname === "/notification" ? 'actives' : 'sidedata'} onClick={() => managesidebar(1)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15 17H20L18.595 15.595C18.4063 15.4063 18.2567 15.1822 18.1546 14.9357C18.0525 14.6891 18 14.4249 18 14.158V11C18.0002 9.75894 17.6156 8.54834 16.8992 7.53489C16.1829 6.52144 15.17 5.75496 14 5.341V5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0391 3.21071 12.5304 3 12 3C11.4696 3 10.9609 3.21071 10.5858 3.58579C10.2107 3.96086 10 4.46957 10 5V5.341C7.67 6.165 6 8.388 6 11V14.159C6 14.697 5.786 15.214 5.405 15.595L4 17H9M15 17H9M15 17V18C15 18.7956 14.6839 19.5587 14.1213 20.1213C13.5587 20.6839 12.7956 21 12 21C11.2044 21 10.4413 20.6839 9.87868 20.1213C9.31607 19.5587 9 18.7956 9 18V17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Notification
                        </Link>
                        <Link to={"/walletHome"} className={location.pathname === "/walletHome" ? 'actives' : 'sidedata'}  >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V8M21 12H19C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16H21M3 8V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.07989 20 6.2 20H17.8C18.9201 20 19.4802 20 19.908 19.782C20.2843 19.5903 20.5903 19.2843 20.782 18.908C21 18.4802 21 17.9201 21 16.8V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H3Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Wallet
                        </Link>
                        <Link to={"/datag8"} className={location.pathname === "/datag8" ? 'actives' : 'sidedata'}  >
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 12.5039V11.5C14 10.3954 13.1046 9.5 12 9.5C10.8954 9.5 10 10.3954 10 11.5V12.5039M10.5 15.5H13.5C13.9659 15.5 14.1989 15.5 14.3827 15.4239C14.6277 15.3224 14.8224 15.1277 14.9239 14.8827C15 14.6989 15 14.4659 15 14C15 13.5341 15 13.3011 14.9239 13.1173C14.8224 12.8723 14.6277 12.6776 14.3827 12.5761C14.1989 12.5 13.9659 12.5 13.5 12.5H10.5C10.0341 12.5 9.80109 12.5 9.61732 12.5761C9.37229 12.6776 9.17761 12.8723 9.07612 13.1173C9 13.3011 9 13.5341 9 14C9 14.4659 9 14.6989 9.07612 14.8827C9.17761 15.1277 9.37229 15.3224 9.61732 15.4239C9.80109 15.5 10.0341 15.5 10.5 15.5ZM8.4 19C5.41766 19 3 16.6044 3 13.6493C3 11.2001 4.8 8.9375 7.5 8.5C8.34694 6.48637 10.3514 5 12.6893 5C15.684 5 18.1317 7.32251 18.3 10.25C19.8893 10.9449 21 12.6503 21 14.4969C21 16.9839 18.9853 19 16.5 19L8.4 19Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            DataG8
                        </Link>
                        <Link className='sidedata' onClick={() => { setCreate(true); HandleBio() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8 12H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 16V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Create
                        </Link>
                        <Link to={"/profile"} className={location.pathname === "/profile" ? 'actives' : location.pathname === "/profilepost" ? 'actives' : location.pathname === "/profilevlog" ? 'actives' : 'sidedata'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.1401 21.62C17.2601 21.88 16.2201 22 15.0001 22H9.00011C7.78011 22 6.74011 21.88 5.86011 21.62C6.08011 19.02 8.75011 16.97 12.0001 16.97C15.2501 16.97 17.9201 19.02 18.1401 21.62Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5799 10.58C15.5799 12.56 13.9799 14.17 11.9999 14.17C10.0199 14.17 8.41992 12.56 8.41992 10.58C8.41992 8.60002 10.0199 7 11.9999 7C13.9799 7 15.5799 8.60002 15.5799 10.58Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Profile
                        </Link>
                        <Link to={"/setting"} className={location.pathname === "/setting" ? 'actives' : location.pathname === "/PrivacyPolicy" ? 'actives' : location.pathname === "/TermsConditions" ? 'actives' : 'sidedata'}>
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#ffffff" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></g></svg>
                            Settings
                        </Link>









                        <div className='text-center' style={{ cursor: "pointer" }} onClick={() => setOut(true)}>
                                    <p className='side-logout'>Log Out</p>
                                </div>

                    </div>
                </div>



            </div >
            <div className="overlay" onClick={hanldeSidebar} id='overlay'></div>

            <SearchSidebar />
            <div className="over" onClick={() => managesidebar(2)} id='over'></div>

            <Notification isCallApiflag={isCallApi} />
            <div className="notifylay" onClick={() => managesidebar(1)} id='notifylay'></div>

            {/* create */}

            {/* Create popup */}
            <Modal
                show={create} onHide={() => setCreate(false)}
                centered
            >
                {/* <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setCrtActivity(true); setCreate(false) }}>
                        <p>Activity</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5.89999 17H18.09C19.99 17 20.99 16 20.99 14.1V2H2.98999V14.1C2.99999 16 3.99999 17 5.89999 17Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 2H22" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 22L12 20V17" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 22L12 20" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.5 11L10.65 8.37C10.9 8.16 11.23 8.22 11.4 8.5L12.6 10.5C12.77 10.78 13.1 10.83 13.35 10.63L16.5 8" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div> */}
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => {
                        setCrtPost(true); setCreate(false); setWhatIs("POST"); setData([]); setImg([])
                    }}>
                        <p>Post</p>
                        <img src={createpost} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setCrtOneg8(true); setCreate(false); setReels([]) }}>
                        <p>Reels</p>
                        <img src={createoneg8} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setCrtVlog(true); setCreate(false) }} >
                        <p>Vlogs</p>
                        <img src={playvlog} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => {
                        setCrtStory(true); setCreate(false); setWhatIs("STORY"); setData([]); setImg([])
                    }}>
                        <p>Story</p>
                        <img src={createpost} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setCreate(false)}>Cancel</button>
                </div>
            </Modal >

            {/* Create Post popup */}
            < Modal show={CrtPost} onHide={() => setCrtPost(false)
            } centered >
                <div className='crt-disc'>

                    <div className='col-12'>
                        <div className='row'>
                        <div className='col-12 text-start'>
                        {
                        post != 1 ?
                            <img className='pointer' src={back} width={25} onClick={() => setPost(post - 1)} /> : null
                    }
                            </div>
                        <div className='col-12 text-center'>
                        <span className='m-0 big-font'>Create Post</span>
                        </div>
                        <div className='col-12 text-center'>
                        {
                        post == 1 ?
                            <>
                                <div className='px-0'>
                                    {
                                        img.length > 0 ?
                                            <>
                                                <input type="file" id="upload" hidden onChange={(e) => handlefilepost(e.target.files)} name="img" multiple />
                                                <label for="upload" className='inp-value'><img src={add} width={30} className='me-3' alt="" /></label>
                                            </>
                                            : ""
                                    }
                                    <button onClick={() => setPost(2)} className='crt-post-btn'>
                                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                            < span className="visually-hidden">Loading...</span>
                                        </Spinner> : "Next"}
                                    </button>
                                </div>
                            </>
                            :
                            <button onClick={IsCreatePost}  className='crt-post-btn'>
                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                    < span className="visually-hidden">Loading...</span>
                                </Spinner> : "Post"}
                            </button>
                    }
                        </div>

                        </div>
                    </div>


                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className="">
                    {
                        post === 1 ?
                            (img.length === 0 ?
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className="img-upload">
                                            <input type="file" id="upload" hidden onChange={(e) => handlefilepost(e.target.files)} name="img" multiple />
                                            <label for="upload" className='inp-value'><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload Image</label>
                                        </div>
                                    </div>
                                </>
                                :
                                <Slider {...settings}>
                                    {
                                        img && img.map((i, index) => {
                                            return (
                                                <>
                                                    <div className='d-flex justify-content-end post-cancle'>
                                                        <p className='preview-delete' onClick={() => imageRemove(i, index)}> <img src={cancel} width={30} /></p>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        {type[index] === 1 ?
                                                            <>
                                                                <div key={index}>
                                                                    <img src={i} alt='image' className='preview-img object-fit-cover' />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div key={index}>
                                                                    <video src={i} controls alt="video" className='preview-img'></video>
                                                                </div>
                                                            </>}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </Slider>
                            )
                            :
                            (<>
                                <div className='pst-disc-head'>
                                    {
                                        personalData.profile_pic ?
                                            <img src={IMG_URL + personalData.profile_pic} className='srch-img rounded' />
                                            :
                                            <img src={placeholderimg} className='srch-img rounded' alt="" />
                                    }
                                    <div className='d-flex align-itmes-center'>
                                        <p className='m-0 ps-3'>{personalData.first_name}</p>
                                        <p className='m-0 ps-2'>{personalData.last_name}</p>
                                    </div>
                                </div>
                                <div className='pst-disc-footer'>
                                    <textarea cols='60' rows='8' value={postDescription} onChange={(e) => setPostDescription(e.target.value)}   placeholder='What’s on your mind?' ></textarea>
                                    {/* <input type='text' value={postDescription} onChange={(e) => setPostDescription(e.target.value)} className='pst-disc-inp' placeholder='What’s on your mind?' /> */}
                                </div>
                            </>)
                    }
                </div>

                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setCrtPost(false)}>Cancel</button>
                </div>
            </Modal >

              {/* Create Story popup */}
              < Modal show={CrtStory} onHide={() => setCrtStory(false)
            } centered >
                <div className='crt-disc'>
                <div className='col-12'>
                <div className='row'>
                <div className='col-12 text-start'>
                    {
                        post != 1 ?
                        <img className='pointer' src={back} width={25} onClick={() => setPost(post - 1)} /> : null
                    }
                    </div>
                    <div className='col-12 text-center'>
                    <span className='m-0 big-font'>Create Story</span>
                    </div>
                    <div className='col-12 text-center'>
                    {
                        post == 1 ?
                            <>
                                <div className='px-4'>
                                    {
                                        img.length > 0 ?
                                            <>
                                                <input type="file" id="upload" hidden onChange={(e) => handlefilepost(e.target.files)} name="img" multiple />

                                            </>
                                            : ""
                                    }
                                            <button onClick={IsCreatePost} className='crt-post-btn mx-2'>
                                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                                    < span className="visually-hidden">Loading...</span>
                                                </Spinner> : "Post"}
                                            </button>
                                </div>
                            </>
                            :
                            <button onClick={IsCreatePost} className='crt-post-btn mx-2'>
                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                    < span className="visually-hidden">Loading...</span>
                                </Spinner> : "Post"}
                            </button>

                    }
                    </div>
                    </div>
                    </div>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className="">
                    {
                        post === 1 ?
                            (img.length === 0 ?
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className="img-upload">
                                            <input type="file" id="upload" hidden onChange={(e) => handlefilepost(e.target.files)} name="img" multiple />
                                            <label for="upload" className='inp-value'><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload Image</label>
                                        </div>
                                    </div>
                                </>
                                :
                                <Slider {...settings}>
                                    {
                                        img && img.map((i, index) => {
                                            return (
                                                <>
                                                    <div className='d-flex justify-content-end post-cancle'>
                                                        <p className='preview-delete' onClick={() => imageRemove(i, index)}> <img src={cancel} width={30} /></p>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        {type[index] === 1 ?
                                                            <>
                                                                <div key={index}>
                                                                    <img src={i} alt='image' className='preview-img object-fit-cover' />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div key={index}>
                                                                    <video src={i} controls alt="video" className='preview-img'></video>
                                                                </div>
                                                            </>}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </Slider>
                            )
                            :
                            (<>
                                <div className='pst-disc-head'>
                                    {
                                        personalData.profile_pic ?
                                            <img src={IMG_URL + personalData.profile_pic} className='srch-img rounded' />
                                            :
                                            <img src={placeholderimg} className='srch-img rounded' alt="" />
                                    }
                                    <div className='d-flex align-itmes-center'>
                                        <p className='m-0 ps-3'>{personalData.first_name}</p>
                                        <p className='m-0 ps-2'>{personalData.last_name}</p>
                                    </div>
                                </div>
                                <div className='pst-disc-footer'>
                                    <input type='text' value={postDescription} onChange={(e) => setPostDescription(e.target.value)} className='pst-disc-inp' placeholder='What’s on your mind?' />
                                </div>
                            </>)
                    }
                </div>


                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setCrtStory(false)}>Cancel</button>
                </div>
            </Modal >

            {/* Create Vlog popup */}
            < Modal show={CrtVlog} onHide={() => setCrtVlog(false)} centered >
                <div className='crt-disc'>
                <div className='col-12'>
                <div className='row'>
                <div className='col-12 text-start'>
                    {
                        post != 1 ?
                            <img className='pointer' src={back} width={25} onClick={() => setPost(post - 1)} /> : null
                    }
                    </div>
                    <div className='col-12 text-center'>
                        <span className='m-0 big-font'>Create Vlog</span>
                    </div>
                    <div className='col-12 text-center'>
                    {
                        post == 1 ?
                            <button onClick={() => setPost(2)} className='crt-post-btn mx-3'>
                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                    < span className="visually-hidden">Loading...</span>
                                </Spinner> : "Next"}
                            </button>
                            :
                            <button onClick={IsCreateVlog} className='crt-post-btn'>
                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                    < span className="visually-hidden">Loading...</span>
                                </Spinner> : "Post"}
                            </button>
                    }
                    </div>
                    </div>
                    </div>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className="">
                    {
                        post === 1 ?
                            (imgVlog.length === 0 ?
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className="img-upload">
                                            <input type="file" id="upload" accept='video/*' hidden onChange={(e) => handlefilevlog(e.target.files)} name="img" />
                                            <label for="upload" className='inp-value'><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload Video</label>
                                        </div>
                                    </div>
                                </>
                                :
                                <Slider {...settings}>
                                    {
                                        imgVlog && imgVlog.map((i, index) => {
                                            return (
                                                <>
                                                    <div className='d-flex justify-content-end post-cancle'>
                                                        <p className='preview-delete' onClick={() => imageRemove1(i, index)}> <img src={cancel} width={30} /></p>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        {type[index] === 1 ?
                                                            <>
                                                                <div key={index}>
                                                                    <img src={i} alt='image' className='preview-img' />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div key={index}>
                                                                    <video src={i} controls alt="video" className='preview-img'></video>
                                                                </div>
                                                            </>}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </Slider>
                            )
                            :
                            (<>
                                <div className='pst-disc-head'>
                                    {
                                        personalData.profile_pic ?
                                            <img src={IMG_URL + personalData.profile_pic} className='srch-img rounded' />
                                            :
                                            <img src={placeholderimg} className='srch-img rounded' alt="" />
                                    }
                                    <div className='d-flex align-itmes-center'>
                                        <p className='m-0 ps-3'>{personalData.first_name}</p>
                                        <p className='m-0 ps-2'>{personalData.last_name}</p>
                                    </div>
                                </div>
                                <div className='pst-disc-footer'>
                                    <input type='text' value={postDescriptionVlog} onChange={(e) => setPostDescriptionVlog(e.target.value)} className='pst-disc-inp' placeholder='What’s on your mind?' />
                                </div>
                            </>)
                    }
                </div>

                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setCrtVlog(false)}>Cancel</button>
                </div>
            </Modal >

            {/* create reels popup */}
            <Modal
                contentClassName='create-oneg8-modal'
                show={CrtOneg8} onHide={() => setCrtOneg8(false)}
                centered
            >

                <div className='crt-post1'>
                <div className='col-12'>
                <div className='row'>
                <div className='col-12 text-center'>
                    <span className='big-font'>Create Reels</span>
                    </div>
                    <div className='col-12 text-center'>
                    <button onClick={IsCreateReels} className='crt-post-btn'>
                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                            < span className="visually-hidden">Loading...</span>
                        </Spinner> : "Post"}
                    </button>
                    </div>
                </div>
                </div>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className='d-flex'>
                    <div className="">
                        {
                            Reels.length === 0 ?
                                <div className='oneg8-upload'>
                                    <input type="file" id="upload" accept='video/*' hidden onChange={(e) => handlefileReels(e.target.files)} name="img" />
                                    <label for="upload" className='inp-value pointer'><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload Video</label>
                                </div>
                                :
                                <>
                                    {
                                        Reels && Reels.map((i, index) => {
                                            return (
                                                <>
                                                    <div className='d-flex justify-content-end reel-cancel'>
                                                        <p className='preview-delete' onClick={() => imageRemove2(i, index)}> <img src={cancel} width={30} /></p>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        {type[index] === 1 ?
                                                            <>
                                                                <div key={index}>
                                                                    <img src={i} alt='image' className='preview-img' />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div key={index}>
                                                                    <video src={i} controls alt="video" className='preview-reels' ></video>
                                                                </div>
                                                            </>}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </div>
                    <div className='pt-4'>
                        <div className='pst-disc-head'>
                            {
                                personalData.profile_pic ?
                                    <img src={IMG_URL + personalData.profile_pic} className='srch-img rounded' />
                                    :
                                    <img src={placeholderimg} className='srch-img rounded' alt="" />
                            }
                            <div className='d-flex align-itmes-center'>
                                <p className='m-0 ps-3'>{personalData.first_name}</p>
                                <p className='m-0 ps-2'>{personalData.last_name}</p>
                            </div>
                        </div>
                        <div className='pst-disc-footer' style={{ width: "350px" }}>
                            <input type='text' className='pst-disc-inp' value={postDescriptionReel} onChange={(e) => setPostDescriptionReel(e.target.value)} placeholder='What’s on your mind?' />
                        </div>
                    </div>

                </div>
                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setCrtOneg8(false)}>Cancel</button>
                </div>
            </Modal>

            <CreateActivity show={CrtActivity} onHide={() => setCrtActivity(false)} />

            {/* verify */}
            <Modal
                show={bluteTick} onHide={() => setBlueTick(false)}
                centered
            >

                <div className='vr-img'>
                    <div className='verify-new-img'>
                        <img src={verify1} width={65} />
                    </div>
                </div>
                <div className='ver-center'>
                    <p>Verified As a</p>
                </div>

                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setFormKyc(true); setBlueTick(false) }}>
                        <p>User Account</p>
                        <img src={createoneg8} />
                    </div>
                </div>
                <div className='data-pro-modal' onClick={() => { setKycPrivet(true); setBlueTick(false) }}>
                    <div className='pro-danger'>
                        <p>Business Account</p>
                        <img src={playvlog} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <button className="btn-reel2 pointer mt-2" onClick={() => setBlueTick(false)}>Cancel</button>
                </div>

            </Modal>

            {/* verify public */}
            <Modal
                contentClassName='kyc-datas hide-scrollbar'
                show={formKyc} onHide={() => setFormKyc(false)}
                centered
            >
                <div className='crt-disc'>
                    {
                        kycStep == 1 ? <p width={24} height={24}></p> :
                            kycStep == 2 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={() => setKycStep(1)} viewBox="0 0 24 24" fill="none">
                                    <path d="M7.12988 18.3096H15.1299C17.8899 18.3096 20.1299 16.0696 20.1299 13.3096C20.1299 10.5496 17.8899 8.30957 15.1299 8.30957H4.12988" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.43012 10.8104L3.87012 8.25043L6.43012 5.69043" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={() => setKycStep(2)} viewBox="0 0 24 24" fill="none">
                                    <path d="M7.12988 18.3096H15.1299C17.8899 18.3096 20.1299 16.0696 20.1299 13.3096C20.1299 10.5496 17.8899 8.30957 15.1299 8.30957H4.12988" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.43012 10.8104L3.87012 8.25043L6.43012 5.69043" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                    }
                    <p className='m-0' style={{ paddingLeft: "-160px" }}>KYC Form</p>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>
                <div className='kyc-head'>
                    <p className='ps-detail'>Personal Details</p>
                    <p className='ps-id'>ID Proof</p>
                    <p className='ps-address'>Address Proof</p>
                </div>

                <ul>
                    <li className="current"></li>
                    <li></li>
                    <li></li>
                </ul>

                {
                    kycStep === 1 ?

                        <div className='kyc-form'>
                            <div className="floating-label-input">
                                <input id="email" type="text" className='new-put' value={personalData.first_name + personalData.last_name} autoComplete="off" />
                                <label className="floating-label">Full Name</label>
                            </div>
                            <div className="floating-label-input">
                                <input id="email" type="text" className='new-put' value={personalData.email_id} autoComplete="off" />
                                <label className="floating-label">Email</label>
                            </div>
                            <div className="country-number">
                                <PhoneInput
                                    international
                                    withCountryCallingCode
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    name='phone_no'
                                    initialValueFormat="national"
                                    value={personalData.phone_number}
                                />
                            </div>
                            <button className="bt-1" type='submit' style={{ marginTop: "124px" }}
                                onClick={() => setKycStep(2)}
                            >Next</button>
                        </div>
                        : kycStep === 2 ?
                            <div className='d-flex align-items-start flex-column bd-highlight kyc-form'>
                                <div className=''>
                                    <div className="mt-2">
                                        <select placeholder="Gender" value={documetId} onChange={(e) => setDocumentId(e.target.value)} className="i-put-1 acc-menu">
                                            <option className="gen-der">Document Type</option>
                                            <option className="gen-der" value="Driving Licence">Driving Licence</option>
                                            <option className="gen-der" value="Passport">Passport</option>
                                        </select>
                                    </div>
                                    <div className="field padding-bottom--24">

                                        <div className='id-proof-inp'>
                                            <input type="file" id="upload" hidden
                                                onChange={(e) => handlefile(e.target.files)} accept="image/*" name="img" />

                                            <label for="upload" style={{ color: "#2D50BC" }} className='inp-value'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.46583 5.23624C8.24276 5.53752 8.26838 5.96727 8.5502 6.24C8.8502 6.54 9.3402 6.54 9.6402 6.24L11.2302 4.64V8.78H12.7702V4.64L14.3602 6.24L14.4464 6.31438C14.7477 6.53752 15.1775 6.51273 15.4502 6.24C15.6002 6.09 15.6802 5.89 15.6802 5.69C15.6802 5.5 15.6002 5.3 15.4502 5.15L12.5402 2.23L12.4495 2.14848C12.3202 2.0512 12.1602 2 12.0002 2C11.7902 2 11.6002 2.08 11.4502 2.23L8.5402 5.15L8.46583 5.23624ZM6.23116 8.78512C3.87791 8.89627 2 10.8758 2 13.2875V18.2526L2.00484 18.4651C2.1141 20.8599 4.06029 22.7802 6.45 22.7802H17.56L17.7688 22.7753C20.1221 22.6641 22 20.6843 22 18.2628V13.3078L21.9951 13.0945C21.8853 10.6909 19.93 8.7802 17.55 8.7802H12.77V14.8849L12.7629 14.9922C12.7112 15.3776 12.385 15.6683 12 15.6683C11.57 15.6683 11.23 15.3224 11.23 14.8849V8.7802H6.44L6.23116 8.78512Z" fill="#2D50BC" />
                                            </svg> Upload Document</label>
                                        </div>

                                        {image ? (
                                            <span className='id-proof-img'>
                                                <img src={image} className="rounded" alt='hello' width={80} height={80} />
                                                <p className="m-0" onClick={removePublicVerified}>
                                                    <img src={cancel} width={30} alt="Cancel" />
                                                </p>
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className='mt-auto bd-highlight'>
                                    <button className="bt-1 kyc-btn" onClick={() => setKycStep(3)}>Next</button>
                                </div>
                            </div>
                            :
                            <div className='d-flex align-items-start flex-column bd-highlight kyc-form'>
                                <div>
                                    <div className="mt-2">
                                        <select placeholder="" value={documetProof} onChange={(e) => setDocumentProof(e.target.value)} className="i-put-1 acc-menu">
                                            <option className="gen-der">Document Type</option>
                                            <option value="Electricity Bill">Electricity Bill</option>
                                            <option value="Other Residential">Other Residential</option>
                                        </select>
                                    </div>
                                    <div className="field padding-bottom--24">

                                        <div className='id-proof-inp'>
                                            <input type="file" id="upload" hidden
                                                onChange={(e) => handlefiles(e.target.files)}
                                                name="img" />

                                            <label for="upload" style={{ color: "#2D50BC" }} className='inp-value'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.46583 5.23624C8.24276 5.53752 8.26838 5.96727 8.5502 6.24C8.8502 6.54 9.3402 6.54 9.6402 6.24L11.2302 4.64V8.78H12.7702V4.64L14.3602 6.24L14.4464 6.31438C14.7477 6.53752 15.1775 6.51273 15.4502 6.24C15.6002 6.09 15.6802 5.89 15.6802 5.69C15.6802 5.5 15.6002 5.3 15.4502 5.15L12.5402 2.23L12.4495 2.14848C12.3202 2.0512 12.1602 2 12.0002 2C11.7902 2 11.6002 2.08 11.4502 2.23L8.5402 5.15L8.46583 5.23624ZM6.23116 8.78512C3.87791 8.89627 2 10.8758 2 13.2875V18.2526L2.00484 18.4651C2.1141 20.8599 4.06029 22.7802 6.45 22.7802H17.56L17.7688 22.7753C20.1221 22.6641 22 20.6843 22 18.2628V13.3078L21.9951 13.0945C21.8853 10.6909 19.93 8.7802 17.55 8.7802H12.77V14.8849L12.7629 14.9922C12.7112 15.3776 12.385 15.6683 12 15.6683C11.57 15.6683 11.23 15.3224 11.23 14.8849V8.7802H6.44L6.23116 8.78512Z" fill="#2D50BC" />
                                            </svg> Upload Document</label>
                                        </div>

                                        {images ? (
                                            <span className='id-proof-img'>
                                                <img src={images} className="rounded" alt='hello' width={80} height={80} />
                                                <p className="m-0" onClick={removePublicVerifieds}>
                                                    <img src={cancel} width={30} alt="Cancel" />
                                                </p>
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                    </div>
                                </div>

                                <div className='mt-auto bd-highlight'>
                                    <button className="bt-1 kyc-btn" onClick={(e) => handleKycApi(e)} >
                                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                            < span className="visually-hidden">Loading...</span>
                                        </Spinner> : "Complete"}
                                    </button>
                                </div>

                            </div>
                }
            </Modal >

            {/* verify privet */}
            < Modal
                contentClassName='kyc-datas hide-scrollbar'
                show={kycPrivet} onHide={() => setKycPrivet(false)
                }
                centered
            >
                <div className='crt-disc'>
                    {
                        kycStep == 1 ? <p width={24} height={24}></p> :
                            kycStep == 2 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={() => setKycStep(1)} viewBox="0 0 24 24" fill="none">
                                    <path d="M7.12988 18.3096H15.1299C17.8899 18.3096 20.1299 16.0696 20.1299 13.3096C20.1299 10.5496 17.8899 8.30957 15.1299 8.30957H4.12988" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.43012 10.8104L3.87012 8.25043L6.43012 5.69043" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={() => setKycStep(2)} viewBox="0 0 24 24" fill="none">
                                    <path d="M7.12988 18.3096H15.1299C17.8899 18.3096 20.1299 16.0696 20.1299 13.3096C20.1299 10.5496 17.8899 8.30957 15.1299 8.30957H4.12988" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.43012 10.8104L3.87012 8.25043L6.43012 5.69043" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                    }
                    <p className='m-0' style={{ paddingLeft: "-160px" }}>KYC Form</p>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>
                <div className='kyc-head'>
                    <p className='ps-detail'>Personal Details</p>
                    <p className='ps-id'>ID Proof</p>
                    <p className='ps-address'>Address Proof</p>
                </div>

                <ul>
                    <li className="current"></li>
                    <li></li>
                    <li></li>
                </ul>
                {
                    kycStep === 1 ?

                        <div className='kyc-form'>
                            <div className="lgn-input-1" >
                                <input type="text" className="i-put-1" value={personalData.first_name + personalData.last_name} />
                                <label for="text">
                                    <span>Full Name</span>
                                </label>
                            </div>
                            <div className="lgn-input-1">
                                <input type="text" className="i-put-1" value={personalData.email_id} />
                                <label for="text">
                                    <span>Business Email</span>
                                </label>
                            </div>
                            <div className='pb-3'>
                                <div className="country-number" >
                                    <PhoneInput
                                        international
                                        withCountryCallingCode
                                        countryCallingCodeEditable={false}
                                        defaultCountry="US"
                                        value={personalData.phone_number}
                                        initialValueFormat="national" />
                                </div>
                            </div>
                            <p className='mt-4'>Business Details</p>

                            <form action="" onSubmit={() => setKycStep(2)}>
                                <div className="mt-2 mb-4">
                                    <Form.Select className="i-put-select" placeholder="Business Category" name='business_category' onChange={(e) => handleChange(e)}>
                                        <option className="gen-der">Business Category</option>
                                        <option required value="RESTAURANT">Restaurant</option>
                                        <option required value="CLOTHES SHOP">Clothes Shop</option>
                                        <option required value="OTHER">Other</option>
                                    </Form.Select>
                                </div>

                                <div className="floating-label-input">
                                    <input id="text" type="text" className='new-put' name='business_name' onChange={(e) => handleChange(e)} autoComplete="off" required placeholder=" " />
                                    <label className="floating-label">Business Name</label>
                                </div>

                                <div className="floating-label-input">
                                    <input id="text" type="text" className='new-put' name='website_link' onChange={(e) => handleChange(e)} autoComplete="off" required placeholder=" " />
                                    <label className="floating-label">Website Link (optional)</label>
                                </div>

                                <div className="floating-label-input">
                                    <input id="text" type="text" className='new-put' name='business_address' onChange={(e) => handleChange(e)} autoComplete="off" required placeholder=" " />
                                    <label className="floating-label">Address</label>
                                </div>

                                {/* <Autocomplete
                                    apiKey={apiKeys}
                                    onPlaceSelected={(place) => {
                                        console.log(place);
                                    }}
                                />; */}

                                <button className="bt-1" style={{ marginTop: "20px", marginBottom: "20px" }} type='submit'>Next</button>
                            </form>

                        </div>
                        : kycStep === 2 ?
                            <div className='d-flex align-items-start flex-column bd-highlight kyc-form'>
                                <div className=''>
                                    <div className="mt-2">
                                        <select placeholder="Gender" value={documetId} onChange={(e) => setDocumentId(e.target.value)} className="i-put-1 acc-menu">
                                            <option className="gen-der">Document Type</option>
                                            <option className="gen-der" value="Driving Licence">Driving Licence</option>
                                            <option className="gen-der" value="Passport">Passport</option>
                                        </select>
                                    </div>
                                    <div className="field padding-bottom--24">

                                        <div className='id-proof-inp'>
                                            <input type="file" id="upload" hidden
                                                onChange={(e) => handlefile(e.target.files)} accept="image/*" name="img" />

                                            <label for="upload" style={{ color: "#2D50BC" }} className='inp-value'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.46583 5.23624C8.24276 5.53752 8.26838 5.96727 8.5502 6.24C8.8502 6.54 9.3402 6.54 9.6402 6.24L11.2302 4.64V8.78H12.7702V4.64L14.3602 6.24L14.4464 6.31438C14.7477 6.53752 15.1775 6.51273 15.4502 6.24C15.6002 6.09 15.6802 5.89 15.6802 5.69C15.6802 5.5 15.6002 5.3 15.4502 5.15L12.5402 2.23L12.4495 2.14848C12.3202 2.0512 12.1602 2 12.0002 2C11.7902 2 11.6002 2.08 11.4502 2.23L8.5402 5.15L8.46583 5.23624ZM6.23116 8.78512C3.87791 8.89627 2 10.8758 2 13.2875V18.2526L2.00484 18.4651C2.1141 20.8599 4.06029 22.7802 6.45 22.7802H17.56L17.7688 22.7753C20.1221 22.6641 22 20.6843 22 18.2628V13.3078L21.9951 13.0945C21.8853 10.6909 19.93 8.7802 17.55 8.7802H12.77V14.8849L12.7629 14.9922C12.7112 15.3776 12.385 15.6683 12 15.6683C11.57 15.6683 11.23 15.3224 11.23 14.8849V8.7802H6.44L6.23116 8.78512Z" fill="#2D50BC" />
                                            </svg> Upload Document</label>
                                        </div>

                                        {image ? (
                                            <span className='id-proof-img'>
                                                <img src={image} className="rounded" alt='hello' width={80} height={80} />
                                                <p className="m-0" onClick={removePublicVerified}>
                                                    <img src={cancel} width={30} alt="Cancel" />
                                                </p>
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className='mt-auto bd-highlight'>
                                    <button className="bt-1 kyc-btn" onClick={() => setKycStep(3)}>Next</button>
                                </div>
                            </div>
                            :

                            <div className='d-flex align-items-start flex-column bd-highlight kyc-form'>
                                <div>
                                    <div className="mt-2">
                                        <select placeholder="" value={documetProof} onChange={(e) => setDocumentProof(e.target.value)} className="i-put-1 acc-menu">
                                            <option className="gen-der">Document Type</option>
                                            <option value="Electricity Bill">Electricity Bill</option>
                                            <option value="Other Residential">Other Residential</option>
                                        </select>
                                    </div>
                                    <div className="field padding-bottom--24">

                                        <div className='id-proof-inp'>
                                            <input type="file" id="upload" hidden multiple
                                                onChange={(e) => handlefiles(e.target.files)}
                                                name="img" />

                                            <label for="upload" style={{ color: "#2D50BC" }} className='inp-value'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.46583 5.23624C8.24276 5.53752 8.26838 5.96727 8.5502 6.24C8.8502 6.54 9.3402 6.54 9.6402 6.24L11.2302 4.64V8.78H12.7702V4.64L14.3602 6.24L14.4464 6.31438C14.7477 6.53752 15.1775 6.51273 15.4502 6.24C15.6002 6.09 15.6802 5.89 15.6802 5.69C15.6802 5.5 15.6002 5.3 15.4502 5.15L12.5402 2.23L12.4495 2.14848C12.3202 2.0512 12.1602 2 12.0002 2C11.7902 2 11.6002 2.08 11.4502 2.23L8.5402 5.15L8.46583 5.23624ZM6.23116 8.78512C3.87791 8.89627 2 10.8758 2 13.2875V18.2526L2.00484 18.4651C2.1141 20.8599 4.06029 22.7802 6.45 22.7802H17.56L17.7688 22.7753C20.1221 22.6641 22 20.6843 22 18.2628V13.3078L21.9951 13.0945C21.8853 10.6909 19.93 8.7802 17.55 8.7802H12.77V14.8849L12.7629 14.9922C12.7112 15.3776 12.385 15.6683 12 15.6683C11.57 15.6683 11.23 15.3224 11.23 14.8849V8.7802H6.44L6.23116 8.78512Z" fill="#2D50BC" />
                                            </svg> Upload Document</label>
                                        </div>

                                        {images ? (
                                            <span className='id-proof-img'>
                                                <img src={images} className="rounded" alt='hello' width={80} height={80} />
                                                <p className="m-0" onClick={removePublicVerifieds}>
                                                    <img src={cancel} width={30} alt="Cancel" />
                                                </p>
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                    </div>
                                </div>

                                <div className='mt-auto bd-highlight'>
                                    <button className="bt-1 kyc-btn" onClick={(e) => handleKycPrivetApi(e)} >
                                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                            < span className="visually-hidden">Loading...</span>
                                        </Spinner> : "Complete"}
                                    </button>
                                </div>

                            </div>

                }

            </Modal >

            {/* KYC Conform */}
            < Modal
                show={kycDone} onHide={() => setKycDone(false)}
                centered
            >
                <div className='refer-img mt-4 mb-4'>
                    <img src={yes} width={150} />
                </div>
                <p className='text-center fs-5'>KYC Form</p>
                <p className='text-center fs-5'>Submitted Successfully!</p>
                <p className='text-center kyc-conform-text'>We will inform you shortly if you are eligible <br /> for
                    a verification badge, and you <br /> will
                    receive it soon.</p>
                <div className='kyc-con-btn'>
                    <button className="bt-1" onClick={() => setKycDone(false)}>OK</button>
                </div>
            </Modal >

            {/* member */}
            <TeamMember show={team} onHide={() => setTeam(false)} />
            <InviteFriend show={invite} onHide={() => setInvite(false)} />
            <InviteMember show={join} onHide={() => setJoin(false)} />
            <RemoveMember show={remove} onHide={() => setRemove(false)} />

            <ReferEarn show={date} onHide={() => setDate(false)} />

            {/* Logout popup */}
            <Modal
                show={out} onHide={() => setOut(false)}
                centered
            >

                <div className='text-center mt-4 mb-4'>
                    <img src={log} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with <br />
                    logging out?</p>
                <div className='kyc-con-btn' onClick={HandleClose}>
                    <button className="delete-btn">
                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                            < span className="visually-hidden">Loading...</span>
                        </Spinner> : "Yes, Log Out"}
                    </button>
                </div>

            </Modal>
        </>

    )

}


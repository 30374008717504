import React, { useEffect, useState } from 'react'
import "./MemberSidebar.css"
import arrow from "../assets/img/Sidebar/back-arrow.png"
import dp from "../assets/img/Sidebar/food disk.png"
import human1 from "../assets/img/Sidebar/s-1.png"
import human2 from "../assets/img/Sidebar/s-2.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import Modal from 'react-bootstrap/Modal';
import send from "../assets/img/Sidebar/send(1).png"
import danger from "../assets/img/Sidebar/danger.png"
import block1 from "../assets/img/Sidebar/m-1.png"
import leave1 from "../assets/img/Sidebar/m-2.png"
import delete1 from "../assets/img/Sidebar/m-3.png"
import { IMG_URL } from '../API/ApiUrl'
import placeholderimg from "../assets/img/Sidebar/placeholder1.png"
import banner from "../assets/img/Sidebar/coverpic.png"

function MemberSidebar(props) {

    let data = props.profile
    const userId = localStorage.getItem("UserToken")
    const [show, setShow] = useState(false)
    const [report, setReport] = useState(false)
    const [block, setBlock] = useState(false)
    const [leave, setLeave] = useState(false)
    const [deletes, setDeletes] = useState(false)


    console.log("props profile >>", data);


    const handmem = () => {
        if (show) {
            setShow(false)
            document.getElementById('Members').style.display = 'block'
            document.getElementById('Member-bar').style.right = '400px'

        } else {

            setShow(true)
            document.getElementById('Members').style.display = 'none'
            document.getElementById('Member-bar').style.right = '0'
        }
    }

    return (
        <>
            <p className="m-0" id='functionMember' onClick={handmem}></p>

            <div className='memberside'>
                <div className='member-sidebar' id='Member-bar'>
                    <div className="member-banner">
                        <div className='member-head'>
                            <img src={arrow} width={30} onClick={handmem} alt="" />
                            <div className='member-menu' onClick={() => setReport(true)}>
                                <i className="bi bi-three-dots"></i>
                            </div>
                        </div>
                        <img src={data.chatroom_cover_image ? IMG_URL + data.chatroom_cover_image : banner} className='img-fluid' alt="" />
                        <div className="member-group-head">
                            <img src={data.chatroom_image ? IMG_URL + data.chatroom_image : placeholderimg} className='member-group-dp' alt="" />
                            <div className="member-group-tital">
                                <p className='group-tital-1'>{data.chatroom_name || ""}</p>
                                <p className='group-tital-2'>{data.total_member || 0} Members</p>
                            </div>
                        </div>
                        <p className='group-bio text-break'>
                            {data.chatroom_description || ""}
                        </p>
                        {/* <p className="group-bio-more">
                            More...
                        </p> */}
                    </div>
                    <span className='member-side-hr'>
                        <hr />
                    </span>
                    <div className="member-show-data">
                        <p className="member-count-text">{data.total_member} Members</p>
                        {
                            data.member?.map((item, index) => {
                                return (
                                    <div className='member-center' key={index}>
                                        <div className='srch-icon'>
                                            <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='srch-img' />
                                            <div className='srch-line'>
                                                <p>{item.first_name || ""} {item.last_name || ""}</p>
                                                {/* <div className='d-flex m-0 mt-2  align-items-center' >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M16.0923 16.2113L13.9253 15.7344L13.2536 17.8547C13.2053 18.0071 12.9989 18.0295 12.9191 17.8909L10.2383 13.2339C11.5588 12.9478 12.7338 12.2696 13.6359 11.3271L16.2911 15.9401C16.3708 16.0785 16.2482 16.2456 16.0923 16.2113Z" fill="#2D50BC" />
                                                        <path d="M16.0923 16.2113L14.0893 15.7705C13.9964 15.75 13.9032 15.8038 13.8745 15.8945L13.2536 17.8547C13.2053 18.0071 12.9989 18.0295 12.9191 17.8909L10.6225 13.901L10.2383 13.2339C11.5588 12.9478 12.7338 12.2696 13.6359 11.3271L14.0172 11.989L16.2911 15.9401C16.3707 16.0785 16.2482 16.2457 16.0923 16.2113Z" fill="#2D50BC" />
                                                        <path d="M16.0921 16.211L14.0892 15.7702C13.9962 15.75 13.9032 15.8037 13.8745 15.8941L14.0901 15.2242C14.1192 15.1338 14.2123 15.0805 14.305 15.1011L16.0285 15.4842L16.291 15.9401C16.3707 16.0783 16.2479 16.2456 16.0921 16.211Z" fill="#2D50BC" />
                                                        <path d="M7.80382 13.1778L5.08062 17.9076C5.00084 18.0462 4.79438 18.0239 4.74607 17.8714L4.12514 15.9112C4.09643 15.8206 4.00327 15.7668 3.91041 15.7872L1.90743 16.228C1.75148 16.2624 1.62895 16.0952 1.70862 15.9568L4.24135 11.5566C5.1869 12.4531 6.46233 12.957 7.80382 13.1778Z" fill="#2D50BC" />
                                                        <path d="M4.59971 12.2113L2.34282 16.1321L1.90747 16.228C1.75152 16.2624 1.62895 16.0952 1.70861 15.9568L3.86449 12.2113L4.44748 11.1958C4.60446 11.3444 4.76842 11.4856 4.93937 11.6184L4.59971 12.2113Z" fill="#2D50BC" />
                                                        <path d="M5.34613 17.446L5.08033 17.9077C5.00056 18.0463 4.79431 18.0243 4.74579 17.8717L4.12485 15.9115C4.09618 15.8207 4.00317 15.767 3.91016 15.7872L4.59689 15.6362C4.68976 15.6157 4.78288 15.6695 4.81159 15.7601L5.34613 17.446Z" fill="#2D50BC" />
                                                        <path d="M4.30237 11.4448L6.09344 12.2525L7.80355 13.178L7.34501 13.9739C6.06638 13.7143 4.88003 13.1191 3.89955 12.2426C3.88779 12.2323 3.87602 12.222 3.86426 12.2113L4.30237 11.4448Z" fill="#2D50BC" />
                                                        <path d="M14.0172 11.989L13.6359 11.3271L11.6186 12.2176L10.2383 13.2339L10.3934 13.5033L10.7744 14.1649L12.9191 17.8908C12.9989 18.0294 13.2053 18.0071 13.2536 17.8546L13.3902 17.4235L11.2585 13.7103C12.2905 13.3512 13.2299 12.767 14.0172 11.989Z" fill="#2D50BC" />
                                                        <path d="M15.672 6.69231C15.672 8.49103 14.9643 10.1246 13.8125 11.3271C12.9103 12.2696 11.7354 12.9477 10.4148 13.2338C9.95859 13.3326 9.48545 13.3846 8.9998 13.3846C8.62923 13.3846 8.26601 13.3544 7.91198 13.2961C6.63923 13.0867 5.48744 12.5159 4.56542 11.6921C4.51542 11.6479 4.46652 11.6029 4.418 11.5568C4.24594 11.3938 4.08272 11.2216 3.92831 11.0413C3.78052 10.868 3.64156 10.6869 3.51178 10.4989C3.12209 9.93509 2.81659 9.30784 2.61329 8.63599C2.42763 8.02094 2.32764 7.36861 2.32764 6.69235C2.32764 2.99639 5.31502 0 8.9998 0C9.67367 0 10.3244 0.10029 10.9372 0.286901C11.6074 0.490091 12.2324 0.796513 12.7949 1.18775C12.9824 1.31793 13.1629 1.4573 13.3353 1.60589C13.5662 1.80353 13.7831 2.01705 13.9849 2.24418C15.0345 3.42674 15.672 4.98503 15.672 6.69231Z" fill="url(#paint0_linear_208_10058)" />
                                                        <path d="M8.99964 11.4857C11.639 11.4857 13.7786 9.33962 13.7786 6.69231C13.7786 4.045 11.639 1.89893 8.99964 1.89893C6.3603 1.89893 4.2207 4.045 4.2207 6.69231C4.2207 9.33962 6.3603 11.4857 8.99964 11.4857Z" fill="white" />
                                                        <path d="M13.7784 6.69252C13.7784 9.33973 11.6388 11.4858 8.99956 11.4858C6.36033 11.4858 4.2207 9.33973 4.2207 6.69252C4.2207 6.49263 4.23283 6.29573 4.25673 6.10254C4.54643 8.47171 6.55958 10.3058 8.99956 10.3058C11.4395 10.3058 13.4527 8.47171 13.7424 6.10254C13.7663 6.29577 13.7784 6.49267 13.7784 6.69252Z" fill="url(#paint1_linear_208_10058)" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.99956 1.89893C6.36033 1.89893 4.2207 4.04502 4.2207 6.69223C4.2207 9.33945 6.36033 11.4855 8.99956 11.4855C11.6388 11.4855 13.7784 9.33945 13.7784 6.69223C13.7784 4.04502 11.6388 1.89893 8.99956 1.89893ZM8.99956 10.9324C6.6684 10.9324 4.77215 9.03041 4.77215 6.69223C4.77215 4.35405 6.6684 2.45207 8.99956 2.45207C11.3307 2.45207 13.227 4.35405 13.227 6.69223C13.227 9.03041 11.3307 10.9324 8.99956 10.9324Z" fill="#6663FA" />
                                                        <path d="M9.16513 4.04149L9.82162 5.3825C9.84834 5.43713 9.90036 5.475 9.96058 5.48364L11.4359 5.69561C11.5869 5.7173 11.6473 5.90285 11.538 6.00924L10.468 7.05122C10.4245 7.09354 10.4047 7.15453 10.415 7.21427L10.67 8.68756C10.6961 8.83807 10.5378 8.95284 10.4028 8.88134L9.08609 8.18357C9.03227 8.15504 8.96779 8.15504 8.91397 8.18357L7.59722 8.88134C7.46222 8.95288 7.30396 8.8381 7.33002 8.68756L7.58501 7.21427C7.59534 7.15453 7.57556 7.09354 7.53211 7.05122L6.46204 6.00924C6.35274 5.90281 6.41314 5.7173 6.56413 5.69561L8.03948 5.48364C8.09966 5.475 8.15168 5.43713 8.17844 5.3825L8.83493 4.04149C8.90217 3.90414 9.09789 3.90414 9.16513 4.04149Z" fill="url(#paint2_linear_208_10058)" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_208_10058" x1="2.32764" y1="0" x2="15.7122" y2="13.3443" gradientUnits="userSpaceOnUse">
                                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                                <stop offset="0.8125" stopColor="#B900C6" />
                                                            </linearGradient>
                                                            <linearGradient id="paint1_linear_208_10058" x1="4.2207" y1="6.10254" x2="8.82439" y2="14.2761" gradientUnits="userSpaceOnUse">
                                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                                <stop offset="0.8125" stopColor="#B900C6" />
                                                            </linearGradient>
                                                            <linearGradient id="paint2_linear_208_10058" x1="6.40625" y1="3.93848" x2="11.3661" y2="9.12104" gradientUnits="userSpaceOnUse">
                                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                                <stop offset="0.8125" stopColor="#B900C6" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p className='ps-2'>#200</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        {
                                            item.chat_created_by == item.chat_created_to ?
                                                <div className='chat-min'>
                                                    <p className='m-0'>Admin</p>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                    <span className='member-side-hr'>
                        <hr />
                    </span>
                    <div className="member-footer">
                        {
                            data.chatroom_created_by != userId ? ""
                                :
                                <div className="member-block" onClick={() => setBlock(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clipPath="url(#clip0_208_10145)">
                                            <path d="M9.99984 1.6665C5.39984 1.6665 1.6665 5.39984 1.6665 9.99984C1.6665 14.5998 5.39984 18.3332 9.99984 18.3332C14.5998 18.3332 18.3332 14.5998 18.3332 9.99984C18.3332 5.39984 14.5998 1.6665 9.99984 1.6665ZM3.33317 9.99984C3.33317 6.3165 6.3165 3.33317 9.99984 3.33317C11.5415 3.33317 12.9582 3.85817 14.0832 4.7415L4.7415 14.0832C3.85817 12.9582 3.33317 11.5415 3.33317 9.99984ZM9.99984 16.6665C8.45817 16.6665 7.0415 16.1415 5.9165 15.2582L15.2582 5.9165C16.1415 7.0415 16.6665 8.45817 16.6665 9.99984C16.6665 13.6832 13.6832 16.6665 9.99984 16.6665Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_208_10145">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className='pt-2 m-0'>Block</p>
                                </div>
                        }

                        <div className="member-block" onClick={() => setLeave(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14.5332 12.1832L16.6665 10.0498L14.5332 7.9165" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.1333 10.0498H16.6083" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.79997 16.6668C6.11663 16.6668 3.1333 14.1668 3.1333 10.0002C3.1333 5.8335 6.11663 3.3335 9.79997 3.3335" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className='pt-2 m-0'>Leave</p>
                        </div>

                        {
                            data.chatroom_created_by != userId ? ""
                                :
                                <div className="member-block" onClick={() => setDeletes(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.0835 4.1415L7.26683 3.04984C7.40016 2.25817 7.50016 1.6665 8.9085 1.6665H11.0918C12.5002 1.6665 12.6085 2.2915 12.7335 3.05817L12.9168 4.1415" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.6084 13.75H11.3834" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.9165 10.4165H12.0832" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p className='pt-2 m-0'>Delete</p>
                                </div>
                        }

                    </div>
                </div>
            </div >
            <div className="members" onClick={handmem} id='Members'></div>

            <Modal
                show={report} onHide={() => setReport(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Report</p>
                        <img src={danger} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Copy Link</p>
                        <img src={send} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setReport(false)}>Cancel</p>
                </div>
            </Modal>

            <Modal
                show={block} onHide={() => setBlock(false)}
                centered
            >
                <div className='text-center mt-4 mb-4'>
                    <img src={block1} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with blocking <br />
                    this group?</p>
                <div className='kyc-con-btn'>
                    <button className='boost-btn m-0' onClick={() => setBlock(false)} >Yes, Block</button>

                </div>
            </Modal>

            <Modal
                show={leave} onHide={() => setLeave(false)}
                centered
            >
                <div className='text-center mt-4 mb-4'>
                    <img src={leave1} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with leaving <br />
                    this group?</p>
                <div className='kyc-con-btn'>
                    <button className='boost-btn m-0' onClick={() => setLeave(false)} >Yes, Leave</button>
                </div>

            </Modal>

            <Modal
                show={deletes} onHide={() => setDeletes(false)}
                centered
            >
                <div className='text-center mt-4 mb-4'>
                    <img src={delete1} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with deleting <br />
                    this group?</p>
                <div className='kyc-con-btn'>
                    <button className="delete-btn" onClick={() => setDeletes(false)}>Yes, Delete</button>
                </div>

            </Modal>

        </>
    )
}

export default MemberSidebar

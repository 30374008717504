import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import "../DataG8/DataG8.css"
// import DataG8Sidebar from '../DataG8/DataG8Sidebar'


export default function DataG8Home() {


    const [renameModel, setRenameModel] = useState(false)
    const [detailsModel, setDetailsModel] = useState(false)

    return (
        <div>
            <div className="row">
                {/* <div className="col-2">
                    <DataG8Sidebar></DataG8Sidebar>
                </div> */}
                <div className="col-12">

                    <div className="container mx-auto mt-4">
                    <div className="datag8-row1 mt-3 p-3 text-center">
                        <span className="text-white">Messenger</span>
                    </div>
                        <div className="row">
                            <div className="col-md-3 pt-3">
                                <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
                                    <div className="folderPadding d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-folder fa-4x"></i>
                                    </div>
                                    <div className="card-body py-2">
                                        <div className="row">
                                            <div className=" col-10 my-auto">
                                                <h6 className="card-subtitle">Images</h6>
                                            </div>
                                            <div className="col-2">

                                                <Dropdown id='dropdown-data-option'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title="">
                                                        <div className='option-menudatag8'>
                                                            <div className='menu-message d-flex align-items-center py-1'>
                                                                <i className="fa-solid fa-download px-2"></i>
                                                                Download
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setRenameModel(true); }}>
                                                                <i className="fa-regular fa-pen-to-square px-2"></i>
                                                                Rename
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setDetailsModel(true); }}>
                                                                <i className="fa-solid fa-circle-info px-2"></i>
                                                                Details
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pt-3">
                                <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
                                    <div className="folderPadding d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-folder fa-4x"></i>
                                    </div>
                                    <div className="card-body py-2">
                                        <div className="row">
                                            <div className=" col-10 my-auto">
                                                <h6 className="card-subtitle">Video</h6>
                                            </div>
                                            <div className="col-2">

                                                <Dropdown id='dropdown-data-option'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title="">
                                                        <div className='option-menudatag8'>
                                                            <div className='menu-message d-flex align-items-center py-1'>
                                                                <i className="fa-solid fa-download px-2"></i>
                                                                Download
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setRenameModel(true); }}>
                                                                <i className="fa-regular fa-pen-to-square px-2"></i>
                                                                Rename
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setDetailsModel(true); }}>
                                                                <i className="fa-solid fa-circle-info px-2"></i>
                                                                Details
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pt-3">
                                <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
                                    <div className="folderPadding d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-folder fa-4x"></i>
                                    </div>
                                    <div className="card-body py-2">
                                        <div className="row">
                                            <div className=" col-10 my-auto">
                                                <h6 className="card-subtitle">Audios</h6>
                                            </div>
                                            <div className="col-2">

                                                <Dropdown id='dropdown-data-option'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title="">
                                                        <div className='option-menudatag8'>
                                                            <div className='menu-message d-flex align-items-center py-1'>
                                                                <i className="fa-solid fa-download px-2"></i>
                                                                Download
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setRenameModel(true); }}>
                                                                <i className="fa-regular fa-pen-to-square px-2"></i>
                                                                Rename
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setDetailsModel(true); }}>
                                                                <i className="fa-solid fa-circle-info px-2"></i>
                                                                Details
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pt-3">
                                <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
                                    <div className="folderPadding d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-folder fa-4x"></i>
                                    </div>
                                    <div className="card-body py-2">
                                        <div className="row">
                                            <div className=" col-10 my-auto">
                                                <h6 className="card-subtitle">Documents</h6>
                                            </div>
                                            <div className="col-2">

                                                <Dropdown id='dropdown-data-option'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title="">
                                                        <div className='option-menudatag8'>
                                                            <div className='menu-message d-flex align-items-center py-1'>
                                                                <i className="fa-solid fa-download px-2"></i>
                                                                Download
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setRenameModel(true); }}>
                                                                <i className="fa-regular fa-pen-to-square px-2"></i>
                                                                Rename
                                                            </div>
                                                            <div className='menu-message d-flex align-items-center py-1' onClick={() => { setDetailsModel(true); }}>
                                                                <i className="fa-solid fa-circle-info px-2"></i>
                                                                Details
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* rename model */}
            <Modal
                show={renameModel} onHide={() => setRenameModel(false)}
                centered
            >
                <div className='activity-disck'>

                    <div className="lgn-input-1">
                        <div className="datag8_modal-header">
                            <h5 className="modal-title">Rename</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setRenameModel(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <input type="text" className="i-put-1" required />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center pt-3'>
                    <p onClick={() => setRenameModel(false)}>Create</p>
                </div>
            </Modal >
            {/* end */}

            {/* details model */}
            <Modal
                show={detailsModel} onHide={() => setDetailsModel(false)}
                centered
            >
                <div className='activity-disck'>

                    <div className="lgn-input-1">
                        <div className="datag8_modal-header">
                            <h5 className="modal-title">Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setDetailsModel(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="row pt-3">
                            <div className="col-6 pb-2">Name:</div>
                            <div className="col-6 pb-2">sample-5s.mp4</div>

                            <div className="col-6 pb-2">Uploaded by:</div>
                            <div className="col-6 pb-2">Self</div>

                            <div className="col-6 pb-2">Location:</div>
                            <div className="col-6 pb-2">Folder Name</div>

                            <div className="col-6 pb-2">Uploaded:</div>
                            <div className="col-6 pb-2">Dec 1, 2023, 6:23 PM</div>

                            <div className="col-6 pb-2">Modified:</div>
                            <div className="col-6 pb-2">Nov 27, 2023, 11:46 AM</div>

                            <div className="col-6 pb-2">Type:</div>
                            <div className="col-6 pb-2">Video file</div>

                            <div className="col-6 pb-2">MIME type:</div>
                            <div className="col-6 pb-2">video/mp4</div>

                            <div className="col-6 pb-2">Size:</div>
                            <div className="col-6 pb-2">2.77 MB</div>

                            <div className="col-6 pb-2">Original size:</div>
                            <div className="col-6 pb-2">2.72 MB</div>

                            <div className="col-6 pb-2">Shared:</div>
                            <div className="col-6 pb-2">No</div>
                        </div>
                    </div>
                </div>
                <div className='pro-modal-bottom text-center pt-3'>
                    <p onClick={() => setDetailsModel(false)}>Create</p>
                </div>
            </Modal >
            {/* end */}

        </div>
    )
}

import { ExitIcon } from "@100mslive/react-icons";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import React from "react";
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"


function Header() {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();

  return (
    <header className="headerClass">
      <img
        className="logo"
        src={headlogo}
        alt="logo"
        width={195}
      />
      {isConnected && (
        <button
          id="leave-btn"
          className="btn btn-danger"
          onClick={() => hmsActions.leave()}
        >
          <ExitIcon style={{ marginLeft: "0.25rem" }} /> Leave Room
        </button>
      )}
    </header>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import {
  useHMSStore,
  useHMSActions,
  selectHMSMessages,
} from "@100mslive/react-sdk";

const Chat = ({ userName }) => {
  const hmsActions = useHMSActions();
  const messages = useHMSStore(selectHMSMessages);
  const [message, setMessage] = useState("");

//   const handleSendMessage = async () => {
//     if (message.trim()) {
//       await hmsActions.sendBroadcastMessage({
//         message: message,
//         type: 'chat',
//         senderName: userName,
//       });
//       setMessage("");
//     }
//   };
  const handleSendMessage = async () => {
    if (message.trim()) {
      await hmsActions.sendBroadcastMessage(message);
      setMessage("");
    }
  };

  useEffect(() => {
    // Optionally, scroll to the bottom of the chat when a new message is received
    const chatMessagesElement = document.querySelector(".chat-messages");
    chatMessagesElement.scrollTop = chatMessagesElement.scrollHeight;
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className="chat-message">
            <strong>{msg.senderName || "Unknown"}:</strong> {msg.message}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here"
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;

import React, { Suspense, lazy } from 'react';
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"


// Lazy load the component
const HomelazyComponent = lazy(() => import('./homelazyComponent'));

const App = () => {
    return (
        <div>
            <Suspense fallback={
             <div className='head-logo text-center'>
                <img src={headlogo}/>
            </div>}>
                <HomelazyComponent />
            </Suspense>
        </div>
    );
};

export default App;
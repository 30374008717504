import { createSlice } from "@reduxjs/toolkit";



const customDataSlice=createSlice({
    name:"customeData",
    initialState:{},
    reducers:{
        setCustomData:(state,action)=>{
            return action.payload;
        }
    }
})

const {setCustomData} =customDataSlice.actions;

export {
    setCustomData,
    customDataSlice
} 
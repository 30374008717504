import React, { useState } from 'react'
import "../Profile/Profile.css"
import bar from "../assets/img/Sidebar/menu (1).png"
import { Col, Container, Row } from 'react-bootstrap'
import setting from "../assets/img/Sidebar/Setting.png"
import bbanner from "../assets/img/Sidebar/business-banner.png"
import edit from "../assets/img/Sidebar/pro-banr-edit.png"
import vlg1 from "../assets/img/Sidebar/vlg-1.png"
import vlg2 from "../assets/img/Sidebar/vlg-2.png"
import vlg3 from "../assets/img/Sidebar/vlg-3.png"
import vlg4 from "../assets/img/Sidebar/vlg-4.png"
import vlg5 from "../assets/img/Sidebar/vlg-5.png"
import vlg6 from "../assets/img/Sidebar/vlg-6.png"
import reels from "../assets/img/Sidebar/REELS.png"
import bpost1 from "../assets/img/Sidebar/b-1.png"
import bpost2 from "../assets/img/Sidebar/b-2.png"
import bpost3 from "../assets/img/Sidebar/b-3.png"
import bpost4 from "../assets/img/Sidebar/b-4.png"
import bpost5 from "../assets/img/Sidebar/b-5.png"
import bpost6 from "../assets/img/Sidebar/b-6.png"
import bdp from "../assets/img/Sidebar/cal-img.png"
import activity1 from "../assets/img/Sidebar/Rectangle 23.png"
import activity2 from "../assets/img/Sidebar/Rect.png"
import activity3 from "../assets/img/Sidebar/Rectangle 23 (1).png"
import { clsx } from 'clsx';
import Sidebar from '../Sidebar/Sidebar'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom'
import cal from "../assets/img/Sidebar/cal-img.png"
import bluetik from "../assets/img/Sidebar/bluetik.png"
import ractangle from "../assets/img/Sidebar/Rectangle 23.png"
import edit1 from "../assets/img/Sidebar/edit-2.png"
import trash from "../assets/img/Sidebar/trash.png"
import move from "../assets/img/Setting/delete.png"
import p1 from "../assets/img/Sidebar/s-1.png"
import p2 from "../assets/img/Sidebar/s-3.png"
import p3 from "../assets/img/Sidebar/s-4.png"
import p4 from "../assets/img/Sidebar/s-5.png"

function BusinessProfile() {

    let navigate = useNavigate()

    const [isChange, setIsChange] = useState(1)
    const [activity, setActivity] = useState(false)
    const [menu, setMenu] = useState(false)
    const [remove, setRemove] = useState(false)


    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    const handleSetting = () => {
        navigate("/setting")
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} width={50} alt="" />
            <Sidebar />

            <div className='sub-profile'>
                <Container className='subprofile-1'>
                    <Row>
                        <Col>
                            <div className='subprofile-2'>
                                <img src={bbanner} className='pro-banner' />
                                <img src={edit} className='pro-banner-edit' />
                            </div>
                            <div className='sub-pro-head'>
                                <div className='sub-pro-head1'>
                                    <div>
                                        <img src={bdp} className='sub-pro-img' />
                                    </div>
                                    <div className='sub-pro-text'>
                                        <p className='m-0 pb-2 pt-4'>Jacob Williams</p>
                                        <div className='d-flex'>
                                            <div className=''>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                    <path d="M14.6131 3.71487L11.08 3.2015L9.49998 0L7.91997 3.2015L4.3869 3.71487L6.94348 6.20688L6.33993 9.72566L9.49998 8.06431L12.66 9.72566L12.0565 6.20688L14.6131 3.71487Z" fill="url(#paint0_linear_204_5774)" />
                                                    <path d="M9.49997 11.6977L3.78717 9.40576V12.6612L9.5333 14.9666L15.2128 12.6595V9.40576L9.49997 11.6977Z" fill="url(#paint1_linear_204_5774)" />
                                                    <path d="M9.49997 15.7314L3.78717 13.4395V16.6949L9.5333 19.0002L15.2128 16.6932V13.4395L9.49997 15.7314Z" fill="url(#paint2_linear_204_5774)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_204_5774" x1="4.3869" y1="0" x2="14.1003" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                            <stop offset="0.140625" stopColor="#4360BC" />
                                                            <stop offset="0.8125" stopColor="#B900C6" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_204_5774" x1="3.78717" y1="9.40576" x2="8.1634" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                            <stop offset="0.140625" stopColor="#4360BC" />
                                                            <stop offset="0.8125" stopColor="#B900C6" />
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear_204_5774" x1="3.78717" y1="13.4395" x2="8.16336" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                            <stop offset="0.140625" stopColor="#4360BC" />
                                                            <stop offset="0.8125" stopColor="#B900C6" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <p className='ps-3 pb-2'>#200</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='sub-pro-menu' >
                                    <img src={setting} className='profile-setting' onClick={handleSetting} />
                                </div>
                            </div>

                            <div className='sub-pro-count pt-2'>
                                <div className='d-flex'>
                                    <p className='sub-pro-center'>10</p>
                                    <p className='sub-pro-following ps-2'>Posts</p>
                                </div>
                                <div className='d-flex' >
                                    <p className='sub-pro-center'>10.2K</p>
                                    <p className='sub-pro-following ps-2'>Followers</p>
                                </div>
                                <div className='d-flex' >
                                    <p className='sub-pro-center'>10</p>
                                    <p className='sub-pro-following ps-2'>Following</p>
                                </div>
                            </div>

                            <div className='business-bio'>
                                <p>E-Commerce</p>
                                <p style={{ color: "#2D50BC" }}>http://www.womanplanet.ecommerce.com</p>
                            </div>

                            <div className='datapro-pvo'>
                                <p className={clsx([isChange === 1 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(1)}>Posts</p>
                                <p className={clsx([isChange === 2 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(2)}>Vlogs</p>
                                <p className={clsx([isChange === 3 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(3)}>OneG8</p>
                                <p className={clsx([isChange === 4 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(4)}>Activity</p>
                                <p className={clsx([isChange === 5 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(5)}>Members</p>
                            </div>

                            <span style={{ color: "#838287" }}>
                                <hr />
                            </span>

                            {
                                isChange === 1 ?
                                    <div className='business-pro-1'>
                                        <div>
                                            <div className='data-pro-pic'>
                                                <img src={bpost1} className='img-pic' />
                                                <img src={bpost2} className='img-pic' />
                                                <img src={bpost3} className='img-pic' />
                                            </div>
                                            <div className='data-pro-pic'>
                                                <img src={bpost4} className='img-pic' />
                                                <img src={bpost5} className='img-pic' />
                                                <img src={bpost6} className='img-pic' />
                                            </div>
                                        </div>
                                    </div>
                                    : isChange === 2 ?
                                        <div className='business-pro-1'>
                                            <div>
                                                <div className='data-pro-pic'>
                                                    <img src={vlg3} className='img-pic' />
                                                    <img src={vlg5} className='img-pic' />
                                                    <img src={vlg1} className='img-pic' />
                                                </div>
                                                <div className='data-pro-pic'>
                                                    <img src={vlg4} className='img-pic' />
                                                    <img src={vlg2} className='img-pic' />
                                                    <img src={vlg6} className='img-pic' />
                                                </div>
                                            </div>
                                        </div>
                                        : isChange === 3 ?
                                            <div className='business-pro-1'>
                                                <div>
                                                    <div className='data-pro-pic'>
                                                        <img src={reels} className='img-pic' />
                                                        <img src={reels} className='img-pic' />
                                                        <img src={reels} className='img-pic' />
                                                    </div>
                                                    <div className='data-pro-pic'>
                                                        <img src={reels} className='img-pic' />
                                                        <img src={reels} className='img-pic' />
                                                        <img src={reels} className='img-pic' />
                                                    </div>
                                                </div>

                                            </div>
                                            : isChange === 4 ?
                                                <div>
                                                    <div className='activ-part1'>
                                                        <div className='business-head' onClick={() => setActivity(true)}>
                                                            <img src={activity1} width={180} />
                                                            <div className='business-side'>
                                                                <p>Fantastic Friday!</p>
                                                                <p style={{ color: "#817d7d" }}>Event</p>
                                                            </div>
                                                        </div>
                                                        <div className='business-footer'>
                                                            <p>11 September 2023</p>
                                                            <p className='ms-4'>09:00 AM-05:00 PM</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='activ-part1'>
                                                        <div className='business-head'>
                                                            <img src={activity2} width={180} />
                                                            <div className='business-side'>
                                                                <p>Fantastic Friday!</p>
                                                                <p style={{ color: "#817d7d" }}>Event</p>
                                                            </div>
                                                        </div>
                                                        <div className='business-footer'>
                                                            <p>11 September 2023</p>
                                                            <p className='ms-4'>09:00 AM-05:00 PM</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='activ-part1'>
                                                        <div className='business-head'>
                                                            <img src={activity3} width={180} />
                                                            <div className='business-side'>
                                                                <p>Fantastic Friday!</p>
                                                                <p style={{ color: "#817d7d" }}>Event</p>
                                                            </div>
                                                        </div>
                                                        <div className='business-footer'>
                                                            <p>11 September 2023</p>
                                                            <p className='ms-4'>09:00 AM-05:00 PM</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                :
                                                <div className='business-pro-2'>
                                                    <div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p1} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p2} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p3} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='remove-btn' style={{ width: 87 }}>Following</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p4} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p2} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p3} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                        <div className='menber-show'>
                                                            <div className='menber-show-1'>
                                                                <img src={p4} width={50} />
                                                                <div className='block-plate-1 ms-3'>
                                                                    <p>Alisa Williams</p>
                                                                </div>
                                                            </div>
                                                            <button className='unblock-btn'>Follow</button>
                                                        </div>
                                                    </div>
                                                </div>

                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                show={activity} onHide={() => setActivity(false)}
                centered
                contentClassName='businessactivity'
            >
                <div className='date-modal-1'>
                    <div className="date-head">
                        <div className='date-data'>
                            <img src={cal} className='date-img' />
                            <div className='date-text'>
                                <p>Woman Planet</p>
                                <p>10 min Event</p>
                            </div>
                            <img src={bluetik} className='bluetick' />
                        </div>
                        <div style={{ fontSize: "20px" }} onClick={() => { setMenu(true); setActivity(false) }}>
                            <i className="bi bi-three-dots"></i>
                        </div>
                    </div>
                    <img src={ractangle} className='ractangle-img' />
                    <div className='date-center'>
                        <p>08 September 2023</p>
                        <p className='ms-4'>09:00 AM - 05:00 PM</p>
                    </div>
                    <p className='date-center-1'>Fantastic Friday!</p>
                    <p className='date-footer'>
                        Join us for 'Fantastic Friday,' the ultimate shopping extravaganza for fashion enthusiasts! Explore a wide array of stylish clothing options, from trendy streetwear to elegant formal attire, all under one roof. Discover the latest fashion trends, exclusive discounts, and an unforgettable shopping experience. Get ready to revamp your wardrobe and make your Friday truly fantastic!
                    </p>
                </div>
            </Modal>

            <Modal
                show={remove} onHide={() => setRemove(false)}
                centered
            >

                <div className='text-center mt-4 mb-4'>
                    <img src={move} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with deleting <br />
                    your account?</p>
                <div className='kyc-con-btn'>
                    <button className="delete-btn" onClick={() => setRemove(false)}>Yes, Delete</button>
                </div>
            </Modal>

            <Modal
                show={menu} onHide={() => setMenu(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Edit</p>
                        <img src={edit1} />
                    </div>
                </div>
                <div className='data-pro-modal' onClick={() => { setRemove(true); setMenu(false) }}>
                    <div className='pro-danger' >
                        <p>Delete</p>
                        <img src={trash} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setMenu(false)}>Cancel</p>
                </div>
            </Modal>


        </>
    )
}

export default BusinessProfile

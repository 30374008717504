import React from 'react'
import "../Wallet/Overview.css"
import Sidebar from '../Sidebar/Sidebar'
import bar from "../assets/img/Sidebar/menu (1).png"
// import { colors } from '@material-ui/core'
const hanldeSidebar = () => {
    document.getElementById('functionSidebar').click()
}


export default function Overview() {
    return (
        <div>
            <img src={bar} onClick={hanldeSidebar} className='sidebar-menu' alt="" />

            <div className="row">
                <div className="col-3">
                    <Sidebar />

                </div>
                <div className="col-9">
                    <div className="row justify-content-center">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <h5 className="card-title text-white">Coin List <span className=" fw-normal ms-2 text-white">(834)</span></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 bgPrimary py-3" >
                            <div className="row p-2">
                                <div className="col-1 my-auto">
                                    <img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/btc.png" alt="" />
                                </div>
                                <div className="col-5">
                                    <div className="row text-white">
                                        <div>BTC</div>
                                        <div>Bitcoin</div>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="row text-white">
                                        <div>$ 66431.3</div>
                                        <div>Volume : 12333</div>
                                    </div>

                                </div>
                            </div>
                            <hr style={{color : 'white'}} />
                            <div className="row p-2">
                                <div className="col-1 my-auto">
                                    <img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/btc.png" alt="" />
                                </div>
                                <div className="col-5">
                                    <div className="row text-white">
                                        <div>BTC</div>
                                        <div>Bitcoin</div>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="row text-white">
                                        <div>$ 66431.3</div>
                                        <div>Volume : 12333</div>
                                    </div>

                                </div>
                            </div>
                            <hr style={{color : 'white'}} />
                            <div className="row p-2">
                                <div className="col-1 my-auto">
                                    <img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/btc.png" alt="" />
                                </div>
                                <div className="col-5">
                                    <div className="row text-white">
                                        <div>BTC</div>
                                        <div>Bitcoin</div>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="row text-white">
                                        <div>$ 66431.3</div>
                                        <div>Volume : 12333</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

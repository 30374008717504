import React, { useEffect, useState } from 'react'
import "./Profile.css"
import { toast } from "react-toastify";
import bar from "../assets/img/Sidebar/menu (1).png"
import { Col, Container, Row } from 'react-bootstrap'
import setting from "../assets/img/Sidebar/Setting.png"
import banner from "../assets/img/Sidebar/coverpic.png"
import edit from "../assets/img/Sidebar/pro-banr-edit.png"
import { clsx } from 'clsx';
import Sidebar from '../Sidebar/Sidebar'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom'
import { BASE_URL, IMG_URL, decryptData, encryptData } from '../API/ApiUrl'
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"
import placeholderimg1 from "../assets/img/Sidebar/placeholder1.png"
import { Circles, ThreeDots } from 'react-loader-spinner'
import verifiedOrange from "../assets/img/Sidebar/verify-orange.png"
import view from "../assets/img/Sidebar/view more.png"

function Profile() {

    let navigate = useNavigate()

    const [showFollow, setShowFollow] = useState(false)
    const [showFollowing, setShowFollowing] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isChange, setIsChange] = useState(1)
    const [currPage, setCurrPage] = useState(1)
    const [currPages, setCurrPages] = useState(1)
    const [currPagess, setCurrPagess] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalPages, setTotalPages] = useState()
    const [totalPagess, setTotalPagess] = useState()

    const [postView, setPostview] = useState([])
    const [vlogView, setVlogview] = useState([])
    const [reelsView, setReelsview] = useState([])
    const [followersList, setFollowersList] = useState([])
    const [followingList, setFollowingList] = useState([])

    const [PersonalData, setPersonalData] = useState({})

    const [searchFollowers, setSearchFollowers] = useState("")
    const [searchFollowing, setSearchFollowing] = useState("")
    const [coverpic, setCoverpic] = useState("")

    useEffect(() => {
        HandleBio()
        setIsLoading(true);
        MyProfilePostList(currPage)
    }, [])

    useEffect(() => {
        if (searchFollowers) {
            HandleFollowers()
        }
    }, [searchFollowers])

    useEffect(() => {
        if (searchFollowing) {
            HandleFollowing()
        }
    }, [searchFollowing])

    const handleReels = (id) => {
        navigate("/userreels/" + id)
    }

    const profilepost = () => {
        navigate("/profilepost")
    }

    const profilevlog = () => {
        navigate("/profilevlog")
    }

    const handleStting = () => {
        navigate("/setting")
    }

    const handleProfile = (id) => {
        navigate("/userprofile/" + id)
    }

    //current user
    const HandleBio = () => {

        setIsloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("UserToken"));

        let jsonData = {
            user_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "edit_profile", {
            method: 'POST',
            body: JSON.stringify({ "editData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    const decrypt = decryptData(response.info);
                    setPersonalData(decrypt)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })
    }

    const handleViewMore = () => {
        if (totalPage != currPage) {
            MyProfilePostList(currPage + 1);
        }
    }

    //post list API
    const MyProfilePostList = (page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: 'POST',
            other_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {

                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPage(page)
                    setTotalPage(decrypt.total_page)

                    if (page != 1) {
                        setPostview([...postView, ...decrypt.info])
                    } else {
                        setPostview(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })
    }

    const handleViewMores = () => {
        if (totalPages != currPages) {
            MyProfileVlogList(currPages + 1);
        }
    }

    //vlog list API
    const MyProfileVlogList = (page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: 'VLOG',
            other_id: localStorage.getItem("UserId")
        }

        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPages(page)
                    setTotalPages(decrypt.total_page)
                    if (page != 1) {
                        setVlogview([...vlogView, ...decrypt.info])
                    } else {
                        setVlogview(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })
    }

    const handleViewMoress = () => {
        if (totalPagess != currPagess) {
            MyProfileReelsList(currPagess + 1);
        }
    }

    // reels list API
    const MyProfileReelsList = (page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: 'REEL',
            other_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPagess(page)
                    setTotalPagess(decrypt.total_page)
                    if (page != 1) {
                        setReelsview([...reelsView, ...decrypt.info])
                    } else {
                        setReelsview(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })
    }

    // followers list API
    const HandleFollowers = () => {
        setIsloading(true)
        let jsonData = {
            page_no: 1,
            user_id: localStorage.getItem("UserId"),
            other_id: localStorage.getItem("UserId"),
            search_text: searchFollowers
        }

        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "list_followers", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setFollowersList(decrypt.info)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })
    }

    // following list API
    const HandleFollowing = () => {
        setIsloading(true)
        let jsonData = {
            page_no: 1,
            user_id: localStorage.getItem("UserId"),
            other_id: localStorage.getItem("UserId"),
            search_text: searchFollowing
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_following", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setFollowingList(decrypt.info)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })
    }

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    const followingRemove = (index,uid,e) => {
        e.preventDefault()
        let following = [...followingList]
        following.splice(index, 1)
        setFollowingList(following)
        toast.success("Unfollowing Successfully",
            { theme: "dark" }
          );
    }
    function followingFunction(e,index,follow_to) {
        e.preventDefault()
        IsFollowUnfollowing(follow_to)
        let following = [...followingList]
        following.splice(index, 1)
        setFollowingList(following)
        toast.success("Unfollowing Successfully",
            { theme: "dark" }
          );

    }

    const IsFollowUnfollow = (e, uid) => {
        e.preventDefault()
        console.log(uid)

        let jsonData = {
            user_id: uid
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("UserToken"));

        const encrypt = encryptData(jsonData);
        console.log(encrypt)

        fetch(BASE_URL + "follow_unfollow_user", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response, "yes")
                    HandleFollowers()
                    toast.success("Following Successfully",
                        { theme: "dark" }
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const IsFollowUnfollowing = (userid) => {

        let jsonData = {
            user_id: userid
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("UserToken"));

        const encrypt = encryptData(jsonData);
        console.log(encrypt)

        fetch(BASE_URL + "follow_unfollow_user", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })

            .then(response => response.json())
            .then(response => {

                if (response.Status === 0) {
                    //error data
                    console.log(response, "no")
                } else {
                    console.log(response, "yes")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));

    const getBase64 = (file) => {
        let x = []
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            x.push(reader.result)
            setCoverpic(x)
        }
    }

    const handlefile = (image) => {
        for (let i = 0; i < image.length; i++) {
            getBase64(image[i])
        }
        IsHandleSubmit(image[0])
    }

    // cover image API
    const IsHandleSubmit = (e) => {
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));
        var formdata = new FormData();
        formdata.append("cover_pic", e);
        let jsonData = {
            user_id: localStorage.getItem("UserId")
        }
        const encrypt = encryptData(jsonData);
        formdata.append("editData", encrypt)
        fetch(BASE_URL + "edit_profile", {
            method: 'POST',
            body: formdata,
            headers: myHeaders1,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response, "Yes");
                }
            })
            .catch((error) => {
                console.log("error >>", error);
            })
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} className='sidebar-menu' alt="" />
            <Sidebar />

            <div className='sub-profile' style={{ height: "90vh", overflowY: "scroll" }}>
                <Container className='subprofile-1'>
                    <Row>
                        <Col >
                            <div className='subprofile-2' >
                                {
                                    PersonalData.cover_pic ?
                                        <>
                                            {
                                                coverpic != "" ?
                                                    <img src={coverpic} alt="" className='pro-banner object-fit-cover' />
                                                    :
                                                    <img src={IMG_URL + PersonalData.cover_pic} alt="" className='pro-banner object-fit-cover' />
                                            }
                                        </>
                                        :
                                        <img src={banner} className='pro-banner' />
                                }
                                <div className='d-flex justify-content-end'>
                                    <input type="file" id="uploadcover" accept="image/*" hidden onChange={(e) => handlefile(e.target.files)} name="img" />
                                    <label htmlFor="uploadcover" className='pointer'><img src={edit} className='pro-banner-edit' /></label>
                                </div>
                            </div>

                            <div className='sub-pro-head'>
                                <div className='sub-pro-head1'>
                                    <div>
                                        {
                                            PersonalData.profile_pic ?
                                                <img src={IMG_URL + PersonalData.profile_pic} style={{ objectFit: "cover" }} className='sub-pro-img' />
                                                :
                                                <img src={placeholderimg1} className='sub-pro-img' alt="" />
                                        }
                                    </div>
                                    <div className='sub-pro-text'>
                                        <div className='d-flex align-items-center'>

                                            {
                                                isloading ?
                                                    <Circles
                                                        height="30"
                                                        width="30"
                                                        color="#fff"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        wrapperClass="plate-loader"
                                                    />
                                                    :
                                                    <>
                                                        <p className='m-0 pb-2 pt-4'>{PersonalData.first_name}</p>
                                                        <p className='m-0 pb-2 pt-4 ms-2'>{PersonalData.last_name}</p>
                                                        {/* {
                                                            PersonalData.is_user_verified == "NO" ? "" :
                                                                <img src={verifiedOrange} width={25} className='pt-3 ms-2' alt="" />
                                                        } */}
                                                    </>
                                            }

                                        </div>
                                        {/* <div className='d-flex align-items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 19 19" fill="none">
                                                <path d="M14.6131 3.71487L11.08 3.2015L9.49998 0L7.91997 3.2015L4.3869 3.71487L6.94348 6.20688L6.33993 9.72566L9.49998 8.06431L12.66 9.72566L12.0565 6.20688L14.6131 3.71487Z" fill="url(#paint0_linear_204_5774)" />
                                                <path d="M9.49997 11.6977L3.78717 9.40576V12.6612L9.5333 14.9666L15.2128 12.6595V9.40576L9.49997 11.6977Z" fill="url(#paint1_linear_204_5774)" />
                                                <path d="M9.49997 15.7314L3.78717 13.4395V16.6949L9.5333 19.0002L15.2128 16.6932V13.4395L9.49997 15.7314Z" fill="url(#paint2_linear_204_5774)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_204_5774" x1="4.3869" y1="0" x2="14.1003" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_204_5774" x1="3.78717" y1="9.40576" x2="8.1634" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_204_5774" x1="3.78717" y1="13.4395" x2="8.16336" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <p className='ps-2 m-0' onClick={HandleBio}>#200</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='sub-pro-menu pointer' >
                                    <img src={setting} onClick={handleStting} className='profile-setting' />
                                </div>
                            </div>

                            <div className='sub-pro-count pt-3 pb-2'>
                                <div className='sub-pro-count1'>
                                    <p className='m-0 sub-pro-center'>{PersonalData.total_post || 0}</p>
                                    <p className='m-0 sub-pro-following'>Posts</p>
                                </div>
                                <div className='sub-pro-count1' onClick={() => { setShowFollow(true); HandleFollowers() }}>
                                    <p className='m-0 sub-pro-center'>{PersonalData.total_followers || 0}</p>
                                    <p className='m-0 sub-pro-following'>Followers</p>
                                </div>
                                <div className='sub-pro-count1' onClick={() => { setShowFollowing(true); HandleFollowing() }}>
                                    <p className='m-0 sub-pro-center'>{PersonalData.total_following || 0}</p>
                                    <p className='m-0 sub-pro-following'>Following</p>
                                </div>
                            </div>

                            <div className='datapro-pvo'>
                                <p className={clsx([isChange === 1 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(1)}>Posts</p>
                                <p className={clsx([isChange === 2 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(2); MyProfileVlogList(currPages); setIsLoading(true) }}>Vlogs</p>
                                <p className={clsx([isChange === 3 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(3); MyProfileReelsList(currPagess); setIsLoading(true) }}>Reels</p>
                            </div>

                            <span style={{ color: "#838287" }}>
                                <hr />
                            </span>

                            <div className="d-flex justify-content-center m-25">
                                {
                                    isChange === 1 ?
                                        <Container className='profile-photo'>
                                            <Row>
                                                {
                                                    isLoading ?
                                                        <Circles
                                                            height="40"
                                                            width="40"
                                                            color="#fff"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            visible={true}
                                                            wrapperClass="profile-loader"
                                                        />
                                                        :
                                                        <>
                                                            {
                                                                postView == "" ?
                                                                    <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                    :
                                                                    <>
                                                                        {
                                                                            postView.map((item, index) => {
                                                                                return (
                                                                                    <Col lg={4} md={4} sm={4} key={index}>
                                                                                        {
                                                                                            item.post[0].thumbnail != null ?
                                                                                                <video src={IMG_URL + item.post[0].image} onClick={profilepost} className='img-pic pointer'></video>
                                                                                                :
                                                                                                <img src={IMG_URL + item.post[0].image} onClick={profilepost} className='img-pic pointer' alt="" />
                                                                                        }
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div className='d-flex justify-content-center pointer'>
                                                                            {
                                                                                loader ?
                                                                                    <ThreeDots
                                                                                        visible={true}
                                                                                        height="40"
                                                                                        width="40"
                                                                                        color="#fff"
                                                                                        radius="9"
                                                                                        ariaLabel="three-dots-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass=""
                                                                                    />
                                                                                    :
                                                                                    currPage == totalPage ? null
                                                                                        :
                                                                                        <img src={view} width={150} onClick={handleViewMore} alt="" />
                                                                            }
                                                                        </div>
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </Row>
                                        </Container>
                                        : isChange === 2 ?
                                            <Container className='profile-photo'>
                                                <Row>
                                                    {
                                                        isLoading ?
                                                            <Circles
                                                                height="40"
                                                                width="40"
                                                                color="#fff"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                visible={true}
                                                                wrapperClass="profile-loader"
                                                            />
                                                            :
                                                            <>
                                                                {
                                                                    vlogView == "" ?
                                                                        <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                        :
                                                                        <>
                                                                            {
                                                                                vlogView.map((item) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Col lg={4} md={4} sm={4} onClick={profilevlog}>
                                                                                                {item.post && item.post.length > 0 && item.post[0].thumbnail ? (
                                                                                                    <img src={IMG_URL + item.post[0].thumbnail} className='img-pic pointer' alt="" />
                                                                                                ) : (
                                                                                                    <img src={placeholderimg} className='img-pic1 pointer' alt="" />
                                                                                                )}
                                                                                            </Col>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div className='d-flex justify-content-center pointer'>
                                                                                {
                                                                                    loader ?
                                                                                        <ThreeDots
                                                                                            visible={true}
                                                                                            height="40"
                                                                                            width="40"
                                                                                            color="#fff"
                                                                                            radius="9"
                                                                                            ariaLabel="three-dots-loading"
                                                                                            wrapperStyle={{}}
                                                                                            wrapperClass=""
                                                                                        />
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                currPages == totalPages ? ""
                                                                                                    :
                                                                                                    <img src={view} width={150} onClick={handleViewMores} alt="" />
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </Row>
                                            </Container>
                                            :
                                            <Container className='profile-photo'>
                                                <Row>
                                                    {
                                                        isLoading ?
                                                            <Circles
                                                                height="40"
                                                                width="40"
                                                                color="#fff"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                visible={true}
                                                                wrapperClass="profile-loader"
                                                            />
                                                            :
                                                            <>
                                                                {
                                                                    reelsView == "" ?
                                                                        <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                        :
                                                                        <>
                                                                            {
                                                                                reelsView.map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Col lg={4} md={4} sm={4} key={index} onClick={(e) => handleReels(item.user_id)}>
                                                                                                {item.post && item.post.length > 0 && item.post[0].thumbnail ? (
                                                                                                    <img src={IMG_URL + item.post[0].thumbnail} className='img-pic1 pointer' alt="" />
                                                                                                ) : (
                                                                                                    // Render alternative content if image is not available
                                                                                                    <img src={placeholderimg} className='img-pic1 pointer' alt="" />
                                                                                                )}
                                                                                            </Col>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div className='d-flex justify-content-center pointer'>
                                                                                {
                                                                                    loader ?
                                                                                        <ThreeDots
                                                                                            visible={true}
                                                                                            height="40"
                                                                                            width="40"
                                                                                            color="#fff"
                                                                                            radius="9"
                                                                                            ariaLabel="three-dots-loading"
                                                                                            wrapperStyle={{}}
                                                                                            wrapperClass=""
                                                                                        />
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                currPage == totalPage ? ""
                                                                                                    :
                                                                                                    <img src={view} width={150} onClick={handleViewMoress} alt="" />
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                    }


                                                </Row >
                                            </Container>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* followers list popup */}
            <Modal
                show={showFollow} onHide={() => setShowFollow(false)}
                centered
                contentClassName='popup-box-fixed'
            >
                <div className='hyep-head mt-2'>
                    <span>Followers ({followersList.length})</span>

                    <div onClick={() => setShowFollow(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />
                <div className='srch-bar follow-srch d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.6">
                                <path d="M2.76311 11.4842L3.49329 11.3129L2.76311 11.4842ZM2.76311 6.93667L3.49329 7.10794L2.76311 6.93667ZM15.6578 6.93667L16.3879 6.76539L15.6578 6.93667ZM15.6578 11.4842L16.3879 11.6555L15.6578 11.4842ZM11.4842 15.6578L11.3129 14.9276L11.4842 15.6578ZM6.93667 15.6578L6.76539 16.3879L6.93667 15.6578ZM6.93667 2.76311L6.76539 2.03293V2.03293L6.93667 2.76311ZM11.4842 2.76311L11.6555 2.03293L11.4842 2.76311ZM16.9697 18.0303C17.2626 18.3232 17.7374 18.3232 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L16.9697 18.0303ZM3.49329 11.3129C3.1689 9.93004 3.1689 8.49084 3.49329 7.10794L2.03293 6.76539C1.65569 8.3736 1.65569 10.0473 2.03293 11.6555L3.49329 11.3129ZM14.9276 7.10795C15.252 8.49084 15.252 9.93004 14.9276 11.3129L16.3879 11.6555C16.7652 10.0473 16.7652 8.3736 16.3879 6.76539L14.9276 7.10795ZM11.3129 14.9276C9.93004 15.252 8.49084 15.252 7.10795 14.9276L6.76539 16.3879C8.3736 16.7652 10.0473 16.7652 11.6555 16.3879L11.3129 14.9276ZM7.10794 3.49329C8.49084 3.1689 9.93004 3.1689 11.3129 3.49329L11.6555 2.03293C10.0473 1.65569 8.3736 1.65569 6.76539 2.03293L7.10794 3.49329ZM7.10795 14.9276C5.31441 14.5069 3.914 13.1065 3.49329 11.3129L2.03293 11.6555C2.58373 14.0037 4.41721 15.8371 6.76539 16.3879L7.10795 14.9276ZM11.6555 16.3879C14.0037 15.8371 15.8371 14.0037 16.3879 11.6555L14.9276 11.3129C14.5069 13.1065 13.1065 14.5069 11.3129 14.9276L11.6555 16.3879ZM11.3129 3.49329C13.1065 3.91399 14.5069 5.31441 14.9276 7.10795L16.3879 6.76539C15.8371 4.41721 14.0037 2.58373 11.6555 2.03293L11.3129 3.49329ZM6.76539 2.03293C4.41721 2.58373 2.58373 4.41721 2.03293 6.76539L3.49329 7.10794C3.914 5.3144 5.31441 3.91399 7.10794 3.49329L6.76539 2.03293ZM13.9156 14.9763L16.9697 18.0303L18.0303 16.9697L14.9763 13.9156L13.9156 14.9763Z" fill="white" />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <input type='search' className='srch-follow' placeholder='Search' value={searchFollowers} onChange={(e) => setSearchFollowers(e.target.value)} />
                    </div>
                </div>

                <div>

                    {
                        isloading ?
                            <Circles
                                height="30"
                                width="30"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass="follower-loader"
                            />
                            :
                            <>
                                {
                                    followersList == "" ?
                                        <p className='text-light d-flex justify-content-center'>No User Found</p>
                                        :
                                        <>
                                            {
                                                followersList.map((item) => {
                                                    return (
                                                        <>
                                                            <div className='hype-follow'>
                                                                <div className='d-flex align-items-center hyep-data' onClick={() => handleProfile(item.follow_by)}>
                                                                    {
                                                                        item.profile_pic === null ?
                                                                            <img src={placeholderimg} alt="" className='hype-img-1' />
                                                                            :
                                                                            <img className='hype-img-1' src={IMG_URL + item.profile_pic} />
                                                                    }
                                                                    <div className='d-flex align-items-center ps-3'>
                                                                        <p className='m-0'>{item.first_name}</p>
                                                                        <p className='m-0 ps-2'>{item.last_name}</p>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    item.is_follow_by_me === 2 && item.is_follow_me === 2 ?
                                                                        <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by)}>Remove</button>
                                                                        :
                                                                        item.is_follow_by_me === 0 && item.is_follow_me === 2 ?
                                                                            <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by)}>Follow Back</button>
                                                                            :
                                                                            item.is_follow_by_me === 2 ?
                                                                                <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by)}>Following</button>
                                                                                :
                                                                                <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by)}>Requested</button>
                                                                }

                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>
                    }


                </div>

            </Modal>

            {/* following list popup */}
            <Modal
                show={showFollowing} onHide={() => setShowFollowing(false)}
                centered
                contentClassName='popup-box-fixed'
            >
                <div className='hyep-head mt-2'>
                    <span>Following ({followingList.length})</span>
                    <div onClick={() => setShowFollowing(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />

                <div className='srch-bar follow-srch d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.6">
                                <path d="M2.76311 11.4842L3.49329 11.3129L2.76311 11.4842ZM2.76311 6.93667L3.49329 7.10794L2.76311 6.93667ZM15.6578 6.93667L16.3879 6.76539L15.6578 6.93667ZM15.6578 11.4842L16.3879 11.6555L15.6578 11.4842ZM11.4842 15.6578L11.3129 14.9276L11.4842 15.6578ZM6.93667 15.6578L6.76539 16.3879L6.93667 15.6578ZM6.93667 2.76311L6.76539 2.03293V2.03293L6.93667 2.76311ZM11.4842 2.76311L11.6555 2.03293L11.4842 2.76311ZM16.9697 18.0303C17.2626 18.3232 17.7374 18.3232 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L16.9697 18.0303ZM3.49329 11.3129C3.1689 9.93004 3.1689 8.49084 3.49329 7.10794L2.03293 6.76539C1.65569 8.3736 1.65569 10.0473 2.03293 11.6555L3.49329 11.3129ZM14.9276 7.10795C15.252 8.49084 15.252 9.93004 14.9276 11.3129L16.3879 11.6555C16.7652 10.0473 16.7652 8.3736 16.3879 6.76539L14.9276 7.10795ZM11.3129 14.9276C9.93004 15.252 8.49084 15.252 7.10795 14.9276L6.76539 16.3879C8.3736 16.7652 10.0473 16.7652 11.6555 16.3879L11.3129 14.9276ZM7.10794 3.49329C8.49084 3.1689 9.93004 3.1689 11.3129 3.49329L11.6555 2.03293C10.0473 1.65569 8.3736 1.65569 6.76539 2.03293L7.10794 3.49329ZM7.10795 14.9276C5.31441 14.5069 3.914 13.1065 3.49329 11.3129L2.03293 11.6555C2.58373 14.0037 4.41721 15.8371 6.76539 16.3879L7.10795 14.9276ZM11.6555 16.3879C14.0037 15.8371 15.8371 14.0037 16.3879 11.6555L14.9276 11.3129C14.5069 13.1065 13.1065 14.5069 11.3129 14.9276L11.6555 16.3879ZM11.3129 3.49329C13.1065 3.91399 14.5069 5.31441 14.9276 7.10795L16.3879 6.76539C15.8371 4.41721 14.0037 2.58373 11.6555 2.03293L11.3129 3.49329ZM6.76539 2.03293C4.41721 2.58373 2.58373 4.41721 2.03293 6.76539L3.49329 7.10794C3.914 5.3144 5.31441 3.91399 7.10794 3.49329L6.76539 2.03293ZM13.9156 14.9763L16.9697 18.0303L18.0303 16.9697L14.9763 13.9156L13.9156 14.9763Z" fill="white" />
                            </g>
                        </svg>
                    </div>
                    <input type='search' className='srch-follow' placeholder='Search' value={searchFollowing} onChange={(e) => setSearchFollowing(e.target.value)} />
                </div>

                <div>
                    {
                        isloading ?
                            <Circles
                                height="30"
                                width="30"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass="follower-loader"
                            />
                            :
                            <>
                                {
                                    followingList == "" ?
                                        <p className='text-light d-flex justify-content-center'>No User Found</p>
                                        :
                                        <>
                                            {
                                                followingList.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className='hype-follow' key={index}>
                                                                <div className='d-flex align-items-center hyep-data' onClick={() => handleProfile(item.follow_to)}>
                                                                    {
                                                                        item.profile_pic === null ?
                                                                            <img src={placeholderimg} className='hype-img-1' alt="" />
                                                                            :
                                                                            <img className='hype-img-1' src={IMG_URL + item.profile_pic} />
                                                                    }
                                                                    <div className='d-flex align-items-center ps-3'>
                                                                        <p className='m-0'>{item.first_name}</p>
                                                                        <p className='m-0 ps-2'>{item.last_name}</p>
                                                                    </div>
                                                                </div>

                                                                {/* <button  className='remove-btn' onClick={(e) => {followingRemove(index, item.follow_to); IsFollowUnfollowing(item.follow_to) }}>Following</button> */}
                                                                <button type='button' className='remove-btn' onClick={(e) => { followingFunction(e,index, item.follow_to) }}>Following</button>

                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>
                    }
                </div>

            </Modal>
        </>
    )
}

export default Profile

import React, { useEffect, useState } from "react";
import "./Login.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import Spinner from "react-bootstrap/Spinner";
import { backgroundImage } from "../assets/base64/file";
export default function ResetPassword() {

  let location = useLocation()
  let navigate = useNavigate()

  const [newpass, setNewpass] = useState("")
  const [conformpass, setConformpass] = useState("")
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [icon, setIcon] = useState("bi bi-eye-slash-fill");
  const [icon1, setIcon1] = useState("bi bi-eye-slash-fill");
  const [isloading, setIsloading] = useState(false)

  useEffect(() => {
    let utoken = localStorage.getItem("UserToken")

    if (utoken) {
      navigate("/homescreen")
    }
  }, [])

  let EmailId;
  try {
    const { email_id } = location.state;
    EmailId = email_id;
  } catch (e) {
    //id not found
  }

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "bi bi-eye-slash-fill" ? setIcon("bi bi-eye-fill") : setIcon("bi bi-eye-slash-fill")
  }
  const show1 = () => {
    type1 === "password" ? setType1("text") : setType1("password");
    icon1 === "bi bi-eye-slash-fill" ? setIcon1("bi bi-eye-fill") : setIcon1("bi bi-eye-slash-fill")
  }

  const jsonData = {
    email_id: EmailId,
    new_pass: newpass
  }

  //resetpasseord API
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const encrypt = encryptData(jsonData);
    if (newpass == conformpass) {
      fetch(BASE_URL + "reset_password", {
        method: 'POST',
        body: JSON.stringify({ "resetData": encrypt }),
        headers: { "Content-Type": "application/json" }
      })

        .then(response => response.json())
        .then(response => {
          if (response.Status === 0) {
            console.log(response, "no")
            toast.error(response.Message);
          } else {

            console.log(response, "yes")
            toast.success(response.Message);
            const decrypt = decryptData(encrypt);
            navigate("/login")
          }
          setIsloading(false)
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false)
        })
    }
    else {
      toast.error("Don't match password");
      setTimeout(() => {
        setIsloading(false)
      }, 1000);
    }
  }

  return (
    <>
      <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row>
          <Col lg={6} md={6} sm={12} className="m-part-1">
            <div className="lgn-rgt">
              <img src={logo} className="img-fluid img-1" alt="" />
              <img src={oneg8} className="img-fluid img-1" alt="" />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="m-part-2">
            <div className="lgn-left">
              <h5 className="lgn-head">Reset Password</h5>
              <p className="lgn-acnt">
                Rediscover your account with a new <br /> password.
              </p>
              <form action="" onSubmit={HandleSubmit}>
                <div className="rst-data">

                  <div className="floating-label-input">
                    <input id="password" type={type} className='new-put' value={newpass} minLength={8} onChange={(e) => setNewpass(e.target.value)} autoComplete="off" required placeholder=" " />
                    <label className="floating-label">New Password</label>
                    <span className="pass-eye1" style={{ left: "200px" }}>
                      <i onClick={show} className={icon}></i>
                    </span>
                  </div>

                  <div className="floating-label-input">
                    <input id="password" type={type1} className='new-put' value={conformpass} minLength={8} onChange={(e) => setConformpass(e.target.value)} autoComplete="off" required placeholder=" " />
                    <label className="floating-label">Confirm New Password</label>
                    <span className="pass-eye1" style={{ left: "135px" }}>
                      <i onClick={show1} className={icon1}></i>
                    </span>
                  </div>

                </div>

                <button type="submit" className="bt-1" style={{ marginTop: "30px", marginBottom: "20px" }}>
                  {isloading ? <Spinner className="loderImage" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : "Reset Password"}
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  );
}

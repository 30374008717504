

exports.storeDriveToken=(token)=>{
    sessionStorage.setItem("driveToken",token);
    sessionStorage.removeItem("drivePasswordSkip");
    return true;
}
exports.deleteDriveToken=()=>{
    sessionStorage.removeItem("driveToken");
    // sessionStorage.removeItem("drivePasswordSkip");
    return true;
}

exports.getDriveTokenData=()=>{
    let token=sessionStorage.getItem("driveToken");
    return token;
}



exports.skipGeneratePassword=()=>{
    sessionStorage.setItem("drivePasswordSkip",true);
    return true;
}

exports.deleteSkipGeneratePassword=()=>{
    sessionStorage.removeItem("drivePasswordSkip");
    return true;
}

exports.getSkipGeneratePassword=()=>{
    return !sessionStorage.getItem("drivePasswordSkip") ? false : sessionStorage.getItem("drivePasswordSkip") ;
    
}


import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import { useNavigate } from "react-router-dom";
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import { toast } from 'react-toastify';
import Spinner from "react-bootstrap/Spinner";
import { backgroundImage } from "../assets/base64/file";


export default function ForgotPassword() {

  let navigate = useNavigate();

  const [email, setEmail] = useState("")
  const [isloading, setIsloading] = useState(false)

  useEffect(() => {
    let utoken = localStorage.getItem("UserToken")
    if (utoken) {
      navigate("/homescreen")
    }
  }, [])

  // forgotpassword API
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const jsonData = {
      email_id: email,
    }
    const encrypt = encryptData(jsonData);
    console.log('encrypt', encrypt);

    fetch(BASE_URL + "forgot_password", {
      method: 'POST',
      body: JSON.stringify({ "forgotData": encrypt }),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(response => {
        if (response.Status === 0) {
          console.log(response, "no")
          toast.error(response.Message);
        } else {
          console.log(response, "yes")
          toast.success(response.Message);
          const decrypt = decryptData(encrypt);
          navigate("/verification", { state: { email_id: email, type: 2 } })
        }
        setIsloading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false)
      })
  }

  return (
    <>
      <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row>
          <Col lg={6} md={6} sm={12} className="m-part-1">
            <div className="lgn-rgt">
              <img src={logo} className="img-fluid img-1" alt="" />
              <img src={oneg8} className="img-fluid img-1" alt="" />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="m-part-2">
            <div className="lgn-left">
              <h5 className="lgn-head">Forgot Password </h5>
              <p className="lgn-acnt">
                Please enter your registered email address to <br /> forgot your
                password.
              </p>

              <form action="" onSubmit={HandleSubmit}>
                <div className="floating-label-input mt-5">
                  <input id="email" type="email" className='new-put' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Email</label>
                </div>

                <button type="submit" className="bt-1" style={{ marginTop: "80px" }}>
                  {isloading ? <Spinner className="loderImage" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : "Forgot Password"}
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

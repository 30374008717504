import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import google from "../assets/img/Login/google.png";
import apple from "../assets/img/Login/apple.png";
import { useNavigate } from "react-router-dom";
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { auth, appleprovider, googleprovider } from "../Firebase/Firebase";
import { signInWithPopup } from "firebase/auth";
import { backgroundImage } from "../assets/base64/file";

export default function SignUp() {
  let navigate = useNavigate();
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [icon, setIcon] = useState("bi bi-eye-slash-fill");
  const [icon1, setIcon1] = useState("bi bi-eye-slash-fill");
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [isloading, setIsloading] = useState(false)
  const [conformpass, setConformpass] = useState('')
  const [latitude, setlatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);

  useEffect(() => {

    let utoken = localStorage.getItem("UserToken")

    if (utoken) {
      navigate("/homescreen")
    }

    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setlatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, [])

  const signIn = () => {
    navigate("/login")
  };

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "bi bi-eye-slash-fill" ? setIcon("bi bi-eye-fill") : setIcon("bi bi-eye-slash-fill")
  }
  const show1 = () => {
    type1 === "password" ? setType1("text") : setType1("password");
    icon1 === "bi bi-eye-slash-fill" ? setIcon1("bi bi-eye-fill") : setIcon1("bi bi-eye-slash-fill")
  }


  // Signup
  const HandleSubmit = (e) => {

    e.preventDefault();
    setIsloading(true);

    const jsonData = {
      email_id: email,
      password: pass,
      cnpass: conformpass,
      device_token: "abc",
      device_type: 3,
      device_id: localStorage.getItem('deviceId'),
      latitude: latitude,
      longitude: longitude,
    }

    const encrypt = encryptData(jsonData);
    console.log('encrypt', encrypt);

    let validEmail = false
    let validPass = false

    const newEmail = document.getElementById('email').value
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    if (regex.test(newEmail)) {
      validEmail = true
      if (pass == conformpass) {
        validPass = true
      }
    }

    if (validEmail && validPass) {
      setIsloading(true);
      fetch(BASE_URL + "sign_up", {
        method: 'POST',
        body: JSON.stringify({ "signupData": encrypt }),
        headers: { "Content-Type": "application/json" }
      })

        .then(response => response.json())
        .then(response => {
          if (response.Status === 0) {
            //error data
            console.log(response, "no")
            toast.error(response.Message);
          } else {

            console.log(response, "yes")
            toast.success(response.Message);
            const decrypt = decryptData(encrypt);
            console.log("decrypt", decrypt);
            navigate("/verification", { state: { email_id: email, type: 1, UserToken: response.UserToken } })
          }
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false)
        })

      setIsloading(false)
    }
    else {
      setIsloading(true)
      if (validEmail) {
        toast.error("Don't match Password & Conform Password");
        setTimeout(() => {
          setIsloading(false)
        }, 1000);
      }
      else {
        toast.error("Not valid your Email");
        setTimeout(() => {
          setIsloading(false)
        }, 1000);
      }
    }
  }

  // Thirdparty Signup Google
  const signInWithGoogle = () => {
    signInWithPopup(auth, googleprovider)
      .then((result) => {
        console.log(result)
        const email = result.user.email;
        const ThirdpartyId = result.user.uid;
        const type = "GOOGLE"
        HandleThirdpartyData(ThirdpartyId, email, type)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Thirdparty Signup Apple
  function signinapple(e) {
    e.preventDefault();

    signInWithPopup(auth, appleprovider)

      .then((data) => {
        console.log("data", data);
        var user = data.user;
        var username = user.displayName;
        var email = user.email;
        var thirdpartyid = user.uid;
        var type = "APPLE"
        HandleThirdpartyData(thirdpartyid, email, type)
      })
      .catch((err) => console.log("err", err));
  }

  const HandleThirdpartyData = (ThirdpartyId, ThirdpartyEmail, type) => {
    console.log(ThirdpartyId, ThirdpartyEmail, type)
    const ThirdpartyData = {
      email_id: ThirdpartyEmail,
      thirdparty_id: ThirdpartyId,
      login_type: type,
      device_token: "abc",
      device_type: 3,
      device_id: localStorage.getItem('deviceId'),
      latitude: latitude,
      longitude: longitude,
    }

    const encrypt = encryptData(ThirdpartyData);
    
    fetch(BASE_URL + "login_by_thirdparty", {
      method: 'POST',
      body: JSON.stringify({ "thirdpartyData": encrypt }),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if (response.Status === 0) {
          console.log(response, "Status0");
          toast.error(response.Message);
        }
        else if (response.Status === 1) {
          console.log(response, "Status1");
          toast.success(response.Message);
          const decrypt = decryptData(response.info);
          if (decrypt.is_account_setup === "YES") {
            localStorage.setItem("UserToken", response.UserToken)
            navigate("/homescreen");
          }
          else {
            navigate("/accountsetup", { state: { token: response.UserToken } });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row>
          <Col lg={6} md={6} sm={12} className="m-part-1">
            <div className="lgn-rgt">
              <img src={logo} className="img-fluid img-1" alt="" />
              <img src={oneg8} className="img-fluid img-1" alt="" />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="m-part-2">

            <div className="lgn-left">
              <h5 className="lgn-head">Create Account</h5>
              <p className="lgn-acnt">Let’s get start!</p>

              <form action="" onSubmit={HandleSubmit} >


                <div className="floating-label-input">
                  <input id="email" type="email" className='new-put' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Email</label>
                </div>

                <div className="floating-label-input">
                  <input id="password" type={type} className='new-put' minLength={8} value={pass} onChange={(e) => setPass(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Password</label>
                  <span className="pass-eye1">
                    <i onClick={show} className={icon}></i>
                  </span>
                </div>

                <div className="floating-label-input">
                  <input id="password" type={type1} className='new-put' minLength={8} value={conformpass} onChange={(e) => setConformpass(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Confirm Password</label>
                  <span className="pass-eye1" style={{ left: "170px" }}>
                    <i onClick={show1} className={icon1} ></i>
                  </span>
                </div>

                <div className="floating-label-input">
                  <input id="referral" type="text" className='new-put' autoComplete="off" placeholder=" " />
                  <label className="floating-label">Referral Code (Optional)</label>
                </div>

                <button type="submit" className="bt-1 mt-2">
                  {isloading ? <Spinner className="loderImage" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> : "Sign Up"}
                </button>
              </form>


              <p className="lgn-mid pt-4">Or</p>
              <h6 className="lgn-mid-1">Continue With</h6>
              <div className="lgn-footer">
                <img src={google} onClick={signInWithGoogle} className="img-fluid img-foot1 mt-2" />
                <img src={apple} onClick={signinapple} className="img-fluid img-foot1 mt-2" />
              </div>
              <p className="lgn-footer-sp pt-3">
                You don't have an account?{" "}
                <b style={{ color: "white", fontWeight: "500", cursor: "pointer", }} onClick={signIn}>Sign In</b>
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}

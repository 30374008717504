import React, { useState } from 'react'
import "./RequestSidebar.css"
import human1 from "../assets/img/Sidebar/s-1.png"
import human2 from "../assets/img/Sidebar/s-2.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import back from "../assets/img/Sidebar/back-arrow.png"


function RequestSidebar() {

    const [show, setShow] = useState(false)


    const handmem = () => {

        if (show) {
            setShow(false)
            document.getElementById('Requests').style.display = 'block'
            document.getElementById('Request-bar').style.right = '400px'

        } else {

            setShow(true)
            document.getElementById('Requests').style.display = 'none'
            document.getElementById('Request-bar').style.right = '0'
        }
    }

    return (
        <>
            <p className="m-0" id='SidebarRequest' onClick={handmem}></p>

            <div className='Requestside'>
                <div className='request-sidebar' id='Request-bar'>
                    <div className='d-flex align-items-center text-light p-4'>
                        <img src={back} width={30} alt="" onClick={handmem} />
                        <p className='srch-name m-0 ps-4'>Requests</p>
                    </div>
                    <div className='requstside-menu'>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human1} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>
                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human2} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human3} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human4} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human5} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human1} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human2} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                        <div className='notify-part-1'>
                            <div className='noti-fel'>
                                <img src={human3} />
                                <p>Alisa Williams wants to join your “Food Mania" chatroom. <span className="nitify-min"> 2 min</span></p>
                            </div>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center request-star'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M14.6129 3.71487L11.0798 3.2015L9.4998 0L7.91979 3.2015L4.38672 3.71487L6.94329 6.20688L6.33975 9.72566L9.4998 8.06431L12.6598 9.72566L12.0563 6.20688L14.6129 3.71487Z" fill="url(#paint0_linear_212_14675)" />
                                        <path d="M9.49991 11.6977L3.78711 9.40576V12.6612L9.53324 14.9666L15.2127 12.6595V9.40576L9.49991 11.6977Z" fill="url(#paint1_linear_212_14675)" />
                                        <path d="M9.49991 15.7314L3.78711 13.4395V16.6949L9.53324 19.0002L15.2127 16.6932V13.4395L9.49991 15.7314Z" fill="url(#paint2_linear_212_14675)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_212_14675" x1="4.38672" y1="0" x2="14.1001" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_212_14675" x1="3.78711" y1="9.40576" x2="8.16334" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_212_14675" x1="3.78711" y1="13.4395" x2="8.16329" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.140625" stopColor="#4360BC" />
                                                <stop offset="0.8125" stopColor="#B900C6" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className='m-0 ps-2'>#200</p>
                                </div>
                                <div className='nitify-btn d-flex align-items-center pe-2 ps-2'>
                                    <button className='nitify-reject-btn'>Reject</button>
                                    <button className='nitify-accept-btn'>accept</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

            <div className="rqst-side" onClick={handmem} id='Requests'></div>
        </>
    )
}

export default RequestSidebar

import React, { useEffect, useState } from 'react'
import "../Search/SearchSidebar.css"
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"
import arrow from "../assets/img/Sidebar/Arrow - Top.png"
import backphoto from "../assets/img/Sidebar/sweets.png"
import food from "../assets/img/Sidebar/food disk.png"
import { useNavigate } from 'react-router-dom'
import { clsx } from 'clsx';
import Modal from 'react-bootstrap/Modal';
// import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl'
import { encryptData, decryptData, BASE_URL, IMG_URL, generateChatKey, encryptDataConvertForChat, MainKey, MainIv } from '../API/ApiUrl'
import Spinner from "react-bootstrap/Spinner";
import banner from "../assets/img/Sidebar/coverpic.png"
import danger from "../assets/img/Sidebar/danger.png"
import sendline from "../assets/img/Sidebar/send(1).png"

function SearchSidebar() {

    let navigate = useNavigate()

    const [isflag, setisflag] = useState(1);

    const [joinGroup, setJoinGroup] = useState(false);
    const [newshow, setNewShow] = useState(false);
    const [isloading, setIsloading] = useState(false)
    const [reportShow, setReportShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);

    const [currentPage, setCurrentPage] = useState(1)
    const [currPage, setCurrPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalsPage, setTotalsPage] = useState()

    const [searchUserData, setSearchUserData] = useState("")
    const [searchGroupData, setSearchGroupData] = useState("")
    const [report, setReport] = useState("")

    const [chatroomData, setChatroomData] = useState({})

    const [userData, setUserData] = useState([])
    const [groupData, setGroupData] = useState([])

    useEffect(() => {
        if (searchUserData.length >= 3) {
            handleSearchUser(currentPage)
        }
    }, [searchUserData])

    useEffect(() => {
        if (searchGroupData.length >= 3) {
            handleSearchGroup(currentPage)
        }
    }, [searchGroupData])

    useEffect(() => {
        console.log("isflag >>", isflag);
    }, [isflag])

    const dataprofile = (UserId) => {
        navigate("/userprofile/" + UserId)
        setSearchUserData("")
        userscreen()
    }

    const userscreen = () => {
        document.getElementById('over').style.display = 'none'
        document.getElementById('searchSidebar').style.left = '-400px'
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));


    const handleSearchUser = (page) => {
        setIsloading(true)
        let jsonData = {
            page_no: page,
            search_text: searchUserData,
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "search_homescreen", {
            method: 'POST',
            body: JSON.stringify({ "searchData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                } else {
                    setCurrentPage(page)
                    const decrypt = decryptData(response.key);
                    console.log("decrypt >>", decrypt);
                    setUserData(decrypt.info)
                    setTotalPage(decrypt.total_page)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })

    }

    const handleSearchGroup = (page) => {
        setIsloading(true)

        let jsonData = {
            page_no: page,
            search_text: searchGroupData,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "search_chatroom", {
            method: 'POST',
            body: JSON.stringify({ "searchData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                } else {
                    setCurrPage(page)
                    const decrypt = decryptData(response.key);
                    console.log("decrypt >>", decrypt);
                    setGroupData(decrypt.info)
                    setTotalsPage(decrypt.total_page)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })

    }

    const searchSidebar = () => {

        if (newshow) {
            setNewShow(false)
            document.getElementById('over').style.display = 'none'
            document.getElementById('searchSidebar').style.left = '-400px'

        } else {
            setNewShow(true)
            document.getElementById('over').style.display = 'block'
            document.getElementById('searchSidebar').style.marginLeft = '300px'
            document.getElementById('searchSidebar').style.left = '0'

        }

    }

    const handleReportData = (e) => {
        setReport(e.target.value)
    }

    //handle report API
    const handleReports = () => {
        setIsloading(true)
        let jsonData = {
            other_id: chatroomData.chatroom_id,
            report_type: report
        }
        console.log("chatroom report >>", jsonData);
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "add_report", {
            method: 'POST',
            body: JSON.stringify({ "reportData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response.Message);
                } else {
                    console.log(response.Message);
                    setReportShow(false)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })

    }

    const handleChatroomProfile = (id) => {
        let jsonData = {
            chatroom_id: id
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "edit_chatroom", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("chat profile >>", decrypt);
                    setChatroomData(decrypt.info)
                    setJoinGroup(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleJoinRoom = (createId, roomId, privet, roomName, isoutput, type) => {

        if (type === 1) {
            const isoutput1 = isoutput === 0 ? 1 : 0;
            const updatedChatroomData = { ...chatroomData, is_join: isoutput1 };
            setChatroomData(updatedChatroomData);
        } else {
            const isoutput1 = isoutput === 0 ? 1 : 0;
            const updatedChatroomData = { ...chatroomData, is_join: isoutput1 };
            setChatroomData(updatedChatroomData);
        }

        let jsonData = {
            user_id: createId,
            chatroom_id: roomId,
            is_private: privet,
            chatroom_name: roomName
        }
        console.log("jsonData >>", jsonData);
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "join_member", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log("join room >>", response.Message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    // const handleCreateChat = () => {

    //     const data = generateChatKey();

    //     const secretKey = encryptDataConvertForChat(data.key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur");
    //     const secretIv = encryptDataConvertForChat(data.iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")

    //     let jsonData = {
    //         user_id: id,
    //         chat_sec: secretKey,
    //         chat_iv: secretIv,
    //     }

    //     const encrypt = encryptData(jsonData);
    //     fetch(BASE_URL + "create_chat", {
    //         method: 'POST',
    //         body: JSON.stringify({ "chatData": encrypt }),
    //         headers: myHeaders,
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             if (response.Status === 0) {
    //                 console.log(response, "no")
    //             } else {
    //                 let decrypt = decryptData(response.info)
    //                 console.log("decrypt >>", decrypt);
    //                 navigate('/message', { state: { DATA: decrypt, Id: decrypt.chat_id } })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }
    const visitGroup = (groupId) => {
        navigate('/message')
        return false;

        const data = generateChatKey();

        const secretKey = encryptDataConvertForChat(data.key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur");
        const secretIv = encryptDataConvertForChat(data.iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")

        let jsonData = {
            user_id: groupId,
            chat_sec: secretKey,
            chat_iv: secretIv,
        }

        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "create_chat", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    let decrypt = decryptData(response.info)
                    console.log("decrypt >>", decrypt);
                    navigate('/message', { state: { DATA: decrypt, Id: decrypt.chat_id } })
                }
            })
            .catch((error) => {
                console.log(error);
            })
        // console.log(groupId);

        // navigate('/message', { state: {grId: groupId,flag:2 } })

    }

    return (
        <>
            <p className="m-0" id='functionSearchSidebar' onClick={searchSidebar}></p>

            <div className='SearchSidebar' id='searchSidebar' >

                <div className='srch-head pt-4'>
                    <p className='srch-name'>Search</p>
                </div>
                <div className='srch-bar d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <i className="bi bi-search"></i>
                    </div>
                    <div>
                        <input
                            type='search'
                            className='srch-inp'
                            placeholder='Search'
                            value={isflag == 1 ? searchUserData : searchGroupData}
                            onChange={(e) => {
                                if (isflag == 1) {
                                    setSearchUserData(e.target.value)
                                } else {
                                    setSearchGroupData(e.target.value)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='srch-group'>
                    <p className={clsx([isflag === 1 ? "border_bottom" : ""])} onClick={() => setisflag(1)}>People</p>
                    <p className={clsx([isflag === 2 ? "border_bottom" : ""])} onClick={() => { setisflag(2); handleSearchGroup(currPage) }}>Chatrooms</p>
                </div>

                {isflag === 1 ?
                    <>
                        {
                            searchUserData == "" ?
                                <p className='d-flex justify-content-center mt-3'>No recent searches.</p>
                                :
                                <>
                                    {
                                        userData == "" ?
                                            <p className='d-flex justify-content-center mt-3'>
                                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                                    < span className="visually-hidden">Loading...</span>
                                                </Spinner> : " No User Found"}
                                            </p>
                                            :
                                            <>
                                                {
                                                    userData.map((item, index) => {
                                                        return (
                                                            <div className="SidebarItems"
                                                                key={index}
                                                                onClick={(e) => {
                                                                    dataprofile(item.user_id)
                                                                }}
                                                            >
                                                                <div className='srch-center'>
                                                                    <div className='srch-icon'>
                                                                        {
                                                                            item.profile_pic === null ?
                                                                                <img src={placeholderimg} alt="" className='srch-img' />
                                                                                :
                                                                                <img src={IMG_URL + item.profile_pic} className='srch-img rounded' />
                                                                        }
                                                                        <div className='srch-line'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <p>{item.first_name}</p>
                                                                                <p className='ms-2'>{item.last_name}</p>
                                                                            </div>
                                                                            <p className='srch-post'>{item.total_post} Post</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='srch-up'>
                                                                        <img src={arrow} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </>
                                    }
                                </>
                        }

                    </>
                    :
                    <>
                        {
                            searchGroupData == "" ?
                                <p className='d-flex justify-content-center mt-3'>No recent searches.</p>
                                :
                                <>
                                    {
                                        groupData == "" ?
                                            <p className='d-flex justify-content-center mt-3'>
                                                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                                    < span className="visually-hidden">Loading...</span>
                                                </Spinner> : " No Chatroom Found"}
                                            </p>
                                            :
                                            <>
                                                {
                                                    groupData.map((item, index) => {
                                                        return (
                                                            <div className="SidebarItems" key={index}>
                                                                <div
                                                                    className='srch-center'
                                                                    onClick={() => {
                                                                        handleChatroomProfile(item.chatroom_id);
                                                                    }}
                                                                >
                                                                    <div className='srch-icon'>
                                                                        <img src={item.chatroom_image ? IMG_URL + item.chatroom_image : placeholderimg} className='srch-img rounded' />
                                                                        <div className='srch-line'>
                                                                            <p className='chatroom-heading'>{item.chatroom_name || ""}</p>
                                                                            <p className='srch-post'>{item.total_member || 0} Member</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='srch-up'>
                                                                        <img src={arrow} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </>
                        }
                    </>
                }
            </div >

            <Modal
                show={joinGroup}
                onHide={() => setJoinGroup(false)}
                centered
            >
                <img src={chatroomData.chatroom_cover_image ? IMG_URL + chatroomData.chatroom_cover_image : banner} className='chatroom-coverpic object-fit-cover' />

                <div className='text-center'>
                    <img src={chatroomData.chatroom_image ? IMG_URL + chatroomData.chatroom_image : placeholderimg} className='chatroom-dp' />
                    <div className='chat-menu' onClick={() => setMenuShow(true)}>
                        <i className="bi bi-three-dots"></i>
                    </div>
                </div>

                <div className='chatroom-head'>
                    <p className='fs-5 m-0'>{chatroomData.chatroom_name || ""}</p>
                    <p className='chat-member m-0'>{chatroomData.total_member || 0} Member</p>
                </div>

                <p className='chat-text text-break'>
                    {chatroomData.chatroom_description || ""}
                </p>

                {
                    chatroomData.is_private == "PUBLIC" ?
                        <>
                            {
                                chatroomData.is_join == 0 ?
                                    <button className={clsx(["chat-btn"])} onClick={() => handleJoinRoom(chatroomData.chatroom_created_by, chatroomData.chatroom_id, chatroomData.is_private, chatroomData.chatroom_name, chatroomData.is_join, 1)}>Join</button>
                                    :
                                    <button className={clsx(["chat-btn"])} onClick={() => visitGroup(chatroomData.chatroom_id)}>Open Messanger</button>

                            }
                        </>
                        :
                        <>
                            {
                                chatroomData.is_join == 0 ?
                                    <button className={clsx(["chat-btn"])} onClick={() => handleJoinRoom(chatroomData.chatroom_created_by, chatroomData.chatroom_id, chatroomData.is_private, chatroomData.chatroom_name, chatroomData.is_join, 2)}>Request to Join</button>
                                    :
                                    chatroomData.is_join == 1 ?
                                        <button className={clsx(["chat-btns"])}>Requested</button>
                                        : 
                                        <button className={clsx(["chat-btn"])} onClick={() => visitGroup(chatroomData.chatroom_id)}>Open Messanger</button>

                                        

                            }
                        </>
                }

            </Modal>

            < Modal show={menuShow} onHide={() => setMenuShow(false)} centered >
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setReportShow(true); setMenuShow(false) }}>
                        <p>Report</p>
                        <img src={danger} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger'>
                        <p>Copy Link</p>
                        <img src={sendline} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setMenuShow(false)}>Cancel</p>
                </div>
            </Modal >

            {/* report popup */}
            < Modal show={reportShow} onHide={() => setReportShow(false)} centered >
                <div className='report-fram'>
                    <p>Report</p>
                </div>
                <hr />
                <div className='report-center'>
                    <label className='report-data'>
                        <p className='report-name m-0'>It’s Spam</p>
                        <input name='report' type='radio' onChange={handleReportData} value="It’s Spam" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Inappropriate Content</p>
                        <input name='report' type='radio' onChange={handleReportData} value="Inappropriate Content" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Scam & Fraud</p>
                        <input name='report' type='radio' onChange={handleReportData} value="Scam & Fraud" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Bullying</p>
                        <input name='report' type='radio' onChange={handleReportData} value="Bullying" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Harassment</p>
                        <input name='report' type='radio' onChange={handleReportData} value="Harassment" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Violence</p>
                        <input name='report' type='radio' onChange={handleReportData} value="Violence" className='purchs-radio popup-redio' />
                    </label>

                    <div className='text-center'>
                        <button className='report-btn' onClick={handleReports}>
                            {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                < span className="visually-hidden">Loading...</span>
                            </Spinner> : "Submit"}
                        </button>
                    </div>
                </div>
            </Modal >

        </>
    )
}

export default SearchSidebar

import React, { useEffect, useState } from 'react'
import "./SubProfile.css"
import Sidebar from '../Sidebar/Sidebar'
import bar from "../assets/img/Sidebar/menu (1).png"
import { Col, Container, Row } from 'react-bootstrap'
import danger from "../assets/img/Sidebar/danger.png"
import sendline from "../assets/img/Sidebar/send(1).png"
import prodelete from "../assets/img/Sidebar/profile-delete.png"
import Modal from 'react-bootstrap/Modal'
import { clsx } from 'clsx'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { encryptData, decryptData, BASE_URL, IMG_URL, generateChatKey, encryptDataConvertForChat, MainKey, MainIv } from '../API/ApiUrl'
import banner from "../assets/img/Sidebar/coverpic.png"
import go from "../assets/img/Sidebar/ONEG8-Psd-white.png"
import placeholderimg from "../assets/img/Sidebar/placeholder1.png"
import blockimg1 from "../assets/img/Sidebar/block1.png"
import blockimg2 from "../assets/img/Sidebar/block2.png"
import Spinner from "react-bootstrap/Spinner";
import { Circles, ThreeDots } from 'react-loader-spinner'
import view from "../assets/img/Sidebar/view more.png"

function UserProfile() {
    let navigate = useNavigate()
    let location = useLocation()

    const [modalShow, setModalShow] = useState(false);
    const [reportshow, setReportshow] = useState(false);
    const [showFollow, setShowFollow] = useState(false)
    const [showFollowing, setShowFollowing] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loader, setLoader] = useState(false)

    const [isChange, setIsChange] = useState(1)
    const [currPage, setCurrPage] = useState(1)
    const [currPages, setCurrPages] = useState(1)
    const [currPagess, setCurrPagess] = useState(1)
    const [followerPage, setFollowerPage] = useState(1)
    const [followingPage, setFollowingPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalPages, setTotalPages] = useState()
    const [totalPagess, setTotalPagess] = useState()
    const [totalFollower, setTotalFollower] = useState()
    const [totalFollowing, setTotalFollowing] = useState()

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [images, setImages] = useState("")
    const [coverpic, setCoverpic] = useState("")
    const [isfollowers, setIsfollowers] = useState("")
    const [isfollowing, setIsfollowing] = useState("")
    const [TotalPost, setTotalPost] = useState("")
    const [searchData, setSearchdata] = useState("")
    const [report, setReport] = useState("")
    const [searchFollowers, setSearchFollowers] = useState("")
    const [searchFollowing, setSearchFollowing] = useState("")

    const [AccountData, setAccountData] = useState([]);
    const [PostData, setPostdata] = useState([])
    const [vlogData, setVlogdata] = useState([])
    const [followersList, setFollowersList] = useState([])
    const [followingList, setFollowingList] = useState([])
    const [reelsView, setReelsview] = useState([])

    const { id } = useParams();

    useEffect(() => {
        console.log("useEffect col >>>");
        setFollowerPage(1)
        setFollowingPage(1)
        HandleData(id)
        HandlePost(id, currPage)
        setIsLoading(true)
    }, [id])

    useEffect(() => {
        handleFollowers(followerPage)
    }, [searchFollowers])

    useEffect(() => {
        handleFollowing(followingPage)
    }, [searchFollowing])

    const handleReels = (id) => {
        navigate("/userreels/" + id)
    }

    let userId = localStorage.getItem("UserId")

    const handleProfile = (id) => {
        if (id == userId) {
            navigate("/profile")
        }
        else {
            navigate("/userprofile/" + id)
            setShowFollowing(false)
            setShowFollow(false)
        }
    }

    const userPost = (type) => {
        navigate("/userpost/" + id, { state: { Type: type } })
    }

    const handleReport = (e) => {
        setReport(e.target.value)
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));

    // Bio data API
    const HandleData = (e) => {

        setLoader(true)
        let jsonData = {
            other_id: e,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "get_user_profile", {
            method: 'POST',
            body: JSON.stringify({ "profileData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                setLoader(false)

                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response);
                    const decrypt = decryptData(response.info);
                    setFirstname(decrypt.first_name)
                    setLastname(decrypt.last_name)
                    setImages(decrypt.profile_pic ? IMG_URL + decrypt.profile_pic : "")
                    setCoverpic(decrypt.cover_pic ? IMG_URL + decrypt.cover_pic : "")
                    setIsfollowers(decrypt.total_followers)
                    setIsfollowing(decrypt.total_following)
                    setTotalPost(decrypt.total_post)
                    setAccountData(decrypt)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const handleViewMore = () => {
        if (totalPage != currPage) {
            HandlePost(id, currPage + 1);
        }
    }

    // user post API
    const HandlePost = (e, page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: "POST",
            other_id: e,
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPage(page)
                    setTotalPage(decrypt.total_page)
                    if (page != 1) {
                        setPostdata([...PostData, ...decrypt.info])
                    } else {
                        setPostdata(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })

    }

    const handleViewMores = () => {
        if (totalPages != currPages) {
            handleVlog(id, currPages + 1);
        }
    }

    // user vlog API
    const handleVlog = (e, page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: "VLOG",
            other_id: e,
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPages(page)
                    setTotalPages(decrypt.total_page)
                    if (page != 1) {
                        setVlogdata([...vlogData, ...decrypt.info])
                    } else {
                        setVlogdata(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })

    }

    const handleViewMoress = () => {
        if (totalPagess != currPagess) {
            MyProfileReelsList(id, currPagess + 1);
        }
    }

    // user reels API
    const MyProfileReelsList = (e, page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            post_type: 'REEL',
            other_id: e
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "list_my_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setCurrPagess(page)
                    setTotalPagess(decrypt.total_page)
                    if (page != 1) {
                        setReelsview([...reelsView, ...decrypt.info])
                    } else {
                        setReelsview(decrypt.info)
                    }
                }
                setIsLoading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setLoader(false)
            })
    }

    // follow/unfollow API
    const IsFollowUnfollow = (e, uid, type) => {
        e.preventDefault()
        let jsonData = {
            user_id: uid
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "follow_unfollow_user", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response, "yes")
                    if (type == 1) {
                        HandleData(id)
                    } else if (type == 2) {
                        handleFollowers()
                    } else if (type == 3) {
                        handleFollowing()
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleFollowersView = () => {
        if (totalFollower != followerPage) {
            handleFollowers(followerPage + 1)
        }
    }

    // followers list API
    const handleFollowers = (page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            user_id: id,
            other_id: id,
            search_text: searchFollowers
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "list_followers", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setFollowerPage(page)
                    setTotalFollower(decrypt.total_page)
                    if (page != 1) {
                        setFollowersList([...followersList, ...decrypt.info])
                    } else {
                        setFollowersList(decrypt.info)
                    }
                }
                setIsloading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
                setLoader(false)
            })
    }

    const handleFollowingView = () => {
        if (totalFollowing != followingPage) {
            handleFollowing(followingPage + 1)
        }
    }

    // following list API
    const handleFollowing = (page) => {
        setLoader(true)
        let jsonData = {
            page_no: page,
            user_id: id,
            other_id: id,
            search_text: searchFollowing
        }
        const encrypt = encryptData(jsonData);
        console.log("following json >>", jsonData);
        fetch(BASE_URL + "list_following", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsloading(false)
                    setLoader(false)
                } else {
                    let key = response.key
                    const decrypt = decryptData(key);
                    setFollowingPage(page)
                    setTotalFollowing(decrypt.total_page)
                    if (page != 1) {
                        setFollowingList([...followingList, ...decrypt.info])
                    } else {
                        setFollowingList(decrypt.info)
                    }
                }
                setIsloading(false)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
                setLoader(false)
            })
    }

    //handle report API
    const HandleReports = () => {
        setIsloading(true)
        let jsonData = {
            other_id: AccountData.user_id,
            report_type: report
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "add_report", {
            method: 'POST',
            body: JSON.stringify({ "reportData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response.Message);
                } else {
                    console.log(response.Message);
                    setReportshow(false)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })

    }

    //handle block API
    const handleBlock = () => {

        let jsonData = {
            user_id: id,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "block_unblock_user", {
            method: 'POST',
            body: JSON.stringify({ "blockData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response.Message)
                } else {
                    console.log(response);
                    setModalShow(false)
                    HandleData(id)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    // send message API
    const handleCreateChat = () => {

        const data = generateChatKey();

        const secretKey = encryptDataConvertForChat(data.key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur");
        const secretIv = encryptDataConvertForChat(data.iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")

        let jsonData = {
            user_id: id,
            chat_sec: secretKey,
            chat_iv: secretIv,
        }

        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "create_chat", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    let decrypt = decryptData(response.info)
                    console.log("decrypt >>", decrypt);
                    navigate('/message', { state: { DATA: decrypt, Id: decrypt.chat_id } })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} className='sidebar-menu' alt="" />
            <Sidebar />

            <div className='sub-profile' style={{ height: "90vh", overflowY: "scroll" }}>
                <Container className='subprofile-1'>
                    <Row>
                        <Col>
                            <div className='subprofile-2'>
                                {
                                    AccountData.is_block != 0 ?
                                        <img src={blockimg2} alt="" className='pro-banner' />
                                        :
                                        <>
                                            {
                                                coverpic ?
                                                    <img src={coverpic} alt="" className='pro-banner object-fit-cover' />
                                                    :
                                                    <img src={banner} className='pro-banner' />
                                            }
                                        </>
                                }

                            </div>
                            <div className='sub-pro-head'>
                                <div className='sub-pro-head1 mt-4'>
                                    <div>
                                        {
                                            AccountData.is_block != 0 ?
                                                <img src={blockimg1} alt="" className='sub-pro-img' />
                                                :
                                                <>
                                                    {
                                                        images ?
                                                            <img src={images} className='sub-pro-img object-fit-cover' />
                                                            :
                                                            <img src={placeholderimg} className='sub-pro-img' alt="" />
                                                    }
                                                </>
                                        }

                                    </div>
                                    <div className='sub-pro-text'>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <p className='m-0 pb-2 pt-4'>{firstname}</p>
                                                <p className='m-0 pb-2 pt-4 ms-2'>{lastname}</p>
                                            </div>
                                            {/* <img src={verifyorange} width={28} className='ps-2' style={{ paddingTop: "10px" }} alt="" /> */}
                                        </div>
                                        {/* <div className='d-flex align-items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 19 19" fill="none">
                                                <path d="M14.6131 3.71487L11.08 3.2015L9.49998 0L7.91997 3.2015L4.3869 3.71487L6.94348 6.20688L6.33993 9.72566L9.49998 8.06431L12.66 9.72566L12.0565 6.20688L14.6131 3.71487Z" fill="url(#paint0_linear_204_5774)" />
                                                <path d="M9.49997 11.6977L3.78717 9.40576V12.6612L9.5333 14.9666L15.2128 12.6595V9.40576L9.49997 11.6977Z" fill="url(#paint1_linear_204_5774)" />
                                                <path d="M9.49997 15.7314L3.78717 13.4395V16.6949L9.5333 19.0002L15.2128 16.6932V13.4395L9.49997 15.7314Z" fill="url(#paint2_linear_204_5774)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_204_5774" x1="4.3869" y1="0" x2="14.1003" y2="10.2133" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_204_5774" x1="3.78717" y1="9.40576" x2="8.1634" y2="18.3975" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_204_5774" x1="3.78717" y1="13.4395" x2="8.16336" y2="22.4311" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.140625" stopColor="#4360BC" />
                                                        <stop offset="0.8125" stopColor="#B900C6" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <p className='ps-2 m-0'>#200</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='sub-pro-menu mt-5' onClick={() => setModalShow(true)}>
                                    <i className="bi bi-three-dots"></i>
                                </div>
                            </div>

                            <div className='sub-pro-count pt-3 mb-2'>
                                <div className='sub-pro-count1'>
                                    <p className='sub-pro-center m-0'>{TotalPost || 0}</p>
                                    <p className='sub-pro-following m-0'>Posts</p>
                                </div>
                                {
                                    AccountData.is_follow_by_me == 2 || AccountData.account_type === "PUBLIC" ?
                                        <div className='sub-pro-count1' onClick={() => {
                                            setShowFollow(true); handleFollowers(followerPage); setIsloading(true)
                                        }}>
                                            <p className='sub-pro-center m-0'>{isfollowers || 0}</p>
                                            <p className='sub-pro-following m-0'>Followers</p>
                                        </div>
                                        :
                                        <div className='sub-pro-count1'>
                                            <p className='sub-pro-center m-0'>{isfollowers || 0}</p>
                                            <p className='sub-pro-following m-0'>Followers</p>
                                        </div>
                                }
                                {
                                    AccountData.is_follow_by_me == 2 || AccountData.account_type === "PUBLIC" ?
                                        <div className='sub-pro-count1' onClick={() => {
                                            setShowFollowing(true); handleFollowing(followingPage); setIsloading(true)
                                        }}>
                                            <p className='sub-pro-center m-0'>{isfollowing || 0}</p>
                                            <p className='sub-pro-following m-0'>Following</p>
                                        </div>
                                        :
                                        <div className='sub-pro-count1'>
                                            <p className='sub-pro-center m-0'>{isfollowing || 0}</p>
                                            <p className='sub-pro-following m-0'>Following</p>
                                        </div>
                                }

                            </div>


                            {
                                loader == true ? (<div className='text-center'>
                                <Circles
                                    height="40"
                                    width="40"
                                    color="#fff"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    visible={true}
                                    wrapperClass="profile-loader"
                                /></div>) : (
                                    <div className='sub-pro-follow'>
                                    {
                                        AccountData.is_block === 0 ?
                                            <>
                                                {
                                                    AccountData.account_type === "PUBLIC" ?
                                                        <>
                                                            {
                                                                AccountData.is_follow_by_me === 0 && AccountData.is_follow_me === 0 ?
                                                                    <button className='pro-btn-follow' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Follow</button>
                                                                    :
                                                                    AccountData.is_follow_by_me === 2 ?
                                                                        <button className='pro-btn-message' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Following</button>
                                                                        :
                                                                        <button className='pro-btn-follow' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Follow Back</button>
                                                            }
                                                            <button className='pro-btn-message' onClick={handleCreateChat}>Message</button>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                AccountData.is_follow_by_me === 0 && AccountData.is_follow_me === 2 ?
                                                                    <button className='pro-btn-follow' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Follow Back</button>
                                                                    :
                                                                    AccountData.is_follow_by_me === 0 ?
                                                                        <button className='pro-btn-follow' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Follow</button>
                                                                        :
                                                                        AccountData.is_follow_by_me === 2 ?
                                                                            <>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <button className='pro-btn-message' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Following</button>
                                                                                    <button className='pro-btn-message' onClick={handleCreateChat}>Message</button>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <button className='pro-btn-message' onClick={(e) => IsFollowUnfollow(e, id, 1)}>Requested</button>
                                                            }
                                                        </>
                                                }
                                            </>
                                            :
                                            <button className='pro-btn-follow' onClick={handleBlock}>Unblock</button>
                                    }

                                </div>
                                )
                            }


                            <div className='datapro-pvo'>
                                <p className={clsx([isChange === 1 ? "datapro-pvo1" : ""])} onClick={() => setIsChange(1)}>Posts</p>
                                <p className={clsx([isChange === 2 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(2); handleVlog(id, currPages); setIsLoading(true) }}>Vlogs</p>
                                <p className={clsx([isChange === 3 ? "datapro-pvo1" : ""])} onClick={() => { setIsChange(3); MyProfileReelsList(id, currPagess); setIsLoading(true) }}>Reels</p>
                            </div>

                            <span style={{ color: "#838287" }}>
                                <hr />
                            </span>

                            {
                                AccountData.is_block === 0 ?
                                    <>
                                        {
                                            AccountData.account_type === "PUBLIC" || AccountData.is_follow_by_me === 2 ?
                                                <>
                                                    {
                                                        isChange === 1 ?
                                                            <Container className='profile-photo m-25'>
                                                                <Row>
                                                                    {
                                                                        isLoading ?
                                                                            <Circles
                                                                                height="40"
                                                                                width="40"
                                                                                color="#fff"
                                                                                ariaLabel="circles-loading"
                                                                                wrapperStyle={{}}
                                                                                visible={true}
                                                                                wrapperClass="profile-loader"
                                                                            />
                                                                            :
                                                                            <>
                                                                                {
                                                                                    PostData == "" ?
                                                                                        <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                PostData.map((item, index) => {
                                                                                                    return (
                                                                                                        <Col lg={4} md={4} sm={4} key={index}>
                                                                                                            <div onClick={() => userPost('POST')}>
                                                                                                                {
                                                                                                                    item.post[0].thumbnail != null ?
                                                                                                                        <video src={IMG_URL + item.post[0].image} className='img-pic pointer'></video>
                                                                                                                        :
                                                                                                                        <img src={IMG_URL + item.post[0].image} className='img-pic pointer' alt="" />
                                                                                                                }
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            <div className='d-flex justify-content-center mt-3 mb-3'>
                                                                                                {
                                                                                                    loader ?
                                                                                                        <ThreeDots
                                                                                                            visible={true}
                                                                                                            height="40"
                                                                                                            width="40"
                                                                                                            color="#fff"
                                                                                                            radius="9"
                                                                                                            ariaLabel="three-dots-loading"
                                                                                                            wrapperStyle={{}}
                                                                                                            wrapperClass=""
                                                                                                        />
                                                                                                        :
                                                                                                        currPage == totalPage ? null
                                                                                                            :
                                                                                                            <img src={view} width={180} onClick={handleViewMore} alt="" />
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </Row>
                                                            </Container>

                                                            : isChange === 2 ?

                                                                <Container className='profile-photo m-25'>
                                                                    <Row>
                                                                        {
                                                                            isLoading ?
                                                                                <Circles
                                                                                    height="40"
                                                                                    width="40"
                                                                                    color="#fff"
                                                                                    ariaLabel="circles-loading"
                                                                                    wrapperStyle={{}}
                                                                                    visible={true}
                                                                                    wrapperClass="profile-loader"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        vlogData == "" ?
                                                                                            <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    vlogData.map((item) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.post.map((i, index) => {
                                                                                                                        return (
                                                                                                                            <Col lg={4} md={4} sm={4} key={index}>
                                                                                                                                <div onClick={() => userPost('VLOG')}>
                                                                                                                                    <video className='img-pic pointer' >
                                                                                                                                        <source src={IMG_URL + i.image} />
                                                                                                                                    </video>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }

                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                <div className='d-flex justify-content-center mt-3 mb-3'>
                                                                                                    {
                                                                                                        loader ?
                                                                                                            <ThreeDots
                                                                                                                visible={true}
                                                                                                                height="40"
                                                                                                                width="40"
                                                                                                                color="#fff"
                                                                                                                radius="9"
                                                                                                                ariaLabel="three-dots-loading"
                                                                                                                wrapperStyle={{}}
                                                                                                                wrapperClass=""
                                                                                                            />
                                                                                                            :
                                                                                                            currPages == totalPages ? null
                                                                                                                :
                                                                                                                <img src={view} width={180} onClick={handleViewMores} alt="" />
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }

                                                                    </Row>
                                                                </Container>
                                                                :

                                                                <Container className='profile-photo m-25'>
                                                                    <Row>
                                                                        {
                                                                            isLoading ?
                                                                                <Circles
                                                                                    height="40"
                                                                                    width="40"
                                                                                    color="#fff"
                                                                                    ariaLabel="circles-loading"
                                                                                    wrapperStyle={{}}
                                                                                    visible={true}
                                                                                    wrapperClass="profile-loader"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        reelsView == "" ?
                                                                                            <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    reelsView.map((item, index) => {
                                                                                                        return (
                                                                                                            <Col lg={4} md={4} sm={4} key={index} onClick={(e) => handleReels(item.user_id)}>
                                                                                                                {item.post && item.post.length > 0 && item.post[0].thumbnail ? (
                                                                                                                    <img src={IMG_URL + item.post[0].thumbnail} className='img-pic1 pointer' alt="" />
                                                                                                                ) : (
                                                                                                                    // Render alternative content if image is not available
                                                                                                                    <img src={placeholderimg} className='img-pic1 pointer' alt="" />
                                                                                                                )}
                                                                                                            </Col>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                <div className='d-flex justify-content-center mt-3 mb-3'>
                                                                                                    {
                                                                                                        loader ?
                                                                                                            <ThreeDots
                                                                                                                visible={true}
                                                                                                                height="40"
                                                                                                                width="40"
                                                                                                                color="#fff"
                                                                                                                radius="9"
                                                                                                                ariaLabel="three-dots-loading"
                                                                                                                wrapperStyle={{}}
                                                                                                                wrapperClass=""
                                                                                                            />
                                                                                                            :
                                                                                                            currPagess == totalPagess ? null
                                                                                                                :
                                                                                                                <img src={view} width={180} onClick={handleViewMoress} alt="" />
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </Row >
                                                                </Container>
                                                    }
                                                </>

                                                :
                                                <>
                                                    <div className='subpro-go text-center'>
                                                        <img src={go} className='img-go' />
                                                        <p className='m-0 text-pro'>
                                                            Tap the 'Follow' button to send a <br /> friend request
                                                        </p>
                                                    </div>
                                                </>
                                        }
                                    </>
                                    :
                                    ""
                            }

                        </Col>
                    </Row>
                </Container>
            </div>

            {/* followers popup */}
            <Modal show={showFollow} onHide={() => setShowFollow(false)}
                contentClassName='popup-box-fixed'
                centered>
                <div className='hyep-head mt-2'>
                    <span>Followers</span>
                    <div>
                        <p className='ms-2 m-0'>{followersList.length}</p>
                    </div>
                    <div onClick={() => setShowFollow(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />

                <div className='srch-bar follow-srch d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.6">
                                <path d="M2.76311 11.4842L3.49329 11.3129L2.76311 11.4842ZM2.76311 6.93667L3.49329 7.10794L2.76311 6.93667ZM15.6578 6.93667L16.3879 6.76539L15.6578 6.93667ZM15.6578 11.4842L16.3879 11.6555L15.6578 11.4842ZM11.4842 15.6578L11.3129 14.9276L11.4842 15.6578ZM6.93667 15.6578L6.76539 16.3879L6.93667 15.6578ZM6.93667 2.76311L6.76539 2.03293V2.03293L6.93667 2.76311ZM11.4842 2.76311L11.6555 2.03293L11.4842 2.76311ZM16.9697 18.0303C17.2626 18.3232 17.7374 18.3232 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L16.9697 18.0303ZM3.49329 11.3129C3.1689 9.93004 3.1689 8.49084 3.49329 7.10794L2.03293 6.76539C1.65569 8.3736 1.65569 10.0473 2.03293 11.6555L3.49329 11.3129ZM14.9276 7.10795C15.252 8.49084 15.252 9.93004 14.9276 11.3129L16.3879 11.6555C16.7652 10.0473 16.7652 8.3736 16.3879 6.76539L14.9276 7.10795ZM11.3129 14.9276C9.93004 15.252 8.49084 15.252 7.10795 14.9276L6.76539 16.3879C8.3736 16.7652 10.0473 16.7652 11.6555 16.3879L11.3129 14.9276ZM7.10794 3.49329C8.49084 3.1689 9.93004 3.1689 11.3129 3.49329L11.6555 2.03293C10.0473 1.65569 8.3736 1.65569 6.76539 2.03293L7.10794 3.49329ZM7.10795 14.9276C5.31441 14.5069 3.914 13.1065 3.49329 11.3129L2.03293 11.6555C2.58373 14.0037 4.41721 15.8371 6.76539 16.3879L7.10795 14.9276ZM11.6555 16.3879C14.0037 15.8371 15.8371 14.0037 16.3879 11.6555L14.9276 11.3129C14.5069 13.1065 13.1065 14.5069 11.3129 14.9276L11.6555 16.3879ZM11.3129 3.49329C13.1065 3.91399 14.5069 5.31441 14.9276 7.10795L16.3879 6.76539C15.8371 4.41721 14.0037 2.58373 11.6555 2.03293L11.3129 3.49329ZM6.76539 2.03293C4.41721 2.58373 2.58373 4.41721 2.03293 6.76539L3.49329 7.10794C3.914 5.3144 5.31441 3.91399 7.10794 3.49329L6.76539 2.03293ZM13.9156 14.9763L16.9697 18.0303L18.0303 16.9697L14.9763 13.9156L13.9156 14.9763Z" fill="white" />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <input type='search' className='srch-follow' placeholder='Search' value={searchFollowers} onChange={(e) => setSearchFollowers(e.target.value)} />
                    </div>
                </div>

                <div>
                    {
                        isloading ?
                            <Circles
                                height="30"
                                width="30"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass="follower-loader"
                            /> :
                            <>
                                {
                                    followersList == "" ?
                                        <p className='text-light d-flex justify-content-center'>No User Found</p>
                                        :
                                        <>
                                            {
                                                followersList.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className='hype-follow' key={index}>
                                                                <div className='d-flex align-items-center hyep-data' onClick={() => handleProfile(item.follow_by)}>
                                                                    {
                                                                        item.profile_pic === null ?
                                                                            <img src={placeholderimg} alt="" className='hype-img-1' />
                                                                            :
                                                                            <img className='hype-img-1' src={IMG_URL + item.profile_pic} />
                                                                    }
                                                                    <div className='d-flex align-items-center ps-3'>
                                                                        <p className='m-0'>{item.first_name}</p>
                                                                        <p className='m-0 ps-2'>{item.last_name}</p>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    localStorage.getItem("UserId") == item.follow_by ? ""
                                                                        :
                                                                        <>
                                                                            {
                                                                                item.account_type === "PUBLIC" ?
                                                                                    <>
                                                                                        {
                                                                                            item.is_follow_by_me === 0 && item.is_follow_me === 0 ?
                                                                                                <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Follow</button>
                                                                                                :
                                                                                                item.is_follow_by_me === 2 ?
                                                                                                    <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Following</button>
                                                                                                    :
                                                                                                    <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Follow Back</button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            item.is_follow_by_me === 0 && item.is_follow_me === 2 ?
                                                                                                <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Follow Back</button>
                                                                                                :
                                                                                                item.is_follow_by_me === 0 ?
                                                                                                    <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Follow</button>
                                                                                                    :
                                                                                                    item.is_follow_by_me === 2 ?
                                                                                                        <>
                                                                                                            <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Following</button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_by, 2)}>Requested</button>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='d-flex justify-content-center mt-2 mb-2'>
                                                {
                                                    loader ?
                                                        <ThreeDots
                                                            visible={true}
                                                            height="40"
                                                            width="40"
                                                            color="#fff"
                                                            radius="9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                        />
                                                        :
                                                        followerPage == totalFollower ? null
                                                            :
                                                            <img src={view} width={150} onClick={handleFollowersView} alt="" />
                                                }
                                            </div>
                                        </>
                                }
                            </>
                    }


                </div>

            </Modal >

            {/* following popup */}
            <Modal show={showFollowing}
                onHide={() => setShowFollowing(false)}
                contentClassName='popup-box-fixed'
                centered >

                <div className='hyep-head mt-2'>
                    <span>Following</span>
                    <div>
                        <p className='ms-2 m-0'>{followingList.length}</p>
                    </div>
                    <div onClick={() => setShowFollowing(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />

                <div className='srch-bar follow-srch d-flex'>
                    <div style={{ color: "#8f8f8f" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.6">
                                <path d="M2.76311 11.4842L3.49329 11.3129L2.76311 11.4842ZM2.76311 6.93667L3.49329 7.10794L2.76311 6.93667ZM15.6578 6.93667L16.3879 6.76539L15.6578 6.93667ZM15.6578 11.4842L16.3879 11.6555L15.6578 11.4842ZM11.4842 15.6578L11.3129 14.9276L11.4842 15.6578ZM6.93667 15.6578L6.76539 16.3879L6.93667 15.6578ZM6.93667 2.76311L6.76539 2.03293V2.03293L6.93667 2.76311ZM11.4842 2.76311L11.6555 2.03293L11.4842 2.76311ZM16.9697 18.0303C17.2626 18.3232 17.7374 18.3232 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L16.9697 18.0303ZM3.49329 11.3129C3.1689 9.93004 3.1689 8.49084 3.49329 7.10794L2.03293 6.76539C1.65569 8.3736 1.65569 10.0473 2.03293 11.6555L3.49329 11.3129ZM14.9276 7.10795C15.252 8.49084 15.252 9.93004 14.9276 11.3129L16.3879 11.6555C16.7652 10.0473 16.7652 8.3736 16.3879 6.76539L14.9276 7.10795ZM11.3129 14.9276C9.93004 15.252 8.49084 15.252 7.10795 14.9276L6.76539 16.3879C8.3736 16.7652 10.0473 16.7652 11.6555 16.3879L11.3129 14.9276ZM7.10794 3.49329C8.49084 3.1689 9.93004 3.1689 11.3129 3.49329L11.6555 2.03293C10.0473 1.65569 8.3736 1.65569 6.76539 2.03293L7.10794 3.49329ZM7.10795 14.9276C5.31441 14.5069 3.914 13.1065 3.49329 11.3129L2.03293 11.6555C2.58373 14.0037 4.41721 15.8371 6.76539 16.3879L7.10795 14.9276ZM11.6555 16.3879C14.0037 15.8371 15.8371 14.0037 16.3879 11.6555L14.9276 11.3129C14.5069 13.1065 13.1065 14.5069 11.3129 14.9276L11.6555 16.3879ZM11.3129 3.49329C13.1065 3.91399 14.5069 5.31441 14.9276 7.10795L16.3879 6.76539C15.8371 4.41721 14.0037 2.58373 11.6555 2.03293L11.3129 3.49329ZM6.76539 2.03293C4.41721 2.58373 2.58373 4.41721 2.03293 6.76539L3.49329 7.10794C3.914 5.3144 5.31441 3.91399 7.10794 3.49329L6.76539 2.03293ZM13.9156 14.9763L16.9697 18.0303L18.0303 16.9697L14.9763 13.9156L13.9156 14.9763Z" fill="white" />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <input type='search' className='srch-follow' placeholder='Search' value={searchFollowing} onChange={(e) => setSearchFollowing(e.target.value)} />
                    </div>
                </div>

                <div>
                    {
                        isloading ?
                            <Circles
                                height="30"
                                width="30"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass="follower-loader"
                            /> :
                            <>
                                {
                                    followingList == "" ?
                                        <p className='text-light d-flex justify-content-center'>No User Found</p>
                                        :
                                        <>
                                            {
                                                followingList.map((item) => {
                                                    return (
                                                        <>
                                                            <div className='hype-follow'>
                                                                <div className='d-flex align-items-center hyep-data' onClick={() => handleProfile(item.follow_to)}>
                                                                    {
                                                                        item.profile_pic === null ?
                                                                            <img src={placeholderimg} className='hype-img-1' alt="" />
                                                                            :
                                                                            <img className='hype-img-1' src={IMG_URL + item.profile_pic} />
                                                                    }
                                                                    <div className='d-flex align-items-center ps-3'>
                                                                        <p className='m-0'>{item.first_name}</p>
                                                                        <p className='m-0 ps-2'>{item.last_name}</p>
                                                                    </div>
                                                                </div>


                                                                {
                                                                    localStorage.getItem("UserId") == item.follow_to ? ""
                                                                        :
                                                                        <>
                                                                            {
                                                                                item.account_type === "PUBLIC" ?
                                                                                    <>
                                                                                        {
                                                                                            item.is_follow_by_me === 0 && item.is_follow_me === 0 ?
                                                                                                <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Follow</button>
                                                                                                :
                                                                                                item.is_follow_by_me === 2 ?
                                                                                                    <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Following</button>
                                                                                                    :
                                                                                                    <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Follow Back</button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            item.is_follow_by_me === 0 && item.is_follow_me === 2 ?
                                                                                                <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Follow Back</button>
                                                                                                :
                                                                                                item.is_follow_by_me === 0 ?
                                                                                                    <button className='pro-follow-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Follow</button>
                                                                                                    :
                                                                                                    item.is_follow_by_me === 2 ?
                                                                                                        <>
                                                                                                            <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Following</button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <button className='remove-btn' onClick={(e) => IsFollowUnfollow(e, item.follow_to, 3)}>Requested</button>
                                                                                        }
                                                                                    </>
                                                                            }

                                                                        </>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='d-flex justify-content-center mt-2 mb-2'>
                                                {
                                                    loader ?
                                                        <ThreeDots
                                                            visible={true}
                                                            height="40"
                                                            width="40"
                                                            color="#fff"
                                                            radius="9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                        />
                                                        :
                                                        followingPage == totalFollowing ? null
                                                            :
                                                            <img src={view} width={150} onClick={handleFollowingView} alt="" />
                                                }
                                            </div>
                                        </>
                                }
                            </>
                    }


                </div>

            </Modal >

            {/* menu popup */}
            < Modal show={modalShow} onHide={() => setModalShow(false)} centered >
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setReportshow(true); setModalShow(false) }}>
                        <p>Report</p>
                        <img src={danger} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger'>
                        <p>Copy Link</p>
                        <img src={sendline} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={handleBlock}>
                        {
                            AccountData.is_block === 0 ?
                                <p>Block</p>
                                :
                                <p>Unblock</p>
                        }
                        <img src={prodelete} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setModalShow(false)}>Cancel</p>
                </div>

            </Modal >

            {/* report popup */}
            < Modal show={reportshow} onHide={() => setReportshow(false)} centered >
                <div className='report-fram'>
                    <p>Report</p>
                </div>
                <hr />

                <div className='report-center'>
                    <label className='report-data'>
                        <p className='report-name m-0'>It’s Spam</p>
                        <input name='report' type='radio' onChange={handleReport} value="It’s Spam" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Inappropriate Content</p>
                        <input name='report' type='radio' onChange={handleReport} value="Inappropriate Content" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Scam & Fraud</p>
                        <input name='report' type='radio' onChange={handleReport} value="Scam & Fraud" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Bullying</p>
                        <input name='report' type='radio' onChange={handleReport} value="Bullying" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Harassment</p>
                        <input name='report' type='radio' onChange={handleReport} value="Harassment" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Violence</p>
                        <input name='report' type='radio' onChange={handleReport} value="Violence" className='purchs-radio popup-redio' />
                    </label>

                    <div className='text-center'>
                        <button className='report-btn' onClick={HandleReports}>
                            {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                < span className="visually-hidden">Loading...</span>
                            </Spinner> : "Submit"}
                        </button>
                    </div>
                </div>

            </Modal >

        </>
    )
}

export default UserProfile

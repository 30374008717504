import { configureStore } from "@reduxjs/toolkit";

// import { loginApi } from "../features/auth";
import { ApiData } from "./index";
import {customDataSlice} from './customData';
import {customePostDataSlice} from './commonPostData';

export const store = configureStore({
    reducer: {
        // [loginApi.reducerPath]: loginApi.reducer,
        [ApiData.reducerPath]: ApiData.reducer,
        customData:customDataSlice.reducer,
        customPostData:customePostDataSlice.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            // loginApi.middleware,
            ApiData.middleware,
        ),
})
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import data8 from "../assets/img/Sidebar/oneg8-icon.png"
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"



export default function WalletSidebar({data}) {
  console.log(data)
  const navigate = useNavigate();
  const location = useLocation();
  const handleLocation = () => {
    navigate("/location")
}

  return (



    <div>


          <div className='SidebarDataG8 align-items-start d-flex flex-column mb-auto bd-highlight' style={{ height: "100vh" }} id='sidebar'>
              <div className='mb-auto'>
                <div className='head-logo'>
                  <img src={headlogo} />
                </div>

                <div className="pt-4">

            <div><a className="sideBardata" ref={data} href="#wallet"><i className=" px-1 fa-solid fa-wallet"><span class="text10 px-2">Wallet</span></i></a></div>
            <div><a className="sideBardata" href="#explorer"><i className=" px-1 fa-brands fa-wpexplorer"><span class="text10 px-2">Explorer</span></i></a></div>
            {/* <div><a className="sideBardata" href="#overview"><i className=" px-1 fa-solid fa-square-poll-vertical"><span class="text10 px-2">Overview</span></i></a></div>
            <div><a className="sideBardata" href="#watches"><i className=" px-1 fa-regular fa-clock"><span class="text10 px-2">Watches</span></i></a></div> */}
            <div><a className="sideBardata" href="#settings"><i className=" px-1 fa-solid fa-gear"><span class="text10 px-2">Settings</span></i></a></div>
            <Link className='sideBardata' to={"/homescreen"}  >
            <img className="img-21" src={data8} />
            Public Square
            </Link>




                </div>
              </div>
          </div>
    </div>















  )
}

import React, { useEffect, useState, useMemo } from 'react'
import "./Story.css"
import Stories from 'react-insta-stories';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "../assets/img/Sidebar/Group 1000003209.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl'
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"
import danger from "../assets/img/Sidebar/danger.png"
import moment from 'moment';
import 'animate.css';
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";
import trash from "../assets/img/Sidebar/trash.png"
import { toast } from 'react-toastify';
import delete1 from "../assets/img/Sidebar/m-3.png"
import left from "../assets/img/Sidebar/story-left.png"
import right from "../assets/img/Sidebar/story-right.png"

function Story() {

    const userIds = localStorage.getItem("UserId")

    let navigate = useNavigate()
    let location = useLocation()
    const [currIndex, setCurrIndex] = useState("")
    const [report, setReport] = useState("")
    const [postId, setPostId] = useState("")
    const [userId, setUserId] = useState("")


    const [previous, setPreviousStory] = useState({})
    const [nextStory, setNextStory] = useState({})
    const [storyView, setStoryView] = useState({})

    const [Menu, setMenu] = useState(false);
    const [isloading, setIsloading] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [deletepost, setDeletepost] = useState(false)
    const [reportshow, setReportshow] = useState(false);

    let stories = [];
    let data;
    let previouss;
    let index;
    let item;
    try {
        const { Story, Data, prevs, Index, Items } = location.state;
        stories.push(Story);
        data = Data;
        previouss = prevs;
        index = Index;
        item = Items;
    } catch (error) {
        //err
    }

    const [currentstory, setCurrentstory] = useState(stories);

    useEffect(() => {
        handleMix(index)
        setUserId(item.user_id)
    }, [])

    useEffect(() => {
        console.log("userId >>", userId);
    }, [userId])

    const datas = useMemo(() => {
        return currentstory[0];
    }, [currentstory]);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));

    const handleStory = (e) => {
        let items = data[e];
        let details = items.story_details;
        var storydetailss = [];
        setUserId(items.user_id)

        details.forEach((element, index) => {
            storydetailss = [...storydetailss];
            const storyArray = element.story;
            if (storyArray != null) {
                const storyElement = storyArray[0]["image"];
                let obj = {
                    url: IMG_URL + storyElement,
                    type: storyArray[0].thumbnail == null ? "image" : "video",
                    header: {
                        heading: items.first_name + " " + items.last_name,
                        subheading: moment(element.created_at).fromNow(),
                        profileImage: items.profile_pic
                            ? IMG_URL + items.profile_pic
                            : placeholderimg,
                    },
                };
                storydetailss = [...storydetailss, obj];
                if (details.length - 1 === index) {
                    stories = [];
                    console.log("dekh bhai ->>", storydetailss, index, stories);
                    stories?.push(storydetailss);
                    setCurrentstory(stories);
                    console.log("abhi dekh bhai", stories);
                }
            }
        });
    };

    const HomeScreen = () => {
        navigate("/homescreen")
    }

    let handleMix = (e) => {
        setCurrIndex(e)
        handlePrivous(e)
        handleNext(e)
    }

    const handlePrivous = (e) => {
        if (data.length != 1 && e != 0) {
            if (e != 1 || previouss == true || data.length - 1 != e) {
                let previousData = data[e - 1]
                let obj = {
                    profile_pic: previousData.profile_pic ? IMG_URL + previousData.profile_pic : placeholderimg,
                    first_name: previousData.first_name,
                    last_name: previousData.last_name
                }
                setPreviousStory(obj)
                // console.log("handlePrivous >>", obj);
            }
        }
    }

    const handleNext = (e) => {
        if (data.length - 1 != e && data.length != 1) {
            let nextData = data[e + 1];
            let obj = {
                profile_pic: nextData.profile_pic ? IMG_URL + nextData.profile_pic : placeholderimg,
                first_name: nextData.first_name,
                last_name: nextData.last_name
            }
            setNextStory(obj)
        }
    }

    const HandleData = () => {
        return (
            <div>
                <div className='d-flex justify-content-end align-items-center menu-story'>
                    {
                        userIds == userId ?
                            <div
                                className='stories-menu'
                                onClick={() => { setShowEdit(true) }}
                            >
                                <i className="bi bi-three-dots"></i>
                            </div>
                            :
                            <div
                                className='stories-menu'
                                onClick={() => { setMenu(true) }}
                            >
                                <i className="bi bi-three-dots"></i>
                            </div>
                    }
                </div>
                <Stories
                    stories={datas}
                    defaultInterval={2500}
                    className="story-view"
                    keyboardNavigation={true}
                    loop={false}
                    currIndex={currIndex}
                    onAllStoriesEnd={handleClose}
                // onStoryEnd={() => {handleMix(currIndex + 1); handleStory(currIndex + 1)}}
                />
            </div>
        )
    }

    const handleClose = () => {
        if (data.length - 1 == currIndex) {
            navigate("/homescreen")
        }
    }

    // follow & unfollow API
    const IsFollowUnfollow = (e) => {
        e.preventDefault()
        console.log("follow id >>", userId);
        let jsonData = {
            user_id: userId
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "follow_unfollow_user", {
            method: 'POST',
            body: JSON.stringify({ "followData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    //error data
                    console.log(response, "no")
                } else {
                    console.log(response)
                    setMenu(false)
                    navigate("/homescreen")
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleReport = (e) => {
        setReport(e.target.value)
    }

    //handle report API
    const HandleReports = (e) => {
        setIsloading(true)
        let jsonData = {
            other_id: userId,
            report_type: report
        }
        console.log("json >>", jsonData);

        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "add_report", {
            method: 'POST',
            body: JSON.stringify({ "reportData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    toast.error(response.Message);
                    setReportshow(false)
                } else {
                    console.log(response);
                    toast.success(response.Message);
                    setReportshow(false)
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })

    }

    //delete post API
    const DeletePost = () => {
        setIsloading(true)
        let jsonData = {
            post_id: postId
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "delete_post", {
            method: 'POST',
            body: JSON.stringify({ "postData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    //error data
                    console.log(response, "no")
                    setIsloading(false)
                } else {
                    navigate("/homescreen")
                }
                setIsloading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false)
            })
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center story-head'>
                <img src={logo} width={180} alt="" />
                <svg xmlns="http://www.w3.org/2000/svg" onClick={HomeScreen} className='close-story' width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 4L19.9999 19.9999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.00013 19.9999L20 4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <div className='d-flex align-items-center'>
                <Container>
                    <Row className='story-data'>
                        <Col lg={3} md={4} sm={0} className='d-flex justify-content-start align-items-center'>
                            <div className='stories-left'>
                                {
                                    currIndex == 0 ? ""
                                        :
                                        <>
                                            {
                                                currIndex != 1 || previouss == true ?
                                                    <div className='animate__animated animate__fadeInLeft'>
                                                        <div className='story-back1' style={{ backgroundImage: `url(${previous.profile_pic})` }}>
                                                            <div className='black-layer d-flex align-items-center justify-content-center'>
                                                                <div>
                                                                    <div className='storie-pose m-auto text-center'>
                                                                        <img className='photos-s' src={previous.profile_pic} />
                                                                    </div>
                                                                    <p className='text-light text-center pt-2'>
                                                                        {previous.first_name} {previous.last_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                        </>
                                }
                            </div>
                        </Col>

                        <Col lg={6} md={4} sm={12} className='story-view d-flex align-items-center justify-content-center'>
                            {
                                currIndex == 0 ? ""
                                    :
                                    <>
                                        {
                                            currIndex != 1 || previouss == true ?
                                                <img src={left} className='previous-story-btn me-5' onClick={() => { handleMix(currIndex - 1); handleStory(currIndex - 1) }} alt="" />
                                                :
                                                <p className='me-5' style={{ width: 28 }} ></p>
                                        }
                                    </>
                            }

                            <HandleData />

                            {
                                data.length - 1 == currIndex ? <p className='ms-5' style={{ width: 28 }} ></p>
                                    :
                                    <img src={right} className='next-story-btn ms-5' onClick={() => { handleMix(currIndex + 1); handleStory(currIndex + 1) }} alt="" />
                            }
                        </Col>

                        <Col lg={3} md={4} sm={0} className='d-flex justify-content-end align-items-center'>
                            <div className='stories-right'>
                                {
                                    data.length - 1 == currIndex ? ""
                                        :
                                        <div className='animate__animated animate__fadeInRight'>
                                            <div className='story-back2' style={{ backgroundImage: `url(${nextStory.profile_pic})` }}>
                                                <div className='black-layer d-flex align-items-center justify-content-center'>
                                                    <div>
                                                        <div className='storie-pose m-auto text-center'>
                                                            <img className='photos-s' src={nextStory.profile_pic} />
                                                        </div>
                                                        <p className='text-light text-center pt-2'>
                                                            {nextStory.first_name} {nextStory.last_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >


            {/* menu list popup */}
            <Modal
                show={Menu} onHide={() => setMenu(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={() => { setReportshow(true); setMenu(false) }} >
                        <p>Report</p>
                        <img src={danger} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    {
                        item.account_type == "PUBLIC" ?
                            <>
                                {
                                    item.is_follow_by_me === 0 && item.is_follow_me === 0 ?
                                        <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                            <p >Follow</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        :
                                        item.is_follow_by_me === 2 ?
                                            <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                                <p>Following</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                    <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            :
                                            <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                                <p>Follow Back</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                    <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                }
                            </>
                            :
                            <>
                                {
                                    item.is_follow_by_me === 0 && item.is_follow_me === 2 ?
                                        <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                            <p>Follow Back</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        :
                                        item.is_follow_by_me === 0 ?
                                            <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                                <p>Follow</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                    <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            :
                                            item.is_follow_by_me === 2 ?
                                                <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                                    <p>Following</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                        <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                :
                                                <div className='pro-danger' onClick={(e) => IsFollowUnfollow(e)} >
                                                    <p>Requested</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                        <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                }
                            </>
                    }
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setMenu(false)}>Cancel</p>
                </div>
            </Modal >

            < Modal
                show={showEdit}
                onHide={() => setShowEdit(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' onClick={(e) => { setDeletepost(true); setShowEdit(false) }} >
                        <p>Delete</p>
                        <img src={trash} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setShowEdit(false)}>Cancel</p>
                </div>
            </Modal >

            {/* delete post popup */}
            < Modal
                show={deletepost} onHide={() => setDeletepost(false)}
                centered
            >

                <div className='text-center mt-4 mb-4'>
                    <img src={delete1} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you sure you want to Delete.</p>
                <div className='kyc-con-btn'>
                    <button className="delete-btn" onClick={() => DeletePost()}>
                        {isloading ? <Spinner className="loderImage" animation="border" role="status">
                            < span className="visually-hidden">Loading...</span>
                        </Spinner> : "Yes, Delete"}
                    </button>
                </div>
            </Modal >

            {/* report popup */}
            < Modal show={reportshow} onHide={() => setReportshow(false)} centered >
                <div className='report-fram'>
                    <p>Report</p>
                </div>
                <hr />

                <div className='report-center'>
                    <label className='report-data'>
                        <p className='report-name m-0'>It’s Spam</p>
                        <input name='report' type='radio' onChange={handleReport} value="It’s Spam" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Inappropriate Content</p>
                        <input name='report' type='radio' onChange={handleReport} value="Inappropriate Content" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Scam & Fraud</p>
                        <input name='report' type='radio' onChange={handleReport} value="Scam & Fraud" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Bullying</p>
                        <input name='report' type='radio' onChange={handleReport} value="Bullying" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Harassment</p>
                        <input name='report' type='radio' onChange={handleReport} value="Harassment" className='purchs-radio popup-redio' />
                    </label>
                    <label className='report-data'>
                        <p className='report-name m-0'>Violence</p>
                        <input name='report' type='radio' onChange={handleReport} value="Violence" className='purchs-radio popup-redio' />
                    </label>

                    <div className='text-center'>
                        <button className='report-btn' onClick={HandleReports}>
                            {isloading ? <Spinner className="loderImage" animation="border" role="status">
                                < span className="visually-hidden">Loading...</span>
                            </Spinner> : "Submit"}
                        </button>
                    </div>
                </div>

            </Modal >

        </>
    )
}

export default Story

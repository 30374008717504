import React, { useEffect, useRef, useState } from 'react'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { Document, Page, pdfjs } from "react-pdf";
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// Set the workerSrc to the correct path
import Modal from 'react-bootstrap/Modal';
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useSelector,useDispatch } from 'react-redux';
import { setCustomData } from '../apiService/customData';
import data8 from "../assets/img/Sidebar/oneg8-icon.png"
import {backgroundImage} from "../assets/datag8/images/base64pdf"
import { useCreateFolderMutation, useGetFileDirectoresQuery,
   useUploadFileForDriveMutation,useLazyCheckDrivePasswordGeneratedQuery,
  useLoginDriveMutation,
  useGenerateDrivePasswordMutation,
  } from '../apiService/index';
import {getDriveTokenData, getSkipGeneratePassword, skipGeneratePassword, storeDriveToken } from '../util/cacheData';
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// GlobalWorkerOptions.workerSrc = pdfjsWorker;
// GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export default function AllModels() {

  const [create, setCreate] = useState(false)
  const [createFolderModel, setCrtFolderModel] = useState(false)
  const [folderName, setFolderName] = useState("");
  const [currentFolderId, setCurrentFolderId] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [drivePasswordModel,setDrivePasswordModel]=useState(false);
  const [loader,setLoader]=useState(false);
  const [drivePassword,setDrivePassword]=useState({
    password:"",
    confirmPassword:""
  })
  const [driveLogin,setDriveLogin]=useState({
    driveLoginModel:false,
    drivePassword:""
  })
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const [createFolder] = useCreateFolderMutation();
  const [uploadFileForDrive] = useUploadFileForDriveMutation();
  const [generateDrivePassword]=useGenerateDrivePasswordMutation();
  const [loginDrive]=useLoginDriveMutation();

  const customData = useSelector((state) => state.customData);
// console.log(customData);
  useEffect(()=>{
      if(customData?.loginModel)
          setDriveLogin({drivePassword:"",driveLoginModel:true});

  },[customData?.loginModel]);

  useEffect(() => {
    setCurrentFolderId(customData?.currentFolderId);
  }, [customData?.currentFolderId]);

  const base64ToFile = (base64String, filename, mimeType) => {
    const [contentType, base64Content] = base64String.split(',');
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType || mimeType });
    const file = new File([blob], filename, { type: contentType || mimeType });
    return file;
  };
  const generateThumbnailFromPDF = (pdfFile) => {
    return new Promise(async (resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        try {
          const pdfData = new Uint8Array(fileReader.result);

          const pdf = await getDocument({ data: pdfData }).promise;
          const page = await pdf.getPage(1);
          const viewport = page.getViewport({ scale: 0.2 });

          const canvas = document.createElement('canvas');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const context = canvas.getContext('2d');
          await page.render({ canvasContext: context, viewport }).promise;
          const thumbnailData = canvas.toDataURL('image/jpeg');
          resolve(thumbnailData);
        } catch (error) {
          console.log(error)
          resolve(backgroundImage)
          // reject(error);
        }
      };

      // fileReader.onerror = (error) => {
      //   reject(error);
      // };

      fileReader.readAsArrayBuffer(pdfFile);
    });
  };
  const generateThumbnailFromImage = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxThumbSize = 100; // Adjust the maximum thumbnail size as needed
                let width = img.width;
                let height = img.height;
                if (width > height) {
                    if (width > maxThumbSize) {
                        height *= maxThumbSize / width;
                        width = maxThumbSize;
                    }
                } else {
                    if (height > maxThumbSize) {
                        width *= maxThumbSize / height;
                        height = maxThumbSize;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                const thumbnail = canvas.toDataURL('image/jpeg');
                resolve(thumbnail);
            };
            img.onerror = error => reject(error);
        };
        reader.onerror = error => reject(error);
    });
};
  const generateThumbnailFromVideo = (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async () => {
        const duration = video.duration;
        const middleTime = duration / 2;
        video.currentTime = middleTime;
        await new Promise((resolve) => {
          video.onseeked = resolve;
        });
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailData = canvas.toDataURL('image/jpeg');
        resolve(thumbnailData);
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(videoFile);
    });
  };

  const handelUploadFiles = async (event) => {
    setLoader(true)

    const files = event.target.files;


    const formData = new FormData();
    formData.append("currentFolderId", currentFolderId);
    for (const file of files) {
      if (file.type.includes('video')) {
        const thumbnail = await generateThumbnailFromVideo(file);
        let videoFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
        console.log('======', videoFile, thumbnail);
        formData.append('preview', videoFile);

      }if (file.type.includes('pdf')) {
        const thumbnail = await generateThumbnailFromPDF(file);
        let pdfThumbnailFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
        formData.append('preview', pdfThumbnailFile);
        console.log(thumbnail)
      }else{
        const thumbnailimg = await generateThumbnailFromImage(file);
        let imgFile = base64ToFile(thumbnailimg, "example.jpg", 'image/jpeg');
        formData.append('preview', imgFile);
      }
      formData.append('uploadFile', file);
      console.log(file)
    }

  //  const uploadData=await uploadFileForDrive(formData);
  //  console.log(uploadData);
  //   return;
    uploadFileForDrive(formData).then((res) => {
      // toast.success(res.data.message);
      // dispatch(setCustomData({...customData, fileUploaded:Date.now() }));
      console.log(res);
      setLoader(false)
      setCreate(false)

      // setCrtFolderModel(false);
      // toast.success(res.data.message);
      toast.success(res?.data?.message, { theme: "dark" });

      dispatch(setCustomData({...customData, fileUploaded:Date.now() }));

    })
      .catch((err) => {
        console.log(err);
        // toast.error(err?.error?.data?.message);
      toast.success(err?.error?.data?.message, { theme: "dark" });


      })
  }


  const createFolderData = () => {

    let payload = {
      folderName: folderName,
      currentFolderId: currentFolderId
    }

    createFolder(payload).then((res) => {
      setCrtFolderModel(false);
      // toast.success(res.data.message);
      toast.success(res?.data?.message, { theme: "dark" });

      dispatch(setCustomData({...customData, fileUploaded:Date.now() }));
    }).catch((err) => {
      console.log(err);
      // toast.error(err?.error?.data?.message);
      toast.success(err?.error?.data?.message, { theme: "dark" });


    })




  }
  const [ checkDrivePassword,results ]  = useLazyCheckDrivePasswordGeneratedQuery();

  useEffect(()=>{
    if(results?.data?.drivePasswordExist)
    {
      // setDrivePasswordModel(true);
      setDriveLogin({
        driveLoginModel:true,
        drivePassword:""
      });
    }
    if(results?.data && !results?.data?.drivePasswordExist){
      // login password for drive
      // setDrivePasswordModel(true);
      setDrivePasswordModel(true);
    }

  },[results]);
  useEffect(()=>{

    if(!getSkipGeneratePassword() && !getDriveTokenData())
       checkDrivePassword();

  },[]);


  const generatePasswordHandler=()=>{

    generateDrivePassword(drivePassword).then((res)=>{
      toast.success(res.data.message);
      setDrivePasswordModel(false);
      storeDriveToken(res?.data?.token);
      dispatch(setCustomData({...customData, drivePasswordIsExist:true }));
    })
    .catch((err)=>{
      toast.error(err?.error?.data?.message);

    })


  }

  const handleLocation = () => {
    navigate("/location")
}

  const skipGeneratePasswordHandler=()=>{

    skipGeneratePassword();
    setDrivePasswordModel(false);
    dispatch(setCustomData({...customData, drivePasswordIsExist:true }));
  }

  const verifyDriveHandler=()=>{
    loginDrive({password:driveLogin.drivePassword}).then((res)=>{
      storeDriveToken(res?.data?.token);
      setDriveLogin({...driveLogin,driveLoginModel:false});
      dispatch(setCustomData({ drivePasswordIsExist:true }));

    })
    .catch((err)=>{
    console.log(err);
      toast.error(err?.error?.data?.message);

    })
  }

  return (
    <div>
      <div className='SidebarDataG8 align-items-start d-flex flex-column mb-auto bd-highlight' style={{ height: "100vh" }} id='sidebar'>
        <div className='mb-auto'>
          <div className='head-logo'>
            <img src={headlogo} />
          </div>

          <div className="pt-4">

            <button type="button" className="btnDataG8 mb-3" onClick={() => { setCreate(true) }}>+ New</button>
            <Link className={location.pathname === "/datag8" ? 'actives' : 'sidedata' } to={"/datag8"}>
              <i className="fa-solid fa-inbox px-2"></i>
              My Files
            </Link>

            <Link className={location.pathname === "/datag8/SocialMedia" ? 'actives' : 'sidedata' } to={"/datag8/SocialMedia"}>
              <i className="fa-solid fa-sliders px-2"></i>
              Social Media
            </Link>
            {/* <Link className={location.pathname === "/datag8/D8Messanger" ? 'actives' : 'sidedata' } to={"/datag8/D8Messanger"}>
              <i className="fa-regular fa-message px-2"></i>
              Messenger
            </Link> */}
            <Link className={location.pathname === "/datag8/favourites" ? 'actives' : 'sidedata' } to={"/datag8/favourites"}>
              <i className="fa-regular fa-bookmark px-2"></i>
              Favourites
              </Link>
              <Link className={location.pathname === "/datag8/share-with-me" ? 'actives' : 'sidedata' } to={"/datag8/share-with-me"}>
              <i className="fa-solid fa-square-share-nodes px-2"></i>
              Shared with me
              </Link>
            <Link className={location.pathname === "/datag8/Trash" ? 'actives' : 'sidedata' } to={"/datag8/Trash"}>
              <i className="fa-solid fa-trash-can px-2"></i>
              Trash
            </Link>
            <Link className={location.pathname === "/homescreen" ? 'actives' : 'sidedata' } to={"/homescreen"}  >
            <img className="img-20" src={data8} />
            Public Square
            </Link>

            <button type="button" className="btnDataG82 mt-2"><i className="fa-regular fa-hard-drive px-2"></i>Get More Storage</button>
          </div>
        </div>
      </div>

      {/* new create model */}
      <Modal
        show={create} onHide={() => setCreate(false)}
        centered
      >
        <div className='data-pro-modal mt-4'>
          <div className='pro-danger' onClick={() => { setCrtFolderModel(true); setCreate(false) }}>
            <p>Create Folder</p>
            <i className="fa-solid fa-folder fa-2x"></i>
          </div>
        </div>
        <div className='data-pro-modal'>
          <div className='pro-danger' onClick={() => { document.getElementById('uploadId').click() }}>
            <p>Upload Files</p>
            <i className="fa-solid fa-file fa-2x"></i>
          </div>
          <input className='d-none' type="file" id="uploadId" ref={inputFile} onChange={handelUploadFiles} multiple />
        </div>
        {loader != false ?(
          <div className='pro-modal-bottom text-center pt-2'>
          <button type="button" className="btndatag8cancel">Media is uploading...</button>
        </div>
        ):(
        <div className='pro-modal-bottom text-center pt-2'>
          <button type="button" className="btndatag8cancel" onClick={() => setCreate(false)}>Cancel</button>
        </div>
        )}

      </Modal >
      {/* end */}

      {/* create folder model */}
      <Modal
        show={createFolderModel} onHide={() => setCrtFolderModel(false)}
        centered
      >
        <div className='activity-disck'>

          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Create a new folder </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <input type="text" className="i-put-1" onChange={((e) => setFolderName(e.target.value))} />
          </div>
        </div>
        <div className='pro-modal-bottom text-center pt-3'>
          <p onClick={createFolderData}>Create</p>
        </div>
      </Modal >
      {/* end */}



       {/* Set Password for Drive */}
      <Modal
        show={drivePasswordModel} onHide={() => setDrivePasswordModel(false)}
        centered
        backdrop="static"
      >
        <div className='activity-disck'>

          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Generate Drive Password </h5>
              {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <input type="text" placeholder='Password' className="i-put-1" value={drivePassword.password} onChange={(e)=> setDrivePassword({...drivePassword,password:e.target.value}) } />
            <input type="text" placeholder='Confirm Password' className="i-put-1" value={drivePassword.confirmPassword} onChange={(e)=> setDrivePassword({...drivePassword,confirmPassword:e.target.value}) }  />
          </div>
        </div>
        <div className='d-flex justify-content-center px-5 pt-5'>
          <span className='btncreate text-center' onClick={generatePasswordHandler}>Create</span>
        </div>
        <div className='d-flex justify-content-center px-5 pt-2'>
          <span className="btncancel text-center" onClick={skipGeneratePasswordHandler} >skip generate password</span>
        </div>
      </Modal >
      {/* end */}



              {/* login with Drive Password  */}
      <Modal
        show={driveLogin.driveLoginModel} onHide={() => setDriveLogin({...driveLogin,driveLoginModel:false})}
        centered
        backdrop="static"
      >
        <div className='activity-disck'>

          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Enter Your Password to Verify </h5>
              {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <input type="text" placeholder='Password' value={driveLogin.drivePassword}
             className="i-put-1"
             onChange={(e)=>setDriveLogin({...driveLogin,drivePassword:e.target.value})}
             />
          </div>
        </div>
        <div className='pro-modal-bottom text-center mt-5'>
          <button onClick={verifyDriveHandler} type="button" className="btndatag8cancel">Sign in</button>
        </div>
      </Modal >
      {/* end */}





    </div>
  )
}

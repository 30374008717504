import React,{useState} from "react";
import { Outlet } from "react-router-dom";

import DataG8Sidebar from './DataG8Sidebar';





const DataG8 = () => {

    const [folderId,setFolderId]=useState(20);

    return (
        <>
            <div>
                <div className="d-flex justify-content-start">
                    <div className="" style={{ width: "20%" }}>
                    <DataG8Sidebar currFolderId={folderId} />
                    </div>

                    <div className="" style={{ width: "80%" }}>
                    <Outlet setFolderId />
                    </div>
                </div>

            </div>
        </>
    )
}

export default DataG8;
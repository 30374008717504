import React, { useEffect, useState } from "react";
import axios from 'axios';
import "../Login/Login.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import google from "../assets/img/Login/google.png";
import apple from "../assets/img/Login/apple.png";
import { useNavigate } from "react-router-dom";
import { getMessaging, onMessage,getToken } from "firebase/messaging";
import { messaging } from "../Firebase/Firebase";

import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { auth, appleprovider, googleprovider } from "../Firebase/Firebase";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Spinner from "react-bootstrap/Spinner";
// import { backgroundImage } from "../assets/base64/file";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("bi bi-eye-slash-fill");
  const [isloading, setIsloading] = useState(false)
  const [latitude, setlatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);

  useEffect(() => {
    let utoken = localStorage.getItem("UserToken")

    if (utoken) {
      navigate("/homescreen")
    }

    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }
    console.log('Device ID:', deviceId);

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setlatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });

  }, []);



  const ForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const signUp = () => {
    navigate("/signup");
  };

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "bi bi-eye-slash-fill"
      ? setIcon("bi bi-eye-fill")
      : setIcon("bi bi-eye-slash-fill");
  };


  // Login API
  const HandleSubmit = (e) => {

  var fcmToken = localStorage.getItem("fcm_token") != '' || undefined ? localStorage.getItem("fcm_token") : 'abc';
    e.preventDefault();
    setIsloading(true);
    const jsonData = {
      email_id: email,
      password: pass,
      device_token: fcmToken,
      device_type: 3,
      device_id: localStorage.getItem('deviceId'),
      latitude: latitude,
      longitude: longitude,
    }
    const encrypt = encryptData(jsonData);

    const newEmail = document.getElementById('email').value
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    if (regex.test(newEmail)) {
      setIsloading(true);

      fetch(BASE_URL + "login", {
        method: 'POST',
        body: JSON.stringify({ "loginData": encrypt }),
        headers: { "Content-Type": "application/json" }
      })

        .then(response => response.json())
        .then(response => {
          if (response.Status === 0) {
            console.log(response, "Status 0");
            // toast.error(response.Message);
            toast.error(response.Message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
          }
          else if (response.Status === 1) {
            console.log(response, "Status 1");
            // toast.success(response.Message);
            toast.success(response.Message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
            const decrypt = decryptData(encrypt);
            const decrypt1 = decryptData(response.info);
            localStorage.setItem("UserToken", response.UserToken);
            localStorage.setItem("UserId", decrypt1.user_id);
            localStorage.setItem("is_wallet_pass_set", decrypt1.wallet_password == '' ? false : true);
            axios.defaults.headers.common = {
                Authorization: `Bearer ` + response.UserToken,
            };
            navigate("/homescreen");
          }
          else if (response.Status === 2) {
            console.log(response, "Status 2");
            // toast.success(response.Message);
            toast.success(response.Message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });

            const decrypt = decryptData(encrypt);
            navigate("/verification", { state: { type: 3, UserToken: response.UserToken, email_id: email } });
          }
          else if (response.Status === 3) {
            console.log(response, "Status 3");
            // toast.success(response.Message);
            toast.success(response.Message, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
            });
            const decrypt = decryptData(encrypt);
            navigate('/accountsetup', { state: { token: response.UserToken } })
          }
          setIsloading(false)
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false)
        });
    }
    else {
      toast.error("Not valid your Email");
      setTimeout(() => {
        setIsloading(false)
      }, 1000);
    }
  }

  // Login with google
  const signInWithGoogle = () => {
    signInWithPopup(auth, googleprovider)
      .then((result) => {
        console.log(result)
        const email = result.user.email;
        const ThirdpartyId = result.user.uid;
        const type = "GOOGLE"
        HandleThirdpartyData(ThirdpartyId, email, type)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Login with apple
  function signinapple(e) {
    e.preventDefault();

    signInWithPopup(auth, appleprovider)

      .then((data) => {
        console.log("data", data);
        var user = data.user;
        var username = user.displayName;
        var email = user.email;
        var thirdpartyid = user.uid;
        var type = "APPLE"
        HandleThirdpartyData(thirdpartyid, email, type)
      })
      .catch((err) => console.log("err", err));
  }

  const HandleThirdpartyData = (ThirdpartyId, ThirdpartyEmail, type) => {
    const ThirdpartyData = {
      email_id: ThirdpartyEmail,
      thirdparty_id: ThirdpartyId,
      login_type: type,
      device_token: "abc",
      device_type: 3,
      device_id: localStorage.getItem('deviceId'),
      latitude: latitude,
      longitude: longitude
    }
    const encrypt = encryptData(ThirdpartyData);
    fetch(BASE_URL + "login_by_thirdparty", {
      method: 'POST',
      body: JSON.stringify({ "thirdpartyData": encrypt }),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if (response.Status === 0) {
          console.log(response, "Status0");
          toast.error(response.Message);
        }
        else if (response.Status === 1) {
          console.log(response, "Status1");
          toast.success(response.Message);
          const decrypt = decryptData(response.info);
          if (decrypt.is_account_setup === "YES") {
            localStorage.setItem("UserToken", response.UserToken)
            localStorage.setItem("UserId", decrypt.user_id);
            navigate("/homescreen");
          }
          else {
            navigate("/accountsetup", { state: { token: response.UserToken } });
          }
        }

      })
      .catch((error) => {
        console.log(error);

      });
  }


  return (
    <>
      <Container fluid className="login-bg">
        <Row>
          <Col lg={6} md={6} sm={12} className="m-part-1">
            <div className="lgn-rgt">
              <img src={logo} className="img-fluid img-1" alt="" />
              <img src={oneg8} className="img-fluid img-1" alt="" />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="m-part-2">
            <div className="lgn-left">
              <h5 className="lgn-head">Welcome to ONEG8</h5>
              <p className="lgn-acnt">Sing in to your account</p>

              <form action="" onSubmit={HandleSubmit} >
                <div className="floating-label-input">
                  <input id="email" type="email" className='new-put' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Email</label>
                </div>
                <div className="floating-label-input">
                  <input id="password" type={type} className='new-put' value={pass} minLength={8} onChange={(e) => setPass(e.target.value)} autoComplete="off" required placeholder=" " />
                  <label className="floating-label">Password</label>
                  <span className="pass-eye1">
                    <i onClick={show} className={icon}></i>
                  </span>
                </div>
                <h6 className="lgn-center text-center">
                  <span onClick={() => ForgotPassword()}>
                    Forgot Password ?
                  </span>
                </h6>
                <button type="submit" className="bt-1 mt-4">
                  {isloading ?
                    <Spinner className="loderImage" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> : "Sign in"}
                </button>
              </form>

              <p className="lgn-mid pt-4">Or</p>
              <h6 className="lgn-mid-1">Continue With</h6>
              <div className="lgn-footer">
                <img src={google} onClick={signInWithGoogle} className="img-fluid img-foot1 mt-2" />
                <img src={apple} onClick={signinapple} className="img-fluid img-foot1 mt-2" />
              </div>
              <p className="lgn-footer-sp pt-3">
                You don't have an account?{" "}
                <b style={{ color: "white", fontWeight: "500", cursor: "pointer", }} onClick={signUp}>Sign Up</b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal, Button, Breadcrumb, Form } from "react-bootstrap";
// import { useLazyQuery } from "react-redux-toolkit-query";
import { createApi } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import "../DataG8/DataG8.css";
import {
  useLazySocialMediaDataQuery,
  useUpdateSocialMediaFileNameMutation,
  useDeleteSocialMediaFromDg8Mutation,
} from "../apiService/index";
import { encryptData, decryptData, BASE_URL, IMG_URL } from "../API/ApiUrl";

export default function DataG8Home() {
  const [renameModel, setRenameModel] = useState(false);
  const [detailsModel, setDetailsModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [singleData, setSingleData] = useState(false);
  const [sigleMediaData, setSigleMediaData] = useState(false);

  const [renameFile, setRenameFile] = useState({
    fileId: "",
    orignalName: "",
  });
  const [deleteId, setDeleteId] = useState(false);
  const [showDataDiv, setShowDataDiv] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [noData, setNoData] = useState(false);
  const [mediaData, setMediaData] = useState("");
  const [showSingleMediaModal, setShowSingleMediaModal] = useState("");

  const [parameter, setParameter] = useState("");
  const [page, setPage] = useState(1);
  // const { data, error, isLoading, isSuccess } = useLazySocialMediaDataQuery(parameter);
  const [socialMediaData, data] = useLazySocialMediaDataQuery({
    parameter,
    page,
  });
  console.log(data);

  const [updateSocialMediaFileName] = useUpdateSocialMediaFileNameMutation();
  const [deleteSocialMediaFromDg8] = useDeleteSocialMediaFromDg8Mutation();

  const handelUploadFilesNew = (queryData, newPage) => {
    if (queryData !== parameter) {
      setPage(1);
      setMediaData([]);
      setHasMoreData(true);
      setNoData(false);
      setParameter(queryData);
    } else {
      setPage(newPage);
      setShowDataDiv(true);
    }
  };
  useEffect(() => {
    console.log(parameter);
    if (!parameter) return; // Skip if parameter is not set

    const fetchData = async () => {
      try {
        const res = await socialMediaData({ queryData: parameter, page });
        const newData = res.data.data;
        if (newData.length > 0) {
          setMediaData((mediaData) => [...mediaData, ...res.data.data]);
          setHasMoreData(true);
          setNoData(false);
        } else {
          setHasMoreData(false);
          setNoData(true);
        }
        setShowDataDiv(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error state if needed
      }
    };

    fetchData();

    // Clean up function
    return () => {
      // Cancel any ongoing requests or clean up if needed
    };
  }, [parameter, page]); // Run effect when parameter or page changes

  const setDetailsData = (item) => {
    setSingleData(item);
    setDetailsModel(true);
  };

  const setRenameData = (item) => {
    console.log(item);

    setRenameModel(true);
    setRenameFile({
      fileId: item["postImages"][0].imageId,
      orignalName: item["postImages"][0].orignalName,
    });
  };

  const deletePostFromDg8 = () => {
    let data = {
      body: { deletedIds: deleteId },
    };
    console.log(data);

    deleteSocialMediaFromDg8(data)
      .then((res) => {
        toast.success(res.data.message);
        setDeleteModel(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.error.data.message);
      });
  };
  const handleScroll1 = (e) => {
    setPage((page) => page + 1);
    handelUploadFilesNew(parameter, page + 1);
  };
  const showSingleData = (data) => {
    setSigleMediaData(data);
    setShowSingleMediaModal(true);
  };

  function renameApi() {
    if (renameFile.orignalName == "") return;

    let data = {
      body: { updateName: renameFile.orignalName },
      id: renameFile.fileId,
    };
    console.log(data);

    updateSocialMediaFileName(data)
      .then((res) => {
        toast.success(res.data.message);
        setRenameModel(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.error.data.message);
      });
  }
  const downlaodFileHandler = (item) => {
    const link = document.createElement("a");
    link.href = IMG_URL + item?.postImages[0].image
    link.download = item?.postImages[0].orignalName != '' ? item?.postImages[0].orignalName : 'oneg8Post';
    link.click();
  };

  const ImgDiv = ({ item, ind }) => {
    console.log(item);

    return (
      <>
        <div className="col-md-3 pt-3">
          <div
            className="cardForDrive cardColor"
            style={{ border: "none !important" }}

          >
            {item?.postImages[0].thumbnail == null ? (
              <img
                src={IMG_URL + item?.postImages[0].image}
                className="cardimg "
                alt="..."
                onClick={() => showSingleData(item)}
              />
            ) : (
              <img
                src={IMG_URL + item?.postImages[0].thumbnail}
                className="cardimg "
                alt="..."
              />
            )}

            <div className="card-body py-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle text-truncate">
                    {item?.postImages[0].orignalName
                      ? item?.postImages[0].orignalName
                      : "No Name"}
                  </h6>
                </div>
                <div className="col-2">
                  <Dropdown id="dropdown-data-option">
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className="option-menu">
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => setRenameData(item)}
                        >
                          <i className="fa-regular fa-pen-to-square px-2"></i>
                          Rename
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDeleteId(item.postId);
                            setDeleteModel(true);
                          }}
                        >
                          <i className="fa-solid fa-trash px-2"></i>
                          Delete permanently
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDetailsData(item);
                          }}
                        >
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="container mx-auto mt-4">
            <div className="datag8-row1 mt-3 p-3 text-center">
              <span className="text-white">Social Media</span>
            </div>
            {showDataDiv == false ? (
              <div className="row">
                <div
                  className="col-md-3 pt-3 pointer"
                  onClick={() => {
                    handelUploadFilesNew("Image", page);
                  }}
                >
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">Images</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-3 pt-3 pointer"
                  onClick={() => {
                    handelUploadFilesNew("Video", page);
                  }}
                >
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">Video</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pt-3 pointer">
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">Audios</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pt-3 pointer">
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">Documents</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-3 pt-3 pointer"
                  onClick={() => {
                    handelUploadFilesNew("REEL", page);
                  }}
                >
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">REELS</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-3 pt-3 pointer"
                  onClick={() => {
                    handelUploadFilesNew("VLOG", page);
                  }}
                >
                  <div
                    className="cardForDrive cardColor"
                    style={{ border: "none !important" }}
                  >
                    <div className="folderPadding text-center">
                      <i className="fa-solid fa-folder fa-4x iconMiddel"></i>
                    </div>
                    <div className="card-body py-3">
                      <div className="row">
                        <div className=" col-10 my-auto">
                          <h6 className="card-subtitle">VLOG</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className="container mx-auto mt-4 px-0"
                  style={{ height: "100vh", overflowY: "scroll" }}
                  id="imgDiv"
                >
                  <div className="row-color1 col-2 p-2 mx-0 text-white">
                    <span
                      className="pointer"
                      onClick={() => {
                        setShowDataDiv(false);
                      }}
                    >
                      Back
                    </span>
                    {" > " + parameter}
                  </div>
                  <div className="row">
                    {data.isLoading && (
                      <div>
                        {" "}
                        <h1 className="text-white">Loading...</h1>
                      </div>
                    )}
                    {data.isSuccess && (
                      <>
                       {mediaData && mediaData.length > 0 ? (
                            mediaData.map((item, index) => (
                                <ImgDiv key={index} item={item} ind={index} />
                            ))
                            ) : (
                            <h2 className="text-center pt-5">No media data available</h2>
                        )}
                        <InfiniteScroll
                          dataLength={mediaData.length || 0}
                          next={handleScroll1}
                          hasMore={hasMoreData}
                          loader={
                            noData == false ? (
                              <h4 className="text-center text-white">
                                Loading...
                              </h4>
                            ) : (
                              <h4 className="text-center text-white">
                                No Data found
                              </h4>
                            )
                          }
                          scrollableTarget="imgDiv"
                        />
                      </>
                    )}
                    {data.data.length == 0 && (
                        <div>No data found</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showSingleMediaModal}
        onClick={() => setShowSingleMediaModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sigleMediaData && (
            <>
              {parameter == "Image" && sigleMediaData ? (
                <img
                  className="modal-content-new"
                  src={IMG_URL + sigleMediaData?.postImages[0].image}
                  alt="Image"
                />
              ) : parameter == "Video" || ("REEL" && sigleMediaData) ? (
                <video
                  className="modal-content-new"
                  src={IMG_URL + sigleMediaData?.postImages[0].image}
                  controls
                    autoPlay
                />
              ) : (
                <div>no data</div>
              )}
              <div id="caption"></div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => downlaodFileHandler(sigleMediaData != '' ? sigleMediaData : '')}
          >
            Download
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowSingleMediaModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* rename model */}
      <Modal show={renameModel} onHide={() => setRenameModel(false)} centered>
        <div className="activity-disck">
          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Rename</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setRenameModel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <input
              type="text"
              className="i-put-1"
              required
              onChange={(e) =>
                setRenameFile({ ...renameFile, orignalName: e.target.value })
              }
              value={renameFile.orignalName}
            />
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <p onClick={renameApi}>Create</p>
        </div>
      </Modal>
      {/* end */}

      {/* details model */}
      <Modal show={detailsModel} onHide={() => setDetailsModel(false)} centered>
        <div className="activity-disck">
          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Details</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setDetailsModel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {singleData && (
              <div className="row pt-3">
                <div className="col-6 pb-2">Name:</div>
                <div className="col-6 pb-2">
                  {singleData.postImages[0].orignalName != null
                    ? singleData.postImages[0].orignalName
                    : "No Name"}
                </div>

                <div className="col-6 pb-2">Uploaded by:</div>
                <div className="col-6 pb-2">Self</div>

                <div className="col-6 pb-2">Location:</div>
                <div className="col-6 pb-2">Social Media</div>

                <div className="col-6 pb-2">Uploaded:</div>
                <div className="col-6 pb-2">
                  {singleData.postImages[0].createdAt}
                </div>

                <div className="col-6 pb-2">Modified:</div>
                <div className="col-6 pb-2">---</div>

                <div className="col-6 pb-2">Type:</div>
                <div className="col-6 pb-2">Image</div>

                <div className="col-6 pb-2">MIME type:</div>
                <div className="col-6 pb-2">image/jpg</div>

                <div className="col-6 pb-2">Size:</div>
                <div className="col-6 pb-2">
                  {singleData.postImages[0].fileSize != null
                    ? singleData.postImages[0].fileSize
                    : "---"}{" "}
                  MB
                </div>

                <div className="col-6 pb-2">Original size:</div>
                <div className="col-6 pb-2">
                  {singleData.postImages[0].fileSize != null
                    ? singleData.postImages[0].fileSize
                    : "---"}{" "}
                  MB
                </div>

                <div className="col-6 pb-2">Shared:</div>
                <div className="col-6 pb-2">No</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {/* end */}

      {/* delete folder model */}
      <Modal show={deleteModel} onHide={() => setDeleteModel(false)} centered>
        <div className="activity-disck pt-4 text-center">
          <div className="lgn-input-1">
            <h3>
              Are you sure you want to delete this data? <br />
              Once deleted, this data can no longer be restored!
            </h3>
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <span className="btn btn-danger" onClick={() => deletePostFromDg8()}>
            Delete
          </span>
        </div>
      </Modal>
      {/* end */}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLazyGetFileDirectoresQuery, useMoveFilesAndFolderMutation } from '../apiService';
import { getDriveTokenData, getSkipGeneratePassword } from '../util/cacheData';
import { useSelector,useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setCustomData } from '../apiService/customData';



const MoveModel = ({ moveData }) => {

    // console.log(moveData);
    const [moveToFolderModel, setMoveToFolderModel] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [folderData, setFolderData] = useState([]);
    const [page, setPage] = useState(1);
    const [currentFolderId, setCurrentFolderId] = useState(0);
    const [modelGetDataCount, setModelGetDataCount] = useState(1);
    const [moveHasMoreData, setMoveHasMoreData] = useState(true);
    const [breadCrumbData, setBreadCrumbData] = useState([
        {
          title: "Home",
          id: 0,
        },
      ]);
      const [selectedFolderData, setSelectedFolderData] = useState({
        name: null,
        id: 0,
      });
    
    const [selectedBreadCrumb, setSelectedBreadCrumb] = useState(null);
    const [getDataCount, setGetDataCount] = useState(1);

    const [getFileDirectores, fileDirectoresList] = useLazyGetFileDirectoresQuery();
    const [moveFilesAndFolder] = useMoveFilesAndFolderMutation();

  const dispatch = useDispatch();

    const customData = useSelector((state) => state.customData);


    useEffect(() => {
        setMoveToFolderModel(moveData.model);
        setSelectedFileId(moveData.selectedFileId);
        setCurrentFolderId(0);
        // setModelGetDataCount(1);
    }, [moveData]);

    useEffect(() => {
        // getFolderData();
        if (!getSkipGeneratePassword() && !getDriveTokenData()) {
            return;
        }
        getFileDirectores({ id: currentFolderId, page: modelGetDataCount, fileType: 2 }).then((res) => {
            // console.log(res);
            if (res?.data?.status) {
                setCurrentFolderId(res?.data?.currentFolderId);
                if (res?.data.page != 1) {
                    setFolderData([...folderData, ...res?.data?.data]);
                } else {
                    setFolderData(res?.data?.data);
                }

                if (selectedBreadCrumb) {
                    let breadData = JSON.parse(JSON.stringify(breadCrumbData));
                    breadData = breadData.slice(0, selectedBreadCrumb.index + 1);
                    setBreadCrumbData(breadData);
                    setSelectedBreadCrumb(null);
                  }
                  if (selectedFolderData?.name) {
                    let breadData = JSON.parse(JSON.stringify(breadCrumbData));
                    breadData.push({
                      title: selectedFolderData.name,
                      id: selectedFolderData.id,
                    });
                    setBreadCrumbData(breadData);
                    setSelectedFolderData({
                      name: null,
                      id: null,
                    });
                  }

            }
        }).catch((err) => {
            console.log(err);
            toast.error(err?.error?.data?.message);

        })
    }, [modelGetDataCount, customData?.drivePasswordIsExist, currentFolderId]);

    // const getFolderData=()=>{
    // }

    const moveFetchMoreData = () => {
        console.log("tes", modelGetDataCount);
        setModelGetDataCount(modelGetDataCount + 1);
    }

    useEffect(() => {
        if (folderData?.length >= fileDirectoresList?.data?.count)
            setMoveHasMoreData(false);
        else
            setMoveHasMoreData(true);
    }, [folderData]);

    const moveFolder = () => {

        moveFilesAndFolder({ folderId: +currentFolderId, fileId: selectedFileId }).then((res) => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setMoveToFolderModel(false)
      dispatch(setCustomData({...customData, movedFileId: selectedFileId}));

            }
        }).catch((err) => {
            toast.error(err?.error?.data?.message);

        })
        setMoveToFolderModel(false)
    }
    const changeBreadCrumb = (item, index) => {
        setSelectedBreadCrumb({
          item,
          index,
        });
        // currentFolderId
        setCurrentFolderId(item.id);
        setGetDataCount(1);
      };

    const FolderUi = ({ item, ind }) => {

        return (
            <>
                <div className="col-md-4 pt-3" key={ind}>
                    <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
                        <div className="folderPadding d-flex justify-content-center align-items-center"
                            onClick={() => { setCurrentFolderId(item.id); setModelGetDataCount(1) ;setGetDataCount(1);
                                setSelectedFolderData({ name: item.name, id: item.id });}}
                            // onDoubleClick={() => { setCurrentFolderId(item.id); setModelGetDataCount(1) }}
                        >
                            <i className="fa-solid fa-folder fa-4x"></i>
                        </div>
                        <div className="card-body py-2">
                            <div className="row">
                                <div className=" col-10 my-auto">
                                    <h6 className="card-subtitle limitchar">{item.name} </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

    return (
        <>
            {/* move folder model */}
            <Modal
                size="lg"
                show={moveToFolderModel} onHide={() => setMoveToFolderModel(false)}
                centered
            >
                <div className='activity-disck'>

                    <div className="lgn-input-1">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setMoveToFolderModel(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="datag8_modal-header pt-4">
                            <h3 className="modal-title">Move To a  Folder</h3>

                        </div>
                        <div className="modal-body pt-4">
                            <h5>Select a folder to move to</h5>
                            {
                                breadCrumbData.map((item,index)=> <span className="pointer" onClick={()=>changeBreadCrumb(item,index)} > {item.title} /</span> )
                            }
                            <div className='row' style={{ height: "50vh", overflow: 'scroll' }} id="moveScrollableDiv">
                                {
                                    folderData.length > 0 ?
                                        folderData.map((item, index) => {
                                            return selectedFileId != item.id ? <FolderUi key={index} item={item} ind={index} /> : null
                                        })
                                        :
                                        <>
                                            <h2>Empty Data</h2>
                                        </>
                                }

                            </div>

                        </div>
                        {/* <input type="text" className="i-put-1" required /> */}
                    </div>
                </div>
                <div className='row pro-modal-bottom text-center pt-5 px-5'>
                    <div className="col-6 text-left" data-dismiss="modal">
                        <button type="button" className="createbtn">Create new folder</button>
                    </div>
                    <div className="col-3 text-center" data-dismiss="modal">
                        <button type="button" onClick={() => setMoveToFolderModel(false)} className="btncancel">Close</button>
                    </div>
                    <div className="col-3 text-center" data-dismiss="modal">
                        <button onClick={moveFolder} type="button" className="btncreate">Move</button>
                    </div>

                </div>
                <InfiniteScroll
                    dataLength={folderData.length || 0}
                    next={moveFetchMoreData}
                    hasMore={moveHasMoreData}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="moveScrollableDiv"
                />
            </Modal >


            {/* end */}
        </>
    )
}

export default MoveModel;
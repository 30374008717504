import React, { useState } from "react";
import { useHMSActions } from "@100mslive/react-sdk";
import headlogo from "../assets/img/Sidebar/Group 1000003209.png"


const JoinForm = ({ setUserName }) => {
  const [name, setName] = useState("");
  const hmsActions = useHMSActions();

  const handleJoin = async () => {
    if (name.trim()) {
      await hmsActions.join({
        userName: name,
        authToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoyLCJ0eXBlIjoiYXBwIiwiYXBwX2RhdGEiOm51bGwsImFjY2Vzc19rZXkiOiI2NWMzNmEwYTBmNDVjMTc3OTg1YTIyODUiLCJyb2xlIjoiaG9zdCIsInJvb21faWQiOiI2Njc0MTJmZGM5Y2ZjMGY4ODA4OTc1MDciLCJ1c2VyX2lkIjoiNDU3MWI1M2EtODNhYy00Y2E2LWIwMzgtMzUwNzNiMjU1NWI2IiwiZXhwIjoxNzE5MDM5MDM2LCJqdGkiOiJjMjA3NDgwMy1jMDlmLTQ1ODAtODNkOS1lZWQyNjJiNTAxODAiLCJpYXQiOjE3MTg5NTI2MzYsImlzcyI6IjY1YzM2YTBhMGY0NWMxNzc5ODVhMjI4MyIsIm5iZiI6MTcxODk1MjYzNiwic3ViIjoiYXBpIn0.ic5uvmOR9iZB2kUrEun3xFxDBjqyIGPuPurImyIqpcE", // Replace with actual auth token
        settings: {
          isAudioMuted: true,
          isVideoMuted: true,
        },
      });
      setUserName(name); // Set user name in parent component
    }
  };

  return (
    <div className="conatainer">
        <div className="row justify-content-center">
            <div className="col-12 text-center mt-5">
                <img
                    className="logo"
                    src={headlogo}
                    alt="logo"
                    width={'30%'}
                />
            </div>
            <div className="col-6 text-center">
                <div className="join-form">
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                />
                <button className="btn btn-info mt-5" onClick={handleJoin}>Join</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default JoinForm;

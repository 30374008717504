import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/Sidebar/Group 1000003209.png";
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import { toast } from 'react-toastify';
import Spinner from "react-bootstrap/Spinner";
import { backgroundImage } from "../assets/base64/file";

export default function DeleteAccount() {

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState("bi bi-eye-slash-fill");
    const [isloading, setIsloading] = useState(false)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const HandleSubmit = (e) => {
        e.preventDefault()
        let param = {
            email_id: email,
            password: pass
        }
        const encrypt = encryptData(param);

        const newEmail = document.getElementById('email').value
        const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        if (regex.test(newEmail)) {
            setIsloading(true);
            fetch(BASE_URL + "delete_account", {
                method: 'POST',
                body: JSON.stringify({ "deleteData": encrypt }),
                headers: myHeaders,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        toast.error(response.Message);
                    } else {
                        console.log(response, "Yes");
                        toast.success(response.Message);
                        setEmail("")
                        setPass("")
                    }
                    setIsloading(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsloading(false)
                })
        }
        else {
            toast.error("Not valid your Email");
            setTimeout(() => {
                setIsloading(false)
            }, 1000);
        }
    }

    const show = () => {
        type === "password" ? setType("text") : setType("password");
        icon === "bi bi-eye-slash-fill"
            ? setIcon("bi bi-eye-fill")
            : setIcon("bi bi-eye-slash-fill");
    };


    return (
        <>
            <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <Row>
                    <Col lg={12} md={12} sm={12} className="text-light">
                        <div className="delete-box d-flex justify-content-center align-items-center">
                            <div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={logo} className="img-fluid img-delete" alt="" />
                                </div>
                                <div className="lgn-left">
                                    <div className="text-center mb-5">
                                        <h5 className="lgn-head fs-4">Delete Account</h5>
                                    </div>
                                    <form action="" onSubmit={HandleSubmit} >
                                        <div className="floating-label-input">
                                            <input id="email" type="email" name="email_id" value={email} onChange={(e) => setEmail(e.target.value)} className='new-put' autoComplete="off" required placeholder=" " />
                                            <label className="floating-label">Email</label>
                                        </div>
                                        <div className="floating-label-input">
                                            <input id="password" type={type} name="password" value={pass} onChange={(e) => setPass(e.target.value)} className='new-put' minLength={8} autoComplete="off" required placeholder=" " />
                                            <label className="floating-label">Password</label>
                                            <span className="pass-eye1">
                                                <i onClick={show} className={icon}></i>
                                            </span>
                                        </div>
                                        <button type="submit" className="bt-1 mt-4">
                                            {isloading ?
                                                <Spinner className="loderImage" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner> : "Delete"}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

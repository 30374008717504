import React, { useEffect, useState } from 'react'
import "./Notification.css"
import "../Search/SearchSidebar.css"
import { toast } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroll-component';
import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl'
import placeholderimg from "../assets/img/Sidebar/placeholder2.png"
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner'
import view from "../assets/img/Sidebar/view more.png"
import {
    useDeleteNotificationMutation
   }from '../apiService/index';

function Notification({isCallApiflag}) {
    console.log("===",isCallApiflag)

    let navigate = useNavigate()
    const [currPage, setCurrPage] = useState(1)
    const [totalPage, setTotalPage] = useState()

    const [show, setShow] = useState(false)
    const [isLoading, setIsLoadnig] = useState(false)

    const [notificationData, setNotificationData] = useState([])
    const [hasMoreData, setHasMoreData] = useState(true);
    const [noData, setNodata] = useState(false);
    const [callApiFlag, setCallApiFlag] = useState(false);

    const [deleteNotification] = useDeleteNotificationMutation();




    useEffect(() => {
        if(isCallApiflag == 1){
            handleNotification(currPage)
        }
    }, [isCallApiflag])

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));

    const handleViewMore = () => {
        if (currPage != totalPage) {
            handleNotification(currPage + 1)
        }
    }
    const handleScroll1 = (e) => {
        if (currPage != totalPage) {
            handleNotification(currPage + 1)
            setHasMoreData(true);
            setCurrPage(currPage + 1);
        } else {
            setHasMoreData(false);
        }
    }

    const handleNotification = (page) => {
        console.log("current page >>", page);
        setIsLoadnig(true)
        let jsonData = {
            page_no: page,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "list_notification", {
            method: 'POST',
            body: JSON.stringify({ "notificationData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoadnig(false)
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("decrypt >>", decrypt);
                    setCurrPage(page)
                    setTotalPage(decrypt.total_page)
                    if (decrypt.info.length == 0) {
                        setNodata(true)
                    }
                    if (page != 1) {
                        setNotificationData([...notificationData, ...decrypt.info])
                    } else {
                        setNotificationData(decrypt.info)
                    }
                    setIsLoadnig(false)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoadnig(false)
            })
    }

    const handleRequst = (id, type) => {
        setCurrPage(1)
        let jsonData = {
            follow_id: id,
            is_direct: type
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "update_follow_request_status", {
            method: 'POST',
            body: JSON.stringify({ "requestData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response);
                    if (type == 1) {
                        console.log("yes");
                    } else {
                        console.log("no");
                    }
                    handleNotification(currPage)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const notifySidebar = () => {

        if (show) {

            setShow(false)
            document.getElementById('notifylay').style.display = 'none'
            document.getElementById('notifyside').style.left = '-380px'

        } else {

            setShow(true)
            document.getElementById('notifylay').style.display = 'block'
            document.getElementById('notifyside').style.marginLeft = '300px'
            document.getElementById('notifyside').style.left = '0'

        }

    }

    const handleProfile = (id,type) => {
        if (type != 'post') {
            navigate("/userprofile/" + id)
            closeSidebar()
        }else{
            navigate("/singlePost/" + id)
            closeSidebar()
        }

    }

    const closeSidebar = () => {
        setShow(false)
        document.getElementById('notifylay').style.display = 'none'
        document.getElementById('notifyside').style.left = '-380px'
    }
    const deleteNotificationFunction = (id,index) => {
        let data = {
            body: { id: id.notification_id }
        }
        console.log(data)

        deleteNotification(data).then((res) => {
            toast.success(res.data.message);
            toast.success("Notification remove success", { theme: "dark" });
            // setDeleteModel(false);
            console.log(res)
            notificationData.splice(index, 1);

        })
        .catch((err) => {
            console.log(err);
            toast.error(err.error.data.message);
        })
    }
    function deleteAllNotification() {

        fetch(BASE_URL + "deleteAllNotification", {
            method: 'DELETE',
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.success == true) {
                    setNotificationData("");

                    toast.success("Notification remove success", { theme: "dark" });

                }else{
                    console.log(response)
                    toast.error("Somthing error", { theme: "dark" });

                }

            })
            .catch((error) => {
                console.log(error);
            })

    }



    return (
        <>
            <p className="m-0" id='notifiySidebar'></p>

            <div className='Notify-sidebar' id='notifyside'>
                <div className='nitify-title pt-4'>
                    <p className='notification-name'>Notifications</p>
                    <div className='text-end pointer' onClick={() => deleteAllNotification()}>
                        <span className="nitify-min">Delete All<i class="fa-regular fa-trash-can px-1"></i></span>
                    </div>
                </div>
                <div className='notify-today'>
                    {
                        notificationData == "" ?
                            <p className='text-center'>No Notifications detected</p>
                            :
                            <>
                                {
                                    notificationData.map((item, index) => {
                                        return (
                                            <div className='notify-part-1' key={index}>
                                                <div className="row">
                                            <div className='notify-delete-btn text-end pointer' onClick={() => deleteNotificationFunction(item,index)}><i className="fa-regular fa-circle-xmark px-1"></i></div>
                                                <div className='noti-fel' >
                                                <div className="col-2">
                                                    {
                                                        item.profile_pic == null ? (
                                                            < img src={placeholderimg} alt="" className='rounded' />
                                                        ) :
                                                            (<img onClick={() => handleProfile(item.user_id,'user')} src={IMG_URL + item.profile_pic} className='rounded' />)
                                                    }
                                                    </div>
                                                    <div className="col-8">
                                                    <p onClick={() => handleProfile(item.user_id,'user')} className='mx-1'>{item.notification_text} <br/><span className="nitify-min">
                                                        {moment(item.created_at).fromNow()}
                                                    </span></p>
                                                    </div>
                                                    <div className="col-2">
                                                    {
                                                        item.post_id != 0 ? (
                                                            (<img onClick={() => handleProfile(item.post_id,'post')} src={IMG_URL + item.thumbnail} className='rounded' />)
                                                        ) :
                                                           ''
                                                    }
                                                    </div>
                                                </div>
                                                {
                                                    item.other_id != 0 ?
                                                        <div className='nitify-btn text-center'>
                                                            <button className='nitify-reject-btn' onClick={() => handleRequst(item.other_id, 2)}>Reject</button>
                                                            <button className='nitify-accept-btn' onClick={() => handleRequst(item.other_id, 1)}>accept</button>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                    }

                    {/* <div className='d-flex justify-content-center'>
                        {
                            isLoading ?
                                <ThreeDots
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#fff"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                :
                                <>
                                    {
                                        currPage == totalPage || totalPage == 0 ? ""
                                            :
                                            <img src={view} width={150} onClick={handleViewMore} alt="" />
                                    }
                                </>
                        }
                    </div> */}
                </div >

                <InfiniteScroll
                    dataLength={notificationData.length || 0}
                    next={handleScroll1}
                    hasMore={hasMoreData}
                    loader={noData == false ? (
                        <h4 className='text-center text-white'>Loading...</h4>
                    ) : (
                        <h4 className='text-center text-white'>No Data found</h4>
                    )}
                    scrollableTarget="notifyside"
                />
            </div>
        </>
    )
}

export default Notification

import React, { useEffect, useState } from "react";
import "./Login.css";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import logo from "../assets/img/Login/ONEG8-Psd-white.png";
import oneg8 from "../assets/img/Login/ONEG8.png";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { encryptData, decryptData, BASE_URL } from '../API/ApiUrl';
import { backgroundImage } from "../assets/base64/file";

export default function Verification() {
  let navigate = useNavigate();
  let location = useLocation();

  const [otp, setOtp] = useState('')
  const [isloading, setIsloading] = useState(false)

  useEffect(() => {
    let utoken = localStorage.getItem("UserToken")

    if (utoken) {
      navigate("/homescreen")
    }
  }, [])

  let Type;
  let EmailId;
  let Token;
  try {
    const { type, email_id, UserToken } = location.state;
    Type = type;
    EmailId = email_id;
    Token = UserToken;
  } catch (e) {
    //id not found
  }

  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const jsonData = {
      temp_pass: otp,
      email_id: EmailId,
    }
    const encrypt = encryptData(jsonData);

    fetch(BASE_URL + "verification_for_email", {
      method: 'POST',
      body: JSON.stringify({ "verificationData": encrypt }),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(response => {
        if (response.Status === 0) {
          console.log(response, "no")
          toast.error(response.Message);
        } else {
          console.log(response, "yes")
          toast.success(response.Message);
          const decrypt = decryptData(encrypt);
          console.log("decrypt >>", decrypt);
          Type === 1 ? navigate('/accountsetup', { state: { token: Token } })
            : Type === 2 ? navigate('/resetpassword', { state: { email_id: EmailId } })
              : navigate("/accountsetup", { state: { token: Token } })

        }
        setIsloading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false)
      })
  }

  const ResendCode = (e) => {

    const jsonData1 = {
      email_id: EmailId,
    }

    e.preventDefault();
    setOtp("")
    const encrypt = encryptData(jsonData1);
    console.log('encrypt', encrypt);

    fetch(BASE_URL + "forgot_password", {
      method: 'POST',
      body: JSON.stringify({ "forgotData": encrypt }),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(response => {
        if (response.Status === 0) {
          console.log(response, "no")
          toast.error(response.Message);
        } else {

          console.log(response, "yes")
          toast.success(response.Message);
          const decrypt = decryptData(encrypt);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  return (
    <Container fluid className="login-bg" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row>
        <Col lg={6} md={6} sm={12} className="m-part-1">
          <div className="lgn-rgt">
            <img src={logo} className="img-fluid img-1" alt="" />
            <img src={oneg8} className="img-fluid img-1" alt="" />
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="m-part-2" >
          <div className="lgn-left">
            <h5 className="lgn-head">Verification</h5>
            <p className="lgn-acnt">
              We just sent a 4-digit code to your registered <br /> email.
            </p>


            <form action="" onSubmit={HandleSubmit} >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputType="tel"
                renderInput={(props) => <input {...props} required className="otp" />}
              />

              <div className="pt-5 ">
                <h6 className="lgn-mid-1" onClick={ResendCode}>Resend Code</h6>
              </div>

              <button type="submit" className="bt-1" style={{ marginTop: "30px" }}>
                {isloading ? <Spinner className="loderImage" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner> : "Verify"}
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar';
import LinkPreview from '../Message/linkpreview';
import InfiniteScroll from "react-infinite-scroll-component";
import DOMPurify from "dompurify";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import clsx from 'clsx';
import "./Message.css"
import { Link } from 'react-router-dom'
import setting from "../assets/img/Sidebar/Setting.png"
import human1 from "../assets/img/Sidebar/s-1.png"
import human2 from "../assets/img/Sidebar/s-2.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import human6 from "../assets/img/Sidebar/sub-pro.png"
import chat1 from "../assets/img/Sidebar/chat1.png"
import chat2 from "../assets/img/Sidebar/chat2.png"
import chat3 from "../assets/img/Sidebar/chat3.png"
import chat4 from "../assets/img/Sidebar/chat4.png"
import chat5 from "../assets/img/Sidebar/chat5.png"
import chat6 from "../assets/img/Sidebar/chat6.png"
import chat7 from "../assets/img/Sidebar/chat7.png"
import chat8 from "../assets/img/Sidebar/chat8.png"
import send from "../assets/img/Sidebar/send.png"
import multi from "../assets/img/Sidebar/multi-img.png"
import uploadimg from "../assets/img/Sidebar/upload.png"
import upload from "../assets/img/Sidebar/Upload 1.png"
import MemberSidebar from './MenberSidebar'
import GroupAdmin from '../GroupAdmin/GroupAdmin';
import Modal from 'react-bootstrap/Modal';
import mans1 from "../assets/img/Sidebar/sub-pro.png"
import back from "../assets/img/Sidebar/back-arrow.png"
import iconmessage from "../assets/img/Sidebar/sms-notification.png"
import trash from "../assets/img/Sidebar/trash.png"
import edit from "../assets/img/Sidebar/edit-2.png"
import share from "../assets/img/Sidebar/Arrow - Top(12).png"
import { Socket } from '../assets/Socket/Socket';
import { encryptData, decryptData, BASE_URL, IMG_URL, decryptDataConvertForChat, encryptDataConvertForChat, generateChatKey } from '../API/ApiUrl';
import placeholderimg from "../assets/img/Sidebar/placeholder1.png"
import { json, useLocation, useNavigate } from 'react-router-dom';
import InputEmoji from "react-input-emoji";
import moment from 'moment';
import cancel from "../assets/img/Sidebar/cancel.png"
import { Circles } from 'react-loader-spinner';
import axios from 'axios';
import options from "../assets/img/Sidebar/option(2).png"
import replys from "../assets/img/Sidebar/reply.png"
import EmojiPicker from 'emoji-picker-react';
import delete1 from "../assets/img/Sidebar/m-3.png"
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from "react-bootstrap/Spinner";
import { toast } from 'react-toastify';
import { Theme } from 'emoji-picker-react';

function Message() {
    let navigate = useNavigate()
    let location = useLocation()
    const ref = useRef(null);
    const [isFlag, setisFlag] = useState(1);
    const [newFlag, setNewflag] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageChatroom, setCurrentPageChatroom] = useState(1);

    const [currPage, setCurrPage] = useState(1);
    const [totalpage, setTotalpage] = useState();
    const [totalspage, setTotalspage] = useState();
    const [activeservicid, setActiveservicid] = useState(0);

    const [room, setRoom] = useState(false)
    const [addMember, setAddMemner] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isLoaders, setIsLoaders] = useState(false)
    const [copy, setCopy] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isArchive, setIsArchive] = useState(false)


    const [searchUser, setSearchUser] = useState("")
    const [searchGroup, setSearchGroup] = useState("")
    const [isChatId, setIsChatId] = useState("")
    const [page, setPage] = useState("")
    const [isChatroomId, setIsChatroomId] = useState("")
    const [isOtherId, setIsOtherId] = useState("")
    const [isMessageId, setIsMessageId] = useState("")
    const [isIndex, setIsIndex] = useState("")
    const [chatIndex, setChatIndex] = useState("")
    const [messageText, setMessageText] = useState("")
    const [replyMsg, setReplyMsg] = useState("")
    const [groupImg, setGroupImg] = useState("")
    const [groupImage, setGroupImage] = useState("")
    const [secKey, setSecKey] = useState("")
    const [secIv, setSecIv] = useState("")
    const [key, setKey] = useState("")

    const [userId, setUserId] = useState("")
    const [iv, setIv] = useState("")
    const [userKey, setUserKey] = useState("")
    const [userIv, setUserIv] = useState("")
    const [chatrromKey, setChatroomKey] = useState("")
    const [chatroomIv, setChatroomIv] = useState("")
    const [account, setAccount] = useState("PRIVATE")
    const [optionRoom, setOptionRoom] = useState("GROUP")
    const [flagForChatGr, setFlagForChatGr] = useState("USER")
    // /ANNOUNCEMENT
    const [chatroomInfo, setChatroomInfo] = useState({})
    const [profiles, setProfiles] = useState({})
    const [roomDatas, setRoomDatas] = useState({})

    const [userData, setUserData] = useState([])
    const [groupData, setGroupData] = useState([])
    const [messages, setMessages] = useState([])
    const [chatrooms, setChatrooms] = useState([])
    const [type, setType] = useState([])
    const [img, setImg] = useState([])
    const [data, setData] = useState([])
    const [thumbnailList, setThumbnailList] = useState([]);
    const [uesrList, setUserList] = useState([])
    const [memberId, setMenberId] = useState([])
    const [hasMoreData, setHasMoreData] = useState(true);
    const [hasMoreDataChatroom, setHasMoreDataChatroom] = useState(true);
    const [likeListModal, setLikeListModal] = useState(false);
    const [singleMessage, setSingleMessage] = useState([]);
    const [binaryData, setBinaryData] = useState('');
    const [binaryDataMain, setBinaryDataMain] = useState('');



    const [isPagination, setIsPagination] = useState(true);

    const [file, setFile] = useState(null);
    const [base64, setBase64] = useState('');


  const [lgShow, setLgShow] = useState(false);
  const [decryptMediaModel, setDecryptMediaModel] = useState(false);
  const [isLoding, setIsLoding] = useState(true);
  const [fileType, setFileType] = useState("");
  const [decryptMediaData, setDecryptMediaData] = useState("");
  const [fileName, setFileName] = useState("");








    const utoken = localStorage.getItem("UserToken")

    let my_user_id = localStorage.getItem("UserId")
    let chatId;
    let chatData;
    let chatflag;
    let groupId;
    try {

        const { DATA, Id, flag, grId } = location.state;
        chatId = Id;
        chatData = DATA;
        chatflag = flag;
        groupId = grId;
        // setNewflag(flag)
        // setIsChatId(Id)
    } catch (error) {
        //error
    }


    const [activePicker, setActivePicker] = useState(null);
    const pickerRef = useRef(null);
    const [hoveredMessage, setHoveredMessage] = useState(null);
    const handleMouseEnter = (messageId) => {
        setHoveredMessage(messageId);
    };

    const handleMouseLeave = () => {
        setHoveredMessage(null);
    };

    useEffect(() => {
        //for colse emoji box
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setActivePicker(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onEmojiClick = (event, messageId, oldmessage, index) => {
        console.log(event);
        console.log(messageId);
        console.log(index);
        console.log(activeservicid)

        // oldmessage.reaction_text_by = event.emoji
        messages[index].reaction_text_to = event.emoji
        setActivePicker(null);

        var message = encryptDataConvertForChat(event.emoji, userKey, userIv);
        let jsonData = {
            chat_id: activeservicid,
            message_id: messageId,
            reaction_text: message,
        }
        console.log(jsonData)
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "send_chat_reaction", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(async response => {
                // setIsLoader(false)

            })
            .catch((error) => {
                console.error("Error fetching chat messages:", error);
                setIsLoader(false);
            });

    };
    const onEmojiClickForGroup = (event, messageId, oldmessage, index) => {
        console.log(event);
        console.log(messageId);
        console.log(index);
        console.log(activeservicid)
        console.log(chatrooms[index])

        // oldmessage.reaction_text_by = event.emoji
        // chatrooms[index].reaction_text_to = event.emoji
        setActivePicker(null);
        var message = encryptDataConvertForChat(event.emoji, key, iv);
        let jsonData = {
            chatroom_id: activeservicid,
            message_id: messageId,
            reaction_text: message,
        }
        console.log(jsonData)
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "send_chatroom_reaction", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(async response => {
                // setIsLoader(false)
                console.log(response)
                // setIsChatId(activeservicid)
            handleChatroomMessage(2, currentPageChatroom, isChatId, key, iv);

                // handleChatroomMessage(currentPageChatroom);

            })
            .catch((error) => {
                console.error("Error fetching chat messages:", error);
                setIsLoader(false);
            });

    };
    function openllikeListModal(item) {
        // return false;
        console.log(item)
        setSingleMessage(item.message_reaction);
        setLikeListModal(true)
        console.log(singleMessage)

    }

    useEffect(() => {
        // Function to call the API
        const fetchData = async () => {
            setTimeout(() => {
                // setNewflag(1)
                // setIsChatId(chatId)
            }, 3000);


        };

        if (chatflag) {
            fetchData();
        }
    }, [chatflag]);

    useEffect(() => {
        console.log('3')

        Socket.on("connect", () => {
            console.log('soket connection', Socket.connected, Socket.id);
        });

        Socket.emit("socket_register", {
            user_id: my_user_id,
        });

        Socket.on("count_update", (data) => updateCount(data));
        Socket.on('online', (data) => {
            // console.log('online',data)
            let userlist = [...userData];
            // console.log(userlist)
            const index = userlist.findIndex(user => user.other_id === data.user_id);
            // console.log('index',index)

            if (index !== -1) {
                console.log(index)
                userlist[index].is_online = 1;
                setUserData(userlist)
            } else {
                // console.log('User not found',data.user_id);
            }

        })
        Socket.on('offline', (data) => {
            // console.log('offline',data)
            let userlist = [...userData];
            const index = userlist.findIndex(user => user.other_id === data.user_id);
            // console.log('index',index)
            if (index !== -1) {
                userlist[index].is_online = 0;
                setUserData(userlist)
            } else {
                // console.log('User not found');
            }
        })
        Socket.on("new_message", async (data) => {
            console.log("new_message", data);
            if (isChatId) {
                if (isChatId === data.info.chat_id) {
                    var decrypt_msg = await decryptDataConvertForChat(data.info.message_text, userKey, userIv)
                    data.info.message_text = decrypt_msg;
                    setMessages([data.info, ...messages]);
                } else {
                    setMessages([...messages]);
                }
            }
            console.log(messages);

            if (isChatroomId) {
                if (isChatroomId === data.info.chatroom_id) {
                    var decrypt_msg = await decryptDataConvertForChat(data.info.message_text, key, iv)
                    data.info.message_text = decrypt_msg;
                    setChatrooms([data.info, ...chatrooms]);
                } else {
                    setChatrooms([...chatrooms]);
                }
            }
        });

        Socket.on("delete_message", (data) => {
            console.log("delete_message", data);
            let deleteData = messages.filter(obj => obj.message_id != data.message_id)
            setMessages(deleteData)
        });

        Socket.on("edit_message", async (data) => {
            console.log("edit_message", data);
            for (let i = 0; i < messages.length; i++) {
                if (data.message_id == messages[i].message_id) {
                    if (isFlag == 1) {
                        var decrypt_msg = await decryptDataConvertForChat(data.message_text, userKey, userIv)
                    } else {
                        var decrypt_msg = await decryptDataConvertForChat(data.message_text, key, iv)
                    }
                    messages[i].message_text = decrypt_msg;
                    messages[i].is_update = 1;
                }
            }
        });

    }, [Socket, messages, chatrooms, isChatId,userData])

    useEffect(() => {
        console.log('4')
        handleListChatUser(currentPage)
    }, [searchUser])

    // useEffect(() => {
    //     console.log('4')
    //     handleListChatUserForArchive(currentPage)
    // }, [searchUser])


    useEffect(() => {
        console.log('5')

        handleListChatroom(currentPageChatroom)
    }, [searchGroup, flagForChatGr])

    useEffect(() => {
        console.log('6')

        if (messages.length && currentPage === 1) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: "end" });
        }
        if (chatrooms.length && currentPageChatroom === 1) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: "end" });
        }
    }, [messages, chatrooms])






    const updateCount = (datas) => {
        console.log("update count", datas);
        let is_update = 0;
        let chat = [...userData]
        let groupChat = [...groupData]

        if (datas.chatroom_id) {
            groupChat.map((item, index) => {
                console.log(item.chat_id, datas.chat_id)
                if (item.chat_id === datas.chat_id) {
                    groupChat[index] = datas
                    is_update = 1;
                    groupChat[index].unread_count += 1
                }
                if (groupChat.length - 1 === index) {
                    if (is_update === 1) {
                        setGroupData(groupChat)
                    } else {
                        setGroupData([datas, ...groupChat])
                    }

                }

            })
        }
        else {
            chat.map((item, index) => {
                console.log(item.chat_id, datas.chat_id)
                if (item.chat_id === datas.chat_id) {
                    chat[index] = datas
                    is_update = 1;
                    chat[index].unread_count += 1
                }
                if (chat.length - 1 === index) {
                    if (is_update === 1) {
                        setUserData(chat)
                    } else {
                        setUserData([datas, ...chat])
                    }

                }

            })
        }




        // if(messages.length != 0){
        //     setMessages([messages, ...datas])

        // }
    }

    const handleUser = (id) => {
        navigate(`/userprofile/${id}`)
    }

    const handleChatroomProfile = () => {
        let jsonData = {
            chatroom_id: isChatroomId
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "edit_chatroom", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("chat profile >>", decrypt);
                    setProfiles(decrypt.info)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaders(false)
            })
    }

    const handleAdmin = () => {
        handleChatroomProfile()
        document.getElementById('GroupAdmin').click()
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", utoken);

    // get chat user for archive chat
    const handleListChatUserForArchive = (page) => {

        setNewflag(0)


        let userlist = [...userData];
        userlist = [];
        setUserData(userlist);

        setIsArchive(true)
        let jsonData = {
            page_no: page,
            search_text: searchUser,
            is_archive:1
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "get_list_chat_user", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("chat user >>", decrypt);

                    if (decrypt.info.length != 0) {
                        if (page != 1) {
                            // setCurrentPage(page);
                            setUserData([...decrypt.info.reverse(), ...userData])
                        }
                        else {

                            setUserData(decrypt.info)
                            setTotalpage(decrypt.total_page);
                            handleChatroom(decrypt.info)
                        }
                    }

                    setIsLoaders(false)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaders(false)
            })
    };

    // ChatUser list API
    const handleListChatUser = (page) => {
        setIsLoaders(true)
        let jsonData = {
            page_no: page,
            search_text: searchUser
        }
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "get_list_chat_user", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoaders(false)
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("chat user >>", decrypt);

                    if (decrypt.info.length != 0) {
                        if (page != 1) {
                            // setCurrentPage(page);
                            setUserData([...decrypt.info.reverse(), ...userData])
                        }
                        else {
                            setUserData(decrypt.info)
                            setTotalpage(decrypt.total_page);
                            handleChatroom(decrypt.info)
                        }
                    }

                    setIsLoaders(false)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaders(false)
            })
    };

    const handleListChatroom = (page) => {
        setIsLoaders(true)
        let jsonData = {
            is_group: flagForChatGr,
            page_no: page,
            search_text: searchGroup
        }
        console.log("////", jsonData)
        // ANNOUNCEMENT
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "get_list_chatroom", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoaders(false)
                } else {
                    // setCurrentPageChatroom(page);
                    const decrypt = decryptData(response.key);
                    console.log("chatroom list >>", decrypt.info);
                    setGroupData(decrypt.info)
                    setIsLoaders(false)

                    console.log('chatData', decrypt.info[0])
                    handleChatroom(decrypt.info)


                    let ketDecrypt = decryptDataConvertForChat(decrypt.info[0].chatroom_sec, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                    let ivDecrypt = decryptDataConvertForChat(decrypt.info[0].chatroom_iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                    setKey(ketDecrypt)
                    setIv(ivDecrypt)

                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaders(false)
            })
    };

    const handleChatroom = (data) => {
        console.log(data, chatId, chatflag)
        if (data) {

            if (chatflag == 1) {

            } else {

                if (chatData.chat_sec != "" && chatData.chat_iv != "") {
                    let ketDecrypt = decryptDataConvertForChat(chatData.chat_sec, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                    let ivDecrypt = decryptDataConvertForChat(chatData.chat_iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                    setKey(ketDecrypt)
                    setIv(ivDecrypt)
                }
                else {
                    const data = generateChatKey();
                    const secretKey = encryptDataConvertForChat(data.key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur");
                    const secretIv = encryptDataConvertForChat(data.iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                    handleUpdateCredentials(chatId, secretKey, secretIv)
                }
            }


            data.forEach((element, index) => {
                if (element.chat_id === chatId) {
                    // alert('2')
                    console.log("chatroon function call");
                    Socket.emit("join_room", {
                        user_id: my_user_id,
                        chat_id: chatId,
                    });
                    setNewflag(1)
                    setIsIndex(index)
                    handleChatMessage(2, 1, chatId)
                }
            })

        }
    }

    // Message API
    // const handleChatMessage = (type, page, id) => {
    //     // setIsLoader(true)
    //     setData([])
    //     setImg([])
    //     setActiveservicid(id)
    //     let jsonData = {
    //         page_no: page,
    //         chat_id: id
    //     }
    //     const encrypt = encryptData(jsonData);
    //     fetch(BASE_URL + "get_chat_messages", {
    //         method: 'POST',
    //         body: JSON.stringify({ "chatData": encrypt }),
    //         headers: myHeaders,
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             if (response.Status === 0) {
    //                 console.log(response, "no")
    //                 setIsLoader(false)
    //             } else {
    //                 const decrypt = decryptData(response.key);
    //                 console.log("chatroom message >>", decrypt.info);
    //                 console.log('key, iv',key, iv)

    //                 decrypt.info.forEach(async (item, index) => {
    //                     if(item.message_type == 3){
    //                         var decImg = await handleRead(IMG_URL + item.message_text,index)
    //                         decrypt.info[index].message_text = decImg.imageUrl;
    //                     }
    //                 });

    //                 setTotalpage(decrypt.total_page)
    //                 console.log('page====',page,decrypt.total_page)
    //                 console.log("decrypt.info.length",decrypt.info.length)

    //                 if (decrypt.info.length != 0 ) {
    //                     if (page != 1) {
    //                         setMessages([...messages, ...decrypt.info])
    //                         // setMessages([...decrypt.info.reverse(), ...messages])
    //                         console.log('========2',messages);
    //                     }
    //                     else {
    //                         setMessages(decrypt.info)
    //                         // setMessages(decrypt.info.reverse())
    //                     }
    //                     setHasMoreData(true);

    //                 }else{
    //                     setHasMoreData(false);

    //                 }
    //             }
    //             console.log('Messages',messages)
    //             setIsLoader(false)
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setIsLoader(false)
    //         })
    // }


    const handleChatMessage = (type, page, id) => {
        // setIsLoader(true)
        // return console.log(type, page, id,key,iv)
        setData([]);
        setImg([]);
        setActiveservicid(id);
        let jsonData = {
            page_no: page,
            chat_id: id
        };
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "get_chat_messages", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(async response => {
                // setIsLoader(false)
                if (response.Status === 0) {
                    console.log(response, "no");
                    setIsLoader(false);
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("chatroom message >>", decrypt.info);
                    // Create an array to store all the image decryption promises
                    const decryptionPromises = decrypt.info.map(async (item, index) => {
                        if (item.message_type == 3) {
                            try {
                                var decImg = await handleRead(IMG_URL + item.message_text, index);
                                console.log(decImg.imageUrl)
                                decrypt.info[index].message_text = decImg.imageUrl;
                            } catch (error) {
                                console.error("Error fetching image URL:", error);
                            }
                        } if (item.message_type == 1) {
                            //mesg decryption
                            var decrypt_msg = decryptDataConvertForChat(item.message_text, key, iv)
                            var emojiDecryptby = item.reaction_text_by != null ? decryptDataConvertForChat(item.reaction_text_by, key, iv) : '';
                            var emojiDecryptto = item.reaction_text_to != null ? decryptDataConvertForChat(item.reaction_text_to, key, iv) : '';
                            decrypt.info[index].message_text = decrypt_msg;
                            decrypt.info[index].reaction_text_by = emojiDecryptby;
                            decrypt.info[index].reaction_text_to = emojiDecryptto;
                        }

                    });

                    // Wait for all image decryption promises to resolve
                    await Promise.all(decryptionPromises);

                    setTotalpage(decrypt.total_page);
                    console.log('page====', page, decrypt.total_page);
                    console.log("decrypt.info.length", decrypt.info.length);

                    if (decrypt.info.length != 0) {
                        if (page != 1) {
                            setMessages(prevMessages => [...prevMessages, ...decrypt.info]);
                            // setMessages([...decrypt.info.reverse(), ...messages])
                            console.log('========2', messages);
                        } else {
                            setMessages(decrypt.info);
                            // setMessages(decrypt.info.reverse())
                        }
                        setHasMoreData(true);
                    } else {
                        setHasMoreData(false);
                    }
                }
                console.log('Messages', messages);
                setIsLoader(false);
            })
            .catch((error) => {
                console.error("Error fetching chat messages:", error);
                setIsLoader(false);
            });
    }




    const onClickHandlerForChatroom = (type, page, id, key, iv) => {

        let decryptedkey = decryptDataConvertForChat(key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
        let decryptediv = decryptDataConvertForChat(iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
        setCurrentPageChatroom(1)
        setKey(decryptedkey)
        setIv(decryptediv)
        setIsChatId(id);
    };
    useEffect(() => {
        console.log('1')
        if (key && iv && isChatId) {
            handleChatroomMessage(2, currentPageChatroom, isChatId, key, iv);
        }
    }, [key, iv, isChatId, currentPageChatroom]);

    useEffect(() => {
        console.log('2')

        if (userKey && userIv && userId) {
            handleChatMessage(type, currentPage, userId, userKey, userIv);
        }
    }, [userKey, userIv, userId, currentPage]);

    const onClickHandlerForUser = (type, page, id, key, iv) => {
        // return console.log(key,iv);
        let decryptedkey = decryptDataConvertForChat(key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
        let decryptediv = decryptDataConvertForChat(iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
        setCurrentPage(1);
        setUserKey(decryptedkey);
        setUserIv(decryptediv);
        setUserId(id);
    };


    const handleChatroomMessage = (type, page, id) => {
        // setIsLoader(true)
        // setHasMoreDataChatroom(true);
        setData([])
        setImg([])
        setActiveservicid(id)
        setIsChatId(id);
        let jsonData = {
            page_no: page,
            chatroom_id: id
        }
        console.log(jsonData);
        const encrypt = encryptData(jsonData);
        fetch(BASE_URL + "get_chatroom_messages", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(async response => {
                if (response.Status === 0) {
                    console.log(response, "no");
                    setIsLoader(false);
                } else {
                    var decrypt = decryptData(response.key);
                    console.log("chatroom message >>", decrypt.info);
                    // console.log('key, iv',key, iv)

                    // Create an array to store all the image decryption promises


                    const decryptionPromises = decrypt.info.map(async (item, index) => {
                        console.log(item)
                        if (item.message_type == 3) {
                            try {
                                var decImg = await handleRead(IMG_URL + item.message_text, index);
                                // Update message_text to imageUrl
                                const decryptedReactions = item?.message_reaction.map((element) => {
                                    const decrypt_emoji = decryptDataConvertForChat(element.reaction_text, key, iv);
                                    return { ...element, reaction_text: decrypt_emoji };
                                });
                                return { ...item, message_text: decImg.imageUrl,message_reaction: decryptedReactions };
                            } catch (error) {
                                console.error("Error fetching image URL:", error);
                                return item;
                            }
                        }
                        if (item.message_type == 2) {
                            try {
                                // Update message_text to imageUrl
                                const decryptedReactions = item?.message_reaction.map((element) => {
                                    const decrypt_emoji = decryptDataConvertForChat(element.reaction_text, key, iv);
                                    return { ...element, reaction_text: decrypt_emoji };
                                });
                                return { ...item,message_reaction: decryptedReactions };
                            } catch (error) {
                                console.error("Error fetching image URL:", error);
                                return item;
                            }
                        }
                        if (item.message_type == 1) {
                            // var decImg = await handleRead(IMG_URL + item.message_text, index);
                            // var decrypt_msg = decryptDataConvertForChat(item.message_text, key, iv)
                            // item?.message_reaction.forEach((element, index1)  => {

                            //     var decrypt_emoji = decryptDataConvertForChat(element.reaction_text, key, iv)
                            //     element[index1].reaction_text = decrypt_emoji;

                            // });

                            const decryptedReactions = item?.message_reaction.map((element) => {
                                const decrypt_emoji = decryptDataConvertForChat(element.reaction_text, key, iv);
                                return { ...element, reaction_text: decrypt_emoji };
                            });
                            // console.log('decryptedReactions',decryptedReactions)

                            if (item.is_encrypted == 1) {
                                var decrypt_msg = decryptDataConvertForChat(item.message_text, key, iv)
                            }else{
                                var decrypt_msg = item.message_text;
                            }

                            // Update message_text to imageUrl
                            return { ...item, message_text: decrypt_msg, message_reaction: decryptedReactions };
                        }
                        return item;
                    });

                    // Wait for all image decryption promises to resolve
                    const decryptedMessages = await Promise.all(decryptionPromises);
                    setChatrooms(prevChatrooms => {
                        return page !== 1 ? [...prevChatrooms, ...decryptedMessages] : decryptedMessages;
                    });

                    setTotalspage(decrypt.total_page);
                    setHasMoreDataChatroom(decrypt.info.length !== 0);
                }
                setIsLoader(false);
                // if (response.Status === 0) {
                //     console.log(response, "no")
                //     setIsLoader(false)
                // } else {
                //     var decrypt = decryptData(response.key);
                //     console.log("chatroom message >>", decrypt.info);


                //     decrypt.info.forEach(async (item, index) => {
                //         if(item.message_type == 3){
                //             var decImg = await handleRead(IMG_URL + item.message_text,index)
                //             decrypt.info[index].message_text = decImg.imageUrl;
                //         }
                //     });


                //     setTotalspage(decrypt.total_page)
                //     if (decrypt.info.length != 0 ) {
                //         if (page != 1) {
                //             setChatrooms([...chatrooms, ...decrypt.info])
                //         }
                //         else {
                //             setChatrooms(decrypt.info)
                //         }
                //         setHasMoreDataChatroom(true);

                //     }else{
                //         setHasMoreDataChatroom(false);

                //     }

                // }
                // console.log("Chatrooms",chatrooms)
                // setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false)
            })
    }

    const handleUpdateCredentials = (id, key, iv) => {
        let param = {
            chat_id: id,
            chat_sec: key,
            chat_iv: iv
        }

        let encrypt = encryptData(param)
        fetch(BASE_URL + "update_chat_credentials", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoader(false)
                } else {
                    console.log("response", response);
                    const decrypt = decryptData(response.key);
                    console.log("decrypt >>", decrypt);
                }
                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false)
            })
    }

    const handleFileSend = async (images) => {
        setData([...data, ...images]);
        const imageList = [...img];
        const typeList = [...type];
        const thumbnailLists = [...thumbnailList];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            if (file.type.includes('image')) {
                const reader = new FileReader();
                const imagePromise = new Promise((resolve) => {
                    reader.onload = (event) => {
                        imageList.push(event.target.result);
                        typeList.push(1);
                        resolve();
                    };
                });
                reader.readAsDataURL(file);
                await imagePromise;

                // Create thumbnail for image
                const imgElement = new Image();
                const thumbnailPromise = new Promise((resolve) => {
                    imgElement.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = 150; // Thumbnail width
                        canvas.height = 150; // Thumbnail height
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
                        canvas.toBlob((blob) => {
                            thumbnailLists.push(blob);
                            resolve();
                        }, 'image/jpeg');
                    };
                });
                imgElement.src = URL.createObjectURL(file);
                await thumbnailPromise;

            } else if (file.type.includes('video')) {
                try {
                    const thumbnail = await generateThumbnailFromVideo(file);
                    let videoFile = base64ToFile(thumbnail, "example.jpg", 'image/jpeg');
                    imageList.push(URL.createObjectURL(file));
                    typeList.push(2);
                    thumbnailLists.push(videoFile);
                } catch (error) {
                    console.error('Error generating thumbnail:', error);
                }
            }
        }
        setImg(imageList);
        setType(typeList);
        setThumbnailList(thumbnailLists);
    };

    const base64ToFile = (base64String, filename, mimeType) => {
        const [contentType, base64Content] = base64String.split(',');
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType || mimeType });
        const file = new File([blob], filename, { type: contentType || mimeType });
        return file;
    };

    const generateThumbnailFromVideo = (videoFile) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = async () => {
                const duration = video.duration;
                const middleTime = duration / 2;
                video.currentTime = middleTime;
                await new Promise((resolve) => {
                    video.onseeked = resolve;
                });
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnailData = canvas.toDataURL('image/jpeg');
                resolve(thumbnailData);
            };
            video.onerror = (error) => {
                reject(error);
            };
            video.src = URL.createObjectURL(videoFile);
        });
    };

    const imageRemove = (index) => {
        let image = [...img]
        image.splice(index, 1)
        setImg(image)
        let images = [...data]
        images.splice(index, 1)
        setData(images)
        let typ = [...type]
        typ.splice(index, 1)
        setType(typ)
    }


    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const base64String = reader.result;
    //             setBase64(base64String);

    //             // Create a text file from the base64 string
    //             const textFile = new File([base64String], 'image_base64.txt', { type: 'text/plain' });
    //             setFile(textFile);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    // function convertToBase64(file) {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             resolve(reader.result);
    //         };
    //         reader.onerror = reject;
    //         reader.readAsDataURL(file);
    //     });
    // }
    // const arrayBufferToBinary = (buffer) => {
    //     const uint8Array = new Uint8Array(buffer);
    //     return uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
    // };


    const readFileAsBinaryString = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                const binaryString = arrayBufferToBinary(arrayBuffer);
                resolve(binaryString);
            };
            reader.onerror = (error) => {
                reject('Error reading file: ' + error);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    // Function to convert ArrayBuffer to binary string
    const arrayBufferToBinary = (buffer) => {
        const uint8Array = new Uint8Array(buffer);
        return uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
    };

    // Function to read and encrypt file
    const readFileAndEncrypt = async (file, userKey, userIv) => {
        const binaryString = await readFileAsBinaryString(file);
        const base64convert = btoa(binaryString);
        const encryptedData = encryptDataConvertForChat(base64convert, userKey, userIv);
        return new File([encryptedData], 'image_base64.txt', { type: 'text/plain' });
    };

    // Send Message API
    // const handleSendMessage1 = async (e) => {
    //     e.preventDefault()

    //     setData([]);
    //     setImg([]);
    //     setType([]);
    //     setMessageText("");
    //     console.log("data", data)
    //     if (data.length != 0) {
    //         let formData = new FormData();
    //         if (data.length) {

    //             // for (let i = 0; i < data.length; i++) {
    //             //     formData.append('image', data[i])
    //             // }
    //             for (let i = 0; i < data.length; i++) {
    //                 const file = data[i];

    //                 if (file.type.startsWith('image/')) {

    //                     const reader = new FileReader();
    //                     reader.onload = () => {
    //                         const arrayBuffer = reader.result;
    //                         const binaryString = arrayBufferToBinary(arrayBuffer);
    //                         // console.log(binaryString);
    //                          const base64convert = btoa(binaryString);
    //                         const encrypt = encryptDataConvertForChat(base64convert, userKey, userIv);
    //                         const textFile = new File([encrypt], 'image_base64.txt', { type: 'text/plain' });
    //                         formData.append('image', textFile);
    //                         setBinaryDataMain(binaryString);
    //                     };
    //                     reader.onerror = (error) => {
    //                         console.error('Error reading file:', error);
    //                     };
    //                     reader.readAsArrayBuffer(file);



    //                     const base64convert = btoa(binaryDataMain);
    //                     const encrypt = encryptDataConvertForChat(base64convert, userKey, userIv);
    //                     const textFile = new File([encrypt], 'image_base64.txt', { type: 'text/plain' });
    //                     formData.append('image', textFile);

    //                     // const base64String = await convertToBase64(file);
    //                     // // const base64convert = btoa(base64String);
    //                     // const encrypt = encryptDataConvertForChat(base64convert, userKey, userIv);
    //                     // const textFile = new File([encrypt], 'image_base64.txt', { type: 'text/plain' });
    //                     // formData.append('image', textFile);
    //                 } else {
    //                     formData.append('image', file);
    //                 }
    //             }
    //         }
    //         if (thumbnailList.length) {
    //             // for (let j = 0; j < thumbnailList.length; j++) {
    //             //     formData.append('thumbnail', thumbnailList[j])
    //             // }
    //             for (let j = 0; j < thumbnailList.length; j++) {
    //                 const file2 = thumbnailList[j];
    //                 if (file2.type.startsWith('image/')) {


    //                     const reader = new FileReader();
    //                     reader.onload = () => {
    //                         const arrayBuffer = reader.result;
    //                         const binaryString = arrayBufferToBinary(arrayBuffer);
    //                         // console.log(binaryString);

    //                         setBinaryData(binaryString);
    //                     };
    //                     reader.onerror = (error) => {
    //                         console.error('Error reading file:', error);
    //                     };
    //                     reader.readAsArrayBuffer(file2);



    //                     const base64convert = btoa(binaryData);
    //                     const encrypt = encryptDataConvertForChat(base64convert, userKey, userIv);
    //                     const textFile = new File([encrypt], 'image_base64.txt', { type: 'text/plain' });
    //                     formData.append('thumbnail', textFile);


    //                     // const base64String2 = await convertToBase64(file2);
    //                     // const base64convert = btoa(base64String2);
    //                     // const encryptThumnails = encryptDataConvertForChat(base64convert,userKey, userIv);
    //                     // const textFile = new File([encryptThumnails], 'thumbnail_base64.txt', { type: 'text/plain' });
    //                     // formData.append('thumbnail', textFile);

    //                     // const base64String = await convertToBase64(file);
    //                     // const encryptedBase64String = encryptDataConvertForChat(file2, key, iv);
    //                     // const byteCharacters = atob(encryptedBase64String);
    //                     // console.log(byteCharacters)
    //                     // const byteNumbers = new Array(byteCharacters.length);
    //                     // for (let j = 0; j < byteCharacters.length; j++) {
    //                     //     byteNumbers[j] = byteCharacters.charCodeAt(j);
    //                     // }
    //                     // const byteArray = new Uint8Array(byteNumbers);
    //                     // const encryptedBlob = new Blob([byteArray], { type: file.type });
    //                     // formData.append('thumbnail', encryptedBlob);

    //                 } else {
    //                     formData.append('thumbnail', file);
    //                 }
    //             }
    //         }
    //         let jsonData = {
    //             msg_type: 3,
    //             type: type.toString(),
    //             other_id: isOtherId,
    //             chat_id: isChatId,
    //             is_encrypted: key != "" & iv != "" ? 1 : 0,
    //         }
    //         // return false;
    //         var myHeaders1 = new Headers();
    //         myHeaders1.append("Authorization", localStorage.getItem("UserToken"));
    //         const encrypt = encryptData(jsonData);
    //         formData.append("chatData", encrypt)
    //         fetch(BASE_URL + "send_message", {
    //             method: 'POST',
    //             body: formData,
    //             headers: myHeaders1,
    //         })
    //             .then(response => response.json())
    //             .then(response => {
    //                 if (response.Status === 0) {
    //                     console.log(response, "no")
    //                 } else {
    //                     console.log("yes", response);
    //                     const decrypt = decryptData(response.key);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //     }
    //     else {
    //         let jsonData = {
    //             msg: encryptDataConvertForChat(messageText, key, iv),
    //             msg_type: 1,
    //             other_id: isOtherId,
    //             chat_id: isChatId,
    //             is_encrypted: key != "" & iv != "" ? 1 : 0,
    //         }
    //         console.log("jsonData >>>", jsonData);
    //         const encrypt = encryptData(jsonData);
    //         fetch(BASE_URL + "send_message", {
    //             method: 'POST',
    //             body: JSON.stringify({ "chatData": encrypt }),
    //             headers: myHeaders,
    //         })
    //             .then(response => response.json())
    //             .then(response => {
    //                 if (response.Status === 0) {
    //                     console.log(response, "no")
    //                 } else {
    //                     console.log("yes", response);
    //                     // const decrypt = decryptData(response.key);
    //                     // console.log("decrypt", decrypt);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             })
    //     }
    // }
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };
    const handleSendMessage = async (e) => {
        // e.preventDefault();
        setData([]);
        setImg([]);
        setType([]);
        setMessageText("");
        console.log("data", data);

        let formData = new FormData();

        try {
            if (data.length != 0) {
                    // Handle data (images)
                    let mediaType

                    if (data.length) {
                        const imagePromises = data.map(file => {
                            if (file.type.startsWith('image/')) {
                                 mediaType = 3;
                                return readFileAndEncrypt(file, userKey, userIv);
                            } else {
                                 mediaType = 2;

                                return Promise.resolve(file);
                            }
                        });

                        const encryptedImages = await Promise.all(imagePromises);
                        encryptedImages.forEach((file) => {
                            formData.append('image', file);
                        });
                    }
                    // Handle thumbnailList
                    if (thumbnailList.length) {
                        const thumbnailPromises = thumbnailList.map(file => {
                            if (file.type.startsWith('image/')) {
                                return readFileAndEncrypt(file, userKey, userIv);
                            } else {
                                return Promise.resolve(file);
                            }
                        });

                        const encryptedThumbnails = await Promise.all(thumbnailPromises);
                        encryptedThumbnails.forEach((file) => {
                            formData.append('thumbnail', file);
                        });
                    }

                    // Create JSON data
                    let jsonData = {
                        msg_type: data.length ? 3 : 1,
                        type: mediaType,
                        // type: type.toString(),
                        other_id: isOtherId,
                        chat_id: isChatId,
                        is_encrypted: key != "" && iv != "" ? 1 : 0,
                    };
                    console.log(jsonData);

                    // Append JSON data to form data
                    const encrypt = encryptData(jsonData);
                    formData.append("chatData", encrypt);
                    if (replyMsg != '') {
                        formData.append("reply_id", isMessageId);
                    }

                    // Set headers
                    const myHeaders1 = new Headers();
                    myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

                    // Send the form data using fetch
                    const response = await fetch(BASE_URL + "send_messagev2", {
                        method: 'POST',
                        body: formData,
                        headers: myHeaders1,
                    });
                    const result = await response.json();

                    if (result.Status === 0) {
                        console.log(result, "no");
                    } else {
                        console.log("yes", result);
                        // const decrypt = decryptData(result.key);
                        // console.log("decrypt", decrypt);
                    }
        }else{
                    // If no files to send, handle text message

                    if (messageText == '' || null) {
                        return false;
                    }
                    if (replyMsg != '') {
                        var jsonData = {
                            msg: encryptDataConvertForChat(messageText, key, iv),
                            msg_type: 1,
                            other_id: isOtherId,
                            chat_id: isChatId,
                            is_encrypted: key != "" && iv != "" ? 1 : 0,
                            reply_id:isMessageId
                        };
                    }else{
                        var jsonData = {
                            msg: encryptDataConvertForChat(messageText, key, iv),
                            msg_type: 1,
                            other_id: isOtherId,
                            chat_id: isChatId,
                            is_encrypted: key != "" && iv != "" ? 1 : 0,
                        };
                    }

                    console.log("jsonData >>>", jsonData);
                    const encrypt = encryptData(jsonData);

                    try {
                        const response = await fetch(BASE_URL + "send_messagev2", {
                            method: 'POST',
                            body: JSON.stringify({ "chatData": encrypt }),
                            headers: myHeaders,
                        });
                        const result = await response.json();

                        if (result.Status === 0) {
                            console.log(result, "no");
                        } else {
                            setReplyMsg('')
                            console.log("yes", result);
                            // const decrypt = decryptData(result.key);
                            // console.log("decrypt", decrypt);
                        }
                    } catch (error) {
                        console.log(error);
                    }
            }
        } catch (error) {
            console.log(error);
        }


    };

    // for user
    const handelEnterFunction = async () => {
        if (isUpdate == false) {
            handleSendMessage()
        } else {
            handleEditMessage()
        }
    };

    // for group
    const handleKeyPress = () => {

            if (isUpdate == false) {
                handleMessageSend()
            } else {
                handleEditMessage()
            }
    };

    const handleKeyDown = () => {
        // alert('1')
        console.log('wwww')
    }

    // Send Group message API
    const handleMessageSend = async (e) => {
        // e.preventDefault()
        setData([]);
        setImg([]);
        setType([]);
        setMessageText("");
        console.log("data", data)
        if (data.length != 0) {
            let formData = new FormData();
            let mediaType
            if (data.length) {
                const imagePromises = data.map(file => {
                    if (file.type.startsWith('image/')) {
                        mediaType = 3;
                        return readFileAndEncrypt(file, key, iv);
                    } else {
                        mediaType = 2;

                        return Promise.resolve(file);
                    }
                });

                const encryptedImages = await Promise.all(imagePromises);
                encryptedImages.forEach((file) => {
                    formData.append('image', file);
                });
            }

            // Handle thumbnailList
            if (thumbnailList.length) {
                const thumbnailPromises = thumbnailList.map(file => {
                    if (file.type.startsWith('image/')) {
                        return readFileAndEncrypt(file, key, iv);
                    } else {
                        return Promise.resolve(file);
                    }
                });

                const encryptedThumbnails = await Promise.all(thumbnailPromises);
                encryptedThumbnails.forEach((file) => {
                    formData.append('thumbnail', file);
                });
            }

            // if (data.length) {
            //     for (let i = 0; i < data.length; i++) {
            //         formData.append('image', data[i])
            //     }
            // }
            // if (thumbnailList.length) {
            //     for (let j = 0; j < thumbnailList.length; j++) {
            //         formData.append('thumbnail', thumbnailList[j])
            //     }
            // }
            let jsonData = {
                msg_type: 3,
                type: mediaType,
                // type: type.toString(),
                chatroom_id: isChatroomId,
                is_encrypted: key != "" & iv != "" ? 1 : 0,
            }
            var myHeaders1 = new Headers();
            myHeaders1.append("Authorization", localStorage.getItem("UserToken"));
            const encrypt = encryptData(jsonData);
            formData.append("chatroomData", encrypt)
            fetch(BASE_URL + "send_group_messagev2", {
                method: 'POST',
                body: formData,
                headers: myHeaders1,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                    } else {
                        setReplyMsg('')
                        console.log("yes", response);
                        // const decrypt = decryptData(response.key);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            if (replyMsg != '') {
                var jsonData = {
                    msg: encryptDataConvertForChat(messageText, key, iv),
                    msg_type: 1,
                    chatroom_id: isChatroomId,
                    is_encrypted: key != "" & iv != "" ? 1 : 0,
                    reply_id:isMessageId
                }
            }else{
                var jsonData = {
                    msg: encryptDataConvertForChat(messageText, key, iv),
                    msg_type: 1,
                    chatroom_id: isChatroomId,
                    is_encrypted: key != "" & iv != "" ? 1 : 0,
                }
            }

            console.log("jsonData >>>", jsonData);
            const encrypt = encryptData(jsonData);
            fetch(BASE_URL + "send_group_messagev2", {
                method: 'POST',
                body: JSON.stringify({ "chatroomData": encrypt }),
                headers: myHeaders,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                    } else {
                        setReplyMsg('')
                        console.log("yes", response);
                        // const decrypt = decryptData(response.key);
                        // console.log("decrypt", decrypt);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    // const handleSendChatReactionUser = (e) => {
    //     try {

    //     axios.post(BASE_URL + `send_chat_reaction`, {
    //         chat_id: chat_id,
    //         message_id: message_id,
    //         reaction_text: reaction_text,
    //       })
    //       .then((res) => {

    //       });
    //     } catch (error) {

    //     }

    // }
    // const handleSendChatReactionGroup = (e) => {
    //     try {

    //         axios.post(BASE_URL + `send_chatroom_reaction`, {
    //             chat_id: chat_id,
    //             message_id: message_id,
    //             reaction_text: reaction_text,
    //           })
    //           .then((res) => {

    //           });
    //         } catch (error) {

    //         }
    // }

    const handleProfile = (e) => {
        console.log(e.target.files);
        setGroupImage(e.target.files[0]);
        var createurlimage = URL.createObjectURL(e.target.files[0]);
        setGroupImg(createurlimage);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoomDatas({ ...roomDatas, [name]: value })
    }

    // create chatroom API
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = generateChatKey();
        setLoading(true);
        var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

        var formdata = new FormData();
        if (groupImage) {
            formdata.append("chatroom_image", groupImage);
        }
        let jsonData = {
            chatroom_name: roomDatas.chatroom_name,
            chatroom_description: roomDatas.chatroom_description,
            is_private: account,
            member_id: memberId.toString(),
            is_group: optionRoom,
            chatroom_sec: encryptDataConvertForChat(data.key, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur"),
            chatroom_iv: encryptDataConvertForChat(data.iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur"),
        }

        console.log("jasonData >>>>>", jsonData);
        const encrypt = encryptData(jsonData);
        formdata.append("chatroomData", encrypt)

        if (memberId.length === 0) {
            toast.error("Please Add Member");
            setTimeout(() => {
                setLoading(false)
            }, 1000);

        } else {
            fetch(BASE_URL + "create_chatroom", {
                method: 'POST',
                body: formdata,
                headers: myHeaders1,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        toast.error(response.Message);
                        setLoading(false)

                    } else {
                        console.log("yes >>", response.Message)
                        setRoom(false)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false)
                })
        }
    }

    const handleGetuser = () => {
        setIsLoader(true)
        let jsonData = {
            chatroom_id: 0,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "get_user", {
            method: 'POST',
            body: JSON.stringify({ "chatroomData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                    setIsLoader(false)
                } else {
                    const decrypt = decryptData(response.key);
                    console.log("get user >>>", decrypt.info);
                    setUserList(decrypt.info)
                }
                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false)
            })

    }
    const handleRead = (data, index) => {
        console.log("data >>", data);

        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(data);

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const text = await response.text();
                const decrypt = decryptDataConvertForChat(text, key, iv);

                const byteCharacters = atob(decrypt);
                // console.log(byteCharacters)

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
                // console.log(imageBlob);
                const imageUrl = URL.createObjectURL(imageBlob);
                // console.log(imageUrl)
                resolve({ imageUrl });
            } catch (error) {
                console.error("Error fetching data:", error);
                reject(error);
            }
        });
    };


    // const handleRead = async (data) => {
    //     console.log("data >>", data);

    //     try {
    //         // const imagePromise = new Promise((resolve) => {
    //         //     reader.onload = async (event) => {
    //                 const response = await fetch(data);

    //                 if (!response.ok) {
    //                   throw new Error("Network response was not ok");
    //                 }
    //                 const text = await response.text();
    //                 // return console.log(response)

    //                 const decrypt = decryptDataConvertForChat(text,key, iv);
    //                 // return console.log(decrypt);

    //                 const byteCharacters = atob(decrypt);
    //                 const byteNumbers = new Array(byteCharacters.length);
    //                 for (let i = 0; i < byteCharacters.length; i++) {
    //                   byteNumbers[i] = byteCharacters.charCodeAt(i);
    //                 }
    //                 const byteArray = new Uint8Array(byteNumbers);

    //                 const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
    //                 const imageUrl = URL.createObjectURL(imageBlob);
    //                 // resolve(imageUrl);
    //                 return { imageUrl };
    //         //     };
    //         // });


    //         // setDecryptMediaData(imageUrl);

    //         // if (item.fileType === 3) {
    //         //   // Image
    //         //   const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
    //         //   const imageUrl = URL.createObjectURL(imageBlob);
    //         //   setDecryptMediaData(imageUrl);
    //         // } else if (item.fileType === 5) {
    //         //   // Video
    //         //   const videoBlob = new Blob([byteArray], { type: "video/mp4" });
    //         //   const videoUrl = URL.createObjectURL(videoBlob);
    //         //   setDecryptMediaData(videoUrl);
    //         // } else if (item.fileType === 6) {
    //         //   // PDF
    //         //   const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
    //         //   const pdfUrl = URL.createObjectURL(pdfBlob);
    //         //   setDecryptMediaData(pdfUrl);
    //         // } else if (item.fileType === 4) {
    //         //   // PDF
    //         //   const audioBlob = new Blob([byteArray], { type: "audio/mp3" });
    //         //   const audioUrl = URL.createObjectURL(audioBlob);
    //         //   setDecryptMediaData(audioUrl);
    //         // } else {
    //         //   // Audio or other types
    //         //   // You can handle other file types here if needed
    //         //   // console.log('Unsupported file type:', item.fileType);
    //         // }

    //         // setIsLoding(false);
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       }


    //     // let jsonData = {
    //     //     image_url: data,
    //     // }
    //     // const encrypt = encryptData(jsonData);
    //     // fetch(BASE_URL + "read_space_image_file", {
    //     //     method: 'POST',
    //     //     body: JSON.stringify({ "imageData": encrypt }),
    //     //     headers: myHeaders,
    //     // })
    //     //     .then(response => response.json())
    //     //     .then(response => {
    //     //         if (response.Status === 0) {
    //     //             console.log(response, "no")
    //     //         } else {
    //     //             const decrypt = decryptData(response);
    //     //             console.log("read image >>", decrypt)
    //     //         }
    //     //     })
    //     //     .catch((error) => {
    //     //         console.log(error);
    //     //     })
    // }

    // copy message
    const onClick = (e) => {
        setCopy(true)
        navigator.clipboard.writeText(e);
    }

    // delete message API
    const handleDeleteMessage = () => {
        console.log(flagForChatGr);
        if (flagForChatGr != 'USER') {
            setIsLoading(true)
            let jsonData = {
                message_id: isMessageId,
                chatroom_id: isChatroomId,
                // other_id: isOtherId,
            }
            const encrypt = encryptData(jsonData);

            fetch(BASE_URL + "delete_chatroom_message", {
                method: 'POST',
                body: JSON.stringify({ "chatroomData": encrypt }),
                headers: myHeaders,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        setIsLoading(false)
                    } else {
                        console.log(response)
                        let data = [...chatrooms]
                        data.splice(chatIndex, 1)
                        setChatrooms(data)
                        setIsLoading(false)
                        setDeleteMessage(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false)
                })

        }else{
            setIsLoading(true)
            let jsonData = {
                message_id: isMessageId,
                chat_id: isChatId,
                other_id: isOtherId,
            }
            const encrypt = encryptData(jsonData);

            fetch(BASE_URL + "delete_message", {
                method: 'POST',
                body: JSON.stringify({ "chatData": encrypt }),
                headers: myHeaders,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response, "no")
                        setIsLoading(false)
                    } else {
                        console.log(response)
                        let data = [...messages]
                        data.splice(chatIndex, 1)
                        setMessages(data)
                        setIsLoading(false)
                        setDeleteMessage(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false)
                })
        }

    }


    // edit message API
    const handleEditMessage = (e) => {
        // e.preventDefault()
        setMessageText("");
        let jsonData = {
            message_id: isMessageId,
            message_text: encryptDataConvertForChat(messageText, key, iv),
            chat_id: isChatId,
            other_id: isOtherId,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "edit_message", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response, "no")
                } else {
                    console.log(response)
                    setIsUpdate(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleOption = (event) => {
        setAccount(event.target.value);
    }

    const handleOptions = (event) => {
        setOptionRoom(event.target.value)
    }

    const handleUserChange = (followId) => {
        if (memberId.includes(followId)) {
            setMenberId(memberId.filter(id => id !== followId));
        } else {
            setMenberId([...memberId, followId]);
        }
    };

    const isUserSelected = (followId) => {
        return memberId.includes(followId);
    };

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };




    const handleScroll1 = (e) => {

        if (totalpage !== messages.length) {
            // handleChatMessage(2, currentPage + 1,isChatId)
            setCurrentPage(currentPage + 1)
            setHasMoreData(true);
        } else {
            setHasMoreData(false);
        }

    };
    const handleScroll1Chatroom = (e) => {

        if (totalpage !== chatrooms.length) {
            // handleChatroomMessage(2, currentPageChatroom + 1,isChatId)
            setCurrentPageChatroom(currentPageChatroom + 1)
            setHasMoreDataChatroom(true);
        } else {
            setHasMoreDataChatroom(false);
        }

    };



    const handleBlock = (id,index) => {
        // return console.log(id)

        let jsonData = {
            user_id: id,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "block_unblock_user", {
            method: 'POST',
            body: JSON.stringify({ "blockData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response.Message)
                } else {
                    console.log(response);
                    let data = [...userData];
                    data.splice(index, 1);
                    setUserData(data);
                    // setModalShow(false)
                    // HandleData(id)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }
    const handleMute = (id,index,is_mute,type) => {
        // return console.log(id)
        if (type == 'user') {

                let jsonData = {
                    chat_id: id,
                }
                const encrypt = encryptData(jsonData);

                fetch(BASE_URL + "mute_unmute_chat", {
                    method: 'POST',
                    body: JSON.stringify({ "chatData": encrypt }),
                    headers: myHeaders,
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.Status === 0) {
                            console.log(response.Message)
                        } else {
                            console.log(response);
                            var data = [...userData]
                            data[index].is_chat_mute_by_me = data[index].is_chat_mute_by_me == 1 ? 0 : 1;
                            setUserData(data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
        }else{

            let jsonData = {
                chat_id: id,
                is_mute:is_mute == 0 ? 1 : 0,
            }
            const encrypt = encryptData(jsonData);

            fetch(BASE_URL + "mute_unmute_chatroom", {
                method: 'POST',
                body: JSON.stringify({ "chatroomData": encrypt }),
                headers: myHeaders,
            })
                .then(response => response.json())
                .then(response => {
                    if (response.Status === 0) {
                        console.log(response.Message)
                    } else {
                        console.log(response);
                        var data = [...groupData]
                        data[index].is_mute_to = data[index].is_mute_to == 1 ? 0 : 1;
                        setGroupData(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }


    }

    const handleArchive = (id,index) => {


        let jsonData = {
            chat_id: id,
        }
        const encrypt = encryptData(jsonData);

        fetch(BASE_URL + "archive_unarchive_chat", {
            method: 'POST',
            body: JSON.stringify({ "chatData": encrypt }),
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(response => {
                if (response.Status === 0) {
                    console.log(response.Message)
                } else {
                    console.log(response);
                    let data = [...userData];
                    data.splice(index, 1);
                    setUserData(data);
                    handleListChatUserForArchive(currentPage)
                    // setModalShow(false)
                    // HandleData(id)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const getUserList = () => {
        setNewflag(0)
        setIsArchive(false);
        handleListChatUser(currentPage)
    }
    const extractUrls = (text) => {
        // console.log('akash',text)
        // console.log('akash',text != '' || null)
        if(text !=  null){
            // console.log('akash1',text)

        let regex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
        let matches = text.matchAll(regex);
        let extractedUrls = [];
        for (let match of matches) {
            extractedUrls.push(match[0]);
        }
        return extractedUrls;
    }else{
        return null;
    }

    };
    const showMediaModal = (item) => {
        console.log(item);
        setDecryptMediaData(item)
        setDecryptMediaModel(true)

    }
    const downlaodFileHandler = (item) => {
        const link = document.createElement("a");
        link.href = item;
        link.download = fileName;
        link.click();
      };
    


    return (
        <>
            <div className={isVisible ? "d-flex" : "d-none"}>
                <Sidebar />
            </div>

            <div className='message-sreen'>
                <div className='messageView'>
                    <div className='sticky-top bg-backdrop'>
                        <div className='srch-head d-flex justify-content-between align-items-center pt-5'>

                            <span className="btn-2 btn-info pointer" onClick={toggleVisibility}><i className="fa-solid fa-bars px-1"></i>Menu</span>


                            <p className='srch-name text-center'>Message</p>
                            <div className={isVisible ? "d-flex" : "d-none"}>
                                <span className="btn-2 btn-info pointer" onClick={toggleVisibility}><i className="fa-regular fa-circle-xmark px-1"></i></span>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setRoom(true); setGroupImg("") }} width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z" fill="white" />
                                <path d="M7.94975 17.7498C7.47975 17.7498 7.04975 17.5798 6.72975 17.2698C6.34975 16.8898 6.17972 16.3498 6.26972 15.7698L6.54972 13.7898C6.60972 13.3598 6.87974 12.8098 7.18974 12.4998L12.3797 7.30981C14.1597 5.52981 15.7097 6.32981 16.6897 7.30981C17.4597 8.07981 17.8097 8.88981 17.7297 9.69981C17.6697 10.3598 17.3197 10.9798 16.6897 11.6198L11.4997 16.8098C11.1897 17.1198 10.6497 17.3898 10.2097 17.4598L8.22975 17.7398C8.13975 17.7398 8.03975 17.7498 7.94975 17.7498ZM14.5297 7.74981C14.1597 7.74981 13.8297 7.98981 13.4497 8.35981L8.25974 13.5498C8.17974 13.6298 8.05974 13.8798 8.03974 13.9898L7.75974 15.9698C7.74974 16.0698 7.75972 16.1598 7.79972 16.1998C7.83972 16.2398 7.92973 16.2498 8.02973 16.2398L10.0097 15.9598C10.1297 15.9398 10.3697 15.8198 10.4497 15.7398L15.6397 10.5498C16.0197 10.1698 16.2197 9.83981 16.2497 9.53981C16.2797 9.19981 16.0797 8.79981 15.6397 8.35981C15.1997 7.93981 14.8497 7.74981 14.5297 7.74981Z" fill="white" />
                                <path d="M15.4198 12.5797C15.3498 12.5797 15.2798 12.5697 15.2198 12.5497C13.4098 12.0397 11.9598 10.5997 11.4498 8.77972C11.3398 8.37972 11.5698 7.96972 11.9698 7.84972C12.3698 7.73972 12.7798 7.96972 12.8898 8.36972C13.2598 9.67972 14.3098 10.7297 15.6198 11.0997C16.0198 11.2097 16.2498 11.6297 16.1398 12.0297C16.0498 12.3597 15.7498 12.5797 15.4198 12.5797Z" fill="white" />
                            </svg>
                        </div>
                        <div className='srch-bar d-flex align-items-center'>
                            <div style={{ color: "#8f8f8f" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g opacity="0.6">
                                        <path d="M2.76311 11.4842L3.49329 11.3129L2.76311 11.4842ZM2.76311 6.93667L3.49329 7.10794L2.76311 6.93667ZM15.6578 6.93667L16.3879 6.76539L15.6578 6.93667ZM15.6578 11.4842L16.3879 11.6555L15.6578 11.4842ZM11.4842 15.6578L11.3129 14.9276L11.4842 15.6578ZM6.93667 15.6578L6.76539 16.3879L6.93667 15.6578ZM6.93667 2.76311L6.76539 2.03293V2.03293L6.93667 2.76311ZM11.4842 2.76311L11.6555 2.03293L11.4842 2.76311ZM16.9697 18.0303C17.2626 18.3232 17.7374 18.3232 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L16.9697 18.0303ZM3.49329 11.3129C3.1689 9.93004 3.1689 8.49084 3.49329 7.10794L2.03293 6.76539C1.65569 8.3736 1.65569 10.0473 2.03293 11.6555L3.49329 11.3129ZM14.9276 7.10795C15.252 8.49084 15.252 9.93004 14.9276 11.3129L16.3879 11.6555C16.7652 10.0473 16.7652 8.3736 16.3879 6.76539L14.9276 7.10795ZM11.3129 14.9276C9.93004 15.252 8.49084 15.252 7.10795 14.9276L6.76539 16.3879C8.3736 16.7652 10.0473 16.7652 11.6555 16.3879L11.3129 14.9276ZM7.10794 3.49329C8.49084 3.1689 9.93004 3.1689 11.3129 3.49329L11.6555 2.03293C10.0473 1.65569 8.3736 1.65569 6.76539 2.03293L7.10794 3.49329ZM7.10795 14.9276C5.31441 14.5069 3.914 13.1065 3.49329 11.3129L2.03293 11.6555C2.58373 14.0037 4.41721 15.8371 6.76539 16.3879L7.10795 14.9276ZM11.6555 16.3879C14.0037 15.8371 15.8371 14.0037 16.3879 11.6555L14.9276 11.3129C14.5069 13.1065 13.1065 14.5069 11.3129 14.9276L11.6555 16.3879ZM11.3129 3.49329C13.1065 3.91399 14.5069 5.31441 14.9276 7.10795L16.3879 6.76539C15.8371 4.41721 14.0037 2.58373 11.6555 2.03293L11.3129 3.49329ZM6.76539 2.03293C4.41721 2.58373 2.58373 4.41721 2.03293 6.76539L3.49329 7.10794C3.914 5.3144 5.31441 3.91399 7.10794 3.49329L6.76539 2.03293ZM13.9156 14.9763L16.9697 18.0303L18.0303 16.9697L14.9763 13.9156L13.9156 14.9763Z" fill="white" />
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <input
                                    className='message-inp'
                                    value={isFlag == 1 ? searchUser : searchGroup}
                                    onChange={(e) => {
                                        if (isFlag == 1) {
                                            setSearchUser(e.target.value)
                                        } else {
                                            setSearchGroup(e.target.value)
                                        }
                                    }}
                                    placeholder='Search'
                                    type='search'
                                />
                            </div>
                        </div>
                        {isArchive != true ? (
                            <div className='message-group'>
                            <p className={clsx([isFlag === 1 ? "border_bottom" : ""])}
                                onClick={() => {
                                    setisFlag(1);
                                    setNewflag(0);
                                    setIsChatroomId("")
                                    setCurrentPage(1);
                                    setFlagForChatGr("USER")
                                    setReplyMsg('')
                                    if (isChatroomId) {
                                        Socket.emit("left_group", {
                                            user_id: my_user_id,
                                            chatroom_id: isChatroomId,
                                        });
                                    }
                                }}>
                                Chats
                            </p>

                            <p className={clsx([isFlag === 2 ? "border_bottom" : ""])}
                                onClick={() => {
                                    setisFlag(2);
                                    setNewflag(0);
                                    setIsChatId("")
                                    setFlagForChatGr("GROUP")
                                    setCurrentPageChatroom(1)
                                    setReplyMsg('')

                                    if (isChatId) {
                                        Socket.emit("left_room", {
                                            user_id: my_user_id,
                                            chat_id: isChatId,
                                        });
                                    }
                                }}>
                                Chatrooms
                            </p>
                            <p className={clsx([isFlag === 3 ? "border_bottom" : ""])}
                                onClick={() => {
                                    setisFlag(3);
                                    setNewflag(0);
                                    setIsChatroomId("")
                                    setCurrentPageChatroom(1);
                                    setFlagForChatGr("ANNOUNCEMENT")
                                    setReplyMsg('')

                                    if (isChatroomId) {
                                        Socket.emit("left_group", {
                                            user_id: my_user_id,
                                            chatroom_id: isChatroomId,
                                        });
                                    }
                                }}>
                                Announcements
                            </p>
                        </div>
                        ):(<div className='text-end'>
                            <i class="fa-regular fa-circle-xmark pointer px-2" onClick={()=> getUserList()}></i>
                        </div>)}

                    </div>

                    {
                        isFlag === 1 ?
                            <div className='message-chat' id='style-4'>
                                {
                                    isLoaders ?
                                        (<div className='d-flex justify-content-center align-items-center w-100 h-75'>
                                            <Circles
                                                height="40"
                                                width="40"
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass="message-loader"
                                            />
                                        </div>)
                                        :
                                        <>
                                            {
                                                userData == "" ?
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "70vh" }}>
                                                        <p>No Archive found</p>
                                                    </div>
                                                    :
                                                    <>
                                                    <div className="row m-0 px-4 pt-3">
                                                        <div className="col-2 text-center my-auto">
                                                        <i class="fa-solid fa-box-archive small-font1"></i>
                                                        </div>
                                                        <div className="col-8 text-center my-auto pointer" onClick={()=>handleListChatUserForArchive(currPage)}>
                                                        {isArchive != true ? (<span className="text-white small-font1 p-1">
                                                            Archieve
                                                            </span>):(
                                                                <span className="text-white small-font1 p-1">
                                                                Archieve List
                                                                </span>
                                                            )}
                                                        </div>
                                                        {/* <div className="col-2 text-end my-auto">
                                                            <span className="text-white small-font1 p-1">
                                                            0
                                                            </span>
                                                        </div> */}
                                                    </div>
                                                        {
                                                            userData.map((item, index) => {
                                                                let keys = []
                                                                let ivs = []
                                                                if (item.chat_sec && item.chat_iv) {
                                                                    let key = decryptDataConvertForChat(item.chat_sec, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                                                                    let iv = decryptDataConvertForChat(item.chat_iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")

                                                                    keys.push(key)
                                                                    ivs.push(iv)
                                                                }
                                                                return (
                                                                    <div key={index}

                                                                        className={parseInt(item.chat_id) === parseInt(activeservicid) ? "chat-center1" : "chat-center"}
                                                                    >
                                                                        <div className='srch-icon'
                                                                          onClick={() => {
                                                                            console.log("isChatId >>", isChatId);
                                                                            console.log("item.chat_id >>", item.chat_id);
                                                                            if (item.chat_id != isChatId) {
                                                                                if (isChatId) {
                                                                                    Socket.emit("left_room", {
                                                                                        user_id: my_user_id,
                                                                                        chat_id: isChatId,
                                                                                    });
                                                                                }
                                                                                Socket.emit("join_room", {
                                                                                    user_id: my_user_id,
                                                                                    chat_id: item.chat_id,
                                                                                });
                                                                            }
                                                                            setNewflag(1);
                                                                            onClickHandlerForUser(2, 1, item.chat_id, item.chat_sec, item.chat_iv)
                                                                            // handleChatMessage(2, 1, item.chat_id,item.chat_sec,item.chat_iv);
                                                                            setIsChatId(item.chat_id);
                                                                            setIsIndex(index);
                                                                            setIsOtherId(item.other_id);
                                                                            setKey(keys)
                                                                            setIv(ivs)
                                                                            let chatss = [...userData];
                                                                            chatss[index].unread_count = 0;
                                                                        }}>
                                                                            <div className='d-flex align-itens-start'>
                                                                            <div className="profile-container">
                                                                                <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='srch-img rounded' />
                                                                                {/* {item.is_online != 0 ? (
                                                                                    <div class="ring-container"><span class="online-badge"></span></div>)
                                                                                    :
                                                                                    (<div class="ring-container"><span className='offlineCircle'></span></div>)
                                                                                } */}
                                                                                <div className="row">
                                                                                    <div className="col-6">
                                                                                        {
                                                                                        item.unread_count != 0 && (
                                                                                            <span className='notification-icon1'>
                                                                                                <p className='notification-icon d-flex justify-content-center align-items-center m-0'>{item.unread_count}</p>
                                                                                            </span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="ring-container">
                                                                                            <span className={item.is_online != 0 ? 'online-badge' : 'offline-badge'}></span>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>



                                                                            </div>
                                                                            </div>
                                                                            <div className='srch-line'>
                                                                                <p>{item.username}</p>
                                                                                <p className='srch-post'>
                                                                                    {
                                                                                        item.message_type == null ? ""
                                                                                            :
                                                                                            item.message_type == 1 ?
                                                                                                <>
                                                                                                    {
                                                                                                        item.is_encrypted != 1 ?
                                                                                                            <p className='chat-caption'>{item.message_text || ""}</p>
                                                                                                            :
                                                                                                            <p className='chat-caption'>{decryptDataConvertForChat(item.message_text, keys, ivs) || ""}</p>

                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                item.message_type == 2 && item.thumbnail != null ?
                                                                                                    <p><i className="bi bi-camera-reels"></i> video</p>
                                                                                                    :
                                                                                                    <p><i className="bi bi-image"></i> Image</p>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='chat-min'>
                                                                            <p className='m-0'>
                                                                                {
                                                                                    item.created_at == null ? "" :
                                                                                        <>{moment(item.created_at).fromNow()}</>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {item.is_chat_mute_by_me != 0 ? (<i class="fas fa-volume-mute px-2"></i>):('')}
                                                                        <div className='chat-min'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu size="sm" title="">
                                                                            <div className="option-menudatag8">

                                                                                {isArchive != true ?(<div className="menu-message d-flex align-items-center py-1"  onClick={ () => handleArchive(item.chat_id,index)}>
                                                                                    <i className="fa-solid fa-box-archive px-2"></i>
                                                                                    Add To Archieve
                                                                                </div>):
                                                                                (<div className="menu-message d-flex align-items-center py-1"  onClick={ () => handleArchive(item.chat_id,index)}>
                                                                                    <i className="fa-solid fa-box-archive px-2"></i>
                                                                                    Remove From Archieve
                                                                                </div>)}
                                                                                <div className="menu-message d-flex align-items-center py-1" onClick={ () => handleMute(item.chat_id,index,item.is_chat_mute_by_me,'user')}>
                                                                                    {item.is_chat_mute_by_me == 0 ? (
                                                                                        <>
                                                                                            <i className="fas fa-volume-mute px-2"></i>
                                                                                            Mute
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <i className="fas fa-volume-up px-2"></i>
                                                                                            Unmute
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                <div className="menu-message d-flex align-items-center py-1" onClick={ () => handleBlock(item.other_id,index)}>
                                                                                    <i className="fa-solid fa-ban px-2"></i>
                                                                                    Block
                                                                                </div>
                                                                            </div>
                                                                            </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </>
                                }

                            </div>
                            :
                            <div className='message-chat'>
                                {
                                    isLoaders ?
                                        (<div className='d-flex justify-content-center align-items-center w-100 h-75'>
                                            <Circles
                                                height="40"
                                                width="40"
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass="message-loader"
                                            />
                                        </div>)
                                        :
                                        <>
                                        {/* <div className="row m-0 px-4 pt-3">
                                            <div className="col-2 text-center my-auto">
                                            <i class="fa-solid fa-box-archive small-font1"></i>
                                            </div>
                                            <div className="col-8 text-start my-auto">
                                                <span className="text-white small-font1 p-1">
                                                Archieve
                                                </span>
                                            </div>
                                            <div className="col-2 text-end my-auto">
                                                <span className="text-white small-font1 p-1">
                                                0
                                                </span>
                                            </div>
                                        </div> */}
                                            {
                                                groupData == "" ?
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "70vh" }}>
                                                        <p>No Data Found</p>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            groupData.map((item, index) => {
                                                                let keys = []
                                                                let ivs = []
                                                                if (item.chatroom_sec && item.chatroom_iv) {
                                                                    let key = decryptDataConvertForChat(item.chatroom_sec, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")
                                                                    let iv = decryptDataConvertForChat(item.chatroom_iv, "ayax-a0o3-va-xvv", "t3yd-vt63-ya-xur")

                                                                    keys.push(key)
                                                                    ivs.push(iv)
                                                                }
                                                                return (
                                                                    <div key={index}
                                                                        className={parseInt(item.chatroom_id) === parseInt(activeservicid) ? "chat-center1" : "chat-center"}

                                                                    >
                                                                        <div className='srch-icon'   onClick={() => {
                                                                            if (item.chatroom_id != isChatroomId) {
                                                                                if (isChatroomId) {
                                                                                    Socket.emit("left_group", {
                                                                                        user_id: my_user_id,
                                                                                        chatroom_id: isChatroomId,
                                                                                    });
                                                                                }
                                                                                Socket.emit("join_group", {
                                                                                    user_id: my_user_id,
                                                                                    chatroom_id: item.chatroom_id,
                                                                                });
                                                                            }
                                                                            setChatroomInfo(item)
                                                                            setIsChatroomId(item.chatroom_id)
                                                                            // handleChatroomMessage(2, 1, item.chatroom_id)
                                                                            onClickHandlerForChatroom(2, 1, item.chatroom_id, item.chatroom_sec, item.chatroom_iv)

                                                                            setNewflag(2)
                                                                            setKey(keys)
                                                                            setIv(ivs)
                                                                        }}>
                                                                            <div className='d-flex align-itens-start'>
                                                                                <img src={item.chatroom_image ? IMG_URL + item.chatroom_image : placeholderimg} className='srch-img rounded' />
                                                                                {
                                                                                    item.unread_count != 0 && (
                                                                                        <span className='notification-icon1'>
                                                                                            <p className='notification-icon d-flex justify-content-center align-items-center m-0'>{item.unread_count}</p>
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className='srch-line'>
                                                                                <p className='chat-caption'>{item.chatroom_name || ""}</p>
                                                                                <p className='srch-post d-flex'>
                                                                                    <span className='font-wt limitmsg'>
                                                                                        {item.username || ""}
                                                                                    </span>
                                                                                    {
                                                                                        item.message_type == null ? ""
                                                                                            :
                                                                                            item.message_type == 1 ?
                                                                                                <>
                                                                                                    {
                                                                                                        item.is_encrypted != 1 ?
                                                                                                            <p className='chat-captions'>: {item.message_text || ""}</p>
                                                                                                            :
                                                                                                            <p className='chat-captions'>: {decryptDataConvertForChat(item.message_text, keys, ivs) || ""}</p>

                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                item.message_type == 2 && item.thumbnail != null ?
                                                                                                    <p>: <i className="bi bi-camera-reels"></i> video</p>
                                                                                                    :
                                                                                                    <p>: <i className="bi bi-image"></i> Image</p>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='chat-min'>
                                                                            <p className='m-0'>
                                                                                {
                                                                                    item.created_at == null ? "" :
                                                                                        <>{moment(item.created_at).fromNow()}</>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {item.is_mute_to != 0 && <i class="fas fa-volume-mute px-2"></i>}
                                                                        <div className='chat-min'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu size="sm" title="">
                                                                            <div className="option-menudatag8">
                                                                                <div className="menu-message d-flex align-items-center py-1" >
                                                                                    <i className="fa-solid fa-box-archive px-2"></i>
                                                                                    Add To Archieve
                                                                                </div>
                                                                            </div>
                                                                             <div className="option-menudatag8">
                                                                             <div className="menu-message d-flex align-items-center py-1" onClick={ () => handleMute(item.chat_id,index,item.is_mute_to,'group')}>
                                                                                    {item.is_mute_to == 0 ? (
                                                                                        <>
                                                                                            <i className="fas fa-volume-mute px-2"></i>
                                                                                            Mute
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <i className="fas fa-volume-up px-2"></i>
                                                                                            Unmute
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </>
                                }

                            </div>
                    }
                </div>
                <div className='vr text-light line-msg'></div>
                <div className='message-box pt-4'>
                    {
                        newFlag === 0 ?
                            <div className='d-flex justify-content-center align-items-center opacity-50' style={{ height: "100vh" }}>
                                <div className='text-center'>
                                    <img src={iconmessage} alt="" />
                                    <p className='m-0 text-light pt-3'>Please select a chat to start messaging.</p>
                                    {/* <LinkPreview data="kjbajdklbjbasjk  https://www.npmjs.com/package/link-preview-js"/> */}
                                </div>
                            </div>
                            : newFlag === 1 ?
                                <>
                                    <div className='chat-human-2'>
                                        <div className='chat-human-1'>
                                            <div className='chat-head' onClick={() => handleUser(userData[isIndex].other_id)}>
                                                <img src={userData[isIndex].profile_pic ? IMG_URL + userData[isIndex].profile_pic : placeholderimg} width={60} height={60} className='rounded object-fit-cover' />
                                                <div className='px-4'>
                                                    <div className='m-0'>{userData[isIndex].username || ""}</div>
                                                    <div className='m-0'>{userData[isIndex].is_online != 0 ? 'Online' : 'Offline'}</div>
                                                </div>
                                            </div>
                                            <div className='message-call'>
                                                {/* <div className='message-call-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className='message-call-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.125 17.75H5.25C4.007 17.75 3 16.743 3 15.5V8.5C3 7.257 4.007 6.25 5.25 6.25H13.125C14.368 6.25 15.375 7.257 15.375 8.5V15.5C15.375 16.743 14.368 17.75 13.125 17.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.375 13.0972L19.17 16.1512C19.906 16.7442 21 16.2202 21 15.2752V8.72522C21 7.78022 19.906 7.25622 19.17 7.84922L15.375 10.9032" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-start flex-column bd-highlight chat-data'>
                                        {
                                            isLoader ?
                                                <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                                                    <Circles
                                                        height="40"
                                                        width="40"
                                                        color="#fff"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        wrapperClass="message-loader"
                                                    />
                                                </div>
                                                :
                                                <>
                                                    {
                                                        messages == "" ?
                                                            <p className='d-flex justify-content-center align-items-center text-light h-100 w-100'>Messages Not Found</p>
                                                            :
                                                            <div className='chat-datas' id='chatdiv' style={{ height: "100vh", overflowY: "scroll", display: "flex", flexDirection: "column-reverse" }}>

                                                                {
                                                                    messages.map((item, index) => {

                                                                        let extractedUrls = [];
                                                                        if (item.message_type == 1) {
                                                                            extractedUrls = extractUrls(item.message_text);
                                                                        }
                                                                        // console.log('extractedUrls',extractedUrls)


                                                                        const isFirstMessage = index === 0;
                                                                        const isDifferentDay = !isFirstMessage && moment(item.created_at).format('YYYY-MM-DD') !== moment(messages[index - 1].created_at).format('YYYY-MM-DD');

                                                                        // Determine the date header to display
                                                                        let dateHeader = '';
                                                                        if (isFirstMessage || isDifferentDay) {
                                                                            const today = moment().format('YYYY-MM-DD');
                                                                            const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                                                                            const messageDate = moment(item.created_at).format('YYYY-MM-DD');

                                                                            if (messageDate === today) {
                                                                                dateHeader = 'Today';
                                                                            } else if (messageDate === yesterday) {
                                                                                dateHeader = 'Yesterday';
                                                                            } else {
                                                                                dateHeader = moment(item.created_at).format('MMM D, YYYY');
                                                                            }
                                                                        }
                                                                        if (item.message_type == 2) {
                                                                            // handleRead(IMG_URL + item.message_text)
                                                                        }
                                                                        return (
                                                                            <div key={index}>
                                                                                {
                                                                                    <>
                                                                                        {/* {(isFirstMessage || isDifferentDay) && (
                                                                                        <div className='chatbox-day1'>
                                                                                            <p className='chatbox-day'>{dateHeader} </p>
                                                                                        </div>
                                                                                     )} */}
                                                                                        {my_user_id != item.message_by ?
                                                                                            (<div className='chat-box'>
                                                                                                <div className='d-flex'>
                                                                                                    <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='rounded object-fit-cover' width={50} height={50} />
                                                                                                    <div className='mb-5'>
                                                                                                        <p className='chat-1 2'>{item.username || ""}</p>
                                                                                                        {
                                                                                                            item.message_type == null ? ""
                                                                                                                :
                                                                                                                item.message_type == 1 ?
                                                                                                                    <>
                                                                                                                        {extractedUrls.length != 0 ? (
                                                                                                                                <LinkPreview data={item.message_text} dataclass={'chatbox-1'}/>
                                                                                                                            ):(
                                                                                                                                <>
                                                                                                                                {item.reply_id !== 0 ? (
                                                                                                                                    <>
                                                                                                                                        <p className='chatbox-1'>
                                                                                                                                            {decryptDataConvertForChat(item.old_message_text, userKey, userIv) || ""}
                                                                                                                                            <p className='chatbox-1 border' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <p className='chatbox-1' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                )}
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }

                                                                                                                        {/* {
                                                                                                                            item.is_encrypted == 1 ?
                                                                                                                                <p className='chatbox-1'>
                                                                                                                                    {item.message_text}</p>
                                                                                                                                :
                                                                                                                                <p className='chatbox-1'>{item.message_text || ""}</p>

                                                                                                                        } */}
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    item.message_type == 2 && item.thumbnail != null ?
                                                                                                                        <video className='message-chatboxs-2 rounded mx-2' src={IMG_URL + item.message_text} poster={IMG_URL + item.thumbnail} playsinline controls ></video>
                                                                                                                        :
                                                                                                                        <img onClick={()=>showMediaModal(item)} src={item.message_text} className='message-chatboxs-1 rounded mx-2' alt="" />
                                                                                                        }

                                                                                                        <p className='chatbox-time'>{moment(item.created_at).format("h:mm A")}
                                                                                                            {item.reaction_text_by != '' || item.reaction_text_to != '' ?
                                                                                                                (<span className='chatbox-e pointer'  >{item.reaction_text_by} {item.reaction_text_to}</span>
                                                                                                                ) : (<div></div>)}
                                                                                                            <i
                                                                                                                className="far fa-smile-beam pointer"
                                                                                                                onClick={() => setActivePicker(activePicker === index ? null : index)}
                                                                                                            ></i>
                                                                                                        </p>
                                                                                                        {activePicker === index && (
                                                                                                            <div ref={pickerRef} style={{ position: 'relative', zIndex: 1 }}>
                                                                                                                <EmojiPicker lazyLoadEmojis="true" skinTonesDisabled='true'  reactionsDefaultOpen="false" onEmojiClick={(event) => onEmojiClick(event, item.message_id, item, index)} />
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {hoveredMessage === item.message_id && (
                                                                                                            // <div style={{
                                                                                                            //     position: 'absolute',
                                                                                                            //     backgroundColor: 'white',
                                                                                                            //     border: '1px solid #ccc',
                                                                                                            //     padding: '10px',
                                                                                                            //     borderRadius: '5px',
                                                                                                            //     top: '40px', // Adjust as necessary
                                                                                                            //     left: '0px', // Adjust as necessary
                                                                                                            //     zIndex: 2
                                                                                                            // }}>
                                                                                                            // <h4>Reactions</h4>
                                                                                                            <div>
                                                                                                                alalla
                                                                                                                {/* {(reactions[item.message_id] || []).map((reaction, reactionIndex) => (
                                                                                                                <span key={reactionIndex} style={{ fontSize: '24px', marginRight: '5px' }}>
                                                                                                                    {String.fromCodePoint(...reaction.split('-').map(code => `0x${code}`))}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                            {(reactions[item.message_id] || []).length === 0 && <span>No reactions yet.</span>} */}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className='pe-2 mt-1 d-flex'>
                                                                                                        <Dropdown id='dropdown-data-option'>
                                                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                                                                <img src={options} onClick={() => { setCopy(false) }} className='option-message' alt="" />
                                                                                                            </Dropdown.Toggle>
                                                                                                            <Dropdown.Menu>
                                                                                                                <div className='option-menu'>

                                                                                                                    <div onClick={() => { setReplyMsg(item.message_text); setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                        <p className='m-0'>Reply3</p>
                                                                                                                        <img src={trash} height={20} alt="" />
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        item.message_type == 1 ?
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.is_encrypted == 1) {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    } else {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className='menu-message d-flex justify-content-between align-items-center'
                                                                                                                            >
                                                                                                                                <p className='m-0'>
                                                                                                                                    {
                                                                                                                                        copy == true ?
                                                                                                                                            "Copied"
                                                                                                                                            :
                                                                                                                                            "Copy"
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                                <span>
                                                                                                                                    <i class="bi bi-copy"></i>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </Dropdown.Menu>
                                                                                                        </Dropdown>
                                                                                                        {/* <img src={replys} className='reply-message' alt="" /> */}
                                                                                                        {/* <EmojiPicker reactionsDefaultOpen /> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>)
                                                                                            :
                                                                                            (<div className='chat-box-send'>
                                                                                                <div className='d-flex'>
                                                                                                    <div className='pe-2 mt-1 d-flex'>
                                                                                                        <Dropdown id='dropdown-data-option'>
                                                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                                                                <img src={options} onClick={() => { setCopy(false) }} className='option-message' alt="" />
                                                                                                            </Dropdown.Toggle>
                                                                                                            <Dropdown.Menu>
                                                                                                                <div className='option-menu'>
                                                                                                                    {
                                                                                                                        item.message_type == 1 ?
                                                                                                                            <div onClick={() => { setIsUpdate(true); setMessageText(item.message_text); setChatIndex(index); setIsMessageId(item.message_id) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                                <p className='m-0'>Edit1</p>
                                                                                                                                <img src={edit} height={20} alt="" />
                                                                                                                            </div>
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    <div onClick={() => { setReplyMsg(item); setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                        <p className='m-0'>Reply1</p>
                                                                                                                        <img src={trash} height={20} alt="" />
                                                                                                                    </div>
                                                                                                                    <div onClick={() => { setDeleteMessage(true); setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                        <p className='m-0'>Delete1</p>
                                                                                                                        <img src={trash} height={20} alt="" />
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        item.message_type == 1 ?
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.is_encrypted == 1) {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    } else {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className='menu-message d-flex justify-content-between align-items-center'
                                                                                                                            >
                                                                                                                                <p className='m-0'>
                                                                                                                                    {
                                                                                                                                        copy == true ?
                                                                                                                                            "Copied"
                                                                                                                                            :
                                                                                                                                            "Copy"
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                                <span>
                                                                                                                                    <i class="bi bi-copy"></i>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </Dropdown.Menu>
                                                                                                        </Dropdown>
                                                                                                        <img src={replys} className='reply-message' alt="" />
                                                                                                        {/* <EmojiPicker reactionsDefaultOpen /> */}
                                                                                                    </div>
                                                                                                    <div className='mb-5'>
                                                                                                        {
                                                                                                            item.message_type == null ? ""
                                                                                                                :
                                                                                                                item.message_type == 1 ?

                                                                                                                    <>

                                                                                                                        {extractedUrls.length != 0 ? (
                                                                                                                            <LinkPreview data={item.message_text} dataclass={'chatbox-2'}/>
                                                                                                                        ):(
                                                                                                                            <>
                                                                                                                                {item.reply_id !== 0 ? (
                                                                                                                                    <>
                                                                                                                                        <p className='chatbox-2'>
                                                                                                                                            {decryptDataConvertForChat(item.old_message_text, userKey, userIv) || ""}
                                                                                                                                            <p className='chatbox-2 border' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <p className='chatbox-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                )}
                                                                                                                            </>


                                                                                                                        )
                                                                                                                        }

                                                                                                                        {/* {
                                                                                                                            item.is_encrypted == 1 ?
                                                                                                                                <p className='chatbox-2'  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                :
                                                                                                                                <p className='chatbox-2'>{item.message_text || ""}</p>
                                                                                                                        } */}
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    item.message_type == 2 && item.thumbnail != null ?
                                                                                                                        <video className='message-send-vid rounded' src={IMG_URL + item.message_text} poster={IMG_URL + item.thumbnail} controls ></video>
                                                                                                                        :
                                                                                                                        <img onClick={()=>showMediaModal(item)} src={item.message_text} className='message-send-img rounded' alt="" />
                                                                                                        }
                                                                                                        <p className='chatbox-send-time m-0'>{moment(item.created_at).format("h:mm A")}
                                                                                                            {item.reaction_text_by != '' || item.reaction_text_to != '' ?
                                                                                                                (<span className='chatbox-f pointer' >{item.reaction_text_by} {item.reaction_text_to}</span>
                                                                                                                ) : (<div></div>)}
                                                                                                            <i
                                                                                                                className="far fa-smile-beam pointer"
                                                                                                                onClick={() => setActivePicker(activePicker === index ? null : index)}
                                                                                                            ></i>
                                                                                                        </p>

                                                                                                                     {/* <EmojiPicker reactionsDefaultOpen /> */}

                                                                                                        {activePicker === index && (

                                                                                                            <div ref={pickerRef} style={{ position: 'relative', zIndex: 1 }}>
                                                                                                                <EmojiPicker lazyLoadEmojis="true" skinTonesDisabled='true'  reactionsDefaultOpen="false" onEmojiClick={(event) => onEmojiClick(event, item.message_id, item, index)} />
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {hoveredMessage === item.message_id && (
                                                                                                            <div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>)
                                                                                        }
                                                                                    </>

                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}

                                                                <InfiniteScroll
                                                                    dataLength={messages.length || 0}
                                                                    next={handleScroll1}
                                                                    inverse={true} // Ensure this is set to true
                                                                    hasMore={hasMoreData}
                                                                    endMessage={<p className="text-center m-5">Your Conversation Ends Here <i class="fa-solid fa-hourglass-end"></i></p>}
                                                                    loader={<p className="text-center m-5">  <i class="fa-solid fa-spinner fa-spin-pulse fa-stack-2x"></i></p>}
                                                                    scrollableTarget="chatdiv"
                                                                >
                                                                </InfiniteScroll>

                                                                {/* <div ref={ref} />  */}

                                                            </div>
                                                    }
                                                </>
                                        }


                                        <form className='mt-auto bd-highlight ms-4'
                                            // onSubmit={(e) => {
                                            //     if (isUpdate == false) {
                                            //         handleSendMessage(e)
                                            //     } else {
                                            //         handleEditMessage(e)
                                            //     }
                                            // }}
                                            style={{ width: "96%" }}
                                        >

                                            <div className='d-flex'>
                                                {
                                                    img && img.map((i, index) => {
                                                        return (
                                                            <div className='d-flex'>
                                                                <div className='d-flex justify-content-center'>
                                                                    {type[index] === 1 ?
                                                                        <>
                                                                            <img src={i} alt='image' className='message-img object-fit-cover rounded' />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <video src={i} alt="video" className='message-img object-fit-cover rounded'></video>
                                                                        </>}
                                                                </div>
                                                                <p onClick={() => imageRemove(i, index)} className='msg-img-remove'> <img src={cancel} width={18} /></p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {replyMsg != '' &&(

                                            <div className='d-flex reply-container' >
                                                <div className='col-12'>
                                                    <div className="row p-2 ">
                                                    <div className="col-1">
                                                            <span><i class="fa-solid fa-reply px-2"></i></span>
                                                    </div>
                                                        <div className="col-10 mh-200 reply-line">
                                                            <div>Reply to {replyMsg.username || ""}</div>
                                                            <div>{replyMsg.message_text}</div>
                                                        </div>

                                                        <div className="col-1">
                                                            <span onClick={()=>setReplyMsg('')} className='pointer' aria-hidden="true"><i class="fa-regular fa-circle-xmark px-2"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}

                                            <div className='d-flex'>
                                                <div style={{ width: "100%" }} className='one-bottommsg d-flex'>
                                                    {
                                                        messageText.length >= 1 ?
                                                            <div style={{ width: "41px" }}></div>
                                                            :
                                                            <div className='add-document ps-3 pointer'>
                                                                <input type="file" id='chat-image' multiple onChange={(e) => handleFileSend(e.target.files)} accept='image/*,video/*' hidden />
                                                                <label htmlFor="chat-image"><i className="bi bi-plus-lg"></i></label>
                                                            </div>
                                                    }

                                                    <InputEmoji
                                                        value={messageText}
                                                        onChange={setMessageText}
                                                        // cleanOnEnter
                                                        className='message-text'
                                                        onEnter={(e) => handelEnterFunction('send',e) }
                                                        placeholder="Text...1"
                                                        shouldReturn='true'
                                                        onFocus
                                                    />
                                                    {/* <EmojiPicker reactions={]} /> */}

                                                    {/* <input type='text' className='message-text' style={{ padding: "12px 15px 12px 0px", flexWrap: "wrap " }} value={messageText} onChange={(e) => setMessageText(e.target.value)} placeholder='Type...' /> */}
                                                </div>
                                                <button type='button' className='one-sebd mx-3' onClick={(e)=> handelEnterFunction('send',e) }>
                                                    {
                                                        messageText.length >= 1 || data.length >= 1 ?
                                                            <img src={send} type="button" className='send-btn' /> : ""
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </>
                                : newFlag === 2 ?
                                    <>
                                        <div className="chat-human-2">
                                            <div className='chat-human-1'>
                                                <div className='chat-head' onClick={handleAdmin} >
                                                    <img src={chatroomInfo.chatroom_image ? IMG_URL + chatroomInfo.chatroom_image : placeholderimg} className='rounded object-fit-cover' width={50} height={50} />
                                                    <p className='m-0'>{chatroomInfo.chatroom_name || ""}</p>
                                                </div>
                                                <div className='message-call'>
                                                    {/* <div className='message-call-1'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div className='message-call-1'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.125 17.75H5.25C4.007 17.75 3 16.743 3 15.5V8.5C3 7.257 4.007 6.25 5.25 6.25H13.125C14.368 6.25 15.375 7.257 15.375 8.5V15.5C15.375 16.743 14.368 17.75 13.125 17.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.375 13.0972L19.17 16.1512C19.906 16.7442 21 16.2202 21 15.2752V8.72522C21 7.78022 19.906 7.25622 19.17 7.84922L15.375 10.9032" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start flex-column bd-highlight chat-data'>
                                            {
                                                isLoader ?
                                                    <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                                                        <Circles
                                                            height="40"
                                                            width="40"
                                                            color="#fff"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            visible={true}
                                                            wrapperClass="message-loader"
                                                        />
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            chatrooms == "" ?
                                                                <p className='d-flex justify-content-center align-items-center text-light h-100 w-100'>Messages Not Found</p>
                                                                :
                                                                <div className='chat-datas'>
                                                                    <div className='chatbox-day1'>
                                                                        <p className='chatbox-day'>Today</p>
                                                                    </div>
                                                                    <div id='chatdivForChatroom' style={{ height: "100vh", overflowY: "scroll", display: "flex", flexDirection: "column-reverse" }}>
                                                                        {
                                                                            chatrooms.map((item, index) => {
                                                                                if (item.message_type == 3) {
                                                                                    // if (item.message_type == 2) {
                                                                                    // var decImg = await handleRead(IMG_URL + item.message_text,index)
                                                                                    // item.message_text = decImg.imageUrl
                                                                                    // console.log("//////////////",decImg.imageUrl)
                                                                                }
                                                                                let extractedUrls = [];
                                                                                if (item.message_type == 1) {
                                                                                    extractedUrls = extractUrls(item.message_text);
                                                                                }
                                                                                return (
                                                                                    <div key={index}>
                                                                                        {
                                                                                            my_user_id != item.message_by ?
                                                                                                (<div className='chat-box'>
                                                                                                    <div className='d-flex'>
                                                                                                        <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='rounded object-fit-cover' width={50} height={50} />
                                                                                                        <div className='mb-5'>
                                                                                                            <p className='chat-1 1'>{item.username || ""}</p>
                                                                                                            {
                                                                                                                item.message_type == null ? ""
                                                                                                                    :
                                                                                                                    item.message_type == 1 ?
                                                                                                                    <>
                                                                                                                        {extractedUrls.length != 0 ? (
                                                                                                                                <LinkPreview data={item.message_text} dataclass={'chatbox-1'}/>
                                                                                                                            ):(
                                                                                                                                <>
                                                                                                                                {item.reply_id !== 0 ? (
                                                                                                                                    <>
                                                                                                                                        <p className='chatbox-1'>
                                                                                                                                            {decryptDataConvertForChat(item.old_message_text, key, iv) || ""}
                                                                                                                                            <p className='chatbox-1 border' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <p className='chatbox-1' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                )}
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }

                                                                                                                    </>
                                                                                                                        // <>
                                                                                                                        //     {
                                                                                                                        //         item.is_encrypted == 1 ?
                                                                                                                        //             <p className='chatbox-1'>{item.message_text}</p>
                                                                                                                        //             :
                                                                                                                        //             <p className='chatbox-1'>{item.message_text || ""}</p>

                                                                                                                        //     }
                                                                                                                        // </>
                                                                                                                        :
                                                                                                                        item.message_type == 2 && item.thumbnail != null ?
                                                                                                                            <video className='message-chatboxs-2 rounded mx-2' src={IMG_URL + item.message_text} poster={IMG_URL + item.thumbnail} playsinline controls ></video>
                                                                                                                            :
                                                                                                                            <img onClick={()=>showMediaModal(item)} src={item.message_text} className='message-chatboxs-1 rounded mx-2' alt="" />
                                                                                                            }
                                                                                                            <p className='chatbox-time'>{moment(item.created_at).format("h:mm A")}
                                                                                                                {item.total_reaction_count != 0 ? (
                                                                                                                    <span className='chatbox-e pointer' onClick={() => openllikeListModal(item)} > {item.total_reaction_count} {item.message_reaction.length != 0 ? item.message_reaction[0].reaction_text : ''}</span>
                                                                                                                ) : (<div></div>)}

                                                                                                                <i
                                                                                                                    className="far fa-smile-beam pointer"
                                                                                                                    onClick={() => setActivePicker(activePicker === index ? null : index)}
                                                                                                                ></i>
                                                                                                            </p>
                                                                                                            {activePicker === index && (
                                                                                                                <div ref={pickerRef} style={{ position: 'relative', zIndex: 1 }}>
                                                                                                                    <EmojiPicker lazyLoadEmojis="true" skinTonesDisabled='true'  reactionsDefaultOpen="false" onEmojiClick={(event) => onEmojiClickForGroup(event, item.message_id, item, index)} />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className='pe-2 mt-1 d-flex'>
                                                                                                        <Dropdown id='dropdown-data-option'>
                                                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                                                                <img src={options} onClick={() => { setCopy(false) }} className='option-message' alt="" />
                                                                                                            </Dropdown.Toggle>
                                                                                                            <Dropdown.Menu>
                                                                                                                <div className='option-menu'>

                                                                                                                    <div onClick={() => { setReplyMsg(item);  setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                        <p className='m-0'>Reply4</p>
                                                                                                                        <img src={trash} height={20} alt="" />
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        item.message_type == 1 ?
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.is_encrypted == 1) {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    } else {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className='menu-message d-flex justify-content-between align-items-center'
                                                                                                                            >
                                                                                                                                <p className='m-0'>
                                                                                                                                    {
                                                                                                                                        copy == true ?
                                                                                                                                            "Copied"
                                                                                                                                            :
                                                                                                                                            "Copy"
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                                <span>
                                                                                                                                    <i class="bi bi-copy"></i>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </Dropdown.Menu>
                                                                                                        </Dropdown>
                                                                                                    </div>
                                                                                                    </div>
                                                                                                </div>)
                                                                                                :
                                                                                                (<div className='chat-box-send'>
                                                                                                    <div className='d-flex'>
                                                                                                        <div className='pe-2 mt-1 d-flex'>
                                                                                                            <Dropdown id='dropdown-data-option'>
                                                                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                                                                    <img src={options} onClick={() => { setCopy(false) }} className='option-message' alt="" />
                                                                                                                </Dropdown.Toggle>
                                                                                                                <Dropdown.Menu>
                                                                                                                    <div className='option-menu'>
                                                                                                                        {
                                                                                                                            item.message_type == 1 ?
                                                                                                                                <div onClick={() => { setIsUpdate(true); setMessageText(item.message_text); setIsMessageId(item.message_id) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                                    <p className='m-0'>Edit2</p>
                                                                                                                                    <img src={edit} height={20} alt="" />
                                                                                                                                </div>
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                        <div onClick={() => { setReplyMsg(item); setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                            <p className='m-0'>Reply2</p>
                                                                                                                            <img src={trash} height={20} alt="" />
                                                                                                                        </div>
                                                                                                                        <div onClick={() => { setDeleteMessage(true); setIsMessageId(item.message_id); setChatIndex(index) }} className='menu-message d-flex justify-content-between align-items-center'>
                                                                                                                            <p className='m-0'>Delete2</p>
                                                                                                                            <img src={trash} height={20} alt="" />
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            item.message_type == 1 ?
                                                                                                                                <div
                                                                                                                                    onClick={() => {
                                                                                                                                        onClick(item.message_text)
                                                                                                                                    }}
                                                                                                                                    className='menu-message d-flex justify-content-between align-items-center'
                                                                                                                                >
                                                                                                                                    <p className='m-0'>
                                                                                                                                        {
                                                                                                                                            copy == true ?
                                                                                                                                                "Copied"
                                                                                                                                                :
                                                                                                                                                "Copy"
                                                                                                                                        }
                                                                                                                                    </p>
                                                                                                                                    <span>
                                                                                                                                        <i class="bi bi-copy"></i>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </Dropdown.Menu>
                                                                                                            </Dropdown>
                                                                                                            <img src={replys} className='reply-message' alt="" />
                                                                                                            {/* <EmojiPicker reactionsDefaultOpen /> */}
                                                                                                        </div>
                                                                                                        <div className='mb-5'>
                                                                                                            {
                                                                                                                item.message_type == null ? ""
                                                                                                                    :
                                                                                                                    item.message_type == 1 ?

                                                                                                                    <>
                                                                                                                    {extractedUrls.length != 0 ? (
                                                                                                                            <LinkPreview data={item.message_text} dataclass={'chatbox-2'}/>
                                                                                                                        ):(
                                                                                                                            <>
                                                                                                                            {item.reply_id !== 0 ? (
                                                                                                                                <>
                                                                                                                                    <p className='chatbox-2 4'>
                                                                                                                                        <div>{item.old_message_by_username}</div>
                                                                                                                                        {decryptDataConvertForChat(item.old_message_text, key, iv) || ""}
                                                                                                                                        <p className='chatbox-2 border' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                                    </p>
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <p className='chatbox-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_text) }} />
                                                                                                                            )}
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }

                                                                                                                </>

                                                                                                                        // <>
                                                                                                                        //     {
                                                                                                                        //         item.is_encrypted == 1 ?
                                                                                                                        //             <p className='chatbox-2 4'>{item.message_text}</p>
                                                                                                                        //             :
                                                                                                                        //             <p className='chatbox-2 5'>{item.message_text || ""}</p>
                                                                                                                        //     }
                                                                                                                        // </>
                                                                                                                        :
                                                                                                                        item.message_type == 2 && item.thumbnail != null ?
                                                                                                                            <video className='message-send-vid rounded' src={IMG_URL + item.message_text} poster={IMG_URL + item.thumbnail} controls ></video>
                                                                                                                            :
                                                                                                                            <img onClick={()=>showMediaModal(item)} src={item.message_text} className='message-send-img rounded' alt="" />
                                                                                                            }
                                                                                                            <p className='chatbox-send-time m-0'>{moment(item.created_at).format("h:mm A")}
                                                                                                                {item.total_reaction_count != 0 ? (
                                                                                                                    <span className='chatbox-f pointer'> {item.total_reaction_count} {item.message_reaction.length != 0 ? item.message_reaction[0].reaction_text : ''}</span>
                                                                                                                ) : (<div></div>)}
                                                                                                                <i
                                                                                                                    className="far fa-smile-beam pointer"
                                                                                                                    onClick={() => setActivePicker(activePicker === index ? null : index)}
                                                                                                                ></i>
                                                                                                            </p>


                                                                                                            {activePicker === index && (
                                                                                                                <div ref={pickerRef} style={{ position: 'relative', zIndex: 1 }}>
                                                                                                                    <EmojiPicker lazyLoadEmojis="true" skinTonesDisabled='true'  reactionsDefaultOpen="false" onEmojiClick={(event) => onEmojiClickForGroup(event, item.message_id, item, index)} />
                                                                                                                </div>
                                                                                                            )}


                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>)
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        <InfiniteScroll
                                                                            dataLength={chatrooms.length || 0}
                                                                            next={handleScroll1Chatroom}
                                                                            inverse={true} // Ensure this is set to true
                                                                            hasMore={hasMoreDataChatroom}
                                                                            endMessage={<p className="text-center m-5">Your Conversation Ends Here <i class="fa-solid fa-hourglass-end"></i></p>}
                                                                            loader={<p className="text-center m-5"> <i class="fa-solid fa-spinner fa-spin-pulse fa-stack-2x"></i></p>}
                                                                            scrollableTarget="chatdivForChatroom"
                                                                        >
                                                                        </InfiniteScroll>
                                                                        {/* <div ref={ref} /> */}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                            }
                                            <form className='mt-auto bd-highlight ms-4'
                                                // onSubmit={(e) => {
                                                //     if (isUpdate == false) {
                                                //         handleMessageSend(e)
                                                //     } else {
                                                //         handleEditMessage(e)
                                                //     }
                                                // }}
                                                style={{ width: "96%" }}
                                            >
                                                <div className='d-flex'>
                                                    {
                                                        img && img.map((i, index) => {
                                                            return (
                                                                <div className='d-flex'>
                                                                    <div className='d-flex justify-content-center'>
                                                                        {type[index] === 1 ?
                                                                            <>
                                                                                <img src={i} alt='image' className='message-img object-fit-cover rounded' />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <video src={i} alt="video" className='message-img object-fit-cover rounded'></video>
                                                                            </>}
                                                                    </div>
                                                                    <p onClick={() => imageRemove(i, index)} className='msg-img-remove'> <img src={cancel} width={18} /></p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {replyMsg != '' &&(
                                                        <div className='d-flex reply-container' >
                                                            <div className='col-12'>
                                                                <div className="row p-2 ">
                                                                <div className="col-1 text-start">
                                                                <img src={replyMsg.profile_pic ? IMG_URL + replyMsg.profile_pic : placeholderimg} width={50} height={50} className='rounded object-fit-cover' />
                                                                    </div>
                                                                    <div className="col-10 my-auto">
                                                                        <span>{replyMsg.username}</span>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <span onClick={()=>setReplyMsg('')} className='pointer' aria-hidden="true"><i class="fa-regular fa-circle-xmark px-2"></i></span>
                                                                    </div>
                                                                    <div className="col-1 pt-2">
                                                                        <span><i class="fa-solid fa-reply px-2"></i></span>
                                                                    </div>
                                                                    <div className="col-10 mh-200 reply-line pt-2">
                                                                        <span>{replyMsg.message_text}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                {flagForChatGr != 'ANNOUNCEMENT' &&(

                                                    <div className='d-flex'>
                                                    <div style={{ width: "100%" }} className='one-bottommsg d-flex'>
                                                        {
                                                            messageText.length >= 1 ?
                                                                <div style={{ width: "41px" }}></div>
                                                                :
                                                                <div className='add-document ps-3'>
                                                                    <input type="file" id='chat-image' multiple onChange={(e) => handleFileSend(e.target.files)} accept='image/*,video/*' hidden />
                                                                    <label htmlFor="chat-image"><i className="bi bi-plus-lg"></i></label>
                                                                </div>
                                                        }

                                                        <InputEmoji
                                                            value={messageText}
                                                            onChange={setMessageText}
                                                            // onKeyPress={handleKeyPress}
                                                            // onEnter={handleKeyPress}
                                                            onEnter={(e) => handleKeyPress('send',e) }
                                                            className='message-text'
                                                            placeholder="Text...2"
                                                            onFocus
                                                        />

                                                        {/* <input type='text' className='message-text' style={{ padding: "12px 15px 12px 0px", flexWrap: "wrap " }} value={messageText} onChange={(e) => setMessageText(e.target.value)} placeholder='Type...' /> */}
                                                    </div>
                                                    <button type='button' className='one-sebd mx-3' onClick={(e)=>handleKeyPress('send',e)}>
                                                        {
                                                            messageText.length >= 1 || data.length >= 1 ?
                                                                <img src={send} type="button" className='send-btn' /> : ""
                                                        }
                                                    </button>
                                                    </div>
                                                )}

                                            </form>

                                        </div>
                                    </>
                                    : newFlag === 3 ?
                                        <>
                                            <div className="chat-human-2">
                                                <div className='chat-human-1'>
                                                    <div className='chat-head' onClick={handleAdmin} >
                                                        <img src={chat2} width={60} />
                                                        <p className='m-0'>Food Mania</p>
                                                    </div>
                                                    <div className='message-call'>
                                                        <div className='message-call-1'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <div className='message-call-1'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 17.75H5.25C4.007 17.75 3 16.743 3 15.5V8.5C3 7.257 4.007 6.25 5.25 6.25H13.125C14.368 6.25 15.375 7.257 15.375 8.5V15.5C15.375 16.743 14.368 17.75 13.125 17.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M15.375 13.0972L19.17 16.1512C19.906 16.7442 21 16.2202 21 15.2752V8.72522C21 7.78022 19.906 7.25622 19.17 7.84922L15.375 10.9032" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-start flex-column bd-highlight chat-data'>
                                                <div className='chat-datas'>

                                                    <div className='chatbox-day1'>
                                                        <p className='chatbox-day'>Today</p>
                                                    </div>
                                                    <div className='chat-box'>
                                                        <div>
                                                            <div className='chat-1'>
                                                                <img src={human6} width={50} />
                                                                <p>Alisa Williams</p>
                                                            </div>
                                                            <p className='chatbox-1'>Okay,I'm definitely doing this.</p>
                                                            <p className='chatbox-time'>09:25 AM</p>
                                                        </div>
                                                    </div>
                                                    <div className='chat-box-send'>
                                                        <div>
                                                            <p className='chatbox-2'>Okay,I'm definitely doing this.</p>
                                                            <p className='chatbox-send-time'>09:25 AM</p>
                                                        </div>
                                                    </div>
                                                    <div className='chat-box'>
                                                        <div>
                                                            <div className='chat-1'>
                                                                <img src={human4} width={50} />
                                                                <p>Alisa Williams</p>
                                                            </div>
                                                            <p className='chatbox-1'>Okay,I'm definitely doing this.</p>
                                                            <p className='chatbox-time'>09:25 AM</p>
                                                        </div>
                                                    </div>
                                                    <div className='chat-box-send'>
                                                        <div>
                                                            <p className='chatbox-2'>Okay,I'm definitely doing this.</p>
                                                            <p className='chatbox-send-time'>09:25 AM</p>
                                                        </div>
                                                    </div>
                                                    <div className='chat-box'>
                                                        <div>
                                                            <div className='chat-1'>
                                                                <img src={human5} width={50} />
                                                                <p>Alisa Williams</p>
                                                            </div>
                                                            <p className='chatbox-1'>Okay,I'm definitely doing this.</p>
                                                            <p className='chatbox-time'>09:25 AM</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mt-auto bd-highlight inp-boxchat'>
                                                    <div style={{ width: "100%" }} className='one-bottommsg d-flex'>
                                                        <div className='add-document ps-3 pe-3'>
                                                            <i className="bi bi-plus-lg"></i>
                                                        </div>
                                                        <input type='text' className='message-text' placeholder='Type...' />
                                                    </div>
                                                    <div className='one-sebd mx-3'>
                                                        <img src={send} className='send-btn' />
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        : ""
                    }
                </div>
            </div>

            {/* <MemberSidebar /> */}
            <GroupAdmin profile={profiles} />

            {/* <Modal
                show={likeListModal} onHide={() => setLikeListModal(false)}
                centered
            >

            </Modal> */}

            <Modal
                show={deleteMessage} onHide={() => setDeleteMessage(false)}
                centered
            >

                <div className='text-center mt-4 mb-4'>
                    <img src={delete1} width={100} />
                </div>
                <p className='text-center remove-acc'>Are you absolutely certain that you <br />
                    want to proceed with deleting
                    this Message ?</p>
                <div className='kyc-con-btn'>
                    <button className="delete-btn" onClick={() => handleDeleteMessage()}>
                        {isLoading ? <Spinner className="loderImage" animation="border" role="status">
                            < span className="visually-hidden">Loading...</span>
                        </Spinner> : "Yes, Delete"}
                    </button>
                </div>

            </Modal>

            <Modal
                show={room} onHide={() => setRoom(false)}
                centered
            >
                <div className='boost-purchage'>
                    <p>Create Chatroom</p>
                    <div onClick={() => setRoom(false)} className='purchage-close'>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />
                <div className='boost-content'>
                    <form action="" onSubmit={(e) => handleSubmit(e)}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div>
                                <img src={groupImg ? groupImg : uploadimg} width={110} height={110} className='object-fit-cover rounded' alt="" />
                            </div>
                            <input type="file" id="chatroom-form" hidden multiple onChange={(e) => handleProfile(e)} accept='image/*' name="img" />
                            <div className='upload-img-dp'>
                                <label htmlFor="chatroom-form" className=''><img src={upload} width={20} style={{ marginRight: "15px" }} />Upload</label>
                            </div>
                        </div>
                        <div className="lgn-input-1">
                            <input type="text" name='chatroom_name' autoComplete='off' onChange={handleChange} className="i-put-1" required />
                            <label htmlFor="text">
                                <span>Chatroom Name</span>
                            </label>
                        </div>
                        <div className="lgn-input-1">
                            <input type="text" name='chatroom_description' autoComplete='off' onChange={handleChange} className="i-put-1" required />
                            <label htmlFor="text">
                                <span>Descrption</span>
                            </label>
                        </div>
                        <div className='room-addmember d-flex justify-content-between align-items-center' onClick={() => { handleGetuser(); setAddMemner(true) }}>
                            <p className='m-0'>Add Members ( {memberId.length || "-"} )</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                            </svg>
                        </div>

                        <div className='d-flex mt-3'>
                            <label className="act-disk act-radio mt-2 me-3" >
                                <p className='m-0'>Private</p>
                                <input checked={account === "PRIVATE"} onChange={handleOption} value="PRIVATE" type="radio" name='active' className="purchs-radio popup-redio" required />
                            </label>

                            <label className="act-disk act-radio mt-2">
                                <p className='m-0'>Public</p>
                                <input checked={account === "PUBLIC"} onChange={handleOption} value="PUBLIC" type="radio" name='active' className="purchs-radio popup-redio" required />
                            </label>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className="act-disk act-radio mt-2 me-3" >
                                <p className='m-0'>Group</p>
                                <input type="radio" checked={optionRoom === "GROUP"} onChange={handleOptions} value="GROUP" name='actives' className="purchs-radio popup-redio" required />
                            </label>

                            <label className="act-disk act-radio mt-2">
                                <p className='m-0'>Announcement</p>
                                <input type="radio" checked={optionRoom === "ANNOUNCEMENT"} onChange={handleOptions} value="ANNOUNCEMENT" name='actives' className="purchs-radio popup-redio" required />
                            </label>
                        </div>

                        <button type='submit' className="bt-1 mt-4">
                            {loading ? <Spinner className="loderImage" animation="border" role="status">
                                < span className="visually-hidden">Loading...</span>
                            </Spinner> : "Create"}
                        </button>
                    </form>
                </div>
            </Modal>

            <Modal
                show={addMember} contentClassName='popup-box-fixed' onHide={() => setAddMemner(false)}
                centered
            >
                <div className='crt-disc'>
                    <img src={back} width={25} onClick={() => setAddMemner(false)} />
                    <p className='m-0'>Add Member</p>
                    {/* <button className='crt-post-btn' onClick={() => setAddMemner(false)}>Save</button> */}
                </div>

                <span className='hr-line'>
                    <hr />
                </span>
                {
                    isLoader ?
                        <Circles
                            height="35"
                            width="35"
                            color="#fff"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            visible={true}
                            wrapperClass="get-user"
                        />
                        :
                        <>
                            {
                                uesrList == "" ?
                                    <p className='text-light d-flex justify-content-center'>No Data Found</p>
                                    :
                                    <>
                                        {
                                            uesrList.map((item, index) => {
                                                // console.log(item)
                                                return (
                                                    <label key={index} className='invite-plate'>
                                                        <div className='invite-text'>
                                                            <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='hype-img-1' />
                                                            <p className='m-0'>{item.first_name || ""} {item.last_name || ""}</p>
                                                        </div>
                                                        <input type='checkbox'
                                                            checked={isUserSelected(item.follow_to)}
                                                            onChange={() => { handleUserChange(item.follow_to) }}
                                                            className="purchs-radio popup-redio" name='' />
                                                    </label>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </>
                }
            </Modal>

            <Modal
                show={likeListModal} onHide={() => setLikeListModal(false)}
                centered
            >
                <div className="hyep-head">
                    <div className="d-flex">
                        All Reactions
                    </div>
                    <div >
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />

                {singleMessage.length !== 0 ? (
                    <>
                        {singleMessage.map((item, index) => (
                            <div className="col-12 pt-3" key={index}>
                                <div className="row pointer m-0 py-2">
                                    <div className="col-2 text-center my-auto">
                                        <img
                                            src={IMG_URL + item.profile_pic}
                                            className="hype-img-1"
                                            alt="Profile"
                                        />
                                    </div>
                                    <div className="col-7 text-start my-auto">
                                        <span className="text-white small-font1 text-uppercase">
                                            {item.first_name} {item.last_name}
                                        </span>
                                        <br />
                                    </div>
                                    <div className="col-3 text-end my-auto ">
                                        {item.reaction_text}
                                    </div>
                                    {/* {my_user_id == item.user_id ? (
                                        <span className="col-4 text-white small-font1 my-auto pointer">
                                        Tap to remove
                                    </span>
                                    ):('')} */}

                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div>
                        <p className="text-light d-flex justify-content-center">
                            No Data Found
                        </p>
                    </div>
                )}




            </Modal>

            {/* show media modal */}
            <Modal
                size="lg"
                onHide={() => setLgShow(false)}
                    show={decryptMediaModel}
                    onClick={() => setDecryptMediaModel(false)}
                    centered
                >

                    <Modal.Body className="p-0">
                    {decryptMediaData?.message_type === 3 ? (
                        <img
                        className="modal-content-new"
                        src={decryptMediaData?.message_text}
                        alt="Image"
                        />
                    ) : decryptMediaData?.message_type === 2 ? (
                        <video
                        className="modal-content-new"
                        src={decryptMediaData?.message_text}
                        controls
                        autoPlay
                        />
                    ) : (
                        <div>no data</div>
                    )}
                    <div id="caption"></div>
                    </Modal.Body>
                    <Modal.Footer>
                    <div className="col-12">
                    <div className="row p-1">
                        <div className="col-6">
                        <button type="button" className="btncreate pointer"  onClick={() => downlaodFileHandler(decryptMediaData?.item)}>
                            Download
                        </button>
                        </div>
                        <div className="col-6">
                        <button
                            onClick={() => setDecryptMediaModel(false)}
                            className="btncancel pointer"
                        >
                            close
                        </button>
                        </div>
                    </div>
                    </div>
                    </Modal.Footer>
            </Modal>







        </>
    )
}

export default Message

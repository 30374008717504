import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const MediaEncryptor = () => {
  const [mediaFile, setMediaFile] = useState(null);
  const [encryptedFile, setEncryptedFile] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    // setMediaFile(file);
    var myHeaders1 = new Headers();
        myHeaders1.append("Authorization", localStorage.getItem("UserToken"));

    const formData = new FormData();
    formData.append('file', file);

    try {
            fetch('api/drive/uploadFileEncrypted', {
                method: 'POST',
                body: formData,
                headers: myHeaders1,
            })
                .then(response => response.json())
                .then(response => {
                     console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                })


      const response = await axios.post('api/drive/uploadFileEncrypted', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const encryptFile = () => {
    if (!mediaFile) return;

    const reader = new FileReader();

    reader.onload = (event) => {
    console.log(event.target)

      const content = event.target.result;
      const encryptedContent = CryptoJS.AES.encrypt(content, 'your-secret-key').toString();
      setEncryptedFile(encryptedContent);
    };

    reader.readAsDataURL(mediaFile);
    console.log(encryptedFile)
  };
  const uploadEncryptedFile = async () => {
    if (!encryptedFile) return;

    const formData = new FormData();
    formData.append('file', mediaFile);

    try {
      const response = await axios.post('api/drive/uploadFileEncrypted', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={encryptFile}>Encrypt Media</button>
      {encryptedFile && <a href={encryptedFile} download>Download Encrypted Media</a>}
      <button onClick={uploadEncryptedFile}>Upload Encrypted Media</button>
    </div>
  );
};

export default MediaEncryptor;

import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery';
import { BASE_URL } from '../API/ApiUrl'
import axios from 'axios';

const apiVersion = "v1";


export const ApiData = createApi({
    reducerPath: 'datag8',
    baseQuery: baseQuery,
    tagTypes: ['getFolderData', 'getSocialMediaData','deleteSocialMediaFromDg8'],
    endpoints: (build) => ({
        getFileDirectores: build.query({
            query: (data) => ({
                // url: `api/drive/getFolderData/${data?.id}?page=${data?.page}&fileType=${data?.fileType ? data?.fileType : ''}`,
                url: `api/drive/getFolderData/${data?.id}?page=${data?.page}&${data?.fileType ? `fileType=${data?.fileType}` : ''}&${data?.orderBy ? `sort=${data?.orderBy}` : ''}&${data?.search ? `search=${data?.search}` : ''}
                `,
                
                method: 'GET',
            }),
            providesTags: ['getFolderData']
        }),
        updateSocialMediaFileName: build.mutation({
            query: ({ body, id }) => ({
                url: `api/drive/updateNameSocialMedia/${id}`,
                method: 'PUT',
                body: body
            }),
            providesTags: ['getSocialMediaData'],
            // transformResponse: (response, meta, arg) => {
            //     return response;
            //   },
        }),
        deleteSocialMediaFromDg8: build.mutation({
            query: ({ body }) => ({
                url: `api/drive/deleteSocialMediaFromDg8`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['getSocialMediaData'],
             
        }),
        deleteNotification: build.mutation({
            query: (body) => ({
                url: BASE_URL+`delete_notification`,
                method: 'DELETE',
                body: body
            }), 
            // invalidatesTags: ['getSocialMediaData'],
        }),
        updateFileName: build.mutation({
            query: ({ body, id }) => ({
                url: `api/drive/updateName/${id}`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: ['getFolderData'],
            // transformResponse: (response, meta, arg) => {
            //     return response;
            //   },
        }),
        createFolder: build.mutation({
            query: (body) => ({
                url: `api/drive/createFolder`,
                method: 'POST',
                body: body
            }),
            // invalidatesTags: ['getFolderData'],
        }),
        moveFilesAndFolder: build.mutation({
            query: (body) => ({
                url: `api/drive/moveFile`,
                method: 'POST',
                body: body
            }),
            // invalidatesTags: ['getFolderData'],
        }),
        deleteFiles: build.mutation({
            query: (body) => ({
                url: `api/drive/deleteFile`,
                method: 'DELETE',
                body: body
            }),
            // invalidatesTags: ['getFolderData'],
            // transformResponse: (response, meta, arg) => {
            //     return response;
            //   },
        }),
        uploadFileForDrive: build.mutation({
            query: (body) => ({
                url: `api/drive/uploadFile`,
                method: 'POST',
                body: body,
            }),
            // queryFn: async ( data) => {
            //     try{
            //         const result = await axios.post( 'api/drive/uploadFil', data, {
            //             //...other options like headers here
            //             onUploadProgress: upload => {
            //               //Set the progress value to show the progress bar
            //         let uploadloadProgress = Math.round((100 * upload.loaded) / upload.total);    
            //         console.log(`upload file ${uploadloadProgress}`)                     
            //         //   api.dispatch(setUploadProgress(uploadloadProgress ));
            //             },
            //         });
            //         console.log(result);
            //         return { data: result }
            //     }
            //     catch (axiosError) {
            //         let err = axiosError
            //         return {
            //               error: {
            //                 status: err.response?.status,
            //                 data: err.response?.data || err.message,
            //               },
            //         }
            //   }
            // },


            invalidatesTags: ['getFolderData'],
        }),
        generateDrivePassword: build.mutation({
            query: (body) => ({
                url: `api/drive/generateDrivePassword`,
                method: 'POST',
                body: body
            })
        }),
        loginDrive: build.mutation({
            query: (body) => ({
                url: `api/drive/loginDrive`,
                method: 'POST',
                body: body
            })
        }),
        socialMediaData: build.query({
            query: (queryData) => 
            ({
                url: `api/drive/getSocialMediaData/${queryData.queryData}/${queryData.page}`,
                method: 'GET',
            }),
            providesTags: ['getSocialMediaData']
        }), 
          getTrashData: build.query({
            query: (queryData) => 
            ({
                url: `api/drive/getTrashData`,
                method: 'GET',
            }),
            providesTags: ['getTrashData']
        }), 

        checkDrivePasswordGenerated: build.query({
            query: (id) => ({
                url: `api/drive/checkDrivePasswordGenerated`,
                method: 'GET',
            }),
            providesTags: ['checkDrivePasswordGenerated']
        }),
        backupDeletedFile: build.mutation({
            query: (body) => ({
                url: `api/drive/backupDeletedFile`,
                method: 'POST',
                body: body
            })
        }),
        listPost: build.mutation({
            query: (body) => ({
                url: BASE_URL+`list_post`,
                method: 'POST',
                body: body
            }),
            // invalidatesTags: ['getFolderData'],
        }),
        addPost: build.mutation({
            query: (body) => ({
                url: BASE_URL+`add_post`,
                method: 'POST',
                body: body
            }),
            // providesTags: ['listPost'],

        }),
        permanentDeleteFile: build.mutation({
            query: (body) => ({
                url: `api/drive/permanentDeleteFile`,
                method: 'POST',
                body: body
            }),
            // providesTags: ['listPost'],
        }),
        getDriveShareMediaData: build.query({
            query: () => ({
                url: `api/drive/getDriveShareMediaData`,
                method: 'GET',
            }),
            providesTags: ['getDriveShareMediaData']
        }),
        getFavouritesData: build.query({
            query: () => ({
                url: `api/drive/getFavouritesData`,
                method: 'GET',
            }),
            providesTags: ['getFavouritesData']
        }),
        addRemoveFavourites: build.mutation({
            query: (body) => ({
                url: `api/drive/addRemoveFavourites`,
                method: 'POST',
                body: body
            }),
            // providesTags: ['listPost'],
        }),
        shareMedia: build.mutation({
            query: (body) => ({
                url: `api/drive/shareMedia`,
                method: 'POST',
                body: body
            }),
            // providesTags: ['listPost'],
        }),
        getCoinOverview : build.query({
            query: (queryData) => 
            ({
                url:  BASE_URL+`getCoinOverview`,
                method: 'GET',
            }),
            providesTags: ['getCoinOverview']
        }),
    })
})

export const {
    // useGetCitiesQuery,
    useCreateFolderMutation,
    useUploadFileForDriveMutation,
    useUpdateFileNameMutation,
    useDeleteFilesMutation, 
    // useSocialMediaDataQuery,
    useLazyCheckDrivePasswordGeneratedQuery,
    useGenerateDrivePasswordMutation,
    useLoginDriveMutation,
    useLazyGetFileDirectoresQuery,
    useUpdateSocialMediaFileNameMutation,
    useDeleteSocialMediaFromDg8Mutation,
    useLazySocialMediaDataQuery,
    useLazyGetTrashDataQuery,
    useMoveFilesAndFolderMutation,
    useBackupDeletedFileMutation,
    useAddPostMutation,
    useListPostMutation,
    usePermanentDeleteFileMutation,
    useLazyGetFavouritesDataQuery,
    useLazyGetDriveShareMediaDataQuery,
    useAddRemoveFavouritesMutation,
    useDeleteNotificationMutation,
    useLazyGetCoinOverviewQuery,
    useShareMediaMutation,
} = ApiData;
import React, { useState } from 'react'
import "./PrivacyPolicy.css"
import bar from "../assets/img/Sidebar/menu (1).png"
import Sidebar from '../Sidebar/Sidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import back from "../assets/img/Sidebar/back-arrow.png"


function PrivacyPolicy() {
    const navigate = useNavigate();
    const location = useLocation();

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} width={50} alt="" />
            <Sidebar />

            <div className='sub-profile'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-1 mx-4 text-start'>
                            <Link className={location.pathname === "/setting" ? 'actives' : 'sidedata' } to={"/setting"}  >
                                <img className="img-20 pointer" src={back} width={35} />
                            </Link>
                        </div>
                    </div>
                </div>


                        <div className="">
                          <p className='text-center activ pb-3'>Privacy Policy</p>

                          <p class="px-5">This privacy policy (“Policy”) describes how the personally identifiable information (“Personal Information”) you may provide on the ONEG8.ONE website, Platform or the Ecosystem (“Website” or “Service”) and any of its related products and services (collectively, “Services”) is collected, protected and used.  </p>

                                <p class="px-5">It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy is a legally binding agreement between you (“User”, “you” or “your”) and ONEG8.ONE (“ONEG8.ONE”, “we”, “us” or “our”).  </p>

                                <p class="px-5">By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage. </p>

                                <p class="px-5 p-big">Automatic collection of information </p>

                                <p class="px-5">When you open the Website and Platform, our servers automatically record information that your browser sends. This data may include information such as your device’s IP address, browser type and version, operating system type and version, language preferences. We are not collecting any kind of personal identifiable data for the use of the App and Website or Ecosystem </p>

                                <p class="px-5">Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services.  </p>

                                <p class="px-5">This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system. </p>

                                <p class="px-5 p-big">Collection of personal information </p>

                                <p class="px-5">You can access and use the Website and Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features on the Website, you may be asked to provide certain Personal Information (for example, your name and e-mail address).  </p>

                                <p class="px-5">We receive and store any information you knowingly provide to us when you create an account, publish content, make a purchase, or fill any online forms on the Website and the App. When required, this information may include the following: </p>

                                <p class="px-5">Personal details such as name, country of residence, etc. </p>

                                <p class="px-5">Contact information such as email address, address, etc. </p>

                                <p class="px-5">Account details such as username, unique user ID, password, etc. </p>

                                <p class="px-5">Proof of identity such as photocopy of a government ID. </p>

                                <p class="px-5">Any other materials you willingly submit to us such as articles, images, feedback, etc. </p>

                                <p class="px-5">Some of the information we collect is directly from you via the Website and Services.  </p>

                                <p class="px-5">You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features and services on the Website and the Application.  </p>

                                <p class="px-5">Users who are uncertain about what information is mandatory are welcome to contact us. </p>

                                <p class="px-5 p-big">Use and processing of collected information </p>

                                <p class="px-5">In order to make the Website and Services available to you, or to meet a legal obligation, we need to collect and use certain Personal Information especially for the payment and subscription fees or if you buy any other service on the App or the Platform. If you do not provide the information that we request, we may not be able to provide you with all the requested products or services. Any of the information we collect from you may be used for the following purposes: </p>

                                <p class="px-5">• Create and manage user accounts </p>

                                <p class="px-5">• Fulfill and manage orders </p>

                                <p class="px-5">• Deliver products or services </p>

                                <p class="px-5">• Improve products and services </p>

                                <p class="px-5">• Send administrative information </p>

                                <p class="px-5">• Respond to inquiries and offer support </p>

                                <p class="px-5">• Request user feedback </p>

                                <p class="px-5">• Improve user experience </p>

                                <p class="px-5">• Post customer testimonials </p>

                                <p class="px-5">• Administer prize draws and competitions </p>

                                <p class="px-5">• Enforce terms and conditions and policies </p>

                                <p class="px-5">• Protect from abuse and malicious users </p>

                                <p class="px-5">• Respond to legal requests and prevent harm </p>

                                <p class="px-5">• Run and operate the Website and Services </p>

                                <p class="px-5">Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; this, however, does not apply, whenever the processing of Personal Information is subject to California Consumer Privacy Act or European data protection law; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. </p>

                                <p class="px-5">Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter a contract. In any case we are deleting your data on the account after 90 days if there is not governmental requirement to keep some of the data longer.  </p>

                                <p class="px-5 p-big">Billing and payments </p>

                                <p class="px-5">In case of services requiring payment, we request credit card or other payment account information, which will be used solely for processing payments. Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction.  </p>

                                <p class="px-5">After that is complete, your purchase transaction information is deleted. All direct payment gateways adhere to the latest security standards as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.  </p>

                                <p class="px-5">Scans for malware are performed on a regular basis for additional security and protection. </p>

                                <p class="px-5 p-big">Managing information </p>

                                <p class="px-5">You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Website and Services change.  </p>

                                <p class="px-5">When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below.  </p>

                                <p class="px-5">If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account on the Website. </p>

                                <p class="px-5 p-big">Disclosure of information </p>

                                <p class="px-5">Depending on the requested Services or as necessary to complete any transaction or provide any service you have requested, we may share your information with your consent with our trusted third parties that work with us, any other affiliates, and subsidiaries we rely upon to assist in the operation of the Website and Services available to you.  </p>

                                <p class="px-5">We do not share Personal Information with unaffiliated third parties. These service providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements.  </p>

                                <p class="px-5">We may share your Personal Information for these purposes only with third parties whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. These third parties are given Personal Information they need only in order to perform their designated functions, and we do not authorize them to use or disclose Personal or share Information for their own marketing or other purposes. </p>

                                <p class="px-5">We will disclose any Personal Information we collect, use or receive if legally required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. </p>

                                <p class="px-5 p-big">Retention of information </p>

                                <p class="px-5">We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted completely and no copies are held in any form.  </p>

                                <p class="px-5">Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period. </p>

                                <p class="px-5 p-big">Transfer of information </p>

                                <p class="px-5">Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. You are entitled to learn about the legal basis of information transfers to a country outside the European Union or Switzerland. </p>

                                <p class="px-5">If any such transfer takes place, you can find out more by checking the relevant sections of this Policy or inquire with us using the information provided in the contact section. </p>

                                <p class="px-5 p-big">The rights of users </p>

                                <p class="px-5">You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance.  </p>

                                <p class="px-5">This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof. </p>

                                <p class="px-5 p-big">The right to object to processing </p>

                                <p class="px-5">Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection. </p>

                                <p class="px-5">Data protection rights under GDPR </p>

                                <p class="px-5">If you are a resident of the European Economic Area (EEA), you have certain data protection rights and ONEG8.ONE aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information if any are collected for payment or service purposes. Other than this we are not collecting any data from you.   </p>

                                <p class="px-5">If you wish to be informed what Personal Information, we hold about you and if you want it to be removed from our systems, please contact us. In certain circumstances, you have the following data protection rights: </p>

                                <p class="px-5">You have the right to request access to your Personal Information that we store and have the ability to access your Personal Information. </p>

                                <p class="px-5">You have the right to request that we correct any Personal Information you believe is inaccurate. You also have the right to request us to complete the Personal Information you believe is incomplete. </p>

                                <p class="px-5">You have the right to request the erase your Personal Information under certain conditions of this Policy. </p>

                                <p class="px-5">You have the right to object to our processing of your Personal Information. </p>

                                <p class="px-5">You have the right to seek restrictions on the processing of your Personal Information. When you restrict the processing of your Personal Information, we may store it but will not process it further. </p>

                                <p class="px-5">You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format. </p>

                                <p class="px-5">You also have the right to withdraw your consent at any time where ONEG8.ONE relied on your consent to process your Personal Information of there are any. </p>

                                <p class="px-5">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority in the European Economic Area (EEA). </p>

                                <p class="px-5 p-big">California privacy rights </p>

                                <p class="px-5">In addition to the rights as explained in this Policy, California residents who provide Personal Information (as defined in the statute) to obtain products or services for personal, family, or household use are entitled to request and obtain from us, once a calendar year, information about the Personal Information we shared, if any, with other businesses for marketing uses.  </p>

                                <p class="px-5">If applicable, this information would include the categories of Personal Information and the names and addresses of those businesses with which we shared such personal information for the immediately prior calendar year (e.g., requests made in the current year will receive information about the prior year). To obtain this information please contact us. </p>

                                <p class="px-5 p-big">How to exercise these rights </p>

                                <p class="px-5">Any requests to exercise your rights can be directed to ONEG8.ONE through the contact details provided in this document. Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you. </p>

                                <p class="px-5 p-big">Privacy of children </p>

                                <p class="px-5">We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Website and Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Website and Services, please contact us. You must also be at least 16 years of age to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf). </p>

                                <p class="px-5 p-big">Cookies </p>

                                <p class="px-5">The Website and Services do not use any “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server.  </p>

                                <p class="px-5">We may use cookies in future to collect, store, and track information for statistical purposes to operate the Website and Services. In such case you would have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the features of the Website and Services. To learn more about cookies and how to manage them, visit internetcookies.org </p>

                                <p class="px-5 p-big">Do Not Track signals </p>

                                <p class="px-5">Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. ONEG8.ONE Website/ Application and Services do not track its visitors over time and across third party websites. However, ONEG8 is operating a local Map that is showing you the offers in your environment. None of this tracking information are recorded in any form and do help us only to identify your position in that moment. You are free to use the “Ghost Mode” button to not be visible at all for others on the Map. You will still see all the offers available in your environment.  </p>

                                <p class="px-5 p-big">Affiliates </p>

                                <p class="px-5">We are not disclosing information about you to our or any affiliates other than for the subscription fees calculation and in case of service provided to support legal requirements.  </p>

                                <p class="px-5 p-big">Email marketing </p>

                                <p class="px-5">We will offer in future electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section. We will maintain the information sent via e-mail in accordance with applicable laws and regulations. </p>

                                <p class="px-5">In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails. </p>

                                <p class="px-5 p-big">Links to other resources </p>

                                <p class="px-5">The Website/ Application and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information. </p>

                                <p class="px-5 p-big">Information security </p>

                                <p class="px-5">We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information if any collected, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts. </p>

                                <p class="px-5 p-big">Data breach </p>

                                <p class="px-5">In the event we become aware that the security of the Website and Services has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities.  </p>

                                <p class="px-5">In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the Website, send you an email. </p>

                                <p class="px-5 p-big">Changes and amendments </p>

                                <p class="px-5">We reserve the right to modify this Policy or its terms relating to the Website and Services from time to time in our discretion and will notify you of any material changes to the way in which we treat Personal Information. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified.  </p>

                                <p class="px-5">Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected. </p>

                                <p class="px-5 p-big">Acceptance of this policy </p>

                                <p class="px-5">You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services. </p>

                                <p class="px-5 p-big">Contacting us </p>

                                <p class="px-5">If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so via:  Contact@ONEG8.ONE  </p>

                                <p class="px-5">This document was last updated on April 18th, 2024 </p>

                                <p class="px-5">We are not processing any personal data from your others then needed for payment purpose and service provided. In this case the handling is either by the credit card operators or the financial institution which information are provided in the respective field and section. No other Institute or processor is appointed or has access to any of your data whatsoever. After deleting your account your uploads and data in chats and other services on the Account are hard deleted and no copy is held after the legal requested period of time in each country.  </p>

                                <p class="px-5">During the Testing Phase and until the final registration in Switzerland, all data and service are provided by our European corporation.  </p>

                                <p class="px-5">ONEG8 ONE Europe KFT. </p>

                                <p class="px-5">9400 Sopron – Hungary – Corp Nr: 2395 7752 -1- 08 – Reg Nr: 08-09- 023 669 </p>

                                <p class="px-5 py-5"></p>
                        </div>


            </div>

        </>
    )
}

export default PrivacyPolicy

import CryptoJS from 'crypto-js';

const encryptionKey = "ayax-a0o3-va-xvv";
const iv = "t3yd-vt63-ya-xur";

export const generateChatKey = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    const generateRandomString = (length) => {
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters[Math.floor(Math.random() * charactersLength)];
        }
        return result;
    };

    const key = `${generateRandomString(4)}-${generateRandomString(4)}-${generateRandomString(2)}-${generateRandomString(3)}`;
    const iv = `${generateRandomString(4)}-${generateRandomString(4)}-${generateRandomString(2)}-${generateRandomString(3)}`;

    console.log(key, iv);
    return { key, iv };
};

export const encryptData = (data) => {
    const jsonString = JSON.stringify(data);
    const utf8Data = CryptoJS.enc.Utf8.parse(jsonString);
    const ciphertext = CryptoJS.AES.encrypt(utf8Data, CryptoJS.enc.Utf8.parse(encryptionKey), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const base64EncryptedData = ciphertext.toString(CryptoJS.format.Base64);
    return base64EncryptedData;
};

export const decryptData = (encryptedData) => {
    const ciphertext = encryptedData; // Assuming encryptedData is the encrypted string
    const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(encryptionKey), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    try {
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        const jsonData = JSON.parse(decryptedText);
        return jsonData;
    } catch (error) {
        console.error('Error parsing decrypted JSON:', error);
        return null; // Or handle the error accordingly
    }
};
export const walletEncryptfunction = (data,superkey) => {
    console.log('data,superkey',data,superkey)
    var CryptoJS = require("crypto-js");
    var encryptionKey = CryptoJS.enc.Utf8.parse(superkey);
    var iv = CryptoJS.lib.WordArray.random(16);
    var encrypted = CryptoJS.AES.encrypt(data, encryptionKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var combined = CryptoJS.lib.WordArray.create().concat(iv).concat(encrypted.ciphertext);
    var base64Result = CryptoJS.enc.Base64.stringify(combined);
        return base64Result;
};
export const walletDecryptfunction = (data,superkey) => {
    var CryptoJS = require("crypto-js");
    var  decoded = CryptoJS.enc.Base64.parse(data);
    var  iv = CryptoJS.lib.WordArray.create(decoded.words.slice(0, 4));
    var  ciphertext = CryptoJS.lib.WordArray.create(decoded.words.slice(4));

    var  decrypted = CryptoJS.AES.decrypt({
        ciphertext: ciphertext
    }, CryptoJS.enc.Utf8.parse(superkey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
      return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encryptDataConvertForChat = (data, chatSec, chatIv) => {
    const jsonString = JSON.stringify(data);
    const utf8Data = CryptoJS.enc.Utf8.parse(jsonString);
    const ciphertext = CryptoJS.AES.encrypt(utf8Data, CryptoJS.enc.Utf8.parse(chatSec), {
        iv: CryptoJS.enc.Utf8.parse(chatIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const encryptDataChat = ciphertext.toString(CryptoJS.format.Base64);
    return encryptDataChat;
};

export const decryptDataConvertForChat = (data, chatSec, chatIv) => {
    const ciphertext = data; // Assuming encryptedData is the encrypted string
    const bytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(chatSec), {
        iv: CryptoJS.enc.Utf8.parse(chatIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    try {
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        const decryptDataChat = JSON.parse(decryptedText);
        return decryptDataChat;
    } catch (error) {
        console.error('Error parsing decrypted JSON:', error);
        return null; // Or handle the error accordingly
    }
};

export const IMG_URL = "https://onespace.fra1.cdn.digitaloceanspaces.com/";

export const BASE_URL = "https://superapp.oneg8.one:8000/";
// export const BASE_URL = "https://superapp.oneg8.one:4000/";
// export const BASE_URL="http://localhost:4000/";



import React, { useState } from 'react'
import "./Location.css"
import bar from "../assets/img/Sidebar/menu (1).png"
import Sidebar from '../Sidebar/Sidebar'
import mans1 from "../assets/img/Sidebar/sub-pro.png"
import Modal from 'react-bootstrap/Modal';
import cal from "../assets/img/Sidebar/cal-img.png"
import bluetik from "../assets/img/Sidebar/bluetik.png"
import ractangle from "../assets/img/Sidebar/Rectangle 23.png"
import back from "../assets/img/Sidebar/back-arrow.png"

function Location() {
    const [isInvite, setIsInvite] = useState(false)

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }
    return (
        <>
            <img src={bar} onClick={hanldeSidebar} width={50} alt="" />
            <Sidebar />

            <div className='explor-data'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6543739.747701117!2d-118.5052205366935!3d36.7901044038501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1699337520266!5m2!1sen!2sin" className='map-lock' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <Modal
                contentClassName='create-date-modal'
                centered
            >
                <div className='d-flex'>
                    <div className='date-modal-1'>
                        <div className="date-head">
                            <div className='date-data'>
                                <img src={cal} className='date-img' />
                                <div className='date-text'>
                                    <p>Woman Planet</p>
                                    <p>10 min Event</p>
                                </div>
                                <img src={bluetik} className='bluetick' />
                            </div>
                            <div>
                                <i className="bi bi-three-dots"></i>
                            </div>
                        </div>
                        <img src={ractangle} className='ractangle-img' />
                        <div className='date-center'>
                            <p>08 September 2023</p>
                            <p className='ms-4'>09:00 AM - 05:00 PM</p>
                        </div>
                        <p className='date-center-1'>Fantastic Friday!</p>
                        <p className='date-footer'>
                            Join us for 'Fantastic Friday,' the ultimate shopping extravaganza for fashion enthusiasts! Explore a wide array of stylish clothing options, from trendy streetwear to elegant formal attire, all under one roof. Discover the latest fashion trends, exclusive discounts, and an unforgettable shopping experience. Get ready to revamp your wardrobe and make your Friday truly fantastic!
                        </p>
                    </div>
                    {/* <div className='date-line'>dhjesoiuh</div> */}
                    <div className='date-modal-2'>
                        <p className='date-side'>Add to Calendar</p>
                        <div className='account-plate'>
                            <input type='time' placeholder='Date Of Birth' className='date-time-plate' />
                        </div>
                        <div className='account-plate' onClick={() => setIsInvite(true)} >
                            <p>Invite Friends(-)</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.96967 17.5303C8.67678 17.2374 8.67678 16.7626 8.96967 16.4697L13.4393 12L8.96967 7.53033C8.67678 7.23744 8.67678 6.76256 8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967L15.0303 11.4697C15.171 11.6103 15.25 11.8011 15.25 12C15.25 12.1989 15.171 12.3897 15.0303 12.5303L10.0303 17.5303C9.73744 17.8232 9.26256 17.8232 8.96967 17.5303Z" fill="white" />
                            </svg>

                        </div>
                        <button className="bt-1 mt-4">Add</button>

                    </div>
                </div>
            </Modal>

            <Modal
                show={isInvite} onHide={() => setIsInvite(false)}
                centered
            >
                <div className='crt-disc'>
                    <img src={back} width={25} />
                    <p className='m-0'>Invite Friends</p>
                    <button className='crt-post-btn'>Invite</button>
                </div>

                <span className='hr-line'>
                    <hr />
                </span>

                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Alisa Williams</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>
                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Jessy Nolan</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>
                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Alisa Williams</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>
                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Alisa Williams</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>
                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Alisa Williams</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>
                <div className='invite-plate'>
                    <div className='invite-text'>
                        <img src={mans1} />
                        <p className='m-0'>Jessy Nolan</p>
                    </div>
                    <div>
                        <input type='radio' />
                    </div>
                </div>


            </Modal>
        </>
    )
}

export default Location

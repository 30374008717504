import React, { useEffect, Component, useState, useRef } from 'react'

import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar'
import "../Wallet/WalletHome.css"

import WalletLogo from "../assets/img/Wallet/wallet-logo.png"
import ethlogo from "../assets/img/Wallet/eth-logo.png"
import bnblogo from "../assets/img/Wallet/bnb-logo.png"
import mtclogo from "../assets/img/Wallet/mtc-logo.png"
import btclogo from "../assets/img/Wallet/btc-logo.png"
import accountlogo from "../assets/img/Wallet/acc.png"
import ogo4logo from "../assets/img/Wallet/ogo4.png"
import news1 from "../assets/img/Wallet/news1.jpg"
import news2 from "../assets/img/Wallet/news2.jpg"
import news3 from "../assets/img/Wallet/news3.jpg"

export default function ReciveScreen() {
  return (
    <div>
        <div className="text-center">
        <img src={WalletLogo} classNameName="logo-img" />

    </div>

    <div className="container wallet-container">
        <div className="col-12 text-center my-auto">
            <span className="text-white mid-font">Receive Ethereum network coin </span><br/>

        </div>
        <div className="col-8 p-5 mx-auto ">
            <div className="row row-color1 p-2">

                <div className="col-12 text-left pt-5">
                    <div className="text-center">
                        <img src="img/qr.jpg" className="qr-img"/>
                    </div>
                </div>
                <div className="col-12 text-center pt-3">
                    <span type="button" className="btn-wallet1 px-3"><button className="btn-wallet1 limitchar">0x1b3ac8165f9de59dd972kdnledcnljedc1234555</button></span>
                </div>
                <div className="col-12 text-left pt-3">
                <div className="row p-4">
                    <button type="button" className="btn-wallet btn-lg btn-block">Copy Address</button>
                </div>
            </div>



            </div>

        </div>


    </div>
    <div className="pt-5"></div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Breadcrumb, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "../DataG8/DataG8.css";
import { setCustomData } from "../apiService/customData";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageViewer from "react-simple-image-viewer";
import MoveModel from "./MoveModel";
import moment from "moment";
import placeholderimg from "../assets/img/Sidebar/placeholder1.png"
import back from "../assets/img/Sidebar/back-arrow.png"
import { Circles } from 'react-loader-spinner';
import {
  useCreateFolderMutation,
  useLazyGetFileDirectoresQuery,
  useAddRemoveFavouritesMutation,
  useUpdateFileNameMutation,
  useDeleteFilesMutation,
  useShareMediaMutation,
} from "../apiService/index";
import { encryptData, decryptData, BASE_URL, IMG_URL } from "../API/ApiUrl";

// import DataG8Sidebar from '../DataG8/DataG8Sidebar'
import { toast } from "react-toastify";
import { getDriveTokenData, getSkipGeneratePassword } from "../util/cacheData";

export default function DataG8Home() {
  const media_url = "https://onespace.fra1.digitaloceanspaces.com";

  const [currentFolderId, setCurrentFolderId] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [getDataCount, setGetDataCount] = useState(1);
  const [imagePreviewArray, setImagePreviewArray] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [callDirectoryList, setCallDirectoryList] = useState(true);
  const [attentionMsg, setAttentionMsg] = useState("");
  const [addMember, setAddMemner] = useState(false)
  const [singleData, setSingleData] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [isLoaders, setIsLoaders] = useState(false)
  const [memberId, setMenberId] = useState([])
  const [fileDetails, setFileDetails] = useState();
  const [linkModal, setLinkModal] = useState();
  // const [enteryFolder, setEnteryFolder] = useState("true");
  const [deleteModel, setDeleteModel] = useState({
    fileName: "",
    id: "",
    modelShow: false,
    index: "",
  });

  const dispatch = useDispatch();
  const [renameFile, setRenameFile] = useState({
    fileId: "",
    name: "",
    index: "",
  });

  const [moveToFolderModel, setMoveToFolderModel] = useState(false);
  const [renameModel, setRenameModel] = useState(false);
  const [detailsModel, setDetailsModel] = useState(false);
  const [moveToTrashModel, setMoveToTrashModel] = useState(false);
  const [directoryListMap, setDirectoryListMap] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [decryptMediaData, setDecryptMediaData] = useState("");
  const [isLoding, setIsLoding] = useState(true);
  const [decryptMediaModel, setDecryptMediaModel] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [uesrList, setUserList] = useState([])


  const [breadCrumbData, setBreadCrumbData] = useState([
    {
      title: "Home",
      id: 0,
    },
  ]);

  const [selectedBreadCrumb, setSelectedBreadCrumb] = useState(null);

  const [selectedFolderData, setSelectedFolderData] = useState({
    name: null,
    id: 0,
  });
  const [orderBy,setOrderBy]=useState("DESC");
  const [search,setSearch]=useState("");

  const [moveData, setMoveData] = useState({
    model: false,
    selectedFileId: "",
  });

  const [getFileDirectores, directoryList] = useLazyGetFileDirectoresQuery();
  const [addFavourites] = useAddRemoveFavouritesMutation();

  const [deleteFiles] = useDeleteFilesMutation();
  const [updateFileName] = useUpdateFileNameMutation();
  const [shareMedia]= useShareMediaMutation();


  const customData = useSelector((state) => state.customData);

  const utoken = localStorage.getItem("UserToken")
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", utoken);

  useEffect(() => {
    // console.log("testing....... ",currentFolderId,directoryList,  currentFolderId!=directoryList?.currentFolderId);

    if (getSkipGeneratePassword() || getDriveTokenData()) {
      // console.log("call data");
      getFileDirectores({
        id: currentFolderId,
        page: getDataCount,
        orderBy:orderBy,
        search:search,
        __: Date.now(),
      }).then((res) => {
        // console.log("TESTing");
        // setCurrentFolderId(directoryList?.data?.currentFolderId);
        if (res?.isSuccess) {
          dispatch(
            setCustomData({
              ...customData,
              currentFolderId: res?.data?.currentFolderId,
            })
          );

          if (res?.data.page != 1) {
            setDirectoryListMap([...directoryListMap, ...res?.data?.data]);
          } else {
            setDirectoryListMap(res?.data?.data);
          }

          if (selectedBreadCrumb) {
            let breadData = JSON.parse(JSON.stringify(breadCrumbData));
            breadData = breadData.slice(0, selectedBreadCrumb.index + 1);
            setBreadCrumbData(breadData);
            setSelectedBreadCrumb(null);
          }

          if (selectedFolderData?.name) {
            let breadData = JSON.parse(JSON.stringify(breadCrumbData));
            breadData.push({
              title: selectedFolderData.name,
              id: selectedFolderData.id,
            });
            setBreadCrumbData(breadData);
            setSelectedFolderData({
              name: null,
              id: null,
            });
          }
        }
        if (res.isError && !res?.error?.data?.status) {
          // dispatch(setCustomData({ loginModel:true }));
          toast.error(res?.error?.data?.message);
        }
      });
    }
  }, [
    currentFolderId,
    customData?.drivePasswordIsExist,
    getDataCount,
    callDirectoryList,
    orderBy,
    search
  ]);

  useEffect(() => {
    if (customData?.fileUploaded) {
      // setGetDataCount(1);
      fetchMoreData(1);
      setCallDirectoryList(!callDirectoryList);
    }
    // setCurrentFolderId(customData?.currentFolderId);
  }, [customData?.fileUploaded]);
  useEffect(() => {
    if (directoryListMap?.length >= directoryList?.data?.count)
      setHasMoreData(false);
    else setHasMoreData(true);
  }, [directoryList]);

  useEffect(() => {
    if (customData?.movedFileId) {
      var index = directoryListMap.findIndex(
        (p) => p.id == customData?.movedFileId
      );
      if (index >= 0) {
        let spliceData = JSON.parse(JSON.stringify(directoryListMap));
        spliceData.splice(index, 1);
        setDirectoryListMap(spliceData);
      }
    }
  }, [customData?.movedFileId]);

  const shareMediaWithFd=()=>{
  
    shareMedia({opponentIds:memberId,media_id:singleData.id,type:singleData.fileType}).then((res)=>{
      console.log(res)
      // toast.success(res?.data?.message);
      // let listData=JSON.parse(JSON.stringify(directoryListMap)) ;

      //   listData.splice(index,1);

      // setDirectoryListMap(listData);


    }).catch((err) => {
      toast.error(err?.error?.data?.message);
    })
  }

  const handleUpdateFileName = () => {
    if (renameFile.name == "") return;

    let data = {
      body: { updateName: renameFile.name },
      id: renameFile.fileId,
    };

    updateFileName(data)
      .then((res) => {
        if (res?.data?.status) {
          let da = JSON.parse(JSON.stringify(directoryListMap));
          da[renameFile.index].name = renameFile.name;

          setDirectoryListMap(da);
          toast.success(res.data.message);

          setDirectoryListMap(da);
          toast.success(res.data.message);

          setRenameModel(false);
        }
      })
      .catch((err) => {
        toast.error(err?.error?.data?.message);
      });
  };

  const changeBreadCrumb = (item, index) => {
    setSelectedBreadCrumb({
      item,
      index,
    });
    // currentFolderId
    setCurrentFolderId(item.id);
    setGetDataCount(1);
  };

  const setRenameData = (item, ind) => {
    setRenameModel(true);
    setRenameFile({ fileId: item.id, name: item.name, index: ind });
  };
  const isUserSelected = (followId) => {
    return memberId.includes(followId);
  };
  const handleUserChange = (followId) => {
    if (memberId.includes(followId)) {
        setMenberId(memberId.filter(id => id !== followId));
    } else {
        setMenberId([...memberId, followId]);
    }
  };

  const deleteFileHandler = () => {
    let body = {
      deletedIds: [deleteModel.id],
    };
    deleteFiles(body)
      .then((res) => {
        toast.success(res.data.message);
        // let spliceData=directoryListMap;
        // spliceData.splice(deleteModel.index,1)
        // setDirectoryListMap(spliceData);
        setDirectoryListMap((directoryListMap) =>
          directoryListMap.filter((img, i) => i !== deleteModel.index)
        );
        setDeleteModel({ fileName: "", id: "", modelShow: false, index: "" });
        // index:ind
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.error?.data?.message);
      });
  };

  const downlaodFileHandler = (item) => {
    const link = document.createElement("a");
    link.href = item;
    link.download = fileName;
    link.click();
  };
  const getLinkFunction = async (item) => {

  }
  const decryptAndView = async (item) => {
    setIsLoding(true);
    setDecryptMediaModel(true);
    setFileType(item.fileType);
    setFileName(item.name);
    setDecryptMediaData(media_url + "/" + item.previewUrl);

    try {
      const response = await fetch(media_url + "/" + item.fileUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      const decrypt = decryptData(text);

      const byteCharacters = atob(decrypt);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      if (item.fileType === 3) {
        // Image
        const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
        const imageUrl = URL.createObjectURL(imageBlob);
        setDecryptMediaData(imageUrl);
      } else if (item.fileType === 5) {
        // Video
        const videoBlob = new Blob([byteArray], { type: "video/mp4" });
        const videoUrl = URL.createObjectURL(videoBlob);
        setDecryptMediaData(videoUrl);
      } else if (item.fileType === 6) {
        // PDF
        const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setDecryptMediaData(pdfUrl);
      } else if (item.fileType === 4) {
        // PDF
        const audioBlob = new Blob([byteArray], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(audioBlob);
        setDecryptMediaData(audioUrl);
      } else {
        // Audio or other types
        // You can handle other file types here if needed
        // console.log('Unsupported file type:', item.fileType);
      }

      setIsLoding(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    directoryListMap.map((item) => {
      if (item?.fileType == 3) {
        setImagePreviewArray([
          ...imagePreviewArray,
          `${media_url}/${item.previewUrl}`,
        ]);
      }
    });
  }, [directoryListMap]);

  const openImageViewer = (url) => {
    // alert('55')
    // console.log(url);
    // console.log(imagePreviewArray);
    let index = imagePreviewArray.findIndex((x) => x == url);
    console.log(index)
    if (index >= 0) {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }
    // setCurrentImage(ind);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getFileDetails = (item) => {
    console.log(item);
    setFileDetails(item);
    setDetailsModel(true);
  };

  const addRemoveFavourite = (item, index, flag) => {
    addFavourites({ id: item.id, checkFavourites: flag })
      .then((res) => {
        console.log(res?.data?.message);
        // toast.success('ddd');
        toast.success(res?.data?.message, { theme: "dark" });
        let listData = JSON.parse(JSON.stringify(directoryListMap));

        listData = listData.map((itemData) => {
          if (item.id == itemData.id && flag == 1)
            itemData.favouritesfile = res?.data?.data;

          if (item.id == itemData.id && flag == 0)
            itemData.favouritesfile = null;

          return itemData;
        });

        setDirectoryListMap(listData);
      })
      .catch((err) => {
        toast.error(err?.error?.data?.message);
      });
  };
  const openFriendModal = () => {
    setIsLoader(true)
    let jsonData = {
        chatroom_id: 0,
    }
    const encrypt = encryptData(jsonData);

    fetch(BASE_URL + "get_user", {
        method: 'POST',
        body: JSON.stringify({ "chatroomData": encrypt }),
        headers: myHeaders,
    })
        .then(response => response.json())
        .then(response => {
            if (response.Status === 0) {
                console.log(response, "no")
                setIsLoader(false)
            } else {
                const decrypt = decryptData(response.key);
                console.log("get user >>>", decrypt.info);
                setUserList(decrypt.info)
            }
            setIsLoader(false)
        })
        .catch((error) => {
            console.log(error);
            setIsLoader(false)
        })

  }
const shareMediaWithFd1 = () => {
  // console.log(singleData)
  // return false;

  let jsonData = {
    memberId: memberId,
    media_id:singleData.id,
    type:singleData.fileType
}
  fetch(BASE_URL + "shareMedia", {
    method: 'POST',
    body: JSON.stringify(jsonData),
    headers: myHeaders,
})
    .then(response => response.json())
    .then(response => {
        if (response.Status === 0) {
            console.log(response, "no")
            setIsLoader(false)
        } else {
          console.log(response)
            // const decrypt = decryptData(response.key);
            // console.log("get user >>>", decrypt.info);
            // setUserList(decrypt.info)
        }
        setIsLoader(false)
    })
    .catch((error) => {
        console.log(error);
        setIsLoader(false)
    })
}

  const FilesUi = ({ item, ind }) => {
    // return false

    // if(item?.fileType==3)
    // {
    //   console.log("Testing");
    //   setImagePreviewArray([...imagePreviewArray,`${media_url}/${item.previewUrl}`]);

    // // setImagePreviewArray(oldArray=>[...oldArray,`${media_url}/${item.previewUrl}`])
    // }

    return (
      <>
        <div className="col-md-3 pt-3" key={ind}>
          <div
            className="cardForDrive cardColor"
            style={{ border: "none !important", height: "200px" }}
          >
            <div
              style={{ width: "100%", height: "158px" }}
              className="d-flex justify-content-center align-items-center folderPadding"

            >
              {
                item?.fileType === 3 || item?.fileType === 5 || item?.fileType === 6 ? (
                  <div
                    className="w-100 h-100"
                    style={{
                      backgroundImage: `url(${media_url}/${item?.previewUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => decryptAndView(item)}
                    // onClick={() =>
                    //   openImageViewer(`${media_url}/${item.previewUrl}`)
                    // }
                  />
                ) 
                // : item?.fileType === 6 ? (
                //   <i className="far fa-file-pdf fa-4x"></i>
                // ) 
                : item?.fileType === 4 ? (
                  <i className="far fa-file-audio fa-4x"></i>
                ) : // This block is executed when none of the above conditions match
                null // You can replace this with a default component or null as per your requirement
              }
            </div>
            <div className="card-body pt-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle limitchar">{item.name} </h6>
                </div>
                <div className="col-2">
                  <Dropdown id={`dropdown-data-option${item.id}`}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical text-pink pointer"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className="option-menudatag8">
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => decryptAndView(item)}
                        >
                          <i className="fa-solid fa-eye px-2"></i>
                          View
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => { openFriendModal(); setAddMemner(true) ; setSingleData(item) } }
                        >
                          <i className="fa-solid fa-share px-2"></i>
                          Share with friend
                        </div>
                        {item?.favouritesfile ? (
                          <div
                            className="menu-message d-flex align-items-center py-1"
                            onClick={() => addRemoveFavourite(item, ind, 0)}
                          >
                            <i className="fa-solid fa-heart px-2"></i>
                            Remove Favourite
                          </div>
                        ) : (
                          <div
                            className="menu-message d-flex align-items-center py-1"
                            onClick={() => addRemoveFavourite(item, ind, 1)}
                          >
                            <i className="fa-solid fa-heart px-2"></i>
                            Add Favourite
                          </div>
                        )}

                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => decryptAndView(item)}
                        >
                          <i className="fa-solid fa-download px-2"></i>
                          Download
                        </div>
                        <div className="menu-message d-flex align-items-center py-1" onClick={() => getLinkFunction(item)}>
                          <i className="fa-solid fa-link px-2"></i>
                          Get Link
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setMoveData({
                              model: true,
                              selectedFileId: item.id,
                            });
                          }}
                        >
                          <i className="fa-solid fa-arrows-up-down-left-right px-2"></i>
                          Move To Folder
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => setRenameData(item, ind)}
                        >
                          <i className="fa-regular fa-pen-to-square px-2"></i>
                          Rename
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            getFileDetails(item);
                          }}
                        >
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDeleteModel({
                              fileName: item.name,
                              id: item.id,
                              modelShow: true,
                              index: ind,
                            });
                          }}
                        >
                          <i className="fa-solid fa-trash px-2"></i>
                          Move To Trash
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const FolderUi = ({ item, ind }) => {
    // return false
    return (
      <>
        <div className="col-md-3 pt-3" key={ind}>
          <div
            className="cardForDrive cardColor"
            style={{ border: "none !important" }}
          >
            <div
              className="folderPadding d-flex justify-content-center align-items-center"
              onDoubleClick={() => {
                setOrderBy("DESC");
                setSearch("");
                setCurrentFolderId(item.id);
                setGetDataCount(1);
                setSelectedFolderData({ name: item.name, id: item.id });
              }}
            >
              <i className="fa-regular fa-folder-closed fa-4x text-green"></i>
            </div>
            <div className="card-body py-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle limitchar">{item.name} </h6>
                </div>
                <div className="col-2">
                  <Dropdown id={`dropdown-data-option${item.id}`}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical text-pink pointer"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className="option-menudatag8">
                        {/* <div className="menu-message d-flex align-items-center py-1">
                          <i className="fa-solid fa-download px-2"></i>
                          Download
                        </div> */}
                        {/* <div className="menu-message d-flex align-items-center py-1">
                          <i className="fa-solid fa-link px-2"></i>
                          Get Link
                        </div> */}
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setMoveData({
                              model: true,
                              selectedFileId: item.id,
                            });
                          }}
                        >
                          <i className="fa-solid fa-arrows-up-down-left-right px-2"></i>
                          Move To Folder
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => setRenameData(item, ind)}
                        >
                          <i className="fa-regular fa-pen-to-square px-2"></i>
                          Rename
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            getFileDetails(item);
                          }}
                        >
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDeleteModel({
                              fileName: item.name,
                              id: item.id,
                              modelShow: true,
                              index: ind,
                            });
                            setAttentionMsg(
                              "*this folder is deleted Permanently when you click delete button"
                            );
                          }}
                        >
                          <i className="fa-solid fa-trash px-2"></i>
                          Delete Permanently
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const fetchMoreData = (data = null) => {
    // setGetDataCount(getDataCount + 1);

    if (data) {
      setGetDataCount(data);
    } else {
      setGetDataCount(getDataCount + 1);
    }
  };

  return (
    <div>
      <div className="">
        {/* <div className="" style={{width:"20%"}}>
          <DataG8Sidebar currFolderId={currentFolderId} />
        </div> */}

        <div style={{ height: "100vh", overflow: "scroll" }} id="scrollableDiv">
          <>
          <div className="d-flex flex-column px-5" >
        <div className="container mx-auto mt-4">
        <div className="datag8-row1 p-3 text-center">
              <span className="text-white">My Files</span>
            </div>
        <div className="pt-2">
          {
            breadCrumbData.map((item,index)=> <span className="pointer" onClick={()=>changeBreadCrumb(item,index)} > {item.title} /</span> )
          }
        </div>
         <div className="d-flex" >
          <div>
         <input type="text" className="input-col1" value={search} onChange={(e)=>{ setSearch(e.target.value); setGetDataCount(1) }} />
          </div>
          <div>
          {/* <select class="form-select" aria-label="Default select example"  >
            <option value="DESC" selected>New</option>
            <option value="ACE">Old</option>
          </select> */}

          <Form.Select className="opt-color" aria-label="Default select example" value={orderBy} onChange={(e)=>{
            setOrderBy(e.target.value);
            setGetDataCount(1)
          }}>
            <option value="DESC">New</option>
            <option value="ASC">Old</option>
          </Form.Select>
          </div>

         </div>


        </div>


            <div className="container mx-auto mt-4">
              <div className="row">
                {directoryListMap.length > 0 ? (
                  directoryListMap?.map((item, index) =>
                    item.fileType == 2 ? (
                      <FolderUi key={index} item={item} ind={index} />
                    ) : (
                      <FilesUi key={index} item={item} ind={index} />
                    )
                  )
                ) : (
                  // directoryList?.data.map((item, index) =>  <FolderUi key={index} item={item} ind={index} /> )
                  <>
                    <h2 class="text-center pt-5">Empty Data</h2>
                  </>
                )}
              </div>
            </div>
            </div>
          </>

          <InfiniteScroll
            dataLength={directoryListMap.length || 0}
            next={fetchMoreData}
            hasMore={hasMoreData}
            loader={directoryListMap.length != <h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          />
        </div>
      </div>

      {/* user modal  */}
      <Modal show={addMember} contentClassName='popup-box-fixed' onHide={() => setAddMemner(false)} centered >
          <div className='crt-disc'>
              <img className='pointer' src={back} width={25} onClick={() => setAddMemner(false)} />
              <p className='m-0 px-5'>My Connections</p>
              <button className='crt-post-btn' onClick={() => shareMediaWithFd()}>Share</button>
          </div>

          <span className='hr-line'>
              <hr />
          </span>
          {
              isLoader ?
                  <Circles
                      height="35"
                      width="35"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      visible={true}
                      wrapperClass="get-user"
                  />
                  :
                  <>
                      {
                          uesrList == "" ?
                              <p className='text-light d-flex justify-content-center'>No Data Found</p>
                              :
                              <>
                                  {
                                      uesrList.map((item, index) => {
                                          // console.log(item)
                                          return (
                                              <label key={index} className='invite-plate'>
                                                  <div className='invite-text'>
                                                      <img src={item.profile_pic ? IMG_URL + item.profile_pic : placeholderimg} className='hype-img-1' />
                                                      <p className='m-0'>{item.first_name || ""} {item.last_name || ""}</p>
                                                  </div>
                                                  <input type='checkbox'
                                                      checked={isUserSelected(item.follow_to)}
                                                      onChange={() => { handleUserChange(item.follow_to) }}
                                                      className="purchs-radio popup-redio" name='' />


                                              </label>
                                          )
                                      })
                                  }

                              </>
                      }
                  </>
          }
      </Modal>


      {/* delete folder model */}
      <Modal
        show={moveToTrashModel}
        onHide={() => setMoveToTrashModel(false)}
        centered
      >
        <div className="activity-disck pt-4 text-center">
          <div className="lgn-input-1">
            <h3>Are you Sure move to Trash.</h3>
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <span
            className="btn btn-danger"
            onClick={() => setMoveToTrashModel(false)}
          >
            Delete
          </span>
        </div>
      </Modal>
      {/* end */}

      {/* rename model */}
      <Modal show={renameModel} onHide={() => setRenameModel(false)} centered>
        <div className="activity-disck">
          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Rename</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setRenameModel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <input
              type="text"
              className="i-put-1"
              onChange={(e) =>
                setRenameFile({ ...renameFile, name: e.target.value })
              }
              value={renameFile.name}
            />
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <p onClick={handleUpdateFileName}>Create</p>
        </div>
      </Modal>
      {/* end */}

      {/* details model */}
      <Modal show={detailsModel} onHide={() => setDetailsModel(false)} centered>
        <div className="activity-disck">
          <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Details</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setDetailsModel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row pt-3">
              <div className="col-6 pb-2">Name:</div>
              <div className="col-6 pb-2">{fileDetails?.name}</div>

              <div className="col-6 pb-2">Uploaded by:</div>
              <div className="col-6 pb-2">Myself</div>

              {/* <div className="col-6 pb-2">Location:</div>
              <div className="col-6 pb-2">.....</div> */}

              <div className="col-6 pb-2">Uploaded at:</div>
              <div className="col-6 pb-2">
                {moment(fileDetails?.createdAt).fromNow()}
              </div>

              <div className="col-6 pb-2">Modified:</div>
              <div className="col-6 pb-2">
                {moment(fileDetails?.updatedAt).fromNow()}
              </div>

              {/* <div className="col-6 pb-2">Type:</div>
              <div className="col-6 pb-2">Video file</div> */}

              <div className="col-6 pb-2">MIME type:</div>
              {fileDetails?.fileType === 5 ? (
                <div className="col-6 pb-2">video</div>
              ) : fileDetails?.fileType === 3 ? (
                <div className="col-6 pb-2">Image</div>
              ) : fileDetails?.fileType === 4 ? (
                <div className="col-6 pb-2">Audio</div>
              ) : fileDetails?.fileType === 6 ? (
                <div className="col-6 pb-2">PDF</div>
              ) : fileDetails?.fileType === 7 ? (
                <div className="col-6 pb-2">Document</div>
              ) : (
                <div className="col-6 pb-2">Other</div>
              )}

              {fileDetails?.fileType != 2 &&(
                  <>
                  <div className="col-6 pb-2">Size:</div>
                  <div className="col-6 pb-2">{fileDetails?.fileSize} MB</div>

                  <div className="col-6 pb-2">Original size:</div>
                  <div className="col-6 pb-2">{fileDetails?.fileSize} MB</div>
                  </>
              )}


              <div className="col-6 pb-2">Shared:</div>
              <div className="col-6 pb-2">No</div>
            </div>
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          {/* <p onClick={() => setDetailsModel(false)}>Create</p> */}
        </div>
      </Modal>
      {/* end */}

      {/* start delete file confirmation */}

      <Modal
        show={deleteModel.modelShow}
        onHide={() => setDeleteModel({ ...deleteModel, modelShow: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {attentionMsg != "" ? (
            <>
              <p>Are you sure do you want delete this ?</p>
              <p className="text-danger">{attentionMsg}</p>
              <div className="text-center">
                <i className="fa-regular fa-folder-closed fa-4x text-green"></i>
                <div className="my-auto">{deleteModel.fileName}</div>
              </div>
            </>
          ) : (
            <>
              <p>Are you sure you want to move this to trash ? </p>
              <div className="text-center">
                <i className="fa-solid fa-file fa-4x"></i>
                <div className="my-auto">{deleteModel.fileName}</div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteModel({ ...deleteModel, modelShow: false })}
          >
            No
          </Button>
          <Button variant="danger" onClick={deleteFileHandler}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* share link modal */}
      <Modal size="lg"  show={linkModal}
        onClick={() => setLinkModal(false)}
        centered
      >

        <Modal.Body className="p-0">
           
          <div id="caption"></div>
        </Modal.Body>
        <Modal.Footer>
        <div className="col-12">
            <div className="row p-1">
                <div className="col-6">
                  <button type="button" className="btncreate pointer"  onClick={() => downlaodFileHandler(decryptMediaData)}>
                    Download
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => setLinkModal(false)}
                    className="btncancel pointer"
                  >
                    close
                  </button>
                </div>
            </div>
        </div>
        </Modal.Footer>
      </Modal>

      {/* show decrpyted image media */}
      <Modal
      size="lg"
      onHide={() => setLgShow(false)}
        show={decryptMediaModel}
        onClick={() => setDecryptMediaModel(false)}
        centered
      >

        <Modal.Body className="p-0">
          {isLoding && <h3>Hold tight! Your data is still decrypting...</h3>}
          {fileType === 3 ? (
            <img
              className="modal-content-new"
              src={decryptMediaData}
              alt="Image"
            />
          ) : fileType === 5 ? (
            <video
              className="modal-content-new"
              src={decryptMediaData}
              controls
              autoPlay
            />
          ) : fileType === 6 ? (
            <iframe style={{height:"550px"}} className="modal-content-new" src={decryptMediaData} />
          ) : fileType === 4 ? (
            <audio
              className="modal-content-new"
              src={decryptMediaData}
              controls
            />
          ) : (
            <div>no data</div>
          )}
          <div id="caption"></div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12">
        <div className="row p-1">
            <div className="col-6">
              <button type="button" className="btncreate pointer"  onClick={() => downlaodFileHandler(decryptMediaData)}>
                Download
              </button>
            </div>
            <div className="col-6">
              <button
                onClick={() => setDecryptMediaModel(false)}
                className="btncancel pointer"
              >
                close
              </button>
            </div>
        </div>
        </div>
        </Modal.Footer>
      </Modal>

      {/* start move model */}
      <MoveModel moveData={moveData} />
      {/* end move model */}

      {/* start delete file confirmation */}

      {isViewerOpen && (
        <ImageViewer
          src={imagePreviewArray}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
}

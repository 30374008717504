import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import "../DataG8/DataG8.css"
import { setCustomData } from '../apiService/customData';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageViewer from "react-simple-image-viewer";
import MoveModel from './MoveModel';
import {
  useCreateFolderMutation, useLazyGetFavouritesDataQuery,
  useAddRemoveFavouritesMutation
} from '../apiService/index';
import { encryptData, decryptData, BASE_URL, IMG_URL } from '../API/ApiUrl'


// import DataG8Sidebar from '../DataG8/DataG8Sidebar'
import { toast } from 'react-toastify';
import { getDriveTokenData, getSkipGeneratePassword } from '../util/cacheData';

export default function DataG8Home() {

  const media_url = 'https://onespace.fra1.digitaloceanspaces.com';


  const [currentFolderId, setCurrentFolderId] = useState(0);
  const [getDataCount, setGetDataCount] = useState(1);
  const [imagePreviewArray, setImagePreviewArray] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [callDirectoryList, setCallDirectoryList] = useState(true);
  const [fileDetails,setFileDetails]=useState();

  const dispatch = useDispatch();

  const [detailsModel, setDetailsModel] = useState(false)
  const [directoryListMap, setDirectoryListMap] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [decryptMediaData, setDecryptMediaData] = useState("");
  const [isLoding, setIsLoding] = useState(true);
  const [decryptMediaModel, setDecryptMediaModel] = useState(false)
  const [fileType, setFileType] = useState("")

  const [getFavourtiesFileDirectores, directoryList] = useLazyGetFavouritesDataQuery();
  const [addFavourites]=useAddRemoveFavouritesMutation();

  const customData = useSelector((state) => state.customData);


  useEffect(() => {
    // console.log("testing....... ",currentFolderId,directoryList,  currentFolderId!=directoryList?.currentFolderId);

    if (getSkipGeneratePassword() || getDriveTokenData()) {
      // console.log("call data");
      getFavourtiesFileDirectores().then((res) => {

        // setCurrentFolderId(directoryList?.data?.currentFolderId);
        if (res?.isSuccess) {
          if (res?.data.page != 1) {
            setDirectoryListMap([...directoryListMap, ...res?.data?.data]);
          }
          else {
            setDirectoryListMap(res?.data?.data);
          }
        }
        if (res.isError && !res?.error?.data?.status) {
          // dispatch(setCustomData({ loginModel:true }));
          toast.error(res?.error?.data?.message);
        }

      })

    }
    if (directoryList?.isSuccess) {
      // console.log("check",directoryList);

    }

  }, [currentFolderId, customData?.drivePasswordIsExist, getDataCount, callDirectoryList]);

  useEffect(() => {
    if (customData?.fileUploaded) {
      // setGetDataCount(1);
      fetchMoreData(1)
      setCallDirectoryList(!callDirectoryList);
    }
    // setCurrentFolderId(customData?.currentFolderId);
  }, [customData?.fileUploaded]);
  useEffect(() => {
    if (directoryListMap?.length >= directoryList?.data?.count)
      setHasMoreData(false);
    else
      setHasMoreData(true);
  }, [directoryList]);


  useEffect(() => {
    if(customData?.movedFileId)
    {
      var index = directoryListMap.findIndex(p => p.id == customData?.movedFileId);
      if(index>=0)
      {
        let spliceData=JSON.parse(JSON.stringify(directoryListMap));
        // console.log(spliceData);
        spliceData.splice(index,1);
      setDirectoryListMap(spliceData)

      }
    }
  }, [customData?.movedFileId]);

  const addRemoveFavourite=(item,index,flag)=>{
    addFavourites({id:item.id,checkFavourites:flag}).then((res)=>{
      toast.success(res?.data?.message);
      let listData=JSON.parse(JSON.stringify(directoryListMap)) ;

        listData.splice(index,1);

      setDirectoryListMap(listData);


    }).catch((err) => {
      toast.error(err?.error?.data?.message);
    })
  }


  const downlaodFileHandler = (item) => {
    const a = document.createElement('a')
    a.href = `${media_url}/${item.fileUrl}`
    a.downlaod = item.fileUrl
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
  const decryptAndView = async (item) => {
    setIsLoding(true);
    setDecryptMediaModel(true);
    setFileType(item.fileType);
    setDecryptMediaData(media_url + '/' + item.previewUrl);

    try {
      const response = await fetch(media_url + '/' + item.fileUrl);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const text = await response.text();
      const decrypt = decryptData(text);

      const byteCharacters = atob(decrypt);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      if (item.fileType === 3) { // Image
        const imageBlob = new Blob([byteArray], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(imageBlob);
        setDecryptMediaData(imageUrl);
      } else if (item.fileType === 5) { // Video
        const videoBlob = new Blob([byteArray], { type: 'video/mp4' });
        const videoUrl = URL.createObjectURL(videoBlob);
        setDecryptMediaData(videoUrl);
      } else if (item.fileType === 6) { // PDF
        const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setDecryptMediaData(pdfUrl);
      }
      else if (item.fileType === 4) { // PDF
        const audioBlob = new Blob([byteArray], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setDecryptMediaData(audioUrl);
      } else { // Audio or other types
        // You can handle other file types here if needed
        // console.log('Unsupported file type:', item.fileType);
      }

      setIsLoding(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };





  useEffect(()=>{
    directoryListMap.map((item)=>{
      if(item?.fileType==3)
    {
      setImagePreviewArray([...imagePreviewArray,`${media_url}/${item.previewUrl}`]);
    }
    });

  },[directoryListMap]);

  const openImageViewer = (url) => {
    let index= imagePreviewArray.findIndex(x=>x==url);
    if(index>=0)
    {
      setCurrentImage(index);
    setIsViewerOpen(true);

    }
    // setCurrentImage(ind);
  }

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getFileDetails=(item)=>{
    // console.log(item);
    setFileDetails(item);
    setDetailsModel(true)
  }

  const FilesUi = ({ item, ind }) => {


    return (
      <>
        <div className="col-md-3 pt-3" key={ind}>
          <div className="cardForDrive cardColor" style={{ border: 'none !important', height: '200px' }}>
            <div style={{ width: "100%", height: "158px" }} className='d-flex justify-content-center align-items-center folderPadding' >
              {
                item?.fileType === 3 ? (
                  <div className='w-100 h-100' style={{
                    backgroundImage: `url(${media_url}/${item.previewUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: 'center'
                  }}
                  onClick={() => decryptAndView(item)}
                  // onClick={()=>openImageViewer(`${media_url}/${item.previewUrl}`)}
                  />
                ) : (
                  item?.fileType === 6 ? (
                    <i className="far fa-file-pdf fa-4x"></i>
                  ) : (
                    item?.fileType === 4 ? (
                      <i className="far fa-file-audio fa-4x"></i>
                    ) : (
                      // This block is executed when none of the above conditions match
                      null // You can replace this with a default component or null as per your requirement
                    )
                  )
                )
              }

            </div>
            <div className="card-body pt-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle limitchar">{item.name}</h6>
                </div>
                <div className="col-2">

                  <Dropdown id={`dropdown-data-option${item.id}`}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className='option-menudatag8'>
                      <div className='menu-message d-flex align-items-center py-1' onClick={() => decryptAndView(item)} >
                          <i className="fa-solid fa-eye px-2"></i>
                          View
                        </div>
                        <div className='menu-message d-flex align-items-center py-1' onClick={() => downlaodFileHandler(item)} >
                          <i className="fa-solid fa-download px-2"></i>
                          Download
                        </div>
                        <div className='menu-message d-flex align-items-center py-1'>
                          <i className="fa-solid fa-link px-2"></i>
                          Get Link
                        </div>


                        <div className='menu-message d-flex align-items-center py-1' onClick={() => { getFileDetails(item) }}>
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                        <div className='menu-message d-flex align-items-center py-1' onClick={()=>addRemoveFavourite(item,ind,0)}>
                          <i className="fa-solid fa-trash px-2"></i>
                          Remove Favourite
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  const FolderUi = ({ item, ind }) => {

    // return false
    return (
      <>
        <div className="col-md-3 pt-3" key={ind}>
          <div className="cardForDrive cardColor" style={{ border: 'none !important' }}>
            <div className="folderPadding d-flex justify-content-center align-items-center" onDoubleClick={() => { setCurrentFolderId(item.id); setGetDataCount(1) }} >
              <i className="fa-solid fa-folder fa-4x"></i>
            </div>
            <div className="card-body py-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle limitchar">{item.name} </h6>
                </div>
                <div className="col-2">
                  <Dropdown id={`dropdown-data-option${item.id}`}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className='option-menudatag8'>

                        <div className='menu-message d-flex align-items-center py-1'>
                          <i className="fa-solid fa-download px-2"></i>
                          Download
                        </div>
                        <div className='menu-message d-flex align-items-center py-1'>
                          <i className="fa-solid fa-link px-2"></i>
                          Get Link
                        </div>

                          <div className='menu-message d-flex align-items-center py-1' onClick={() => { setDetailsModel(true); }}>
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                        <div className='menu-message d-flex align-items-center py-1'>
                          <i className="fa-solid fa-trash px-2"></i>
                          Move To Trash
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const fetchMoreData = (data = null) => {
    // setGetDataCount(getDataCount + 1);

    if (data) {
      setGetDataCount(data);
    }
    else {
      setGetDataCount(getDataCount + 1);
    }
  }

  return (
    <div>
      <div className="">
        {/* <div className="" style={{width:"20%"}}>
          <DataG8Sidebar currFolderId={currentFolderId} />
        </div> */}

        <div style={{ height: "100vh", overflow: 'scroll' }} id="scrollableDiv" >


          <>
            <div className="container mx-auto mt-4">
            <div className="datag8-row1 mt-3 p-3 text-center">
                        <span className="text-white">Favourites</span>
                    </div>
              <div className="row">
                {

                  directoryListMap.length > 0 ?
                    directoryListMap?.map((item, index) => item?.FileDirectory?.fileType == 2 ? <FolderUi key={index} item={item?.FileDirectory} ind={index} /> : <FilesUi key={index} item={item?.FileDirectory} ind={index} />)
                    // directoryList?.data.map((item, index) =>  <FolderUi key={index} item={item} ind={index} /> )
                    :
                    <>
                      <h2>Empty Data</h2>
                    </>
                }


              </div>
            </div>
          </>


          <InfiniteScroll
            dataLength={directoryListMap.length || 0}
            next={fetchMoreData}
            hasMore={hasMoreData}
            loader={<h4></h4>}
            scrollableTarget="scrollableDiv"
          />


        </div>

      </div>















      {/* details model */}
      <Modal
        show={detailsModel} onHide={() => setDetailsModel(false)}
        centered
      >
        <div className='activity-disck'>

        <div className="lgn-input-1">
            <div className="datag8_modal-header">
              <h5 className="modal-title">Details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setDetailsModel(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row pt-3">
              <div className="col-6 pb-2">Name:</div>
              <div className="col-6 pb-2">{fileDetails?.name}</div>

              <div className="col-6 pb-2">Uploaded by:</div>
              <div className="col-6 pb-2">Self</div>

              <div className="col-6 pb-2">Location:</div>
              <div className="col-6 pb-2">Folder Name</div>

              <div className="col-6 pb-2">Uploaded:</div>
              <div className="col-6 pb-2">{fileDetails?.createdAt}</div>

              <div className="col-6 pb-2">Modified:</div>
              <div className="col-6 pb-2">{fileDetails?.updatedAt}</div>

              <div className="col-6 pb-2">Type:</div>
              <div className="col-6 pb-2">Video file</div>

              <div className="col-6 pb-2">MIME type:</div>
              <div className="col-6 pb-2">video/mp4</div>

              <div className="col-6 pb-2">Size:</div>
              <div className="col-6 pb-2">{fileDetails?.fileSize} MB</div>

              <div className="col-6 pb-2">Original size:</div>
              <div className="col-6 pb-2">{setFileDetails?.fileSize} MB</div>

              <div className="col-6 pb-2">Shared:</div>
              <div className="col-6 pb-2">No</div>
            </div>
          </div>
        </div>
        <div className='pro-modal-bottom text-center pt-3'>
          {/* <p onClick={() => setDetailsModel(false)}>Create</p> */}
        </div>
      </Modal >
      {/* end */}



      <Modal show={decryptMediaModel} onClick={() => setDecryptMediaModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isLoding &&(<h3>Your Data is Still decrypting...</h3>)}
        {
          fileType === 3 ?
            <img className="modal-content-new" src={decryptMediaData} alt="Image" /> :
          fileType === 5 ?
            <video className="modal-content-new" src={decryptMediaData} controls /> :
          fileType === 6 ?
            <iframe className="modal-content-new" src={decryptMediaData}  /> :
          fileType === 4 ?
            <audio className="modal-content-new" src={decryptMediaData} controls /> :
          <div>no data</div>
        }
        <div id="caption"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDecryptMediaModel(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      {/* start delete file confirmation */}


      {isViewerOpen && (
        <ImageViewer
          src={imagePreviewArray}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  )
}

import React, { useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import bar from "../assets/img/Sidebar/menu (1).png"
import { Col, Container, Row } from 'react-bootstrap'
import human1 from "../assets/img/Sidebar/s-1.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import post1 from "../assets/img/Sidebar/post1.png"
import post2 from "../assets/img/Sidebar/post2.png"
import post2i from "../assets/img/Sidebar/post2(1).png"
import rocket from "../assets/img/Sidebar/Rocket 1.png"
import comment from "../assets/img/Sidebar/Comment.png"
import share from "../assets/img/Sidebar/Share.png"
import mark from "../assets/img/Sidebar/Bookmark.png"
import followe from "../assets/img/Sidebar/follow.png"
import following from "../assets/img/Sidebar/following.png"
import Modal from 'react-bootstrap/Modal';
import send from "../assets/img/Sidebar/send.png"
import danger from "../assets/img/Sidebar/danger.png"
import one from "../assets/img/Sidebar/ONEG8-Psd-20 1.png"

function Oneg8Post() {

    const [modalShow, setModalShow] = useState(false);
    const [commentShow, setCommentShow] = useState(false);
    const [Menu, setMenu] = useState(false);

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    return (
        <>

            <img src={bar} onClick={hanldeSidebar} className='sidebar-menu' alt="" />
            <Sidebar />

            <div className="section1 ">
                <Container >
                    <Row>
                        <Col lg={12} >
                            <div className='all-pst d-flex justify-content-center'>
                                <div id='like'>
                                    <div className='pst-head d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={one} className='pst-dp' />
                                            <div className='ms-3'>
                                                <p className='pst-head-text-1'>OneG8</p>
                                                <p className='pst-head-text-2'>10 min</p>
                                            </div>
                                        </div>
                                        <div className='pst-menu' onClick={() => setMenu(true)}>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={post1} className='pst-post1' />
                                    </div>
                                    <div>
                                        <p className='pst-ft-text'>Hey, take a look at my fantastic new outfit in this amazing photo!</p>
                                    </div>
                                    <div className='pst-footer'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='foot-rocket'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='pst-ft-img' viewBox="0 0 20 20" fill="none">
                                                    <path d="M11.6667 8.33333H15.6367C15.9207 8.33334 16.2001 8.40595 16.4482 8.54427C16.6963 8.68259 16.9049 8.88202 17.0543 9.12364C17.2036 9.36525 17.2888 9.64104 17.3016 9.9248C17.3144 10.2086 17.2545 10.4909 17.1275 10.745L14.2108 16.5783C14.0724 16.8554 13.8595 17.0884 13.596 17.2512C13.3325 17.414 13.0289 17.5001 12.7192 17.5H9.37167C9.23583 17.5 9.1 17.4833 8.9675 17.45L5.83333 16.6667M11.6667 8.33333V4.16667C11.6667 3.72464 11.4911 3.30072 11.1785 2.98816C10.866 2.67559 10.442 2.5 10 2.5H9.92083C9.50417 2.5 9.16667 2.8375 9.16667 3.25417C9.16667 3.84917 8.99083 4.43083 8.66 4.92583L5.83333 9.16667V16.6667M11.6667 8.33333H10M5.83333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V10C2.5 9.55797 2.67559 9.13405 2.98816 8.82149C3.30072 8.50893 3.72464 8.33333 4.16667 8.33333H6.25" stroke="#ECEBED" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span className='ms-2' onClick={() => setModalShow(true)}>2,245</span>
                                                <img className='pst-ft-img ms-4' id="inActiveLoveIcon" src={rocket} />
                                                <span className='ms-2' onClick={() => setModalShow(true)}>2,245</span>

                                                <img className='sms-ft-img ms-4' src={comment} />
                                                <span className='ms-2' onClick={() => setCommentShow(true)}>45</span>
                                            </div>
                                            <div className='foot-marck d-flex align-items-center'>
                                                <img className='sms-ft-img ms-4' src={share} />
                                                <img className='sms-ft-img ms-4' src={mark} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-pst'>
                                <div id='like'>
                                    <div className='pst-head d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={one} className='pst-dp' />
                                            <div className='ms-3'>
                                                <p className='pst-head-text-1'>OneG8</p>
                                                <p className='pst-head-text-2'>10 min</p>
                                            </div>
                                        </div>
                                        <div className='pst-menu'>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={post2i} className='pst-post1' />

                                    </div>
                                    <div>
                                        <p className='pst-ft-text'>Hey, take a look at my fantastic new outfit in this amazing photo!</p>
                                    </div>
                                    <div className='pst-footer'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='foot-rocket'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 20 20" fill="none">
                                                    <path d="M11.6667 8.33333H15.6367C15.9207 8.33334 16.2001 8.40595 16.4482 8.54427C16.6963 8.68259 16.9049 8.88202 17.0543 9.12364C17.2036 9.36525 17.2888 9.64104 17.3016 9.9248C17.3144 10.2086 17.2545 10.4909 17.1275 10.745L14.2108 16.5783C14.0724 16.8554 13.8595 17.0884 13.596 17.2512C13.3325 17.414 13.0289 17.5001 12.7192 17.5H9.37167C9.23583 17.5 9.1 17.4833 8.9675 17.45L5.83333 16.6667M11.6667 8.33333V4.16667C11.6667 3.72464 11.4911 3.30072 11.1785 2.98816C10.866 2.67559 10.442 2.5 10 2.5H9.92083C9.50417 2.5 9.16667 2.8375 9.16667 3.25417C9.16667 3.84917 8.99083 4.43083 8.66 4.92583L5.83333 9.16667V16.6667M11.6667 8.33333H10M5.83333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V10C2.5 9.55797 2.67559 9.13405 2.98816 8.82149C3.30072 8.50893 3.72464 8.33333 4.16667 8.33333H6.25" stroke="#ECEBED" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span className='ms-2' onClick={() => setModalShow(true)}>2,245</span>
                                                <img className='pst-ft-img ms-4' id="inActiveLoveIcon" src={rocket} />
                                                <span className='ms-2'>2,245</span>
                                                <img className='sms-ft-img ms-4' src={comment} />
                                                <span className='ms-2'>45</span>
                                            </div>
                                            <div className='foot-marck d-flex align-items-center'>
                                                <img className='sms-ft-img ms-4' src={share} />
                                                <img className='sms-ft-img ms-4' src={mark} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='all-pst'>
                                <div id='like'>
                                    <div className='pst-head d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={one} className='pst-dp' />
                                            <div className='ms-3'>
                                                <p className='pst-head-text-1'>Alisa Williams</p>
                                                <p className='pst-head-text-2'>10 min</p>
                                            </div>
                                        </div>
                                        <div className='pst-menu'>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={post2i} className='pst-post1' />
                                    </div>
                                    <div>
                                        <p className='pst-ft-text'>Hey, take a look at my fantastic new outfit in this amazing photo!</p>
                                    </div>
                                    <div className='pst-footer'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='foot-rocket'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 20 20" fill="none">
                                                    <path d="M11.6667 8.33333H15.6367C15.9207 8.33334 16.2001 8.40595 16.4482 8.54427C16.6963 8.68259 16.9049 8.88202 17.0543 9.12364C17.2036 9.36525 17.2888 9.64104 17.3016 9.9248C17.3144 10.2086 17.2545 10.4909 17.1275 10.745L14.2108 16.5783C14.0724 16.8554 13.8595 17.0884 13.596 17.2512C13.3325 17.414 13.0289 17.5001 12.7192 17.5H9.37167C9.23583 17.5 9.1 17.4833 8.9675 17.45L5.83333 16.6667M11.6667 8.33333V4.16667C11.6667 3.72464 11.4911 3.30072 11.1785 2.98816C10.866 2.67559 10.442 2.5 10 2.5H9.92083C9.50417 2.5 9.16667 2.8375 9.16667 3.25417C9.16667 3.84917 8.99083 4.43083 8.66 4.92583L5.83333 9.16667V16.6667M11.6667 8.33333H10M5.83333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V10C2.5 9.55797 2.67559 9.13405 2.98816 8.82149C3.30072 8.50893 3.72464 8.33333 4.16667 8.33333H6.25" stroke="#ECEBED" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span className='ms-2' onClick={() => setModalShow(true)}>2,245</span>
                                                <img className='pst-ft-img ms-4' id="inActiveLoveIcon" src={rocket} />
                                                <span className='ms-2'>2,245</span>
                                                <img className='sms-ft-img ms-4' src={comment} />
                                                <span className='ms-2'>45</span>
                                            </div>
                                            <div className='foot-marck d-flex align-items-center'>
                                                <img className='sms-ft-img ms-4' src={share} />
                                                <img className='sms-ft-img ms-4' src={mark} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered
            >
                <div className='hyep-head'>
                    <span>Hypes</span>
                    <div className='d-flex'>
                        <img className='pst-ft-img ms-1' src={rocket} />
                        <p className='ms-2 m-0'>2,245</p>
                    </div>
                    <div onClick={() => setModalShow(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />
                <div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human1} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <img className='hype-img-2' src={followe} />
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human5} />
                            <p className='m-0 ps-3'>Jessy Nolan</p>
                        </div>
                        <img className='hype-img-2' src={followe} />
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human3} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <img className='hype-img-2' src={followe} />
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human4} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <img className='hype-img-2' src={following} />
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={post2} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <img className='hype-img-2' src={followe} />
                    </div>
                </div>
            </Modal>

            <Modal
                show={commentShow} onHide={() => setCommentShow(false)}
                centered
            >
                <div className='hyep-head'>
                    <span>Comments</span>
                    <div className='d-flex'>
                        <img className='pst-ft-img ms-1' src={comment} />
                        <p className='ms-2 m-0'>45</p>
                    </div>
                    <div onClick={() => setCommentShow(false)}>
                        <i className="bi bi-x-lg pointer"></i>
                    </div>
                </div>
                <hr />
                <div>
                    <div className='commet-data'>
                        <div className='d-flex'>
                            <img src={human5} className='comment-img' />
                            <div className='comment-font pt-1'>
                                <p>Alisa Williams</p>
                                <p>Amazing Photo !!! I love it..</p>
                            </div>
                        </div>
                        <div className='comment-rpy d-flex'>
                            <div className='comment-hyep'>
                                <img className='pst-ft-img ms-1' src={rocket} />
                                <span className='ms-2'>2,245</span>
                            </div>
                            <p className='comment-reply'>Reply</p>
                        </div>
                    </div>
                    <div className='commet-data ps-5 ms-5'>
                        <div className='d-flex'>
                            <img src={post2} className='comment-img' />
                            <div className='comment-font pt-1'>
                                <p>Alisa Williams</p>
                                <p>Yeah me too.</p>
                            </div>
                        </div>
                        <div className='comment-rpy d-flex'>
                            <div className='comment-hyep'>
                                <img className='pst-ft-img' src={rocket} />
                                <span className='ms-2'>2,245</span>
                            </div>
                            <p className='comment-reply'>Reply</p>
                        </div>
                    </div>
                </div>
                <p className='comment-view'>View more reply</p>
                <div>
                    <div className='commet-data'>
                        <div className='d-flex'>
                            <img src={human5} className='comment-img' />
                            <div className='comment-font pt-1'>
                                <p>Alisa Williams</p>
                                <p>Amazing Photo !!! I love it..</p>
                            </div>
                        </div>
                        <div className='comment-rpy d-flex'>
                            <div className='comment-hyep'>
                                <img className='pst-ft-img ms-1' src={rocket} />
                                <span className='ms-2'>2,245</span>
                            </div>
                            <p className='comment-reply'>Reply</p>
                        </div>
                    </div>
                    <div className='commet-data ps-5 ms-5'>
                        <div className='d-flex'>
                            <img src={post2} className='comment-img' />
                            <div className='comment-font pt-1'>
                                <p>Alisa Williams</p>
                                <p>Yeah me too.</p>
                            </div>
                        </div>
                        <div className='comment-rpy d-flex'>
                            <div className='comment-hyep'>
                                <img className='pst-ft-img' src={rocket} />
                                <span className='ms-2'>2,245</span>
                            </div>
                            <p className='comment-reply'>Reply</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex ps-4 pe-5 mt-4'>
                    <div className='one-bottom d-flex'>
                        <div className='one-pluse ps-3 pe-3'>
                            <i className="bi bi-plus-lg"></i>
                        </div>
                        <input type='text' className='one-text' placeholder='Add Comment...' />
                    </div>
                    <div className='one-sebd ms-3'>
                        <img src={send} className='send-btn' />
                    </div>
                </div>
            </Modal>

            <Modal
                show={Menu} onHide={() => setMenu(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Report</p>
                        <img src={danger} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Unfollow</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.2498 15.8333H12.9165" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.1248 9.05835C10.0415 9.05002 9.9415 9.05002 9.84984 9.05835C7.8665 8.99169 6.2915 7.36669 6.2915 5.36669C6.2915 3.32502 7.9415 1.66669 9.9915 1.66669C12.0332 1.66669 13.6915 3.32502 13.6915 5.36669C13.6832 7.36669 12.1082 8.99169 10.1248 9.05835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.9917 18.175C8.47503 18.175 6.9667 17.7917 5.8167 17.025C3.80003 15.675 3.80003 13.475 5.8167 12.1333C8.10837 10.6 11.8667 10.6 14.1584 12.1333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setMenu(false)}>Cancel</p>
                </div>
            </Modal>

        </>
    )
}

export default Oneg8Post

import React, { useRef, useState, useEffect, createRef } from "react";
import { Reels } from '@sayings/react-reels'
import '@sayings/react-reels/dist/index.css'
import { BASE_URL, decryptData, encryptData, IMG_URL } from "../API/ApiUrl";



export default function () { 
    const [reelMetaInfo, setReelMetaInfo] = useState("");


    


    
function onDislikeClicked(params) {
    
}
function onCommentClicked(params) {
    
}
function onShareClicked(params) {
    
}
function onAvatarClicked(params) {
    
}
function onMenuItemClicked(params) {
    
}
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", localStorage.getItem("UserToken"));
const reels = [{
    id: 1,
    reelInfo: {
        url: "https://www.taxmann.com/emailer/images/IBC.mp4",
        type: "string",
        description: "string",
        postedBy: {
            avatar: "string",
            name: "sadas",
        },
        likes: {
            count: 1,
        },
        dislikes: {
            count: 2,
        },
        comments: {
            count: 3,
        },
        shares: {
            count: 4,
        },
    },
    rightMenu: {
        options: [
            {
                id: "1",
                label: "dsfsdf",
                value: '3',
            }
        ]
    },
    bottomSection: {
        component: "<div>div content</div>",
    },
},{
    id: 1,
    reelInfo: {
        url: "https://www.taxmann.com/emailer/images/IBC.mp4",
        type: "string",
        description: "string",
        postedBy: {
            avatar: "string",
            name: "sadas",
        },
        likes: {
            count: 1,
        },
        dislikes: {
            count: 2,
        },
        comments: {
            count: 3,
        },
        shares: {
            count: 4,
        },
    },
    rightMenu: {
        options: [
            {
                id: "1",
                label: "dsfsdf",
                value: '3',
            }
        ]
    },
    bottomSection: {
        component: "<div>div content</div>",
    },
},{
    id: 1,
    reelInfo: {
        url: "https://www.taxmann.com/emailer/images/IBC.mp4",
        type: "string",
        description: "string",
        postedBy: {
            avatar: "string",
            name: "sadas",
        },
        likes: {
            count: 1,
        },
        dislikes: {
            count: 2,
        },
        comments: {
            count: 3,
        },
        shares: {
            count: 4,
        },
    },
    rightMenu: {
        options: [
            {
                id: "1",
                label: "dsfsdf",
                value: '3',
            }
        ]
    },
    bottomSection: {
        component: "<div>div content</div>",
    },
},{
    id: 1,
    reelInfo: {
        url: "https://www.taxmann.com/emailer/images/IBC.mp4",
        type: "string",
        description: "string",
        postedBy: {
            avatar: "string",
            name: "sadas",
        },
        likes: {
            count: 1,
        },
        dislikes: {
            count: 2,
        },
        comments: {
            count: 3,
        },
        shares: {
            count: 4,
        },
    },
    rightMenu: {
        options: [
            {
                id: "1",
                label: "dsfsdf",
                value: '3',
            }
        ]
    },
    bottomSection: {
        component: "<div>div content</div>",
    },
}];

  return (
    <div>
         {reels && (
         <Reels
      reels={reels}
      reelMetaInfo={reelMetaInfo}
      onMenuItemClicked={(event) => {
          console.log(event.value) 
          // other actions
      }}
      onLikeClicked={(reel) => {
          console.log(reel) // current Reel Data
          // other actions
      }}
      onDislikeClicked={(reel) => console.log(reel)}
      onCommentClicked={(reel) => console.log(reel)}
      onShareClicked={(reel) => console.log(reel)}
      onAvatarClicked={(reel) => console.log(reel)}
    />
         )}
      
    </div>

  )
}




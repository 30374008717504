import React, { useState } from 'react'
import "./NewCalender.css"
import bar from "../assets/img/Sidebar/menu (1).png"
import Sidebar from '../Sidebar/Sidebar'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Col, Container, Row } from 'react-bootstrap';
import cal from "../assets/img/Sidebar/cal-img.png"
import bluetik from "../assets/img/Sidebar/bluetik.png"
import ractangle from "../assets/img/Sidebar/Rectangle 23.png"
import p1 from "../assets/img/Sidebar/s-1.png"
import p2 from "../assets/img/Sidebar/s-3.png"
import p4 from "../assets/img/Sidebar/s-5.png"
import Modal from 'react-bootstrap/Modal';
import edit1 from "../assets/img/Sidebar/edit-2.png"
import trash from "../assets/img/Sidebar/trash.png"
import human1 from "../assets/img/Sidebar/s-1.png"
import human3 from "../assets/img/Sidebar/s-3.png"
import human4 from "../assets/img/Sidebar/s-4.png"
import human5 from "../assets/img/Sidebar/s-5.png"
import post2 from "../assets/img/Sidebar/post2.png"

function Calender() {
    const [value, onChange] = useState(new Date());
    const [view, setView] = useState(false)
    const [remove, setRemove] = useState(false)
    const [see, setSee] = useState(false)

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} width={50} alt="" />
            <Sidebar />

            <div className='sub-profile'>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className="Sample">
                                <Calendar onChange={onChange} showWeekNumbers value={value} />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='calender-right'>
                                <p className='calender-head'>11 September, 2023</p>

                                <div className='calender-plate'>
                                    <div className="date-head">
                                        <div className='date-data' style={{ cursor: "pointer" }} onClick={() => setView(true)}>
                                            <img src={cal} className='date-img' />
                                            <div className='date-text'>
                                                <p>Woman Planet</p>
                                                <p>Event</p>
                                            </div>
                                            <img src={bluetik} className='bluetick' />
                                        </div>
                                        <div style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => setRemove(true)}>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <p className=' m-0'>Fantastic Friday!</p>
                                    <div className='d-flex calender-text'>
                                        <p>08 September 2023</p>
                                        <p className='ms-4'>09:00 AM - 05:00 PM</p>
                                    </div>
                                    <span className='calender-hr'>
                                        <hr />
                                    </span>
                                    <div className='d-flex calender-bottom'>
                                        <div>
                                            <img src={p1} width={35} className='' />
                                            <img src={p4} width={35} className='calender-photo' />
                                            <img src={p2} width={35} className='calender-photo' />
                                        </div>
                                        <p className='ps-3'> +3 More Friends</p>
                                        <p className='ps-4' onClick={() => setSee(true)} style={{ color: "#167EFF" }}>See All</p>
                                    </div>
                                </div>
                                <div className='calender-plate'>
                                    <div className="date-head">
                                        <div className='date-data'>
                                            <img src={cal} className='date-img' />
                                            <div className='date-text'>
                                                <p>Woman Planet</p>
                                                <p>Event</p>
                                            </div>
                                            <img src={bluetik} className='bluetick' />
                                        </div>
                                        <div style={{ fontSize: "20px" }}>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <p className=' m-0'>Fantastic Friday!</p>
                                    <div className='d-flex calender-text'>
                                        <p>08 September 2023</p>
                                        <p className='ms-4'>09:00 AM - 05:00 PM</p>
                                    </div>
                                    <span className='calender-hr'>
                                        <hr />
                                    </span>
                                    <div className='d-flex calender-bottom'>
                                        <div>
                                            <img src={p1} width={35} className='' />
                                            <img src={p4} width={35} className='calender-photo' />
                                            <img src={p2} width={35} className='calender-photo' />
                                        </div>
                                        <p className='ps-3'> +3 More Friends</p>
                                        <p className='ps-4' onClick={() => setSee(true)} style={{ color: "#167EFF" }}>See All</p>
                                    </div>
                                </div>
                                <div className='calender-plate'>
                                    <div className="date-head">
                                        <div className='date-data'>
                                            <img src={cal} className='date-img' />
                                            <div className='date-text'>
                                                <p>Woman Planet</p>
                                                <p>Event</p>
                                            </div>
                                            <img src={bluetik} className='bluetick' />
                                        </div>
                                        <div style={{ fontSize: "20px" }}>
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                    </div>
                                    <p className=' m-0'>Fantastic Friday!</p>
                                    <div className='d-flex calender-text'>
                                        <p>08 September 2023</p>
                                        <p className='ms-4'>09:00 AM - 05:00 PM</p>
                                    </div>
                                    <span className='calender-hr'>
                                        <hr />
                                    </span>
                                    <div className='d-flex calender-bottom'>
                                        <div>
                                            <img src={p1} width={35} className='' />
                                            <img src={p4} width={35} className='calender-photo' />
                                            <img src={p2} width={35} className='calender-photo' />
                                        </div>
                                        <p className='ps-3'> +3 More Friends</p>
                                        <p className='ps-4' onClick={() => setSee(true)} style={{ color: "#167EFF" }}>See All</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                show={view} onHide={() => setView(false)}
                centered
                contentClassName='calender-popup'
            >
                <div className='date-modal-1'>
                    <div className="date-head">
                        <div className='date-data'>
                            <img src={cal} className='date-img' />
                            <div className='date-text'>
                                <p>Woman Planet</p>
                                <p>Event</p>
                            </div>
                            <img src={bluetik} className='bluetick' />
                        </div>
                        <div style={{ fontSize: "20px" }}>
                            <i className="bi bi-three-dots"></i>
                        </div>
                    </div>
                    <img src={ractangle} className='ractangle-img' />
                    <div className='date-center'>
                        <p>08 September 2023</p>
                        <p className='ms-4'>09:00 AM - 05:00 PM</p>
                    </div>
                    <p className='date-center-1'>Fantastic Friday!</p>
                    <p className='date-footer'>
                        Join us for 'Fantastic Friday,' the ultimate shopping extravaganza for fashion enthusiasts! Explore a wide array of stylish clothing options, from trendy streetwear to elegant formal attire, all under one roof. Discover the latest fashion trends, exclusive discounts, and an unforgettable shopping experience. Get ready to revamp your wardrobe and make your Friday truly fantastic!
                    </p>
                </div>

            </Modal>

            <Modal
                show={remove} onHide={() => setRemove(false)}
                centered
            >
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Edit</p>
                        <img src={edit1} />
                    </div>
                </div>
                <div className='data-pro-modal'>
                    <div className='pro-danger' >
                        <p>Remove From calendar</p>
                        <img src={trash} />
                    </div>
                </div>
                <div className='pro-modal-bottom text-center'>
                    <p onClick={() => setRemove(false)}>Cancel</p>
                </div>
            </Modal>

            <Modal
                show={see} onHide={() => setSee(false)}
                centered
            >
                <div className='hyep-head'>
                    <span>Invied Friends</span>
                </div>
                <hr />
                <div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human1} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <p>invited</p>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human5} />
                            <p className='m-0 ps-3'>Jessy Nolan</p>
                        </div>
                        <p>invited</p>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human3} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <p>invited</p>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={human4} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <p>invited</p>
                    </div>
                    <div className='hype-follow'>
                        <div className='d-flex hyep-data' style={{ alignItems: "center" }}>
                            <img className='hype-img-1' src={post2} />
                            <p className='m-0 ps-3'>Alisa Williams</p>
                        </div>
                        <p>invited</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Calender

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import "../DataG8/DataG8.css";
import {
  useLazyGetTrashDataQuery,
  useBackupDeletedFileMutation,
  usePermanentDeleteFileMutation,
} from "../apiService/index";
import { getDriveTokenData, getSkipGeneratePassword } from "../util/cacheData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// import DataG8Sidebar from '../DataG8/DataG8Sidebar'

export default function DataG8Home() {
  const customData = useSelector((state) => state.customData);

  const [restoreModel, setResoreModel] = useState(false);
  const [detailsModel, setDetailsModel] = useState(false);
  const [deleteModel, setDeletModel] = useState(false);
  const [trashData, setTrashData] = useState([]);
  const [restoreData, setRestoreData] = useState({ index: "", id: "" });
  const [permanentDelete, setPermanentDelete] = useState({ index: "", id: "" });

  const [getTrashData, trashDataResult] = useLazyGetTrashDataQuery();
  const [permanentDeleteFile] = usePermanentDeleteFileMutation();
  const [backupDeletedFile] = useBackupDeletedFileMutation();
  const media_url = "https://onespace.fra1.cdn.digitaloceanspaces.com";

  //   console.log(trashDataResult);
  useEffect(() => {
    if (!getSkipGeneratePassword() && !getDriveTokenData()) return;

    getTrashData()
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          setTrashData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err?.error?.data?.message);
      });
  }, [customData?.drivePasswordIsExist]);

  const restoreFile = () => {
    console.log(restoreData);
    backupDeletedFile({ backupFileId: restoreData.id })
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        setResoreModel(false);
        let deletedList = JSON.parse(JSON.stringify(trashData));
        deletedList.splice(restoreData.index, 1);
        setTrashData(deletedList);
      })
      .catch((err) => {
        toast.error(err?.error?.data?.message);
      });
  };

  const handlePermanetDelete = () => {
    permanentDeleteFile({ id: permanentDelete.id })
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        setDeletModel(false);
        let deletedList = JSON.parse(JSON.stringify(permanentDelete));
        deletedList.splice(permanentDelete.index, 1);
        setTrashData(deletedList);
      })
      .catch((err) => {
        toast.error(err?.error?.data?.message);
      });
  };

  const FileUi = ({ item, ind }) => {
    return (
      <>
        <div className="col-md-3 pt-3">
          <div
            className="cardForDrive cardColor"
            style={{ border: "none !important" }}
          >
            {
              item?.fileType === 3 ? (
                <div
                  className="w-100 h-100"
                  style={{
                    backgroundImage: `url(${media_url}/${item.previewUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              ) : item?.fileType === 6 ? (
                <i className="far fa-file-pdf fa-4x"></i>
              ) : item?.fileType === 4 ? (
                <i className="far fa-file-audio fa-4x"></i>
              ) : // This block is executed when none of the above conditions match
              null // You can replace this with a default component or null as per your requirement
            }
            <div className="card-body py-2">
              <div className="row">
                <div className=" col-10 my-auto">
                  <h6 className="card-subtitle">{item.name} </h6>
                </div>
                <div className="col-2">
                  <Dropdown id="dropdown-data-option">
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <div className="option-menudatag8">
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDetailsModel(true);
                          }}
                        >
                          <i className="fa-solid fa-circle-info px-2"></i>
                          Details
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setResoreModel(true);
                            setRestoreData({ index: ind, id: item.id });
                          }}
                        >
                          <i className="fa-solid fa-trash-can-arrow-up px-2"></i>
                          Restore from trash
                        </div>
                        <div
                          className="menu-message d-flex align-items-center py-1"
                          onClick={() => {
                            setDeletModel(true);
                            setPermanentDelete({ index: ind, id: item.id });
                          }}
                        >
                          <i className="fa-solid fa-trash px-2"></i>
                          Delete permanently
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="row" style={{ height: "100vh", overflow: "scroll" }}>
        {/* <div className="col-2">
                     <DataG8Sidebar></DataG8Sidebar>
                 </div> */}
        <div className="col-12">
          <div className="container mx-auto mt-4">
              <div className="datag8-row1 mt-3 p-3 text-center">
                        <span className="text-white">Trash</span>
              </div>
            <div className="row">
              {trashData.map((item, index) => (
                <FileUi key={index} item={item} ind={index} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* move folder model */}
      <Modal show={restoreModel} onHide={() => setResoreModel(false)} centered>
        <div className="activity-disck pt-4 text-center">
          <div className="lgn-input-1">
            <h3>Are you want to restore this file.</h3>
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <span className="btn btn-success" onClick={restoreFile}>
            Restore
          </span>
        </div>
      </Modal>
      {/* end */}

      {/* delete folder model */}
      <Modal show={deleteModel} onHide={() => setDeletModel(false)} centered>
        <div className="activity-disck pt-4 text-center">
          <div className="lgn-input-1">
            <h3>Are you sure you want to delete the file permanently?</h3>
          </div>
        </div>
        <div className="pro-modal-bottom text-center pt-3">
          <span className="btn btn-danger" onClick={handlePermanetDelete}>
            Delete
          </span>
        </div>
      </Modal>
      {/* end */}
    </div>
  );
}

import React, { useState } from 'react'
import "./TermsConditions.css"
import bar from "../assets/img/Sidebar/menu (1).png"
import Sidebar from '../Sidebar/Sidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import back from "../assets/img/Sidebar/back-arrow.png"



function TermsConditions() {
    const navigate = useNavigate();
    const location = useLocation();

    const hanldeSidebar = () => {
        document.getElementById('functionSidebar').click()
    }

    return (
        <>
            <img src={bar} onClick={hanldeSidebar} width={50} alt="" />
            <Sidebar />

            <div className='sub-profile'>
                <div className='col-12'>
                    <div className='row'>
                    <div className='col-1 mx-4 text-start'>
                            <Link className={location.pathname === "/setting" ? 'actives' : 'sidedata' } to={"/setting"}  >
                                <img className="img-20 pointer" src={back} width={35} />
                            </Link>
                        </div>
                    </div>
                </div>

                        <div className="">
                          <p className='text-center activ pb-3'>Terms & Conditions</p>

                          <p class="px-5">These terms and conditions (“Agreement”) sets forth the general terms and conditions of your use of the ONEG8.ONE, Platform or the connected Ecosystem (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and ONEG8.ONE the Platform or the Ecosystem (“ONEG8”, “we”, “us” or “our”). </p>

                            <p class="px-5">By accessing and using the Platform, Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of yourself a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms “User”, “you” or “„your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Platform, Website and Services. </p>

                            <p class="px-5">You acknowledge that this Agreement is a contract between you and ONEG8.ONE Platform and Ecosystem even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services. The Platform and the Ecosystem integrated.</p>

                            <p class="px-5 p-big">Accounts and membership</p>

                            <p class="px-5">You must be at least 16 years of age to use the Platform, Website and Services. By using the Website and Services and by agreeing to this Agreement you warrant and represent that you are at least 16 years of age. If you joint the Environment of the Platform or the Ecosystem for Educational Purpose, then you are invited by your Institution and will have limited access to the service and offers on the Platform. For more information, please contact us on contact@ONEG8.ONE </p>

                            <p class="px-5">If you create an account on the Platform or the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. </p>

                            <p class="px-5">We may, but have no obligation to, monitor and review new accounts registrations before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. </p>

                            <p class="px-5">We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. </p>

                            <p class="px-5">If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration. Any paid fees will not be reimbursed and used for the payment of the additional work we have in such case. </p>

                            <p class="px-5">All your Data will be deleted after the legally requested time to hold your Information available. </p>

                            <p class="px-5">If you run a NFT project or any kind of fundraising on the platform, you are responsible to have all legal requirements in place to do so. After the maximum period of 1 year, we can delete your account and block it from further using it for fundraising or any other business activities. Correspondence and legal documentation between you and your clients and community is your sole responsibility. We will request the presentation of all legal documents including a legal opinion about your project of an international operative law firm.</p>

                            <p class="px-5 p-big">User content</p>

                            <p class="px-5">We do not own any data, information, or material (collectively, “Content”) that you submit on the Website, Platform, or the Ecosystem in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. </p>

                            <p class="px-5">We may, but have no obligation to, monitor and review the Content on the Website submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display, and perform the Content of your user account solely as required for the purpose of providing the Services to you. </p>

                            <p class="px-5">Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. In no way we are accepting content displaying drug consumption, alcohol consumption, pornography in any form, violence in verbal or physical form and or advertising of them. In case you violate these rules, we are immediately blocking your account for a period or delete it completely. Please treat all users with respect even you don’t like their opinion about a certain theme or issue.</p>

                            <p class="px-5">Unless specifically permitted by you, your use of the Website, Platform or Ecosystem and Services does not grant us the license to use, reproduce, adapt, modify, publish, or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. Nevertheless, all your content publicly posted can be shared and distributed by other community members without your explicit permission. </p>

                            <p class="px-5 p-big">Billing and payments</p>

                            <p class="px-5">You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If payment for the use of the account and services are not made in due time, we will close the account until these payments are taken care off and after a period of 90 Days of payment delay the account will be completely deleted and no back up will be held. All data’s which are not legally requested to keep in store will be lost forever. You can then register a new account and use our free service. </p>

                            <p class="px-5">If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase and the proof of Address. If you sell products on the Platform we are as well asking for the information of your clients and KYC if in our judgement, we think that it will be necessary to fulfill legal compliance. </p>

                            <p class="px-5">We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us or operate any account as per our sole discretion without naming reason and nature of process involved. </p>

                            <p class="px-5">We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. </p>

                            <p class="px-5">In the event that we make a change to or cancel an order or an agreement on our sole discretion, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. </p>

                            <p class="px-5 p-big">Accuracy of information</p>

                            <p class="px-5">Occasionally there may be information on the Website, Platform or Ecosystem that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information on the Website, Platform, Ecosystem, or Services is inaccurate at any time without prior notice (including after you have submitted your order). </p>

                            <p class="px-5">We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website, Platform or Ecosystem should be taken to indicate that all information on the Website, Platform, Ecosystem or Services has been modified or updated.</p>

                            <p class="px-5 p-big">Third party services</p>

                            <p class="px-5">If you decide to enable, access or use third party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. </p>

                            <p class="px-5">You irrevocably waive any claim against ONEG8.ONE with respect to such other services. </p>

                            <p class="px-5">ONEG8.ONE is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access, or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. </p>

                            <p class="px-5">You may be required to register for or log into such other services on their respective platforms. By enabling any other services, you are expressly permitting ONEG8.ONE to disclose your data as necessary to facilitate the use or enablement of such other service.</p>

                            <p class="px-5 p-big">Backups</p>

                            <p class="px-5">We are not responsible for the Content residing on the Website, Platform or Ecosystem. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. </p>

                            <p class="px-5">We make no guarantee that the data you need will be available. In case you have issued tokens on our Blockchain we are not holding your data available, and it is your sole discretion to keep the books between you and your community. As we are in the process of moving to the new Blockchain based on ETH- EVM compatible no old EOS Blockchain data and blockchain information are kept in backup which are older than 30. March 2o24. Tokens which are not listed on an external exchange have no financial values at all.</p>

                            <p class="px-5">It is your sole responsibility to keep this information to fulfil legal requirements and copies. You can write us and ask for help under Contact@ONEG8.ONE. These services will be subject to cost and fees which must be paid upfront to 100%. Still, we do not and cannot guarantee the availability of your needed data. </p>

                            <p class="px-5 p-big">Advertisements</p>

                            <p class="px-5">During your use of the Website, Platform or Ecosystem and Services, you may enter into correspondence with or participate in promotions of advertisers or sponsors showing their goods or services through the Website, Platform or Ecosystem and Services. </p>

                            <p class="px-5">Any such activity, and any terms, conditions, warranties, or representations associated with such activity, is solely between you and the applicable third party. We shall have no liability, obligation, or responsibility for any such correspondence, purchase, or promotion between you and any such third party.</p>

                            <p class="px-5 p-big">Links to other resources</p>

                            <p class="px-5">Although the Website, Platform or Ecosystem and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly, or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. </p>

                            <p class="px-5">Some of the links on the Website may be “affiliate links”. This means if you click on the link and purchase an item, ONEG8.ONE the Platform or the Ecosystem will may receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. </p>

                            <p class="px-5">We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website, Platform or Ecosystem and Services. Your linking to any other off-site resources is at your own risk.</p>

                            <p class="px-5 p-big">Changes and amendments</p>

                            <p class="px-5">We reserve the right to modify this Agreement or its terms relating to the Website, Platform or Ecosystem and Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. </p>

                            <p class="px-5">Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</p>

                            <p class="px-5 p-big">Acceptance of these terms</p>

                            <p class="px-5">You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website, Platform or Ecosystem and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website, Platform or Ecosystem and Services.</p>

                            <p class="px-5 p-big">Contacting us</p>

                            <p class="px-5">If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may do so via the Contact@ONEG8.ONE</p>

                            <p class="px-5">During the Testing Phase and until the final registration in Switzerland and Dubai, all data and service are provided by our European corporation. </p>

                            <p class="px-5 p-big">ONEG8 ONE Europe KFT.</p>

                            <p class="px-5">9400 Sopron – Hungary – Corp Nr: 2395 7752 -1- 08 – Reg Nr: 08-09- 023 669</p>

                            <p class="px-5">This document was last updated on 18.03.2024.</p>

                            <p class="px-5 py-5"></p>
                        </div>


            </div>

        </>
    )
}

export default TermsConditions
